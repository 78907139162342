import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
const json = {
	1: {
		unit: 'Unit 1',
		id: 'FG11-U1-P18-E1',
		audio: '',
		video: '',
		// recorder: true,
		component: UI,
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style='color:rgb(1 145 67)'>SPEAKING</span>
        Do you sometimes argue with
        your parents? What do you argue about,
        and why? Use the ideas below to help you.`,
				color: '#92278f',
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page18/E1/1.jpg' }]],
	},
	2: {
		unit: 'Unit 1',
		id: 'FG11-U1-P18-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page18/E2/Key/answerKey.png',
		component: Circle_Write,

		titleQuestion: [
			{
				num: '2',
				title: 'Read the text quickly, ignoring the gaps. Choose the best summary of the text: a, b or c.',
				color: '#8e258c',
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
				selectWordStyle: { padding: 5, border: 'solid 3px', borderColor: 'gray' },
				limitSelect: 1,
				listWords: [
					`
          a|&emsp;Parents&ensp;may&ensp;find&ensp;it&ensp;difficult&ensp;to&ensp;understand&ensp;their&ensp;adolescent&ensp;children,&ensp;but&ensp;it&ensp;is&ensp;a&ensp;parent’s&ensp;duty&ensp;to&ensp;communicate&ensp;properly&ensp;with&ensp;them&ensp;and&ensp;avoid&ensp;arguments.
         <br> b|&emsp;Although&ensp;teenagers&ensp;develop&ensp;new&ensp;ideas,&ensp;values&ensp;and&ensp;beliefs&ensp;during&ensp;adolescence,&ensp;that&ensp;is&ensp;no&ensp;excuse&ensp;for&ensp;bad&ensp;behaviour.&ensp;They&ensp;should&ensp;listen&ensp;to&ensp;their&ensp;parents.
         <br> c|&emsp;Teenagers&ensp;experience&ensp;big&ensp;physical&ensp;and&ensp;emotional&ensp;changes&ensp;during&ensp;adolescence.&ensp;It’s&ensp;important&ensp;to&ensp;communicate&ensp;with&ensp;your&ensp;parents&ensp;and&ensp;try&ensp;to&ensp;understand&ensp;their&ensp;point&ensp;of&ensp;view.
         `, //0
				],
				answers: ['0-70'],
				initialValue: [],
			},
			Layout: `
            <div style='width:20cm;margin:10px 10px'>
                <input id='0' type='Circle' /> 
            </div>
          <img src='img/FriendsPlus/Page18/E2/1.jpg' style='width:100%' />  
      `,
		},
	},
}

export default json
