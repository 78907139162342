import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P16-E1',
		audio: 'Audios/1.12-audio.mp3',
		video: '',
		component: T6,
		isHiddenCheck: true,
		inputSize: 125,
		// maxLength: 1,
		// //hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page16/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: `
				<span style='margin-right: 10px;'><headphone1 name="1.12" src="Audios/1.12-tieude.mp3"></headphone1></span>
				Read and listen to the dialogue between a teenager 
				and his grandfather. 
				Which adjective best sums up the grandfather’s attitude: miserable or nostalgic?
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="margin-top: 20px;"><span style="font-weight: bold; font-style: italic;">Answer: </span>#</div>
        
				<div style="margin-top: 20px; display: flex; flex-wrap: wrap; gap: 20px;">
				<div style="flex: 1;"><img src='img/FriendsPlus/Page16/E1/1.jpg' width='100%';/></div>
				<div style="flex: 2; margin-top: 20px; display: flex; flex-direction: column; gap: 10px;">
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Grandad</div>
						<div>Have you seen this photo of me when I was your age?</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">James</div>
						<div>No, I haven’t. Let me see. Wow! You used to have great hair!</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Grandad</div>
						<div>I know. I used to spend ages getting it just right. It’s much quicker now.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">James</div>
						<div>Your clothes look cool too. Did you use to spend a lot of money on them?</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Grandad</div>
						<div>I didn’t use to have much money. My mother made some of them. And I used to share clothes with my brother.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">James</div>
						<div>I used to do that too. But he doesn’t let me borrow them now!</div>
					</div>
				</div>
			</div>
        `,
				answer: ['nostalgic'],
			},
		],
	},
	2: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P16-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page16/E2/Key/answerKey.png',
		component: Circle_Write,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '2',
				title: `
				Read the <span style="color: rgb(246, 141, 31);">Learn this!</span> box. 
				Underline an affirmative, a negative and an interrogative example of <i>used to</i> in the dialogue in exercise 1.
				`,
				color: '#92278f',
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: { border: 'none', borderColor: 'transparent' },
				// initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
				selectWordStyle: { padding: 5, borderBottom: '2px solid', borderColor: 'gray' },
				limitSelect: 1,
				listWords: [
					`No,_I_haven’t. Let_me_see. Wow! You_used_to_have_great_hair!`, // 0
					`I_know. I_used_to_spend_ages_getting_it_just_right. It’s_much_quicker_now.`, // 1
					`Your_clothes_look_cool_too. Did_you_use_to_spend_a_lot_of_money_on_them?`, // 2
					`I_didn’t_use_to_have_much_money.`, // 3
					`My_mother_made_some_of_them. And_I_used_to_share_clothes_with_my_brother.`, // 4
					`I_used_to_do_that_too. But_he_doesn’t_let_me_borrow_them_now!`, // 5
				],
				answers: ['0-6', '1-2', '2-2', '3-0', '4-2', '5-0'],
				initialValue: [],
			},
			Layout: `
			<div><img src='img/FriendsPlus/Page16/E2/1.jpg' width='50%';/></div>
			<div style="margin-top: 20px; display: flex; flex-wrap: wrap; gap: 20px;">
				<div style="flex: 1;"><img src='img/FriendsPlus/Page16/E1/1.jpg' width='100%';/></div>
				<div style="flex: 2; margin-top: 20px; display: flex; flex-direction: column; gap: 10px;">
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Grandad</div>
						<div>Have you seen this photo of me when I was your age?</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">James</div>
						<div><input id='0' type='Circle' /></div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Grandad</div>
						<div><input id='1' type='Circle' /></div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">James</div>
						<div><input id='2' type='Circle' /></div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Grandad</div>
						<div><input id='3' type='Circle' /> <input id='4' type='Circle' /></div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">James</div>
						<div><input id='5' type='Circle' /></div>
					</div>
				</div>
			</div>
      `,
		},
	},
	3: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P16-E3',
		audio: '',
		video: '',
		component: T6,
		textAlign: 'center',
		isHiddenCheck: true,
		inputSize: 220,
		maxLength: 19,
		// //hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page16/E3/Key/answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: `
				Complete the dialogue with the correct form of <i>used to</i> and the verbs below.
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="max-width: 900px; color: rgb(246, 141, 31); font-weight: bold; display: flex; gap: 5px 30px; flex-wrap: wrap;">
					<span>be</span><span>do</span><span>go</span>
					<span>live</span><span>not have</span><span>not pay</span><span>wait</span>
				</div>

				<div style="flex: 2; margin-top: 20px; display: flex; flex-direction: column; gap: 10px;">
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Mum</div>
						<div>I <sup>1</sup># opposite the Palace Cinema when I was ten.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Alice</div>
						<div><sup>2</sup># you # there often?</div>

					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Mum</div>
						<div>Yes. But we <sup>3</sup># . It wasn’t our fault – we <sup>4</sup># any money for tickets.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Alice</div>
						<div>So how did you get in?</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Mum</div>
						<div>We <sup>5</sup># outside the fire exit and run in when somebody opened it!</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Alice</div>
						<div>I can’t believe you <sup>6</sup># that! You’re always telling me how important it is to be honest!</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Mum</div>
						<div>Well, yes. I <sup>7</sup># very naughty, but I grew out of it.</div>

					</div>
				</div>
        `,
				answer: [
					'used to live',
					'Did',
					'use to go',
					`didn't use to pay`,
					`didn't use to have`,
					'used to wait',
					'used to do',
					'used to be',
				],
			},
		],
	},
	4: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P16-E4',
		audio: 'Audios/1.13-audio.mp3',
		video: '',
		component: T6,
		textAlign: 'center',
		isHiddenCheck: true,
		inputSize: 220,
		maxLength: 19,
		// //hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page16/E4/Key/answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `
				<span style='margin-right: 10px;'><headphone1 name="1.13" src="Audios/1.13-tieude.mp3"></headphone1></span>
				<span style="margin-right: 10px; color: rgb(0, 103, 179)">PRONUNCIATION</span>
				Listen and check your answers to exercise 3. How is <i>used to</i> pronounced?
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="max-width: 900px; color: rgb(246, 141, 31); font-weight: bold; display: flex; gap: 5px 30px; flex-wrap: wrap;">
					<span>be</span><span>do</span><span>go</span>
					<span>live</span><span>not have</span><span>not pay</span><span>wait</span>
				</div>

				<div style="flex: 2; margin-top: 20px; display: flex; flex-direction: column; gap: 10px;">
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Mum</div>
						<div>I <sup>1</sup># opposite the Palace Cinema when I was ten.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Alice</div>
						<div><sup>2</sup># you # there often?</div>

					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Mum</div>
						<div>Yes. But we <sup>3</sup># . It wasn’t our fault – we <sup>4</sup># any money for tickets.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Alice</div>
						<div>So how did you get in?</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Mum</div>
						<div>We <sup>5</sup># outside the fire exit and run in when somebody opened it!</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Alice</div>
						<div>I can’t believe you <sup>6</sup># that! You’re always telling me how important it is to be honest!</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 100px; font-weight: bold; margin-right: 10px;">Mum</div>
						<div>Well, yes. I <sup>7</sup># very naughty, but I grew out of it.</div>
					</div>
				</div>
		
        `,
				answer: [
					'used to live',
					'Did',
					'use to go',
					`didn't use to pay`,
					`didn't use to have`,
					'used to wait',
					'used to do',
					'used to be',
				],
			},
		],
	},
	5: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P16-E5',
		audio: '',
		video: '',
		component: T6,
		textAlign: 'center',
		isHiddenCheck: true,
		inputSize: 220,
		maxLength: 22,
		// //hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page16/E5/Key/answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `
				Complete these sentences with the correct form of <i>used to / didn’t use to</i> and the verbs in brackets.
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<div>She # (be) a student, but she finished her university course in June.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<div>She # (eat) meat, but now she has chicken sometimes.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<div>I # (like) cats, but I prefer dogs now.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">4</span>
						<div>I # (enjoy) TV, but now I find most of the programmes boring or annoying.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">5</span>
						<div>He # (speak) a foreign language, but he started going to Spanish lessons last year.</div>
					</div>
				</div>	
        `,
				answer: ['used to be', `didn't use to eat`, 'used to like', 'used to enjoy', `didn't use to speak`],
			},
		],
	},
	6: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P16-E6',
		audio: 'Audios/1.14-audio.mp3',
		video: '',
		component: T6,
		// textAlign: 'center',
		isHiddenCheck: true,
		inputSize: 140,
		maxLength: 13,
		// //hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page16/E6/Key/answerKey.png',
		titleQuestion: [
			{
				num: '6',
				title: `
				<span style='margin-right: 10px;'><headphone1 name="1.14" src="Audios/1.14-tieude.mp3"></headphone1></span>
				Read the <span style="color: rgb(246, 141, 31);">Look out!</span> box. 
				Listen and decide if the sentences contain <i>be / get used to, used to</i> or both.
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="margin-top: 20px;"><img src='img/FriendsPlus/Page16/E6/1.jpg' width='50%';/></div>
				
				<div style="margin-top: 20px; display: flex; flex-wrap: wrap; gap: 0px 120px;">
					<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
						<div style="color: rgb(10, 111, 143); display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">1</span>
							<div>be used to</div>
						</div>
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">2</span>
							<div>#</div>
						</div>
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">3</span>
							<div>#</div>
						</div>
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">4</span>
							<div>#; #</div>
						</div>
					</div>	

					<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">5</span>
							<div>#</div>
						</div>
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">6</span>
							<div>#</div>
						</div>
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">7</span>
							<div>#; #</div>
						</div>
					</div>	
				</div>
        `,
				answer: ['used to', 'get used to', 'used to', 'be used to', 'be used to', 'used to', 'used to', 'get used to'],
			},
		],
	},
	7: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P16-E7',
		audio: '',
		video: '',
		component: T6,
		// recorder: true,
		// textAlign: 'center',
		isHiddenCheck: true,
		inputSize: 600,
		// maxLength: 1,
		hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page16/E7/Key/answerKey.png',
		titleQuestion: [
			{
				num: '7',
				title: `
				<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span>
				Work in pairs. 
				Ask and answer about what you used to be like at the age of five. 
				Use the following prompts.
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<div>
							<div>Be afraid of the dark</div>
							<div style="color: rgb(10, 111, 143);">I used to be / didn’t use to be afraid of the dark.</div>
						</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<div>Listen to stories at bedtime <br /></div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<div>Watch a lot of cartoons <br /></div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">4</span>
						<div>Have piano lessons <br /></div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">5</span>
						<div>Walk to school on my own <br /></div>
					</div>
				</div>
        `,
				answer: ['used to', 'get used to', 'used to', 'be used to', 'be used to', 'used to', 'used to', 'get used to'],
			},
		],
	},
	8: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P16-E8',
		audio: '',
		video: '',
		component: T6,
		// recorder: true,
		// textAlign: 'center',
		isHiddenCheck: true,
		inputSize: 600,
		// maxLength: 1,
		hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page16/E8/Key/answerKey.png',
		titleQuestion: [
			{
				num: '8',
				title: `
				<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span>
				Find out more about your partner’s childhood. 
				Use the correct form of <i>used to</i>. What did he or she use to
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="max-width: 716px; color: rgb(246, 141, 31); display: flex; gap: 5px 30px; flex-wrap: wrap;">
					<span>have for breakfast?</span><span>watch on TV?</span><span>wear to school?</span>
					<span>enjoy playing?</span><span>do at weekends?</span><i>your ideas</i>
				</div>
        `,
				answer: [],
			},
		],
	},
}

export default json
