import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	1: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P21-E1',
		audio: '',
		video: '',
		// recorder: true,
		exerciseKey: 'img/FriendsPlus/Page21/E1/Key/answerKey.png',
		component: T6,
		inputSize: 635,
		isHiddenCheck: true,
		maxLength: 48,
		titleQuestion: [
			{
				num: '1',
				title: `
        <span style='color:rgb(1 145 67)'>SPEAKING</span> Work in pairs. What is a penfriend? Think of
        three reasons why somebody might want a penfriend in a different country.`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 525, height: 70, fontSize: 21 },
		questions: [
			{
				title: `
            <div style='position: relative;'>
                  <div style='position: absolute; top: 34px; left: 120px;'><textArea id='0' rows='2'></textArea></div>
             </div>
               <img style='width: 18cm; height: 14.5cm' src='img/FriendsPlus/Page21/E1/1.jpg'  />

        `,
				answer: [
					'to practise their English, to learn about life in other countries, to have someone visit in the future / to practise their English,to have someone visit in the future, to learn about life in other countries / to have someone visit in the future, to learn about life in other countries, to practise their English / to learn about life in other countries, to have someone visit in the future, to practise their English / to learn about life in other countries, to practise their English, to have someone visit in the future',
				],
			},
		],
	},
	2: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P21-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page21/E2/Key/answerKey.png',
		component: T6,
		inputSize: 635,
		isHiddenCheck: true,
		maxLength: 48,

		titleQuestion: [
			{
				num: '2',
				title: `
        Read the advertisement from a website for international penfriends above. What information does Adam ask for?`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 500 },
		questions: [
			{
				title: `
						  <div style='position: relative;'>
                  <div style='position: absolute; top: 40px; left: 27px;'><u style='font-style:italic'>Answer:</u></div>
                  <div style='position: absolute; top: 34px; left: 120px;'><textArea id='0' rows='2'></textArea></div>
             </div>
               <img src='img/FriendsPlus/Page21/E1/1.jpg'  />
        `,
				answer: ['information about you, your family and why you are looking for a penfriend'],
			},
		],
	},
	3: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P21-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page21/E3/Key/answerKey.png',
		component: T6,
		inputSize: 635,
		isHiddenCheck: true,
		// maxLength: 48,
		titleQuestion: [
			{
				num: '3',
				title: `
        Read the task and the message below. Does Vinh
        provide all of the information that Adam asks for? What
        does Vinh ask for more information about?`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 500 },
		questions: [
			{
				title: `
						<div style='display:flex'>
                <img src='img/FriendsPlus/Page21/E3/1.jpg'  />

              <div>
                Does Vinh provide all of the information that Adam asks for? What does Vinh ask for more information about?
                <textArea id='0' rows='2'></textArea>
              </div>
            </div>

        `,
				answer: ['Yes. He asks for information about Newcastle and what it’s like living there.'],
			},
		],
	},
	4: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P21-E4',
		audio: '',
		video: '',
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page21/E4/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '4',
				title: `Read the <span style='color:rgb(148 50 151)'>KEY PHRASES</span>. Look at the polite requests. Which one
        does Adam use in his advertisement? Which one does
        Vinh use in his message?`,
				color: '#8e258c',
			},
		],
		question: {
			Write: {
				inputStyle: { width: 400 },
				answers: ['Could you please …?', 'Would you mind (+ -ing form)?'],
				maxLength: 30,
				isHiddenCheck: true,
				initialValue: [],
			},
			Circle: {
				// initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
				selectWordStyle: { padding: 0, borderBottom: 'solid 2px none', borderColor: 'none' },
				limitSelect: 0,
				listWords: [
					`Would_it_be_possible_for_you_to_…_? <br>
          Could_you_please_…_?_Would_you_mind_if_…_?<br>
          Would_you_mind_(+_-ing_form)?_I_wonder_if_…`,
				],
				answers: [],
				initialValue: [],
			},
			Layout: `
      <div style='border-left: 3px solid rgb(38 130 197);padding:0px 10px;'>
        <div style='color:rgb(38 130 197)'>Polite requests</div>
        <input id='0' type='Circle' /></input><br>
      </div>
      <div style='margin:10px 0px;line-height:55px'>
        Which one does Adam use in his advertisement? Which one does Vinh use in his message?<br>
        Adam:<input id='0'></input><br>
        Vinh:<input id='1'></input><br>
      </div>    
      `,
		},
	},
	5: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P21-E5',
		audio: '',
		video: '',
		component: T6,
		inputSize: 700,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page21/E5/Key/answerKey.png',
		checkUppercase: true,
		// maxLength: 48,
		titleQuestion: [
			{
				num: '5',
				title: `
        <span style='color:rgb(1 145 67)'>SPEAKING</span> Work in pairs. Request the following
information from your partner. Use different phrases
from exercise 4 and verbs like tell, explain, describe, etc.`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 525 },
		questions: [
			{
				title: `
            <div>#<br>date of birth</div>
            <div>#<br>ideal day out</div>
            #<br>taste in music
        `,
				answer: [
					'Would it be possible for you to describe your earliest memory?',
					'Could you please describe your ideal day out?',
					'Would you mind telling me about your taste in music?',
				],
			},
		],
	},
	6: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P21-E6',
		audio: '',
		video: '',
		component: T6,
		inputSize: 875,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page21/E6/Key/answerKey.png',
		checkUppercase: true,
		// maxLength: 48,
		titleQuestion: [
			{
				num: '6',
				title: `
        Read the <span style='color:rgb(38 130 197)'>Writing Strategy</span>. Then look at the message in
        exercise 3 again. Does Vinh develop the points or does he
        just write a single sentence for each one?`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 925 },
		questions: [
			{
				title: `
                <img src='img/FriendsPlus/Page21/E6/1.jpg'  />
                <div>
                  Does Vinh develop the points or does he just write a single sentence for each one?<br>#
                </div>
        `,
				answer: ['He develops the points.'],
			},
		],
	},
	7: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P21-E7',
		audio: '',
		video: '',
		component: MatchDots,
		exerciseKey: 'img/FriendsPlus/Page21/E7/Key/answerKey.png',
		stylesTextInput: { borderBottom: 'dotted' },
		inputSize: 700,
		fontSize: 55,
		maxLength: 200,
		isHiddenCheck: true,
		// checkUppercase: true,
		titleQuestion: [
			{
				num: '7',
				title: `
        Match sentences 1–5 with sentences a–e. Think of other
        ways that extra detail or information could be added to
        sentences 1–5. `,
				color: '#92278f',
			},
		],
		question: {
			DrawLines: {
				multipleLine: false,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '17px',
							left: '540px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 0
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '50px',
							left: '540px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '83px',
							left: '540px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '115px',
							left: '540px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '147px',
							left: '540px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '17px',
							left: '650px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '50px',
							left: '650px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '83px',
							left: '650px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 7
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '115px',
							left: '650px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 8
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '147px',
							left: '650px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 9
				],
				answers: ['0-6', '1-9', '2-7', '3-5', '4-8'],
				initialValue: [],
			},
			Layout: `
      <div style='display:flex'>
        <input id='0' type= 'boxMatch' />
        <input id='1' type= 'boxMatch' />
        <input id='2' type= 'boxMatch' />
        <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
        <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />
        <input id='9' type= 'boxMatch' />
        <img src='img/FriendsPlus/Page21/E7/1.jpg'></img>

      </div>
        
      `,
		},
	},
	8: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P21-E8',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		inputSize: 875,
		exerciseKey: 'img/FriendsPlus/Page21/E8/Key/answerKey.png',
		checkUppercase: true,
		// maxLength: 48,
		titleQuestion: [
			{
				num: '8',
				title: `
        You are going to do the task in exercise 3. Plan your
        message, using the prompts below to help you. Think
        about how you can add extra details.`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 800 },
		questions: [
			{
				title: `
                <ul>
                  <li>Describe yourself and your family.</li>
                  <li>Explain your reason for wanting a penfriend.</li>
                  <li>Request information from Adam.</li>
                </ul>
               <div>
                <textArea id='0' rows='4'></textArea>
               </div>
        `,
				answer: [''],
			},
		],
	},
	9: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P21-E9',
		audio: '',
		video: '',
		component: T6,
		inputSize: 975,
		exerciseKey: 'img/FriendsPlus/Page21/E9/Key/answerKey.png',
		checkUppercase: true,
		isHiddenCheck: true,
		hideBtnFooter: true,
		// maxLength: 49,
		titleQuestion: [
			{
				num: '9',
				title: `
        Write your message (70–80 words) using your plan from
exercise 8.`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 800 },
		questions: [
			{
				title: `
                <ul>
                  <li>Describe yourself and your family.</li>
                  <li>Explain your reason for wanting a penfriend.</li>
                  <li>Request information from Adam.</li>
                </ul>
                <div>
                  <textArea id='0' rows='4'></textArea>
                </div>
        `,
				answer: [''],
			},
		],
	},
}

export default json
