import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P57-E1',
    // exerciseKey: 'img/FriendsPlus/Page56/E8/Key/E8answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.12.mp3',
    component: T6,
    inputSize: 250,
    maxLength: 20,
    hideBtnFooter: true,
    textAlign: 'center',
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 19,
      height: 90,
    },
    titleQuestion: [
      {
        num: '1',
        title: `
        <div style="margin-bottom: 10px;font-size: 22px;"><span style='color: rgb(99,185,139)'>SPEAKING  
				</span>Work in pairs. If you could move to any city in 
				the world, where would you choose? Why?</div>  
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='position: relative'>
          
        </div>
          
        `,
        answer: [''],
      },
    ],
  },
  2: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P57-E2',
    exerciseKey: 'img/FriendsPlus/Page57/E2/Key/E2answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.12.mp3',
    component: T6,
    inputSize: 250,
    maxLength: 20,
    textAlign: 'center',
    textareaStyle: {
      // background: 'none',
      width: 500,
      fontSize: 20,
      height: 300,
    },
    titleQuestion: [
      {
        num: '2',
        title: `
        <div style="margin-bottom: 10px;font-size: 22px;">Read the email. Do you think Ellie is happy or unhappy with her new home? Find evidence in her email.</div>  
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='position: relative; display: flex'>
          <img style="width:15cm" src='img/FriendsPlus/Page57/E2/1.jpg' />
					
					<div style='font-size:20px; margin-left:10px'>
					<b>ANSWER:</b>
					<textarea id='0' row='6'></textarea>
					</div>

        </div>
          
        `,
        answer: [
          'I think Ellie is happy with her new home. She talks about the advantages of the new flat, such as being close to the shops and making friends with her neighbour.',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P57-E3',
    exerciseKey: 'img/FriendsPlus/Page57/E3/Key/E3answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.12.mp3',
    component: T6,
    inputSize: 100,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 20,
      height: 22,
      textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '3',
        title: `
        <div style="margin-bottom: 10px;font-size: 22px;">Read the task below. Does the email cover all four points in the task? Match sentences in the email with each point.</div>  
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='display:flex; font-size: 20px; position:relative'>
          <div style='color: rgb(55, 86, 97); width: 53%;'>
						
            <img style='position:relative; height:603px; width: 565px;' src='img/FriendsPlus/Page57/E5/2.jpg' />
						<div style='position: absolute; top: 95px; left: 40px; width: 500px'>
						Hi Anna,<br>
						<b style='color: rgb(21, 32, 36)'>A</b> Hope everything is OK with you. 
						<b style='color: rgb(21, 32, 36)'>B</b> We’ve just moved to a new flat nearer the centre of town. 
						<b style='color: rgb(21, 32, 36)'>C</b> It’s brilliant being so close to the shops, and the flat is bigger than our old 
						one. 
						<b style='color: rgb(21, 32, 36)'>D</b> It’s quite noisy, though, as there is a lot of traffic. 
						<b style='color: rgb(21, 32, 36)'>E</b> I’d rather it was further from the main road. 
						<b style='color: rgb(21, 32, 36)'>F</b> I met one of our neighbours yesterday. She is about my age and she is very friendly. 
						<b style='color: rgb(21, 32, 36)'>H</b> She has invited me to a party next weekend. 
						<b style='color: rgb(21, 32, 36)'>J</b> Can you go with me? I hope so. 
						<b style='color: rgb(21, 32, 36)'>K</b> To be honest, I’d rather not go alone. 
						<b style='color: rgb(21, 32, 36)'>L</b> Also, would it be OK if I used your bike for a few days? 
						<b style='color: rgb(21, 32, 36)'>M</b> Mine is at the bike shop and I’d rather cycle to school than get the bus.
						<b style='color: rgb(21, 32, 36)'>N</b> Got to go now. 
						<b style='color: rgb(21, 32, 36)'>X</b> I’d better finish my homework before bed. 
						<b style='color: rgb(21, 32, 36)'>Y</b> I look forward to receiving your reply.<br>
						Love, Ellie
						</div>
					</div>
          <div style='display:flex; flex-direction: column'>
            <div style='background-color: rgb(209,222,239);width: 76%; height: fit-content; border-radius: 5px; padding: 10px; margin-top:15px'>
						<div><b>1.</b>Say why you like your new flat.</div>
						<div><b>2.</b>Mention something you would like to be different.</div>
						<div><b>3.</b>Mention meeting a new neighbour and describe him / her</div>
						<div><b>4.</b>Ask permission to use something belonging to your friend.</div>
					  </div>
            <div style='font-size: 20px; margin-top:40px; margin-left: 20px'>
				    <b>ANSWER:</b><br>
				    1 - # &ensp;<br> 2 - # &ensp;<br> 3 - # &ensp;<br> 4 - #
				    </div>
          </div>

        </div>
        
        `,
        answer: ['C', 'D', 'F', 'L'],
      },
    ],
  },
  4: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P57-E4',
    exerciseKey: 'img/FriendsPlus/Page57/E4/Key/E4answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.12.mp3',
    component: T6,
    inputSize: 850,
    maxLength: 400,
    textAlign: 'left',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 20,
      height: 22,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '4',
        title: `
        <div style="margin-bottom: 10px;font-size: 23px;">Read the <span style='color: rgb(94,114,228)'>Writing Strategy.</span> 
				 Then read Ellie’s email again and underline. </div>
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='position: relative; display: flex; flex-direction: column; font-size: 20px'>
						<div style='display: flex; width: 900px'>
							<img style="width:13cm" src='img/FriendsPlus/Page57/E2/1.jpg' />
							<img style="width:12cm; height:6cm" src='img/FriendsPlus/Page57/E4/1.jpg' />

						</div>
						<div><b>1 </b>
						<div>a piece of formal language.</div>
						<div>-> # </div>
						<div>How could you make it informal? </div>
						<div>-> # </div>
						</div>

					<div><b>2 </b>
						<div>three different contractions.</div>
						<div>-> # </div>
						<div>Can you find any more places where Ellie could have used contractions? </div>
						<div>-> # </div>
						</div>

						<div><b>3 </b>
						<div>an example of a sentence with words omitted at the start.</div>
						<div>-> # </div>
						<div>What exactly has been omitted? </div>
						<div>-> # </div>
						</div>
					</div>

        </div>
          
        `,
        answer: [
          ` I look forward to receiving your reply.`,
          ` More informal: Looking forward to hearing from you! Write soon! `,
          ` We've, It's, I'd`,
          ` Hope everything's OK with you; There's a lot of traffic; Mine's at the bike shop`,
          ` Got to go now`,
          ` I've has been omitted.`,
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P57-E5',
    exerciseKey: 'img/FriendsPlus/Page57/E5/Key/E5answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.12.mp3',

    component: Circle_Write,
    titleQuestion: [
      {
        num: '5',
        title: `
        <div style="margin-bottom: 10px;">Read the <span style='color: rgb(94,114,228)'>Learn this!</span> 
				 box. Circle three examples of <i>would rather</i> in Ellie’s email. </div>
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 149, fontSize: 23, borderBottom: 'none', textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },

      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: "50%",
          borderColor: 'transparent',
        },
        selectWordStyle: {
          // padding: 5,
          borderBottom: 'solid 2px',
          borderColor: '#00aeef',
        },
        limitSelect: 4,
        listWords: [
          `Hi_Anna,<br> Hope_everything_is_OK_with_you. We've_just_moved_to_a_new_flat_nearer_the_centre_of_town. It's_brilliant_being_so_close_to_the_shops,_and_the_flat_is_bigger_than_our_old_one. It's_quite_noisy,_though,_as_there_is_a_lot_of_traffic. I'd_rather_it_was_further_from_the_main_road. I_met_one_of_our_neighbours_yesterday. She_is_about_my_age_and_she_is_very_friendly. She_has_invited_me_to_a_party_next_weekend. Can_you_go_with_me? I_hope_so. To_be_honest,_I'd_rather_not_go_alone. Also,_would_it_be_OK_if_I_used_your_bike_for_a_few_days? Mine_is_at_the_bike_shop_and_I'd_rather_cycle_to_school_than_get_the_bus. Got_to_go_now. I'd_better_finish_my_homework_before_bed. I_look_forward_to_receiving_your_reply.<br>Love,<br>Ellie
         `, //0
        ],
        answers: ['0-26', '0-10', '0-22'],
        initialValue: [],
      },
      Layout: `
        <div style='position: relative;'>
          <img style='height:' src='img/FriendsPlus/Page57/E5/1.jpg' />
          <img style='height:750px; width: 700px; margin-left: 34px' src='img/FriendsPlus/Page57/E5/2.jpg' />
          <div style=" position: absolute; top: 399px; left: 84px; width:16cm "><input id='0'  type='Circle' /></div>
          
        </div> 
      `,
    },
  },
  6: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P57-E6',
    // exerciseKey: 'img/FriendsPlus/Page57/E3/Key/E3answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.12.mp3',
    component: T6,
    inputSize: 850,
    hideBtnFooter: true,
    maxLength: 850,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 20,
      height: 30,
      textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '6',
        title: `
        <div style="margin-bottom: 10px;">
        Read the task below. Make notes for each of the four points in the task.
        </div>  
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='display:flex; position:relative'>
					</div>
					<div style='background-color: rgb(209,222,239);width: 80%; height: fit-content; border-radius: 25px; padding: 10px; font-size: 20px '>
           <div>You have recently moved to a house in the country. Write an email to a friend. Include the following:</div>
						<div><b>1.</b>Describe the advantages of being in the country.</div>
						<div><b>2.</b>Mention something you would change.</div>
						<div><b>3.</b>Mention something that went wrong during the move.</div>
						<div><b>4.</b>Ask if you can stay a night at your friend’s house.</div>
					</div>

        </div>
        <div style='font-size: 20px; margin-top:20px'>
				<b>ANSWER:</b><br>
				1 - # <br> 2 - # <br> 3 - # <br> 4 - #
				</div>
        `,
        answer: ['', '', '', ''],
      },
    ],
  },
  7: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P56-E7',
    exerciseKey: 'img/FriendsPlus/Page57/E7/Key/E7answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 160,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 23,
      height: 35,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '7',
        title: `
        <div style="font-size: 22px; "><span style='color:#92278f'>KEY PHRASES</span> 
        Complete the useful phrases for starting and ending a letter or email. Find two more in Ellie’s email.
        </div>  
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>
          <div style='font-size:23px;'>
              <div style=" gap: 20px; color:rgb(124,181,220);  ">
                &ensp;all
                &ensp;are
                &ensp;from
                &ensp;going
                &ensp;hi
                &ensp;hope
                &ensp;love
                &ensp;touch
                &ensp;write
              </div>

              <div style=" margin-top:20px; padding-left:20px; border-left: 4px solid rgb(124,181,220); font-size: 23px;">
                <div style='margin-bottom: 20px;'>
                  <div><b style='color:rgb(124,181,220)'>Starting an email</b></div>
                  <div style='display:flex;'>
                   <div style='margin-right: 20px'>
                      <div>I <span style='font-size: 13px; padding-top:30px'>1</span> # you’re well.</div>
                      <div>I hope <span style='font-size: 13px; padding-top:30px'>2</span> # is well.</div>
                   </div>
                   <div>
                      <div>How’s everything <span style='font-size: 13px; padding-top:30px'>3</span> # ?</div>
                      <div>How<span style='font-size: 13px; padding-top:30px'>4</span> # you?</div>
                    </div>
                  </div>
                </div>

                <div>
                  <div><b style='color:rgb(124,181,220)'>Ending an email</b></div>
                  <div style='display:flex'>
                   <div style='margin-right: 20px'>
                      <div>That's all <span style='font-size: 13px; padding-top:30px'>5</span> # me.</div>
                      <div>Keep in <span style='font-size: 13px; padding-top:30px'>6</span> # .</div>
                   </div>
                   <div>
                      <div>Please <span style='font-size: 13px; padding-top:30px'>7</span> # soon.</div>
                      <div>Say <span style='font-size: 13px; padding-top:30px'>8</span> # to (Ben) for me.</div>
                      <div>Give (Zoe) my <span style='font-size: 13px; padding-top:30px'>9</span> # .</div>
                    </div>
                  </div>
                </div>

              </div>

          </div>

        </div>      
          
        `,
        answer: ['hope', 'all', 'going', 'are', 'from', 'touch', 'write', 'hi', 'love'],
      },
    ],
  },
  8: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P57-E8',
    // exerciseKey: 'img/FriendsPlus/Page57/E2/Key/E2answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.12.mp3',
    component: T6,
    inputSize: 1000,
    hideBtnFooter: true,
    // maxLength: 20,
    textAlign: 'center',
    textareaStyle: {
      // background: 'none',
      width: 600,
      fontSize: 20,
      height: 400,
    },
    titleQuestion: [
      {
        num: '8',
        title: `
        <div style="margin-bottom: 10px;font-size: 22px;">
        Write your email (150-180 words) using your notes from exercise 6 and phrases from exercise 7
        </div>  
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='position: relative;'>
          <img style="width:15cm" src='img/FriendsPlus/Page57/E8/1.jpg' />
					
					<div style='font-size:20px; margin-left:10px'>
					<b>ANSWER:</b>
					<textarea id='0' row='20'></textarea>
					</div>

        </div>
          
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
