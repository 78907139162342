import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P46-E1',
    audio: '',
    video: '',
    recorder: true,
    hideBtnFooter: true,
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page46/E1/Key/E1answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `
        <span style="margin-right: 10px; color: #57B482;">SPEAKING</span>  
        In pairs, answer the quiz questions (1-5) about SEA Games 31.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 300,
    textAlign: 'center',
    questions: [
      {
        title: `
        <div style='position:relative; font-size:24px; gap:30px; margin-left: 40px; width:800px'>
        
          <div style='display: flex;'><b>1.&ensp;</b><div style=''>How many times has Viet Nam hosted the SEA Games?</div></div>
          <div style='display: flex;'><b>2.&ensp;</b><div style=''>What is the official mascot of SEA Games 31?</div></div>
          <div style='display: flex;'><b>3.&ensp;</b><div style=''>What is the theme song of SEA Games 31?</div></div>
          <div style='display: flex;'><b>4.&ensp;</b><div style=''>How many countries participated in SEA Games 31?</div></div>
          <div style='display: flex;'><b>5.&ensp;</b><div style=''>In which sport did Viet Nam win the most gold medals of all?</div></div>
          
        </div>
			  `,
        answer: ['Twice', 'Sao La', 'Let’s shine', '11', 'Wrestling'],
      },
    ],
  },
  2: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P46-E2',
    audio: 'Audios/1.39_01-audio.mp3',
    video: '',
    component: T6,
    inputSize: 300,
    maxLength: 100,
    textAlign: 'center',
    isHiddenCheck: true,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 22,
    },
    exerciseKey: 'img/FriendsPlus/Page46/E2/Key/E2answerKey.png',
    titleQuestion: [
      {
        num: '',
        title: `
        <div style='width:800px'> <span style=''><headphone1 name="1.39" src="Audios/1.39_01-tieude.mp3"></headphone1></span>
          Listen and check your answers to the quiz in exercise 1. Which contestant wins: Nam or An?
        </div>
         
          `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <div style='position:relative; font-size:22px; gap:30px; margin-left: 40px; width:800px'>
        
          <div style='display: flex;  justify-content: space-between;'><b>1.&ensp;</b><div style='justify-content: space-between; display:flex; flex-direction: row;'>How many times has Viet Nam hosted the SEA Games?</div><span>&ensp;#</span></div>
          <div style='display: flex;  justify-content: space-between;'><b>2.&ensp;</b><div style='justify-content: space-between; display:flex; flex-direction: row;'>What is the official mascot of SEA Games 31?</div><span>&ensp;#</span></div>
          <div style='display: flex;  justify-content: space-between;'><b>3.&ensp;</b><div style='justify-content: space-between; display:flex; flex-direction: row;'>What is the theme song of SEA Games 31?</div><span>&ensp;#</span></div>
          <div style='display: flex;  justify-content: space-between;'><b>4.&ensp;</b><div style='justify-content: space-between; display:flex; flex-direction: row;'>How many countries participated in SEA Games 31?</div><span>&ensp;#</span></div>
          <div style='display: flex;  justify-content: space-between;'><b>5.&ensp;</b><div style='justify-content: space-between; display:flex; flex-direction: row;'>In which sport did Viet Nam win the most gold medals of all?</div><span>&ensp;#</span></div>

          <div style='display: flex;  justify-content: space-between;'><div style='justify-content: space-between; display:flex; flex-direction: row;'>Which contestant wins: Nam or An?</div><span>&ensp;#</span></div>
          
        </div>
			  `,
        answer: ['Twice', 'Sao La', 'Let’s shine', '11', 'Wrestling', 'Neither Nam nor An wins.'],
      },
    ],
  },
  3: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P46-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page46/E3/Key/E3answerKey.png',
    component: DesignUnderLine,
    totalInput: 3,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    titleQuestion: [
      {
        num: '3',
        title: `
        Read the fact files about three sports in SEA Games 31. Which of these pieces of information (a-e) is in all three fact files?
			  `,
        color: '#92278f',
      },
    ],
    questionImage: [
      // Row a
      [{ url: 'img/FriendsPlus/Page46/E3/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page46/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page46/E3/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E3/4.jpg' },
      ],
      // Row  b
      [{ url: 'img/FriendsPlus/Page46/E3/5.jpg' }],
      [
        { url: 'img/FriendsPlus/Page46/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page46/E3/7.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page46/E3/8.jpg' },
      ],
      // Row c
      [{ url: 'img/FriendsPlus/Page46/E3/9.jpg' }],
      [
        { url: 'img/FriendsPlus/Page46/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page46/E3/11.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E3/12.jpg' },
      ],
      // Row d
      [{ url: 'img/FriendsPlus/Page46/E3/13.jpg' }],
      [
        { url: 'img/FriendsPlus/Page46/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page46/E3/15.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page46/E3/16.jpg' },
      ],
      // Row e
      [{ url: 'img/FriendsPlus/Page46/E3/17.jpg' }],
      [
        { url: 'img/FriendsPlus/Page46/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page46/E3/19.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E3/20.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page46/E3/21.jpg' }],
      [{ url: 'img/FriendsPlus/Page46/E3/22.jpg' }],
      [{ url: 'img/FriendsPlus/Page46/E3/23.jpg' }],
    ],
  },
  4: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P46-E4',
    // audio: 'Audios/1.39_01.mp3',
    video: '',
    component: T6,
    inputSize: 300,
    maxLength: 100,
    textAlign: 'center',
    isHiddenCheck: true,
    hideBtnFooter: true,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 22,
    },
    // exerciseKey: 'img/FriendsPlus/Page46/E2/Key/E2answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `
        <div style='font-size:22px'> <span style='color:rgb(154,157,201)'>INTERNET RESEARCH </span>
          Find out about a sporting event in Viet Nam. Use the list in exercise 3 to help you. Present the information as a fact file
        </div>
         
          `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <div style='position:relative; font-size:22px; gap:30px; margin-left: 40px; width:800px'>
          
        </div>
			  `,
        answer: [''],
      },
    ],
  },
};

export default json;
