import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Grammar Builder and  Reference',
    id: 'FGF11-U8-P106-E1',
    audio: '',
    video: '',
    component: T6v2,
    titleQuestion: [
      {
        num: '',
        title:
          "<span style='font-weight:bold; color:rgb(0,103,180)'>Reading</span><br/> Read the following passage about Mary Kingsley, a famous British explorer. Answer the questions.",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: 'none',
      backgroundColor: 'transparent',
    },
    inputSize: 110,
    maxLength: 7,
    exerciseKey: 'img/FriendsPlus/Page107/key/1.png',
    questionImage: [],
    isHiddenCheck: true,
    textareaStyle: {
      width: 850,
    },
    questions: [
      {
        title: `
					<div style=' position: relative; display:flex;flex-direction:column'>
              <div style="display:flex; flex-direction:row">
              <img src="img/FriendsPlus/Page107/E1/1.png" style="max-width:60%" />
              <img src="img/FriendsPlus/Page107/E1/2.png" style="max-width:60%" />
              
              </div>
              <span><span style="font-weight:bold;margin-right:30px">1</span>Why was Mary not sent to school?</span>
              <textarea id="0" row="1"></textarea>
              <span><span style="font-weight:bold;margin-right:30px">2</span>How could she afford to travel to Africa?</span>
               <textarea id="1" row="1"></textarea>
              <span><span style="font-weight:bold;margin-right:30px">3</span>What did she wear to climb Mount Cameroon?</span>
               <textarea id="2" row="1"></textarea>
              <span><span style="font-weight:bold;margin-right:30px">4</span>What was her opinion of missionaries and of British
colonial rule?</span>
 <textarea id="3" row="1"></textarea>
              <span><span style="font-weight:bold;margin-right:30px">5</span>How many expeditions to Africa did she go on? </span>
               <textarea id="4" row="1"></textarea>
              <span><span style="font-weight:bold;margin-right:30px">6</span>What did she die of? </span>
               <textarea id="5" row="1"></textarea>
					</div>
				`,
        answer: [
          'Mary was not sent to school because people did not think it was necessary to educate girls.',
          'She could afford to travel to Africa with the money inherited from her parents.|Because her parents left her a considerable sum of money.',
          'She wore a long black dress.',
          'She was very critical of them.',
          'She went on two expeditions to Africa.',
          'She died of typhoid.',
        ],
      },
    ],
  },
  2: {
    unit: 'Starter',
    id: 'FGF11-U8-P107-E2',
    audio: 'Audios/3.21-audio.mp3',
    video: '',

    component: T6,
    titleQuestion: [
      {
        num: '',
        title:
          '<span style="font-weight:bold;color:rgb(0,103,180)">Listening</span><br/><headphone1 name="3.21" src="-tieude.mp3"></headphone1> Listen to a talk about hovercrafts and fill in each blank with NO MORE THAN three words.',
        color: '#0067b4',
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page107/key/2.png',
    stylesTextInput: {
      fontSize: 25,
      // height: 24,
      // borderBottom: "none",
      backgroundColor: 'transparent',
    },
    textAlign: 'center',
    inputSize: 340,
    maxLength: 13,
    titleImage: '',
    questionImage: [],
    hintBox: [
      {
        src: [
          'aunt',
          '<s>brother</s>',
          'child',
          'cousin',
          'daughter',
          'granddaughter',
          'grandmother',
          'mum',
          'niece',
          'partner',
          'twin',
          'wife',
        ],
        borderColor: '#468dcb',
        width: 525,
      },
    ],
    questions: [
      {
        title: `
          <div>
          <span>A hovercraft is a unique kind of vehicle which has got the (1) <span id="0"> #</span>  over any kind of terrain land, water, mud,
or ice. It has got special engines that blow air below the
vehicle. The pressure of this air is (2) <span id="1"> #</span> than the
pressure in the atmosphere and the (3) <span id="2"> #</span> in the
pressure lifts the hovercraft above the surface so that it
can travel over small obstacles (4)<span id="3"> #</span> , making it
one of the most (5) <span id="4"> #</span> vehicles in the world. The hovercraft was first designed in Britain in the 1950s.
Today, these vehicles are used all over the world. They are
particularly (6) <span id="5"> #</span> during disasters, because they
can get to places that a boat or a helicopter can’t reach.
The manufacturers have created hovercraft (7) <span id="6"> #</span> . The smaller models are easy to drive and
their designers always have (8) <span id="7"> #</span>  in mind.

</span>
          </div>
        `,
        answer: [
          'ability to travel',
          'higher',
          'difference',
          'quite easily',
          'environmentally friendly',
          'used',
          'in various sizes',
          'safety',
        ],
      },
    ],
  },
  3: {
    unit: 'Starter',
    id: 'FGF11-U8-P107-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page6/E3/Key/answerKey.png',
    inputSize: 240,
    recorder: true,
    hideBtnFooter: true,

    titleQuestion: [
      {
        num: '',
        title:
          "<span style='font-weight:bold;color:rgb(0,103,180)'>Speaking</span><br/>Work in groups. Discuss the following questions.",
        color: '#0067b4',
      },
    ],
    questions: [
      {
        title: `
          <div ">
            - What is the most important character trait an explorer
should have? <br/><br/>
- Where on Earth is the most dangerous area to explore:
a desert, the polar regions, or the sea?
          </div>  
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Starter',
    id: 'FGF11-U8-P107-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page6/E3/Key/answerKey.png',
    inputSize: 240,
    textareaStyle: {
      height: 500,
      width: 1200,
      fontSize: 22,
    },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '',
        title:
          "<span style='font-weight:bold;color:rgb(0,103,180)'>WRITING</span><br/>Read the task below and write a letter of complaint (150–180 words) to the service provider.",
        color: '#0067b4',
      },
    ],
    questions: [
      {
        title: `
         <div>
         You recently travelled by plane / coach / train and you
were very dissatisfied with the service. Write a letter of
complaint in which you describe what went wrong and
suggest how the company could improve their service.
<textarea id="0" row="8"></textarea>
         </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
