import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P111-U6-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: ` <strong style='font-size: 800'><span style='color:#860052; font-weight: 800; margin-right: 10px'>6G</span>Working and employment conditions </strong> <br />
         <strong style='color:#860052'>2</strong> Complete the sentences with the words and phrases below. `,
        // color: '#860052',
      },
    ],
    stylesTextInput: {
      fontSize: 21,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 160,
    exerciseKey: 'img/FriendsPlus/Page111/Key/U6E2answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
        <div style='width: 1150px; >
          <div style='font-size:21px;dislay:flex'>
              <div style="color:rgb(146,39,143);  ">
              &ensp;bonus
              &ensp;overtime
              &ensp;paid holiday
              &ensp;paperwork
              &ensp;pay rise
              &ensp;salary
              &ensp; shifts
              &ensp;sick pay
              &ensp;training course
              &ensp;workload

            </div>

          </div>
      </div>
         <div style='line-height:1.75;'>
          <div style="display: flex; column-gap: 20px; margin-top:10px">
            <strong>1</strong>
            <div>
              <div>When she changed jobs, she took a large cut in
              <input />
              </div>

            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
             <div>If he meets his sales targets, he receives a £5,000
              <input />
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div>Some weeks, she has to work three eight-hour
              <input />   in two days.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
            <div> As a teacher, she gets thirteen weeks’
              <input />  a year, including six weeks in the summer.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div> He’s been off work since the accident, but he’ll receive
              <input />  until he’s well enough to go back.
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <div> He’s been doing a lot of
              <input />  recently to earn some extra money for his holiday.
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>7</strong>
            <div>
              <div>  I used to earn more than you, but I haven’t had a
              <input />   for three years.
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>8</strong>
            <div>
              <div> The company is sending all its employees on a
              <input />  to improve their customer service skills.
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>9</strong>
            <div>
              <div>He enjoys the practical aspects of his job, but he  hates sitting at his desk doing the
              <input />
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>10</strong>
            <div>
              <div>It’s an interesting job, but he’s finding it  exhausting because of the huge
              <input />
              </div>
            </div>
          </div>
         </div>
          
          
				`,
        answer: [
          'salary',
          'bonus',
          'shifts',
          'paid holiday',
          'sick pay',
          'overtime',
          'pay rise',
          'training course',
          'paperwork',
          'workload',
        ],
      },
    ],
  },
  2: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P111-U7-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: ` <strong style='font-size: 800'><span style='color:#860052; font-weight: 800; margin-right: 10px'>7G</span> Cultural events and venues</strong> <br />
         <strong style='color:#860052'>1</strong> Complete the sentences with the words and phrases below. `,
        // color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 22,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 150,
    exerciseKey: 'img/FriendsPlus/Page111/Key/U7E1answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
        <div style='width: 1150px; >
          <div style='font-size:21px;dislay:flex'>
              <div style="color:rgb(146,39,143);  ">
              &ensp;arena
              &ensp;art gallery
              &ensp;circus
              &ensp;comedy club
              &ensp;concert hall
              &ensp;library
              &ensp; opera house
              &ensp;museum
              &ensp;theatre

            </div>

          </div>
      </div>
         <div style='line-height:1.5;'>
          <div style="display: flex; column-gap: 20px; margin-top:20px">
            <strong>1</strong>
            <div>
              <div>We saw an amazing collection of Ancient Egyptian jewellery at the
              <input />
              </div>

            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
             <div> I enjoy watching acrobats at the
              <input /> , but I don’t think they should use animals.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div> On Thursday evenings at my local
              <input />    , anyone can have a go at being a comedian.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
            <div>  I went to see Mozart’s The Magic Flute at the Met, a worldfamous
              <input />   in New York.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div>  Some of these Impressionist paintings are privately owned and have never been seen in any
              <input />
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <div>  The Globe is a
              <input />  in London where Shakespeare’s plays are performed.
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>7</strong>
            <div>
              <div>   While we were in Vienna, we went to a
              <input />    to hear a Beethoven piano recital.
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>8</strong>
            <div>
              <div>  At the O2
              <input />   in London, up to twenty thousand people can watch some of the biggest acts in music.
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>9</strong>
            <div>
              <div>The British
              <input />holds around 170 million books and other items for students and researchers to access.
              </div>
            </div>
          </div>
          
         </div>
          
          
				`,
        answer: [
          'museum',
          'circus',
          'comedy club',
          'opera house',
          'art gallery',
          'theatre',
          'concert hall',
          'Arena',
          'Library',
        ],
      },
    ],
  },
  3: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P111-U7-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: ` <strong style='font-size: 800'><span style='color:#860052; font-weight: 800; margin-right: 10px'>7H</span>Describing stories</strong> <br />
         <strong style='color:#860052'>2</strong> Complete the sentences with the words and phrases below. `,
        // color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 22,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 150,
    exerciseKey: 'img/FriendsPlus/Page111/Key/U7E2answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
        <div style='width: 1150px; >
          <div style='font-size:21px;dislay:flex'>
              <div style="color:rgb(146,39,143);  ">
              <b>
              &ensp;based
              &ensp;characters
              &ensp;identify
              &ensp;made
              &ensp;main
              &ensp;story
              &ensp; what</b>


            </div>

          </div>
      </div>
         <div style='line-height:2;'>
          <div style="display: flex; column-gap: 20px; margin-top:20px">
            <strong>1</strong>
            <div>
              <div>The
              <input /> character is Bilbo Baggins.
              </div>

            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
             <div>  I couldn’t
              <input /> ,  with Bella Swan.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div> It’s been
              <input /> into a film called The Golden Compass.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
            <div> It’s the
              <input /> of a group of boys who get stranded on a desert island.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div>
              <input />  I liked about the book was the way it
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <div> It’s
              <input />  on the author’s experience of the unjust treatment of African Americans in 1930s America.
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>7</strong>
            <div>
              <div> I found the
              <input />  of Harry, Ron and Hermione rather unconvincing.
              </div>
            </div>
          </div>
         
          
         </div>
          
          
				`,
        answer: ['main', 'identify', 'made', 'story', 'What', 'based', 'characters'],
      },
    ],
  },
  4: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P111-U7-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page111/Key/U7E3answerKey.png',
    inputSize: 500,
    // //hideBtnFooter: true,
    titleQuestion: [
      {
        num: '',
        title: ` <strong style='color:#860052'>3</strong> Match the sentences in exercise 2 with the books below that they refer to. Which ones have you read? `,
        // color: "#0067b4",
        // prefix: "default",
        // prefix: { icons: ['far fa-comment', 'fas fa-pencil-alt'], text: 'CULTURAL' },
      },
    ],
    questions: [
      {
        title: `
        <div style='display:flex;'>
        <div style='flex:3;line-height:2;font-size: 20px,'>
        <b>1</b>&ensp;<input/> <br>
        <b>2</b>&ensp;<input/> <br>
        <b>3</b>&ensp;<input/> <br>
        <b>4</b>&ensp;<input/> <br>
        <b>5</b>&ensp;<input/> <br>
        <b>6</b>&ensp;<input/> <br>
        <b>7</b>&ensp;<input/> <br>

        </div>
        <div style='flex:2'>
                 <img src='img/FriendsPlus/Page111/U7-E3/1.jpg' style='max-width:100%'>

        </div>
        
        </div>
        `,
        answer: [
          `The Hobbit`,
          `Twilight`,
          `Northern Lights`,
          `Lord of the Flies`,
          `The Catcher in the Rye`,
          `To Kill a Mockingbird`,
          `Harry Potter and the Philosopher's Stone`,
        ],
      },
    ],
  },
};

export default json;
