import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P71-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page71/Key/E1answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: 'Listening',
        title: `
        <br/>
        Read the article and choose the correct answers (A–C).
			  `,
        color: '#2284c8',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `A <br> B <br> C`, //1
          `A <br> B <br> <br> C`, //1
          `A <br> B <br> C`, //1
          `A <br> B <br> C`, //1

        ],
        answers: ['0-8', '1-0', '2-4', '3-4'],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
      <div style='flex:1'>
	        <img src='img/FriendsPlus/Page71/E1/1.jpg' style='max-width:80%'>
</div>



        <div style ='flex:1;width:100%'>
            <b>1</b>&ensp;
           		  The writer says that in the past <br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
              people didn’t invent many things. <br>
              people didn’t want to use wheels. <br>
              it took time for new ideas to change things.
                    </div>
                       </div>

            <b>2</b>&ensp;
		       What does the writer say about the future of communication?
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      We won’t need devices like smartphones.<br>
              We can’t know what the most popular social media will be. <br>
             Microchips will become faster.
		          </div>
            </div>

              <b>3</b>&ensp;
		         In the future, people will use space for
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      making things in space factories. <br>
              living and visiting on holiday. <br>
              producing energy and visiting.
		          </div>
            </div>
                        <b>4</b>&ensp;
            The best title for the article would be:
             <div style="display: flex;">
            <div style="display: flex;margin-left:40px">
            	<div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	       More and more inventions <br>
              Progress now and then <br>
               Will computers rule the world?

		          </div>
            </div>
           </div>
      </div>

                        </div>


        
      `,
    },
  },
  2: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P71-E2',
    audio: 'Audios/2.27-audio.mp3',
    video: '',
    component: Circle_Write,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page71/Key/E2answerKey.png',
    titleQuestion: [
      {
        num: 'Listening',
        title: `
        <br/>
        <headphone1 name='2.27' src='Audios/2.27-tieude.mp3' ></headphone1> You will hear a conversation between two friends about smartphones. Are the sentences true (T)  or false (F)? You will hear the recording twice.
			  `,
        color: '#2284c8',
      },
    ],
    question: {
      Write: {
        inputStyle: {
          borderBottom: '',
          color: '#000',
          textAlign: 'center',
        },
        answers: [],
        initialValue: [],

        textAlign: 'center',
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: '#000',
          fontWeight: 700,
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: ['T / F', 'T / F', 'T / F', 'T / F', 'T / F', 'T / F', 'T / F', 'T / F'],
        answers: ['0-4', '1-4', '2-0', '3-0', '4-4', '5-0', '6-4', '7-4'],
        initialValue: [],
      },
      Layout: `
            <div style=' padding: 5px 5px 25px 5px;' >
              <div style='display: flex;'>
                <div><b> 1 &emsp;</b></div>
                <div> Sal paid too much for her phone. <b><input id='0' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 2 &emsp;</b></div>
                <div> Sal’s parents encouraged her to get a new phone. <b><input id='1' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 3 &emsp;</b></div>
                <div>Tim is not happy with his sister’s use of her phone. <b><input id='2' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 4 &emsp;</b></div>
                <div> Sal’s family avoid using their phones at dinner. <b><input id='3' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 5 &emsp;</b></div>
                <div>  Sal does not like talking when she’s eating. <b><input id='4' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 6 &emsp;</b></div>
                <div> Tim could cause a problem by using his phone. <b><input id='5' type='Circle' /></b></div>
              </div>
               <div style='display: flex;'>
                <div><b> 7 &emsp;</b></div>
                <div>Sal advises Tim not to listen to music on his phone. <b><input id='6' type='Circle' /></b></div>
              </div>
               <div style='display: flex;'>
                <div><b> 8 &emsp;</b></div>
                <div> Tim disagrees with Sal’s suggestion. <b><input id='7' type='Circle' /></b></div>
              </div>
            </div>


      `,
    },
  },
  3: {
    // Exercise num
    unit: 'Unit 5',
    id: 'FGF11-U5-P71-E3',
    recorder: true,
    audio: '',
    video: '',
    // exerciseKey: '',
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: 'Speaking',
        title: `<br>
        Work in pairs. Take turns to describe and compare the photos. Then ask and answer the following questions.  `,
        color: '#007092',
        prefix: { icon: [''], text: '' },
      },
    ],
    component: T6,
    hideBtnFooter: true,
    // isHiddenCheck: true,
    textareaStyle: { width: 700 },
    inputSize: 40,
    textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
                 <img src='img/FriendsPlus/Page71/E4/1.jpg' style='max-width:70%'>

         <div style='display:flex;width:18cm'>
            <b>1</b>
            <div style='margin-left:20px'>
              Do you agree that in the modern world, science is
               a more important subject than languages? Give reasons.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:20px'>
              Do you think technology sometimes makes it harder for <br>
               people to communicate with each other? Why? /  Why not?

            </div>
          </div>

     

  `,
        answer: [''],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 5',
    id: 'FGF11-U5-P71-E4',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page35/Key/E4answerKey.png',
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: 'Writing',
        title: `<br>
        You recently bought a new smartphone. Write a forum <br> post (150–180 words) in which you   `,
        color: '#007092',
        prefix: { icon: [''], text: '' },
      },
    ],

    component: T6,
    // maxLength: 27,
    hideBtnFooter: true,

    isHiddenCheck: true,
    textareaStyle: { width: 500 },
    inputSize: 40,
    textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
        <div style ='display:flex'>
        <div style='flex:1'>
        <div style='width:17cm'>
              <ul>
                  <li>describe the smartphone and some of its features.</li>
                  <li>compare it with another smartphone you have used.</li>
                  <li>give your overall opinion of the smartphone. </li>
                  <li> present the opinions of people who think teenagers use smartphones too much.
</li>
              </ul>
           </div>
        </div>
        <div style='flex:1'>
                   <textarea id='0'  rows='8'></textarea>

        </div>
        
        </div>


          
`,
        answer: [''],
      },
    ],
  },

};

export default json;
