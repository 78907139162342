/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

function HeadPhone1({ name, src }) {
  const audio = new Audio(src);

  React.useEffect(() => {
    return () => {
      audio.pause();
    };
  }, [audio]);

  return (
    <div style={{ display: 'inline-block' }}>
      <img
        src='img/headphone1.jpg'
        alt=''
        style={{
          width: '97px',
          height: '33px',
          marginLeft: -10,
          marginTop: 2,
          cursor: 'url(img/volume.png) 12 12, auto',
        }}
        onClick={() => {
          audio.play();
        }}
      />
      <span
        style={{
          fontSize: '20px',
          color: 'white',
          background: 'none',
          marginLeft: '-67px',
          marginRight: 5,
        }}
      >{` ${name}`}</span>
    </div>
  );
}

export default HeadPhone1;
