import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
	1: {
		unit: 'Science',
		id: 'FG10-S-P100-E1',
		audio: '',
		video: '',
		// exerciseKey: 'img/FriendsPlus/Page100/E1/Key/answerKey.png',
		component: T6,
		inputSize: 280,
		maxLength: 23,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 500, fontSize: 20 },
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. Do you think space programmes, which cost millions, are a good way for governments to spend money? Give reasons
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					
				`,
				answer: [],
			},
		],
		recorder: true,
	},
	2: {
		unit: 'Science',
		id: 'FG10-S-P100-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page100/Key/E2answerKey.png',
		component: T6,
		checkDuplicated: true,
		inputSize: 750,
		maxLength: 1500,
		textareaStyle: { width: 990 },
		//isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '2',
				title: `
					Read Part 1 of the article. What happened to the spaceship? Rewrite the underlined participle or infinitive clauses as relative clauses.	
					
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				<div>
				<div style="color:rgb(0,121,133,1); padding:20px;">… which was launched from Florida on 11 April 1970.</div>	
					<div style="display: flex;">
						<img src="img/FriendsPlus/Page100/E2/1.jpg" width=100%; />
						<img src="img/FriendsPlus/Page100/E2/2.jpg" width=100%; />
					</div>
					<div>
						<div><b> What happened to the spaceship?</b></div>
						<div style="padding-left:100px">#</div>
						<div><b>Rewrite the underlined participle or infinitive clauses as  relative clauses.</b></div>
						<div style="padding-left:100px">#</div>
						<div style="padding-left:100px">#</div>
						<div style="padding-left:100px">#</div>
						<div style="padding-left:100px">#</div>
						<div style="padding-left:100px">#</div>
						<div style="padding-left:100px">#</div>
						<div style="padding-left:100px">#</div>
						</div>
					</div>
				`,
				answer: [
					'a tank of oxygen exploded and the spaceship was damaged',
					'which would land on the moon. / that would land on the moon.',
					'who were chosen for the mission / that were chosen for the mission',
					'which was watched by millions on TV,',
					'which explained how they lived and worked in zero gravity. / that explained how they lived and worked in zero gravity.',
					'which contained oxygen / that contained oxygen',
					'who noticed a red warning light,',
					'which was floating 320,000 kilometres from Earth. / that was floating 320,000 kilometres from Earth.'
				]
			},
		],
	},
	3: {
		unit: 'Science',
		id: 'FG10-S-P100-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page100/Key/E3answerKey.png',
		component: T6,
		inputSize: 650,
		maxLength: 1900,
		textareaStyle: { width: 980 },
		////hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '3',
				title: `
					Read part 2 of the article. Why is Apollo 13 mission described as a 'successful failure'? Rewrite the underlined clauses in part 2 as participle clauses.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				<div>
					
						<div style="display: flex; width: 100%;">
							<div style="display: flex;">
								<img src="img/FriendsPlus/Page100/E3/1.jpg" style="width: 14cm;" />
								<img src="img/FriendsPlus/Page100/E2/2.jpg" style="width: 14cm;" />
							</div>
						</div>
					<div>
							<div><b>Answers</b></div>
							<textarea id='6' rows='3'></textarea>
							<div style="color:rgb(0,121,133,1);">1 … fitted with its own oxygen tank …</div>	
							<div>2#</div>
							<div>3#</div>
							<div>4#</div>
							<div>5#</div>
							<div>6#</div>
							<div>7#</div>
					</div>

				</div>
				`,
				answer: [

					'...designed to hold only two people...',
					'...following instructions from the ground crew...',
					'...working 24 hours a day...',
					'...discussed on all the TV news programmes...',
					'...risking their lives...',
					'...trying to save them...',
					'Although Apollo did not reach the moon, NASA managed to bring the astronauts back to Earth alive.The crisis was discussed on TV, and people in the USA regained interest in the Space Program.'

				],
			},
		],
	},
	4: {
		unit: 'Science',
		id: 'FG10-S-P100-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page100/Key/E4answerKey.png',
		component: T6v2,
		inputSize: 1050,
		stylesTextInput:{fontSize:23,},
		
		// maxLength: 9,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '4',
				title: `
					Identify the relative clauses in these sentences. (Some contain more than one.) Rewrite them as participle or infinitive clauses.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				<div >
          <b>1</b> <span > The word astronaut, which is formed from two Greek words, means 'star sailor'.</span> <br>
					<div style='margin-bottom:15px'>#</div>
          <b>2</b> <span > Only flights which reach an altitude of 100 km or more are considered space flights. </span> <br>
					<div style='margin-bottom:15px'>#</div>
          <b>3</b> <span > NASA astronauts are given a difficult training programme which they have to complete after twenty months.</span><br>
					<div style='margin-bottom:15px'>#</div>
          <b>4</b> <span > Astronauts who spend long periods in space do exercises which have been designed to keep them strong.</span><br> 
					<div style='margin-bottom:15px'>#</div>         
          <b>5</b> <span > Spacecraft which intend to escape from Earth's gravity need to reach a speed of about 40,000 km/h.</span><br>  
					<div style='margin-bottom:15px'>#</div>        
          <b>6</b> <span > The film Apollo 13, which was made in 1995, is a very interesting film on space travel that should be seen.</span><br> 
					<div>#</div>         
        </div>
				`,
				answer: [
					`The word astronaut, formed from two Greek words, means 'star sailor'.`,
					'Only flights reaching an altitude of 100 km or more are considered space flights.',
					'NASA astronauts are given a difficult training programme to complete after twenty months.',
					'Astronauts spending long periods in space do exercises designed to keep them strong.',
					`Spacecraft intending to escape from Earth's gravity need to reach a speed of about 40,000 km/h.`,
					'The film Apollo 13, made in 1995, is a very interesting film on space travel to be seen.',
				],
			},
		],
	},
	5: {
		unit: 'Science',
		id: 'FG10-S-P100-E5',
		audio: '',
		video: '',
		// exerciseKey: 'img/FriendsPlus/Page100/E5/Key/answerKey.png',
		component: T6,
		inputSize: 35,
		maxLength: 1,
		hideBtnFooter: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '5',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Discuss the questions with your partner. Use the nouns and adjectives below to help you.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				<div style="color: rgb(246, 138, 29)">
					<b>Nouns</b> <span style="padding-left:20px"> adventure danger discomfort excitement fear loneliness</span> <br>
					<b>Adjectives</b> <span style="padding-left:20px"> brave calm fit hard-working intelligent logical serious</span> <br>
				</div>
				<div>
					<b>1</b> <span > What personal qualities do astronauts need ?</span> <br>
          <b>2</b> <span > What would the best thing about the job be ?</span> <br>
          <b>3</b> <span > What would the hardest thing about the job be ?</span> <br>
				</div>
				`,
				answer: [],
			},
		],
		recorder: true,
	},
	6: {
		unit: 'Science',
		id: 'FG10-S-P100-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page100/E6/Key/answerKey.png',
		component: T6,
		inputSize: 70,
		maxLength: 3,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '6',
				title: `
          Decide if the sentences below are true (<i>T</i>), false (<i>F</i>) or ‘does not say’ (<i>DNS</i>).
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; font-size: 20px;">
						<div style="flex: 1; padding: 10px; border-radius: 10px; border: 4px dotted rgb(66, 64, 153); background-color: rgb(228, 227, 241); box-shadow: 3px 3px 5px rgba(0,0,0,0.4)">
							<span style="font-weight: bold; color: rgb(66, 64, 153);">Reading strategy</span>
							<div>
								<ul>
									<li>Read the task instructions carefully.</li>
									<li>Read each statement and underline the key words.</li>
									<li>Look for the relevant section of the text, and read it carefully.</li>
									<li>If the information in the statement is the same as in the text, write T. If not, write F.</li>
									<li>If there is no mention of the fact, or there is not enough information, write DNS.</li>
									<li>Do not use your own knowledge (or guesswork) to fill any information gaps!</li>
								</ul>
							</div>
						</div>

						<div style="margin-left: 20px;flex: 1; padding: 10px; border-radius: 10px; border: 1px solid rgb(66, 64, 153); box-shadow: 3px 3px 5px rgba(0,0,0,0.4);">
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">1</span>
								<span>The Telharmonium was invented before the radio.#</span>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">2</span>
								<span>Thaddeus Cahill destroyed the final model of his invention before he died.#</span>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">3</span>
								<span>The Writing Ball was the first machine that used electricity for typing.#</span>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">4</span>
								<span>Other typing machines were cheaper to make because they were not made by hand.#</span>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">5</span>
								<span>The Antikythera Mechanism was at the bottom of the sea for nearly 2,000 years.#</span>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">6</span>
								<span>For about 70 years after it was found, the purpose of the mechanism was not known.#</span>
							</div>
						</div>
					</div>

					<div style="margin-top: 20px; display: flex; justify-content: center;">
						<img src="img/FriendsPlus/Page100/E2/1.jpg" style="max-width: 100%; border-radius: 10px;" />
					</div>
				`,
				answer: ['T', 'F', 'DNS', 'T', 'DNS', 'T'],
			},
		],
	},
	7: {
		unit: 'Science',
		id: 'FG10-S-P100-E7',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page100/E7/Key/answerKey.png',
		component: T6,
		inputSize: 160,
		maxLength: 15,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '7',
				title: `
          <span style="color: rgb(146, 39, 144)">VOCABULARY</span> 
					Find nouns in the text related to these verbs.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 10px; display: flex; align-items: start;">
						<div style="flex: 3;">
							<img src="img/FriendsPlus/Page100/E2/1.jpg" style="max-width: 100%; border-radius: 10px;" />
						</div>

						<div style="margin-left: 10px; flex: 1 50px; padding: 10px; border-radius: 10px; border: 1px solid rgb(234, 231, 216); font-size: 20px;">
							<span style="font-weight: bold; color: rgb(66, 64, 153);">Noun endings</span>
							<div>
								<strong>Text 1</strong>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">a</span>
									<span>invent #</span>
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">b</span>
									<span>record #</span>
								</div>
							</div>
							<div>
								<strong>Text 2</strong>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">c</span>
									<span>move #</span>
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">d</span>
									<span>arrange #</span>
								</div>
							</div>
							<div>
								<strong>Text 3</strong>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">e</span>
									<span>discover #</span>
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">f</span>
									<span>reconstruct #</span>
								</div>
							</div>
						</div>
					</div>
				`,
				answer: ['invention', 'recordings', 'movement', 'arrangement', 'discovery', 'reconstructions'],
			},
		],
	},
	8: {
		unit: 'Science',
		id: 'FG10-S-P100-E8',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 80,
		maxLength: 5,
		//hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		hintBox: [
			{
				src: ['If the wheel didn’t exist, we couldn’t travel by car or bus.'],
				borderColor: 'rgb(182, 179, 217)',
				arrow: true,
				position: 2,
				width: 525,
			},
			{
				src: ['If we didn’t have aeroplanes, journeys would take much longer.'],
				borderColor: 'rgb(182, 179, 217)',
				arrow: true,
				position: 1,
				width: 525,
			},
		],
		titleQuestion: [
			{
				num: '8',
				title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in groups. Decide which three inventions and discoveries from history are the most important. 
					Use the ideas below or think of your own. Give reasons.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 10px; color: rgb(66, 64, 153);">
						<div>
							<span>aeroplanes</span>
							<span style="padding-left: 30px;">antibiotics</span>
							<span style="padding-left: 30px;">books</span>
							<span style="padding-left: 30px;">cars</span>
							<span style="padding-left: 30px;">computers</span>
						</div>
						<div>
							<span>electricity</span>
							<span style="padding-left: 30px;">fire</span>
							<span style="padding-left: 30px;">printing</span>
							<span style="padding-left: 30px;">television</span>
							<span style="padding-left: 30px;">wheels</span>
						</div>
					</div>

					<div style="margin-top: 20px; text-align: center;"><hintbox id='0'></hintbox></div>
					<div style="margin-top: 50px; margin-left: 150px; text-align: center;"><hintbox id='1'></hintbox></div>
				`,
				answer: [],
			},
		],
	},
}

export default json
