import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 8',
    id: 'FGF11-U8-P104-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page104/E1/Key/answerKey.png',
    component: T6,
    inputSize: 280,
    textareaStyle: { width: 700 },
    maxLength: 23,
    // recorder: true, // Show recorder button
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '1',
        title: `
         Describe the photo and answer the questions below.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div style="display: flex;">
            <div style="box-shadow: 3px 3px 5px rgba(0,0,0,0.4);"><img src="img/FriendsPlus/Page104/E1/1.gif" style="max-width: 100%;" /></div>
           
          </div>

          <div style="margin-top: 20px;">
            <div>
              <span style="padding-right: 10px; font-weight: bold;">1</span>
              <span style="font-weight:bold; color:rgb(149 54 159)">VOCABULARY</span><span> Which of these activities are likely to be on 
offer at or near this location? Where could you do the 
others?</span>				
            </div>
						<div style="color: rgb(103 178 106)">
						<span style="font-weight:bold">Holiday activities</span> <span> fishing hiking horse riding 
kayaking mountain biking scuba diving shopping 
sightseeing skiing swimming</span>
						</div>
            <div>
              <span style="padding-right: 10px; font-weight: bold;">2</span>
              <span>Would you prefer to be on holiday here or in an 
expensive hotel in a city? Give reasons.</span>				
            </div>
						<div style='margin-left:100px'><textarea id='0' rows='4' ></textarea></div>
          </div>
				`,
        answer: [],
      },
    ],
  },
  2: {
    unit: 'Unit 8',
    id: 'FGF11-U8-P104-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page104/key/2.png',
    component: T6,
    inputSize: 100,
    maxLength: 23,
    // recorder: true, // Show recorder button
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '2',
        title: `
         Read the <span style="color:rgb(90,161,86)">Speaking Strategy</span>. Then read the task below. 
Match the following (1–8) with the four points in the task. 
Use them to discuss with a friend.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				<img src="img/FriendsPlus/Page104/E2/1.gif" style="width: 800px;" />
				<div style="display:flex; font-size:29px">
					<div style='margin-right: 200px'>
						<b>1</b>&emsp;a job<br>
						<b>2</b>&emsp;a month of the year<br>
						<b>3</b>&emsp;a popular tourist area<br>
						<b>4</b>&emsp;buses and trains
				 
					</div>

						<div>

								<b>5</b>&emsp;a week / a fortnight<br>
								<b>6</b>&emsp;borrowing / lending<br>
								<b>7</b>&emsp;youth hostels<br>
								<b>8</b>&emsp;beaches and the sea</div>
						</div>
				</div>

				<div style="margin-top:30px;font-size:28px">
				  <ul>
					   <li>When you want to go and for how long #</li> 
						   <li>Choice of destination #</li> 
							   <li>Transport #</li> 
								   <li>Paying for the holiday #</li> 
					</ul>
				
				</div>
				`,
        answer: ['2,5', '3,8', '4', '1,6'],
      },
    ],
  },
  3: {
    unit: 'Unit 8',
    id: 'FGF11-U8-P104-E3',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page104/E3/Key/answerKey.png',
    component: T6,
    inputSize: 700,
    // maxLength: 1,
    recorder: true,
    hideBtnFooter: true,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button

    titleQuestion: [
      {
        num: '3',
        title: `
          <span style="color:rgb(66 153 210);font-weight:bold">PRONUNCIATION</span> Read the <span style="color:rgb(64 173 119);font-weight:bold" >Learn this!</span> box. Write reply
questions for statements 1, 3 and 5 and add question tags to
statements 2, 4 and 6. Use the falling or rising intonation to
practise saying the sentences.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div style="display: flex;flex-direction:column; line-height:2">
					 <div style=""><img src="img/FriendsPlus/Page104/E3/1.png" style="width: 700px;" /></div>
						<div style="display: flex; flex-direction: column; justify-content: space-between;margin-top:10px">
						  
							 <span><span style="font-weight:bold;margin-right:5px">1 </span> That’s a long time!</span> 
							 #
							 <span><span style="font-weight:bold;margin-right:5px">2 </span> The Baltic coast is lovely.#</span>
							 <span><span style="font-weight:bold;margin-right:5px">3 </span> There’s more to do there.</span>
							 #
							 <span><span style="font-weight:bold;margin-right:5px">4 </span> We need quite a lot of money.#</span>
							 <span><span style="font-weight:bold;margin-right:5px">5 </span> My parents will give me some money too.</span>
							 #
							 <span><span style="font-weight:bold;margin-right:5px">6 </span> That’s kind of them.#</span>
							 
						

						
						</div>

           
          </div>
				`,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Unit 8',
    id: 'FGF11-U8-P104-E4',
    audio: 'Audios/3.18-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page104/key/4.png',
    component: T6,
    inputSize: 600,
    maxLength: 255,
    textareaStyle: { width: 800 },
    hideBtnFooter: false, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '4',
        title: `
					<span style="font-weight:bold"><headphone1 name="3.18"></headphone1> Listen and check your answers to exercise 3. </span>
					
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				 <div style="display:flex;flex-direction:column">
				 	 <div style=""><img src="img/FriendsPlus/Page104/E4/1.png" style="width:800px;margin-bottom:20px" /></div>
					<span>1 #</span> 
					<span>2 #</span>
					<span>3 #</span>
					<span>4 #</span>
					<span>5 #</span>
					<span>6 #</span>
					 </div>
				`,
        answer: [
          "'That's a long time!' 'Is it?'",
          "The Baltic coast is lovely, isn't it?",
          "'There's more to do there.' 'Is there?'",
          "We need quite a lot of money, don't we ? ",
          "'My parents will give me some money too.' 'Will they?'",
          "That's kind of them, isn't it?",
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 8',
    id: 'FGF11-U8-P104-E5',
    // audio: 'Audios/Friends Global Grade 10 SB_CD3_22.mp3',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page104/E5/Key/answerKey.png',
    component: T6,
    inputSize: 750,
    hideBtnFooter: true,
    maxLength: 68,
    titleQuestion: [
      {
        num: '5',
        title: `
				<span style="color:rgb(186 119 185);font-weight:bold">VOCABULARY</span> Work in pairs. Check the meaning of the words
below. Then discuss which types of accommodation you
prefer.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<span style="display:flex;flex-direction:column; color:rgb(90 161 86);">

     
					<span style="display:flex;flex-direction:row;">
						Holiday <span style="font-weight:bold;margin-left:10px"> accommodation</span> B&B (bed and breakfast)
					</span>
				<span  style="display:flex;flex-direction:row">
				campsite caravan site holiday camp hostel 
				</span>

				<span style="display:flex;flex-direction:row">
				hotel self-catering apartment villa
				</span>
				

					</span>
				
				`,
        answer: [],
      },
    ],
  },
  6: {
    unit: 'Unit 8',
    id: 'FGF11-U8-P104-E6',
    // audio: 'Audios/Friends Global Grade 10 SB_CD3_22.mp3',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page104/E6/Key/answerKey.png',
    component: T6,
    hideBtnFooter: true,
    textareaStyle: { width: 700 },
    inputSize: 40,
    // maxLength: 1,
    titleQuestion: [
      {
        num: '6',
        title: `
				Read the task below. Think about what ideas and
vocabulary you will need for each point. Use notes 1–3
below to help you.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div style="display:flex;flex-direction:column">
					 <div style=""><img src="img/FriendsPlus/Page104/E6/1.png" style="width:800px;margin-bottom:20px" /></div>
					 <span> <span style="font-weight:bold; margin-right:10px">1</span>Think about destinations you know well and can talk about.</span>
					  <span> <span style="font-weight:bold; margin-right:10px">2</span>Use the lists of words in exercises 1 and 5.</span>
						 <span> <span style="font-weight:bold; margin-right:10px">3</span>Think about clothing, equipment (for activities) and
entertainment (books, gadgets, etc.).</span>
					</div>
					<textarea id='0' rows='5'></textarea>
				`,
        answer: [],
      },
    ],
  },
  7: {
    unit: 'Unit 8',
    id: 'FGF11-U8-P104-E7',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page104/E7/Key/answerKey.png',
    component: T6,
    inputSize: 420,
    maxLength: 46,
    recorder: true,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    textareaStyle: { width: 500 },
    titleQuestion: [
      {
        num: '7',
        title: `
         <span style="color: rgb(90,161,86)">	SPEAKING</span> 
			 Work in pairs. Do the task in exercise 6. Decide
who is playing which role. Make use of reply questions and
question tags.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div><img src="img/FriendsPlus/Page104/E7/1.png" style="width:700px" /></div>
				
				`,
        answer: [],
      },
    ],
  },
};

export default json;
