import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
  1: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P120-U4-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '1',
        title:
          "Correct the mistakes in the sentences.",
        color: '#860052',
      },
    ],
    stylesTextInput: {
      fontSize: 23,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 430,
    exerciseKey: '/img/FriendsPlus/Page120/key/E1answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
					<div>
            <p><span style="font-weight:bold;margin-right:10px">1.</span>I’m busyer today than I was yesterday.</p>
            <p>#</p>
            <p><span style="font-weight:bold;margin-right:10px">2.</span>He’s the shortest boy of the class.</p>
            <p>#</p>
            <p><span style="font-weight:bold;margin-right:10px">3.</span>Kate’s more short than Alice.</p>
            <p>#</p>
            <p><span style="font-weight:bold;margin-right:10px">4.</span>You’re not as clever than me.</p>
            <p>#</p>
            <p><span style="font-weight:bold;margin-right:10px">5.</span>Today is longest day of the year.</p>
            <p>#</p>
          </div>
				`,
        answer: [
          "I'm busier today than I was yesterday.", "He's the shortest boy in the class.", "Kate's shorter than Alice.", "You're not as clever as me.", "Today is the longest day of the year."
        ],
      },
    ],
  },
  2: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P120-U4-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title:
          "Complete the sentences with the comparative form of the adverb in brackets and <i>than</i>.",
        color: '#860052',
      },
    ],
    stylesTextInput: {
      fontSize: 23,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 250,
    exerciseKey: '/img/FriendsPlus/Page120/key/E2answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div>
						<p><span style="font-weight:bold;margin-right:10px">1. </span>Mum drives # Dad. (fast)</p>
						<p><span style="font-weight:bold;margin-right:10px">2. </span>Dad drives # Mum. (well)</p>
						<p><span style="font-weight:bold;margin-right:10px">3. </span>Fred writes # Grace. (beautifully)</p>
						<p><span style="font-weight:bold;margin-right:10px">4. </span>Harry works # me. (slowly)</p>
						<p><span style="font-weight:bold;margin-right:10px">5. </span>Pete usually arrives at school than # everyone else. (late)</p>
						<p><span style="font-weight:bold;margin-right:10px">6. </span>Who speaks French #: Dave or Bess? (fluently)</p>
						
					</div>	

        `,
        answer: ['faster than', 'better than', 'more beautifully than', 'more slowly than', 'later than', 'more fluently'],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P120-U4-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page120/key/E3answerKey.png',
    inputSize: 200,
    checkUppercase: true,

    stylesTextInput: {
      fontSize: 24,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    titleQuestion: [
      {
        num: '3',
        title: "Complete the sentences with the superlative form of the adverb in brackets.",
        color: '#860052',
      },
    ],
    questions: [
      {
        title: /*html */ `
          <div>
						<p><span style="font-weight:bold;margin-right:10px">1. </span>Who writes # ? (neatly)</p>
						<p><span style="font-weight:bold;margin-right:10px">2. </span>Sarah finished her homework #. (fast)</p>
						<p><span style="font-weight:bold;margin-right:10px">3. </span>Tom plays chess #. (badly)</p>
						<p><span style="font-weight:bold;margin-right:10px">4. </span>Joe speaks #. (loud)</p>
						<p><span style="font-weight:bold;margin-right:10px">5. </span>Ian definitely tries # of all of us. (hard)</p>
						<p><span style="font-weight:bold;margin-right:10px">6. </span>Who gets up # in your family? (early)</p>
						
					</div>	
        `,
        answer: [
          'the most neatly', 'the fastest', 'the worst', 'the loudest', 'the hardest', 'the earliest',
        ],
      },
    ],
  },

}

export default json
