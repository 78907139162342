import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P96-E1',
		audio: '',
		video: '',
		// exerciseKey: '',
		component: T6,
		inputSize: 280,
		recorder: true,
		hideBtnFooter: true,
		maxLength: 23,
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
         Do you love living in a city? What do you think are the benefits of living 
in a big city?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          
				`,
				answer: ['']
				,
			},
		],
	},
	2: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P96-E2',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page96/key/E2answerKey.png',
		component: T6,
		inputSize: 300,
		maxLength: 54,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 24,
		},
		textareaStyle: { width: 800 },
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '2',
				title: `
          Work in pairs. Match the photos A–E with the cities and the countries. What do the 
countries have in common?
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				<div>
          <div>
					<p style="color:rgb(107 30 105);font-weight:500"><span style="margin-right:50px;font-weight:bold">Cities</span>      <span style="margin-right:20px">Bangkok</span> <span style="margin-right:60px">Ha Noi</span> <span style="margin-right:50px">Jakarta</span> <span style="margin-right:130px">Manila</span> <span>Bandar Seri Begawan</span> 
					<br><span style="margin-right:6px;font-weight:bold">Countries</span>  <span style="margin-right:45px">Brunei</span> <span style="margin-right:29px">Indonesia</span> <span style="margin-right:35px">Thailand</span> <span style="margin-right:40px">the Philippines</span> <span>Viet Nam</span></p>
					</div>
					<div>
					<p style="color:rgb(116 143 204)">Photo D – Ha Noi – Viet Nam</p>
					<img src="/img/FriendsPlus/Page96/E2/1.jpg" alt="">
					</div>
					<div style="margin-top:20px; margin-left:50px">
						<p><span style="font-weight:bold;margin-right:20px">A</span># - #</p>
						<p><span style="font-weight:bold;margin-right:20px">B</span># - #</p>
						<p><span style="font-weight:bold;margin-right:20px">C</span># - #</p>
						<p><span style="font-weight:bold;margin-right:20px">D</span># - #</p>
						<p><span style="font-weight:bold;margin-right:20px">E</span># - #</p>
					</div>
					<div style="margin-top:40px">
						<p style="font-weight: bold">Common:</p>
						<textarea id="10" rows="1"></textarea>
					</div>
				</div>
				<div>

				</div>
				`,
				answer: [
					'Manila', 'the Philippines', 'Bangkok', 'Thailand', 'Bandar Seri Begawan', 'Brunei', 'Ha Noi', 'Viet Nam', 'Jakarta', 'Indonesia', 'All of the cities are located in South East Asia.'
				],
			},
		],
	},
	3: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P96-E3',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page96/key/E3answerKey.png',
		component: T6,
		inputSize: 130,
		maxLength: 50,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 24,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
          Work in pairs. Check the meaning of the following words. Identify the words that 
are in the wrong groups.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div>
						<div>
							<p><span style="font-weight:bold;color:rgb(146,38,143);margin-right:15px">People </span><span style="color:rgb(146,38,143);margin-right:15px">computer </span><span style="color:rgb(146,38,143);margin-right:15px">immigrant </span><span style="color:rgb(146,38,143);margin-right:15px">resident </span><span style="color:rgb(146,38,143);margin-right:15px">street vendor </span><span style="color:rgb(146,38,143);margin-right:15px">temple </span><span style="color:rgb(146,38,143);margin-right:15px">visitor </span></p>
							<p><span style="font-weight:bold;color:rgb(146,38,143);margin-right:15px">Places </span><span style="color:rgb(146,38,143);margin-right:15px">antenna tower </span><span style="color:rgb(146,38,143);margin-right:15px">boulevard</span><span style="color:rgb(146,38,143);margin-right:15px">bus stop </span><span style="color:rgb(146,38,143);margin-right:15px">car park </span><span style="color:rgb(146,38,143);margin-right:15px">city hall </span><span style="color:rgb(146,38,143);margin-right:15px">lake </span><br><span style="color:rgb(146,38,143);margin-right:15px;margin-left:100px">monument </span><span style="color:rgb(146,38,143);margin-right:15px">mosque </span><span style="color:rgb(146,38,143);margin-right:15px">pagoda </span><span style="color:rgb(146,38,143);margin-right:15px">park </span><span style="color:rgb(146,38,143);margin-right:15px">skyscraper </span><span style="color:rgb(146,38,143);margin-right:15px">stadium  </span><span style="color:rgb(146,38,143);margin-right:15px">theatre  </span></p>
							<p><span style="font-weight:bold;color:rgb(146,38,143);margin-right:15px">Means of transport </span><span style="color:rgb(146,38,143);margin-right:15px">hustle </span><span style="color:rgb(146,38,143);margin-right:15px">pedicab </span><span style="color:rgb(146,38,143);margin-right:15px">taxi  </span><span style="color:rgb(146,38,143);margin-right:15px">tram </span><span style="color:rgb(146,38,143);margin-right:15px">tube </span><span style="color:rgb(146,38,143);margin-right:15px">tuk-tuk  </span></p>
							<p><span style="font-weight:bold;color:rgb(146,38,143);margin-right:15px">City problems </span><span style="color:rgb(146,38,143);margin-right:15px">air pollution </span><span style="color:rgb(146,38,143);margin-right:15px">fountain	 </span><span style="color:rgb(146,38,143);margin-right:15px">noise pollution  </span><br><span style="color:rgb(146,38,143);margin-right:15px;margin-left:190px">overpopulation  </span><span style="color:rgb(146,38,143);margin-right:15px">traffic jams  </span></p>
						</div>
						<div>
							<h1>Answer:</h1>
							<p><span style="font-style:italic">The words which are put in the wrong group are</span> #, #, #.</p>
						</div>
					</div>
				`,
				answer: ['temple', 'hustle', 'fountain'],
			},
		],
	},
	4: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P96-E4',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page96/key/E4answerKey.png',
		component: T6,
		inputSize: 200,
		maxLength: 50,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 24,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '4',
				title: `
          Which words in exercise 3 can you use to describe the photos A–E? 
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
         
					
					<img src="/img/FriendsPlus/Page96/E4/1.jpg" alt="">
				<div style="display:flex;margin-top:-30px;">
					<div style=" margin: 0px 50px">
						<p><span style="font-weight:bold;margin-right:20px">A</span># - #</p>
						<p><span style="font-weight:bold;margin-right:20px">B</span># - #</p>
						<p><span style="font-weight:bold;margin-right:20px">C</span># - #</p>
						
					</div>
						<div>
							<p><span style="font-weight:bold;margin-right:20px">D</span># - #</p>
							<p><span style="font-weight:bold;margin-right:20px">E</span># - #</p>
					</div>
				</div>
			
				`,
				answer: ['park', 'monument', 'tuk tuk', 'pagoda', 'mosque', 'lake', 'street vendor', 'pedicab', 'bus stop', 'visistors'],
			},
		],
	},
	5: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P96-E5',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page96/key/E5answerKey.png',
		component: T6,
		inputSize: 250,
		maxLength: 19,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 24,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '5',
				title: `
          Complete the following sentences using the vocabulary in exercise 3. Check your 
					answers with your partner’s.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				<div style="margin-top: 30px">
				<div style="">
				<div>
							<p><span style="font-weight:bold;color:rgb(146,38,143);margin-right:15px">People </span><span style="color:rgb(146,38,143);margin-right:15px">computer </span><span style="color:rgb(146,38,143);margin-right:15px">immigrant </span><span style="color:rgb(146,38,143);margin-right:15px">resident </span><span style="color:rgb(146,38,143);margin-right:15px">street vendor </span><span style="color:rgb(146,38,143);margin-right:15px">temple </span><span style="color:rgb(146,38,143);margin-right:15px">visitor </span></p>
							<p><span style="font-weight:bold;color:rgb(146,38,143);margin-right:15px">Places </span><span style="color:rgb(146,38,143);margin-right:15px">antenna tower </span><span style="color:rgb(146,38,143);margin-right:15px">boulevard</span><span style="color:rgb(146,38,143);margin-right:15px">bus stop </span><span style="color:rgb(146,38,143);margin-right:15px">car park </span><span style="color:rgb(146,38,143);margin-right:15px">city hall </span><span style="color:rgb(146,38,143);margin-right:15px">lake </span><br><span style="color:rgb(146,38,143);margin-right:15px;margin-left:100px">monument </span><span style="color:rgb(146,38,143);margin-right:15px">mosque </span><span style="color:rgb(146,38,143);margin-right:15px">pagoda </span><span style="color:rgb(146,38,143);margin-right:15px">park </span><span style="color:rgb(146,38,143);margin-right:15px">skyscraper </span><span style="color:rgb(146,38,143);margin-right:15px">stadium  </span><span style="color:rgb(146,38,143);margin-right:15px">theatre  </span></p>
							<p><span style="font-weight:bold;color:rgb(146,38,143);margin-right:15px">Means of transport </span><span style="color:rgb(146,38,143);margin-right:15px">hustle </span><span style="color:rgb(146,38,143);margin-right:15px">pedicab </span><span style="color:rgb(146,38,143);margin-right:15px">taxi  </span><span style="color:rgb(146,38,143);margin-right:15px">tram </span><span style="color:rgb(146,38,143);margin-right:15px">tube </span><span style="color:rgb(146,38,143);margin-right:15px">tuk-tuk  </span></p>
							<p><span style="font-weight:bold;color:rgb(146,38,143);margin-right:15px">City problems </span><span style="color:rgb(146,38,143);margin-right:15px">air pollution </span><span style="color:rgb(146,38,143);margin-right:15px">fountain	 </span><span style="color:rgb(146,38,143);margin-right:15px">noise pollution  </span><br><span style="color:rgb(146,38,143);margin-right:15px;margin-left:190px">overpopulation  </span><span style="color:rgb(146,38,143);margin-right:15px">traffic jams  </span></p>
						</div>
				</div>
					<div>
						<p><span style="font-weight:bold;color:rgb(122 33 120);margin-right:10px">1</span> Kuala Lumpur Tower is the world’s 7th tallest tower with an # at the top. </p>
						<p><span style="font-weight:bold;color:rgb(122 33 120);margin-right:10px">2</span> The first two lines for # were built in Ha Noi in 1901, Line 1 leading to Bach Mai and Line 2 to Giay Village.  </p>
						<p><span style="font-weight:bold;color:rgb(122 33 120);margin-right:10px">3</span> Many tourists love to visit an Islamic # to see the difference between it and a pagoda. </p>
						<p><span style="font-weight:bold;color:rgb(122 33 120);margin-right:10px">4</span> In front of the city hall is a # garden with a in the middle. </p>
						<p><span style="font-weight:bold;color:rgb(122 33 120);margin-right:10px">5</span> Baiyoke Tower II is an 88-storey # in Bangkok.  </p>
						<p><span style="font-weight:bold;color:rgb(122 33 120);margin-right:10px">6</span> Most city dwellers want to escape the # and bustle of the city for the weekend. </p>
						<p><span style="font-weight:bold;color:rgb(122 33 120);margin-right:10px">7</span> The tube is overcrowded with who # travel a long way to work every day.  </p>
						<p><span style="font-weight:bold;color:rgb(122 33 120);margin-right:10px">8</span> Thong Nhat, a multi-purpose # located in District 10 in Ho Chi Minh City, has a capacity of 15,000.  </p>
					</div>
				</div>
				`,
				answer: ['antenna tower', 'tram', 'mosque', 'fountain', 'skyscrapper/building', 'hustle', 'commuters', 'stadium'],
			},
		],
	},
	6: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P96-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page96/E6/Key/answerKey.png',
		component: T6,
		inputSize: 200,
		maxLength: 17,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '6',
				title: `
          Complete the questions about the reading texts in Units 1-7 
					using the correct passive form of the verbs in brackets.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>
								Why # Ashlyn Blocker # (watch) more carefully 
								than all the other children at her school? [1F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">2</strong>
							<span>
								# Dan and Kate Suski # (rescue) 
								by helicopter after their boat sank? [2F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">3</strong>
							<span>
								What skills # (improve) by playing online video games, 
								according to a report? [3F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">4</strong>
							<span>
								Who # (nearly / kill) by a storm at a paragliding event? [4F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">5</strong>
							<span>
								Where # Andrew Johnson # (employ) as a Master Model Builder? [5F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">6</strong>
							<span>
								Where # meetings # (sometimes / hold) if Aaron Levie 
								is choosing the venue? [6F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">7</strong>
							<span>
								What # Hillary Bradt # (show) to do on her holiday? [7F]
							</span>				
						</div>
					</div>
				`,
				answer: [
					'was',
					'watched',
					'Were',
					'rescued',
					'are improved',
					'was nearly killed',
					'is',
					'employed',
					'are',
					'sometimes held',
					'was',
					'shown',
				],
			},
		],
	},
	7: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P96-E7',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 200,
		maxLength: 17,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 20,
		},
		//hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '7',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in pairs. Ask and answer the questions in exercise 6.

				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>
								Why # Ashlyn Blocker _____ (watch) more carefully 
								than all the other children at her school? [1F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">2</strong>
							<span>
								_____ Dan and Kate Suski _____ (rescue) 
								by helicopter after their boat sank? [2F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">3</strong>
							<span>
								What skills _____ (improve) by playing online video games, 
								according to a report? [3F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">4</strong>
							<span>
								Who _____ (nearly / kill) by a storm at a paragliding event? [4F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">5</strong>
							<span>
								Where _____ Andrew Johnson _____ (employ) as a Master Model Builder? [5F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">6</strong>
							<span>
								Where _____ meetings _____ (sometimes / hold) if Aaron Levie 
								is choosing the venue? [6F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">7</strong>
							<span>
								What _____ Hillary Bradt _____ (show) to do on her holiday? [7F]
							</span>				
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
