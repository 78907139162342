import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P110-U4-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: ` <strong style='font-size: 800'><span style='color:#860052; font-weight: 800; margin-right: 10px'>4G</span>In the house</strong> <br />
         <strong style='color:#860052'>3</strong> Match the pictures (1–6) with six words from the list below. `,
        // color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 150,
    exerciseKey: 'img/FriendsPlus/Page110/Key/U4E3answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `

      <div style='display:flex;'>
      <div style='flex:1'>
        <div style='width: 550px; >
          <div style='font-size:21px;'>
              <div style="gap: 20px;color:rgb(146,39,143);">
              &ensp;armchair
              &ensp;basin
              &ensp;blinds
              &ensp;bucket
              &ensp;bunk bed
              &ensp;carpet
              &ensp;chandelier
              &ensp;cot
              &ensp;curtains
              &ensp;desk lamp
              &ensp;dishwasher
              &ensp; doormat
              &ensp;duvet
              &ensp;fireplace
              &ensp;freezer
              &ensp;fridge
              &ensp; hairdryer
              &ensp;kettle
              &ensp;mattress
              &ensp;pillow
              &ensp;radiator
              &ensp;rug
              &ensp;sink
              &ensp;sofa
              &ensp;shutters
              &ensp;stool
              &ensp;toaster
              &ensp;tumble dryer
              &ensp;wall light
              &ensp;wardrobe
              &ensp; washing machine
            </div>

          </div>
      </div>
      <div style ='flex:1'>
      <div>
          <img src='img/FriendsPlus/Page110/U4-E3/1.jpg' style='max-width:100%'>
          </div>
      </div>
      </div>


           <div style=' margin:10px 100px;display:flex; justify-content: space-between; margin-top:30px;'>
          <b>1</b>#&emsp;
          <b>2</b>#&emsp;
          <b>3</b>#&emsp;
          <b>4</b>#&emsp;
          <b>5</b>#&emsp;
          <b>6</b>#&emsp;
          </div>
				`,
        answer: ['stool', 'cot', 'chandelier', `pillow`, 'bucket', 'kettle'],
      },
    ],
  },
  2: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P110-U4-E4',
    audio: '',
    video: '',
    component: T6v2,
    titleQuestion: [
      {
        num: '4',
        title: ' In the list of words in exercise 3, find ',
        color: '#860052',
      },
    ],
    stylesTextInput: {
      fontSize: 18,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 170,
    exerciseKey: 'img/FriendsPlus/Page110/Key/U4E4answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
        <div style='padding:10px;font-size:22px'>
            <b>1</b>&ensp;three things you sit on.<br>
            <div style=' margin:1px 100px;display:flex;'>
          #&emsp;
          ,#&emsp;
          ,#&emsp;
          </div>
            <b>2</b>&ensp;four things you can fill with water.<br>
              <div style=' margin:5px; 100px;display:flex; '>
          #&emsp;,
          #&emsp;,
          #&emsp;,
          #&emsp;
          </div>
            <b>3</b>&ensp;at least nine things powered by electricity.<br>
             <div style='display:flex; '>
          #&emsp;,
          #&emsp;,
          #&emsp;,
          #&emsp;,
          #&emsp;

          </div>
          <div style='display:flex; '>
          #&emsp;,
          #&emsp;,
          #&emsp;,
          #&emsp;,
          #&emsp;



          </div> 
           <div style='display:flex; '>
          #&emsp;,
          #&emsp;,
          #&emsp;,
          #&emsp;



          </div>
            <b>4</b>&ensp;six things you usually find only in a bedroom.<br>
            <div style='display:flex; '>
          #&emsp;,
          #&emsp;,
          #&emsp;,
          #&emsp;,
          #&emsp;,
          #&emsp;
                 </div>
            <b>5</b>&ensp;three things that cover windows.<br>
             <div style=' margin:5px; 100px;display:flex; '>
          #&emsp;,
          #&emsp;,
          #&emsp;,
          </div>
            <b>6</b>&ensp;three forms of lighting.<br>
             <div style=' margin:5px; 100px;display:flex; '>
          #&emsp;,
          #&emsp;,
          #&emsp;
          </div>
            <b>7</b>&ensp;two things which can make a house warmer.<br>
            <div style=' margin:5px; 100px;display:flex; '>
          #&emsp;,
          #&emsp;,
          </div>
            <b>8</b>&ensp;three things which you use to cover the floor, or part of it.
            <div style=' margin:5px; 100px;display:flex; '>
            #&emsp;,
            #&emsp;,
            #&emsp;
          </div>

            

        </div>
				`,
        answer: [
          `armchair`,
          `sofa`,
          `stool`,
          `basin`,
          `bucket`,
          `kettle`,
          `sink`,
          `chandelier`,
          `desk lamp`,
          `dishwasher`,
          `freezer`,
          `fridge`,
          `hairdryer`,
          `kettle`,
          `toaster`,
          ` tumble dryer`,
          ` wall light`,
          'washing machine',
          `(electric) blinds `,
          `(electric) fireplace `,
          `(electric) radiator`,
          ` bunk bed`,
          `cot`,
          `duvet`,
          `mattress`,
          `pillow`,
          `wardrobe`,
          `blinds`,
          `curtains`,
          `shutters`,
          `chandelier`,
          `desk lamp`,
          `wall light`,
          `fireplace`,
          `radiator`,
          `carpet`,
          `doormat`,
          `rug`,
        ],
      },
    ],
  },
  3: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P110-U5-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page110/Key/U5E1answerKey.png',
    titleQuestion: [
      {
        num: '',
        title: ` <strong style='font-size: 800'><span style='color:#860052; font-weight: 800; margin-right: 10px'>5F</span>Verb–noun collocations</strong> <br />
         <b style='color:#860052'>1</b> Cross out the one verb which <span style='border-bottom:1px solid black'>cannot</span> be used to complete each sentence grammatically. The sentences may have different meanings with each correct verb.  `,
        // color: "#f58024",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: 'black',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          'fail / set / make / complete ', //0
          ' took_part_in / held / organised / opened ', //1
          'informing / receiving / delivering / passing_on', //2
          'got_into / overheard / joined_in / took_up ', //3
          'guess / speculate / offer / expect',
          'gained / deserved / claimed / were_awarded ',
          ' log_onto / update / set_up / post  ',
          ' crack / decipher / smash / unlock',
        ],
        answers: ['0-8', '1-14', '2-0', '3-12', '4-4', '5-0', '6-14', '7-10'],
        initialValue: [],
      },
      Layout: `
       <div style='line-height:2.25;'>
        <div><b>1</b> Today is not the best time to  <b><input id='0' type='Circle' /></b> the test.</div>
        <div><b>2</b>  Our class  <b><input id='1' type='Circle' /></b> a history competition. </div>
        <div><b>3</b>  I hate  <b><input id='2' type='Circle' /></b> messages that are upsetting. </div>
        <div><b>4</b>  John  <b><input id='3' type='Circle' /></b> an interesting conversation about robots in the canteen. </div>
        <div><b>5</b> I’m sure some of us might  <b><input id='4' type='Circle' /></b> some kind of an answer.</div>
        <div><b>6</b>  The team  <b><input id='5' type='Circle' /></b>  a prize for their entry.</div>
        <div><b>7</b>   We regularly   <b><input id='6' type='Circle' /></b>  promotional websites for marketing. </div>
        <div><b>8</b>  Do you think we’ll ever be able to   <b><input id='7' type='Circle' /></b>  the secret codes? </div>


       </div>
      `,
    },
  },
  4: {
    // Exercise num
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P110-U5-E2',
    audio: '',
    video: '',
    component: MatchDots,
    exerciseKey: 'img/FriendsPlus/Page110/Key/U5E2answerKey.png',
    titleQuestion: [
      {
        num: '',
        title: ` <strong style='font-size: 800'><span style='color:#860052; font-weight: 800; margin-right: 10px'>5H</span>Describing computer equipment </strong> <br />
         <strong style='color:#860052'>2</strong> Match a–f with 1–6 and g–l with 7–12.   `,
        // color: '#2284c8',
      },
    ],
    stylesTextInput: { borderBottom: '10px dashed', textAlign: 'center' },
    inputSize: 50,
    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '30px',
              left: '175px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // 1
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '80px',
              left: '175px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // 2
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '135px',
              left: '175px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // 3
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '185px',
              left: '175px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          },
          // 4
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '240px',
              left: '175px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          },
          // 5
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '285px',
              left: '175px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, //6
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '30px',
              left: '670px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // 7
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '80px',
              left: '670px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // 8
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '135px',
              left: '670px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // 9
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '185px',
              left: '670px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          },
          // 10
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '240px',
              left: '670px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          },
          // 11
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '285px',
              left: '670px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          },
          // A
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '30px',
              left: '288px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // B
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '80px',
              left: '288px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // C
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '135px',
              left: '288px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // D
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '185px',
              left: '288px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // E
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '240px',
              left: '288px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          },
          // F
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '285px',
              left: '288px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          },
          // G
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '30px',
              left: '775px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // H
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '80px',
              left: '775px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // J
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '135px',
              left: '775px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // K
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '185px',
              left: '775px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // L
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '240px',
              left: '775px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          },
          // G
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '285px',
              left: '775px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          },

          // 7-12: id-6->11  thứ 3,
          //  12-17: A->F cột thứ 2
          // 18-23 -> cột t4
        ],
        // answers: ['0-17', '1-12', `2-15`, `3-16`, '4-14', '5-13', '6-23', `7-21`, `8-22`, `9-19`, `10-20`, `11-18`,],
        answers: ['0-17', '1-12', '15-2', '16-3', '14-4', '13-5', '23-6', '21-7', '22-8', '19-9', '10-20', '11-18'],

        initialValue: [],
      },
      Layout: `
         <div style ='display:flex;width:1000px; position:relative;line-height:2'>
         <div style ='flex:1'>
          <div style='display: flex; font-size: 26px;'>
            <div >
                <div><b> 1  </b>operating</div>
                <div><b> 2  </b>all-in-one </div>
                <div><b> 3  </b>wireless </div>
                <div><b> 4  </b>widescreen </div>
                <div><b> 5  </b>battery </div>
                <div><b> 6  </b>hard </div>

  </div>
            <div style ='margin-left:170px' >
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> A </b></div>
                <div>  desktop </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> B </b></div>
                <div> drive </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> C </b></div>
                <div> life </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> D </b></div>
                <div> mouse </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> E </b></div>
                <div> display </div>
              </div>
               <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> F </b></div>
                <div> system </div>
              </div>
            </div>
          </div>
         
         
         </div>

         <div style='flex:1'>
          <div style='display: flex;   font-size: 26px;'>
            <div >
                <div><b> 7  </b>flash</div>
                <div><b> 8  </b>graphics</div>
                <div><b> 9  </b>anti-virus </div>
                <div><b> 10  </b>spam </div>
                <div><b> 11  </b>Trojan </div>
                <div><b> 12  </b>search
</div>

            </div>
            <div style ='margin-left:180px' >
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> G </b></div>
                <div>  engine </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> H </b></div>
                <div> filter </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> I </b></div>
                <div> horse </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> J </b></div>
                <div> card </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> K </b></div>
                <div> protection </div>
              </div>
               <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> L </b></div>
                <div> drive </div>
              </div>
            </div>
          </div>
         
         
         </div>
         
         </div>


         <div style='' >
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
          <input id='12' type= 'boxMatch' />
          <input id='13' type= 'boxMatch' />
          <input id='14' type= 'boxMatch' />
          <input id='15' type= 'boxMatch' />
          <input id='16' type= 'boxMatch' />
          <input id='17' type= 'boxMatch' />
          <input id='18' type= 'boxMatch' />
          <input id='19' type= 'boxMatch' />
          <input id='20' type= 'boxMatch' />
          <input id='21' type= 'boxMatch' />
          <input id='22' type= 'boxMatch' />
          <input id='23' type= 'boxMatch' />


         </div>
          
      `,
    },
  },
  5: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P110-U5-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: ` <strong style='color:#860052'>3</strong> Complete the sentences with compounds from exercise 2.    `,
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 22,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 215,
    exerciseKey: 'img/FriendsPlus/Page110/Key/U5E3answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              ‘What type of computer have you got?’ ‘It’s an  <input />

            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              The <input />
               on my laptop isn’t great so I have to keep recharging it.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
               I’ll copy lots of songs onto a  <input />
              so you can load them onto your computer.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
             You can stop most unwanted emails if you install a  I <input />

            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              You use a  <input />
              by entering a keyword in the box and pressing enter.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              You need a really good <input />
               in your computer if you want to play games with fast-moving and detailed images.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>7</strong>
            <div>
              <input />
               helps to protect the data on your computer from hackers.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>8</strong>
            <div>
              Occasionally a  <input />
             is attached to something you download from the internet. This might allow someone to gain access to your computer.
            </div>
          </div>
				`,
        answer: [
          'all-in-one desktop',
          'battery life',
          'flash drive',
          'spam filter',
          'search engine',
          'graphics card',
          'anti-virus protection',
          'trojan horse',
        ],
      },
    ],
  },
  6: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P110-U6-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: ` <strong style='font-size: 800'><span style='color:#860052; font-weight: 800; margin-right: 10px'>6F</span>Verb + preposition</strong> <br />
         <b style='color:#860052'>1</b> Complete the sentences with the prepositions and verbs below.   `,
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 22,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 150,
    exerciseKey: 'img/FriendsPlus/Page110/Key/U6E1answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
        <div style='width: 1100px; >
          <div style='font-size:21px;dislay:flex'>
              <div style="color:rgb(146,39,143);  ">
              &ensp;about
              &ensp;apply
              &ensp;apologise
              &ensp;believe
              &ensp;congratulate
              &ensp;experiment
              &ensp; for
              &ensp;from
              &ensp;like
              &ensp;object
              &ensp;on
              &ensp;to
            </div>

          </div>
      </div>
          <div style="display: flex; column-gap: 20px; margin-top:5px">
            <strong>1</strong>
            <div>
              <div>If you want to visit the USA, you’ll have to
              <input /> for a visa.
              </div>

            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
             <div>I feel
              <input /> an ice cream. We can get one at the corner shop.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div>Do you
              <input />  in the existence of UFOs?
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
            <div>Can you please turn off the music? I’m trying to concentrate
              <input /> my homework.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div> I must
              <input /> Sam on passing his driving test.
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <div>I completely forgot
              <input /> the invitation to Tom’s party.
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>7</strong>
            <div>
              <div> Do you think it’s always unacceptable for scientists to
              <input />  on animals?
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>8</strong>
            <div>
              <div> We’re having a barbecue at the weekend so we’re hoping
              <input /> some fine weather.
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>9</strong>
            <div>
              <div>Jack should
              <input /> to you for his rudeness.
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>10</strong>
            <div>
              <div>I hope that your grandma recovers quickly
              <input />  her operation
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>11</strong>
            <div>
              <div>A lot of people
              <input />to plans for a new airport near London.
              </div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>12</strong>
            <div>
              <div>I’ve subscribed
              <input /> lots of YouTube channels.
              </div>
            </div>
          </div>
          
				`,
        answer: [
          'apply',
          'like',
          'believe',
          'on',
          'congratulate',
          'about',
          'experiment',
          'for',
          'apologise',
          'from',
          'object',
          'to',
        ],
      },
    ],
  },
};

export default json;
