import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Money',
    id: 'FG10-M-P79-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
          <span style="color: rgb(45,206,137);">SPEAKING</span> 
          Read the task. In pairs, think of three possible things you could spend the money on.
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page79/E1/E1.png' }]],
  },
  2: {
    unit: 'Money',
    id: 'FG10-M-P79-E2',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    inputSize: 600,
    maxLength: 50,
    //hideBtnFooter: true,
    textareaStyle: { width: 400 },
    titleQuestion: [
      {
        num: '2',
        title: `
          Read the essay. Does it mention any of your ideas
          from exercise 1? Which of the ideas in the essay do
          you think is best? Why?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex; gap: 20px;">
            <div style="flex: 3"><img src="/img/FriendsPlus/Page79/E2/E2.png" style="max-width: 100%;" /></div>
            <div style="flex: 2"><textarea id="0" rows="15"></textarea></div>
          </div>
        `,
        answer: [],
      },
    ],
  },
  3: {
    unit: 'Money',
    id: 'FG10-M-P79-E3',
    exerciseKey: '/img/FriendsPlus/Page79/E3/Key/E3answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 60,
    maxLength: 4,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    stylesTextInput: {
      textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '3',
        title: `
          Read the <b style='color:#000080;'>Reading Strategy</b>. Match sentences A–F with gaps 1–5 in the text. There is one extra sentence.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">A</span>
						<div>Part of her job was to interest the public in marine and freshwater biology via radio programmes</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">B</span>
						<div>Firstly, Silent Spring suggested a much needed change in people’s way of life.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">C</span>
						<div>She warned the public about its negative long-term effects</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">D</span>
						<div>She had her first story about animals published in a magazine when she was in the fourth grade.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">E</span>
						<div>in which she always concerned herself about the impact that humans made on the natural world.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">F</span>
						<div>The book eventually prompted a change in national policy, 
								leading to the enactment of a national ban of pesticides on agricultural farming..</div>
					</div>
					<img src="/img/FriendsPlus/Page79/E3/1.jpg"  style = "max-width:600px;margin-left:250px"/>
				</div>	
						
				<div>
					<div style="margin-top: 20px; font-style: italic; text-decoration: underline;">Answer:</div>
					<div style="margin-top: 10px; display: flex; gap: 30px; flex-wrap: wrap;">
						<div style="font-weight: bold; margin-right: -15px;">Answer 1: </div>#
						<div style="font-weight: bold; margin-right: -15px;">Answer 2: </div>#
						<div style="font-weight: bold; margin-right: -15px;">Answer 3: </div>#
						<div style="font-weight: bold; margin-right: -15px;">Answer 4: </div>#
						<div style="font-weight: bold; margin-right: -15px;">Answer 5: </div>#
					</div>
				</div>
        `,
        answer: ['D', 'A', 'E', 'C', 'F'],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'FG10-M-P79-E4',
    exerciseKey: '/img/FriendsPlus/Page79/E4/Key/E4answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 50,
    stylesTextInput: {
      fontSize: 21,
    },
    textareaStyle: { width: 450, fontSize: '21px' },
    titleQuestion: [
      {
        num: '4',
        title: `
          Read the text again. Are the sentences true or false? Write T or F
        `,
        color: '#860052',
      },
    ],
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    questions: [
      {
        title: `
          <div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<div>Rachel Cason realised the real danger of pesticides when she was still a young girl.#</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<div>She majored in the scientific study of animals and their behaviour when she took her MA in 1932.#</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<div>Most of Rachel Carson’s works were about environmental issues.#</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">4</span>
						<div>Her opposition to using pesticides on agricultural farming was supported by the chemical industry.#</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">5</span>
						<div>Silent Spring brought about positive changes in the government’s policies.#</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">6</span>
						<div>A lot of people keep on protecting the planet after her death.#</div>
					</div>
					
				</div>	
						
			
        `,
        answer: ['F', 'T', 'F', 'F', 'T', 'T'],
      },
    ],
  },
  5: {
    unit: 'Unit 6',
    id: 'FG10-M-P79-E5',
    exerciseKey: 'img/FriendsPlus/Page79/key/E5answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 100,
    maxLength: 400,
    titleQuestion: [
      {
        num: '5',
        title: `
            	<b style="color:#8e258c">VOCABULARY</b>	atch the phrasal verbs below with definitions 
						1–9. Is each phrasal verb separable or inseparable?
        `,
        color: '#860052',
        prefix: { icon: [''], text: '' },
      },
    ],
    hintBox: [
      {
        src: ['<b>Verb + preposition</b>', 'about', 'about', 'in', 'of', 'for', 'for', 'to', 'of'],
        width: 1100,
        fontColor: '#000080',
        borderColor: 'none',
      },
    ],
    isHiddenCheck: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
						<hintbox id=0></hintbox>
            <div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<div>work # (a business, etc.) (line 10)</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<div>disapprove # (line 20)</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<div>call # (line 22)</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">4</span>
						<div>give # something (line 25)</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">5</span>
						<div>result # something (line 26)</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">6</span>
						<div>warn somebody # something (sentence C)</div>
					</div>
						<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">7</span>
						<div>concern oneself # something (sentence E)</div>
					</div>
						<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">8</span>
						<div>lead # something (sentence F)</div>
					</div>
				</div>	
        `,
        answer: ['for', 'of', 'for', 'up', 'in', 'about', 'about', 'to'],
      },
    ],
  },
  6: {
    unit: 'Unit 6',
    id: 'FG10-M-P79-E6',
    // exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    recorder: true,
    inputSize: 460,
    maxLength: 400,
    hideBtnFooter: true,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: '6',
        title: `
           <b style="color:green">Speaking</b> Work in pairs. Discuss these questions and report your ideas to the class
        `,
        color: '#860052',
      },
    ],
    //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
             <div>
  
          <div><span style="font-weight: bold; margin-right: 10px;">1</span>Do you think it is necessary for pesticides to be banned? Why?</div>
          <div><span style="font-weight: bold; margin-right: 10px;">2</span>Do you agree with the views Rachel Carson expressed in Silent 
Spring? Why? / Why not?</div>
          <div><span style="font-weight: bold; margin-right: 10px;">3</span>Would you like to be an ecologist? Why? / Why not? If so, what 
would you most concern yourself about?</div>
         
        
      </div>
     
        `,
        answer: [],
      },
    ],
  },
};

export default json;
