import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  4: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P85-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page85/E4/Key/E4answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '4',
        title: `
        <span style='color:rgb(2, 146, 97);'>SPEAKING</span>
        Work in pairs. Complete the arts quiz. Use vocabulary from
        exercises 1, 3 and the correct form of the verbs in the following list.
        Then do the quiz and check your answers at the bottom of the page.
  		  `,
        color: '#92278f',
        prefix: { icon: [''], text: '' },
      },
    ],
    hintBox: [
      {
        src: [
          '<b>Artistic activities</b>',
          'act',
          'appear in',
          'carve',
          'compose',
          'conduct',
          'create',
          'dance',
          'direct',
          'draw',
          'paint',
          'perform',
          'play',
          'sing',
          'write',
        ],
        width: 800,
        fontColor: 'rgb(146,39,143)',
        borderColor: 'none',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 120, fontSize: 19, textAlign: 'center', fontWeight: 'bold' },
        answers: ['painted', 'composer', 'directed', 'play', 'painter', 'pop singer', 'appear in', 'sculpture'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
        ],
        answers: ['0-0', '1-4', '2-0', '3-4', '4-0', '5-0', '6-0', '7-0'],
        initialValue: [],
      },
      Layout: `
        <hintbox id='0'></hintbox>
       <div style='position: relative;  '>
	        <img style='width:27cm; height: 27cm' src='img/FriendsPlus/Page85/E4/1.jpg'>

          <div style=" position: absolute; top: 222px; left: 68px; font-size:19px; width:40%">
		        Which Italian Renaisance artist <sup>1</sup><input id='0' ></input> the Mona Lisa?
            <div style="display: flex;">
		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	        Michelangelo<br>
		  	        Leonardo da Vinci<br>
		  	        Caravaggio
		          </div>
            </div>
	  	    </div>
          <div style=" position: absolute; top: 377px; left: 68px; font-size:19px; width:40%">
		       Which Russian <sup>2</sup><input id='1' ></input> wrote the music for the ballet Swan Lake?
            <div style="display: flex;">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	        Shostakovich<br>
		  	        Rachmaninov<br>
		  	        Tchaikovsky
		          </div>
            </div>
	  	    </div>
          <div style=" position: absolute; top: 528px; left: 68px; font-size:19px; width:40%">
		        Which of these films was not <sup>3</sup><input id='2' ></input> by Steven Spielberg?
            <div style="display: flex;">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	        Jaws<br>
		  	        2001: A Space Odyssey<br>
		  	        Schindler’s List
		          </div>
            </div>
	  	    </div>
          <div style=" position: absolute; top: 696px; left: 68px; font-size:19px; width:40%">
		        Which Shakespeare <sup>4</sup><input id='3' ></input> inspired the film West Side Story?
            <div style="display: flex;">
		          <div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	        Romeo and Juliet<br>
		  	        Julius Caesar<br>
		  	        A Midsummer Night’s Dream
		          </div>
            </div>
	  	    </div>

          <div style=" position: absolute; top: 222px; left: 582px; font-size:19px; width:40%">
		        Which famous <sup>5</sup><input id='4' ></input> cut off part of his ear?
            <div style="display: flex;">
		          <div><input id='4' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	        Monet<br>
		  	        Cézanne<br>
		  	        Van Gogh
		          </div>
            </div>
	  	    </div>
          <div style=" position: absolute; top: 377px; left: 582px; font-size:19px; width:40%">
		        Which British <sup>6</sup><input id='5' ></input> sang on the 2011 hit Someone Like You?
            <div style="display: flex;">
		          <div><input id='5' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	        Adele<br>
		  	        Ellie Goulding<br>
		  	        Calvin Harris
		          </div>
            </div>
	  	    </div>
          <div style=" position: absolute; top: 528px; left: 582px; font-size:19px; width:40%">
		        Which of these film series did actor Robert Pattinson not  <sup>7</sup><input id='6' ></input> ?
            <div style="display: flex;">
		          <div><input id='6' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	        Harry Potter<br>
		  	        Twilight<br>
		  	        The Hobbit
		          </div>
            </div>
	  	    </div>
          <div style=" position: absolute; top: 696px; left: 582px; font-size:19px; width:40%">
		        What is the name of the famous <sup>8</sup><input id='7' ></input> in the photo?
            <div style="display: flex;">
		          <div><input id='7' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	        Mark<br>
		  	        David<br>
		  	        Anthony
		          </div>
            </div>
	  	    </div>


	   </div>
      `,
    },
  },

  5: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P85-E5',
    // audio: 'Audios/Friends Global Grade 10 SB_CD3_05.mp3',
    audio: '/Audios/3.02-audio.mp3',
    video: '',
    component: T6,
    inputSize: 150,
    maxLength: 20,
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page85/E5/Key/E5answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `
        <div style='font-size:23px'>
        <headphone1 name="3.02" src="-tieude.mp3"></headphone1> Listen to four people talking about cultural events. Match 
        sentences A–E with the speakers (1–4). There is one extra sentence.
        </div>
        
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      textTransform: 'uppercase',
    },
    questions: [
      {
        title: `
        <div style='font-size: 24px; margin-bottom:50px'>
          <div>This person</div>
          <div><b>A </b>thought that they might change their mind about something.</div>
          <div><b>B </b>was persuaded to go to the event by someone else.</div>
          <div><b>C </b>was disappointed with the experience, but is planning to try it again.</div>
          <div><b>D </b>was surprised by how good the event was.</div>
          <div><b>E </b>organised the outing to the event.</div>
        </div>
        <b>ANSWER:</b>
        <div style='display:flex'> 
        <b>1 - # &ensp;</b>  
        <b>2 - # &ensp;</b> 
        <b>3 - # &ensp;</b> 
        <b>4 - # &ensp;</b> 
        </div>
        
			  `,
        answer: ['A', 'C', 'B', 'E'],
      },
    ],
  },

  6: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P85-E6',
    // exerciseKey: 'img/FriendsPlus/Page85/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 130,
    maxLength: 20,
    textAlign: 'center',
    hideBtnFooter: true,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '6',
        title: `
        <span style='color:rgb(2, 146, 97);'>SPEAKING</span>
       Match the photos (A–H) with art forms from the list below. In your 
      opinion, which art form needs the most skill and which the least? Give reasons, 
      using the phrases below to help you.
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 100%; position: relative'>
          <div style='font-size:24px; position: relative'>
              <div style="color:rgb(146,39,143); margin-bottom:10px  ">
                <b>Cultural activities</b>
                &ensp;went to a rock concert
                &ensp;visited an art gallery
                &ensp;read a novel
                &ensp;read a poem
                &ensp;went to the theatre
                &ensp;saw a musical
                &ensp;listened to a song
                &ensp;watched a sitcom
                &ensp;went to a classical concert / opera / ballet
              </div>

            </div>
              
          </div>
          
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
