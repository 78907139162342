import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P108-IA-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '1',
        title: `
       Complete the adjectives with the missing vowels, a, e, i, o and u. `,
        color: '#860052',
      },
    ],

    inputSize: 35,
    // maxLength: 100,
    exerciseKey: 'img/FriendsPlus/Page108/Key/IA1answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    maxLength: 1,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
          <div style='margin:0px 100px;'>
              <b>1</b> #tm#sph#r#c<br>
              <b>2</b> b###t#f#l<br>
              <b>3</b> b#r#ng <br>
              <b>4</b> b#sy <br>
              <b>5</b> ch##p <br>
              <b>6</b> cr#wd# d<br>
              <b>7</b> d#s#pp##nt#ng<br>
              <b>8</b> #xp#ns#v#<br>
             
          </div>
          <div>
             <b>9</b> h # st # r # c<br>
              <b>10</b> # mpr # ss # v #<br>
              <b>11</b> p##c#f#l<br>
              <b>12</b> r#m#t#<br>
              <b>13</b> r # m # nt # c<br>
              <b>14</b> sp # ct # c # l # r<br>
              <b>15</b> t##r#sty<br>
            
          </div>
        </div>
				`,
        answer: [
          `a`,
          `o`,
          `e`,
          `i`,
          `e`,
          `a`,
          `u`,
          `i`,
          `u`,
          `o`,
          `i`,
          `u`,
          `e`,
          `a`,
          `o`,
          `e`,
          `i`,
          `a`,
          `o`,
          `i`,
          `i`,
          `e`,
          `e`,
          `i`,
          `e`,
          `i`,
          `o`,
          `i`,
          `i`,
          `e`,
          `i`,
          `e`,
          `e`,
          `a`,
          `e`,
          `u`,
          `e`,
          `o`,
          `e`,
          `o`,
          `a`,
          `i`,
          `e`,
          `a`,
          `u`,
          `a`,
          `o`,
          `u`,
          `i`,
        ],
      },
    ],
  },
  2: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P108-IA-E2',
    audio: '',
    video: '',
    component: T6v2,
    titleQuestion: [
      {
        num: '2',
        title: `Answer the questions. `,
        color: '#860052',
      },
    ],
    textAlign: 'center',
    inputSize: 170,
    // maxLength: 10,
    exerciseKey: 'img/FriendsPlus/Page108/Key/IAE2answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>Which two adjectives in exercise 1 describe cost and price? </div>
        </div>
                <div>
                <input/>                 <input/>

                </div>

        <div style='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'> Which three adjectives imply that there will be a lot of visitors there? </div>
        </div>
        <div>
                <input/> <input/>  <input/>

                </div>
        <div style='display:flex'>
        <b>3</b>
        <div style='margin-left:10px'>Which five adjectives usually have negative connotations?  </div>
        </div>
         <div>
                <input/> <input/>  <input/> <input/>  <input/>

                </div>
				`,
        answer: [
          'cheap | expensive',
          'expensive | cheap',
          'busy',
          'crowded',
          'touristy',
          'boring',
          'crowded',
          'disappointing',
          'expensive',
          'touristy',
        ],
      },
    ],
  },
  3: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P108-IA-E3',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '3',
        title: `Describe a visitor attraction that you know, using at least three of the adjectives in exercise 1. `,
        color: '#860052',
      },
    ],
    textAlign: 'center',
    inputSize: 110,
    maxLength: 20,
    exerciseKey: '',
    questionImage: [],
    textareaStyle: { width: 1000 },
    questions: [
      {
        title: `
        <span style="color: rgb(0,110,147);">Old Town Square in Prague is very impressive. There are lots of historic buildings, but it’s a bit touristy.</span>
        <textarea id='0' rows='2'></textarea>
        `,
        answer: [''],
      },
    ],
  },
  4: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P108-IC-E4',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title: `Read the <span style='color:#860052'>Learn this!</span> box and complete the examples.  Can you add any more adjectives to each group? `,
        color: '#860052',
      },
    ],

    textAlign: 'left',
    inputSize: 150,
    maxLength: 5,
    exerciseKey: 'img/FriendsPlus/Page108/Key/ICE4answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
              
             <div style='position: relative;font-size:19px'>
                <div style="position:absolute; top:106px; left: 580px; "><input id='0' font-size='23px' width='90px' border-bottom='0px'></input></div>
                <div style="position: absolute; top:149px; left: 390px; width:80px "><input id='0' font-size='23px' width='100px' border-bottom='0px'></input></div>
                <div style="position: absolute; top:149px; left: 560px; width:80px "><input id='0' font-size='23px' width='100px' border-bottom='0px'></input></div>
                <div style="position: absolute; top:223px; left: 145px; width:80px "><input id='0' font-size='23px' width='70px' border-bottom='0px'></input></div>
                <div style="position: absolute; top:264px; left: 415px; width:80px "><input id='0' font-size='23px' width='150px' border-bottom='0px'></input></div>       
                <div style="position:absolute; top:264px; left: 560px; "><input id='0' font-size='23px' width='90px' border-bottom='0px'></input></div>
                <div style="position: absolute; top:377px; left: 320px; width:80px "><input id='0' font-size='23px' width='100px' border-bottom='0px'></input></div>
                <div style="position: absolute; top:377px; left: 497px; width:80px "><input id='0' font-size='23px' width='100px' border-bottom='0px'></input></div>
                <div style="position: absolute; top:418px; left: 290px; width:80px "><input id='0' font-size='23px' width='70px' border-bottom='0px'></input></div>
                <div style="position: absolute; top:418px; left: 460px; width:80px "><input id='0' font-size='23px' width='150px' border-bottom='0px'></input></div>
                <img src='/img/FriendsPlus/Page108/E4/1.jpg' style='margin-top:10px;max-width:90%'>
        </div>
				`,
        answer: ['ful', 'less', 'less', 'vy', 'able', 'able', 'ous', 'ous', 'tive', 'tive'],
      },
    ],
  },
  5: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P108-IC-E5',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title: `
        Complete each gap with an adjective formed from the noun or verb in brackets. Use a dictionary if necessary.`,
        color: '#860052',
      },
    ],
    textAlign: 'center',
    inputSize: 200,
    maxLength: 12,
    exerciseKey: 'img/FriendsPlus/Page108/Key/ICE5answerKey.png',
    questionImage: [],
    // isHiddenCheck: true,
    questions: [
      {
        title: `
           


             <div style='display:flex;line-height:2'>
        <b>1</b>
        <div style='margin-left:20px'>It was a # (courage) decision, and fortunately his friends were very # (support). </div>
        </div>
        <div style='display:flex'>
        <b>2</b>
        <div style='margin-left:20px'> Parts of the route were very # (mountain) and quite # (danger). </div>
        </div>
        <div style='display:flex'>
        <b>3</b>
        <div style='margin-left:20px'>He’s popular because of his # (friend) and # (like) personality.</div>
        
        </div>
                     <div style='display:flex'>
        <b>4</b>
        <div style='margin-left:20px'>The film wasn’t # (wonder) but it was quite # (watch).  </div>
        </div>
                     <div style='display:flex'>
        <b>5</b>
        <div style='margin-left:20px'>It was a # (snow) day and the drive home was # (hazard). </div>
        </div>
                     <div style='display:flex'>
        <b>6</b>
        <div style='margin-left:20px'>She was trying to be # (help), I know, but it was # (care) of her to drop my laptop bag. </div>
        </div>
				`,
        answer: [
          `courageous`,
          `supportive `,
          `mountainous`,
          `dangerous `,
          `friendly `,
          `likeable`,
          `wonderful`,
          `watchable `,
          `snowy`,
          `hazardous `,
          `helpful`,
          `careless`,
        ],
      },
    ],
  },
  6: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P108-U1-E1',
    audio: '',
    video: '',
    component: T6v2,
    titleQuestion: [
      {
        num: '1',
        title: `Study the plural forms below. Then complete rules a–f in the  <span style='color:#860052'>Learn this!</span>  box with the correct endings. Then match each spelling variation (g–i) with one of the nouns below.`,

        color: '#860052',
      },
    ],
    textAlign: 'center',
    inputSize: 175,
    // maxLength: 100,
    exerciseKey: 'img/FriendsPlus/Page108/Key/1AE1answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style="max-width: 1200px;color: rgb(146, 39, 144); display: flex; gap: 5px 10px; flex-wrap: wrap;">
					<span>ancestors</span><span>boxes</span><span>buses</span><span>lunches</span><span>clothes</span>
					<span>countries</span><span>kisses</span><span>men</span><span>potatoes</span><span>sheep</span><span>toys</span>
          <span>videos</span><span>wishes</span><span>wives</span>

				</div>

        <div style ='display:flex;margin-top:15px'>
        <div style='flex:1'>
           <img src='/img/FriendsPlus/Page108/E6/1.jpg' style='max-width:100%'>

        </div>
         <div style='flex:1'>

         <div style='display:flex'>
                 <b>a</b>  
                 <div style='margin-left:10px'>
                 We add <sup>1</sup><input/>to most nouns.
                 </div>
         </div>
           <div style='display:flex'>
                 <b>b</b>
                 <div style='margin-left:10px'>
                 We add <sup>2</sup><input/>,<input/>,<input/>,<input/>,<input/> <br> to nouns ending in -ch, -sh, -ss, -s and -x.
                 </div>
         </div>
           <div style='display:flex'>
                 <b>c</b>
                 <div style='margin-left:10px'>
                 We add <sup>3</sup><input/>,<input/>to most nouns ending in -o.
                 </div>
         </div>
           <div style='display:flex'>
                 <b>d</b>
                 <div style='margin-left:10px'>
                 We add <sup>4</sup><input/>to nouns ending in a vowel + -y.
                 </div>
         </div>
           <div style='display:flex'>
                 <b>e</b>
                 <div style='margin-left:10px'>
                With nouns ending in a consonant + -y, the spelling changes to <sup>5</sup><input/>
                 </div>
         </div>
         <div style='display:flex'>
                 <b>f</b>
                 <div style='margin-left:10px'>
              With nouns ending in -f or -fe, the spelling changes to <sup>6</sup><input/>
                 </div>
         </div>

        </div>
        
        </div>

				`,
        answer: [
          `-s,ancestors`,
          '-es,boxes',
          '-es,buses',
          '-es,lunches',
          '-es,kisses',
          '-es,wishes',
          `-s,videos`,
          `-es,potatoes`,
          `-s,toys`,
          '-ies,countries',
          '-ves,knives',
        ],
      },
    ],
  },
  7: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P108-U1-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: `How many more nouns can you add to each group? `,

        color: '#860052',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    textareaStyle: { width: 500 },
    inputSize: 500,
    questionImage: [],
    isHiddenCheck: true,
    hideBtnFooter: true,

    questions: [
      {
        title: /*html */ `
 <div style ='display:flex;margin-top:15px;line-height:2'>
        <div style='flex:1'>
           <img src='/img/FriendsPlus/Page108/E6/1.jpg' style='max-width:100%'>

        </div>
         <div style='flex:1'>

         <div style='display:flex'>
                 <b>a</b>
                 <div style='margin-left:10px'>
              <input/>
                 </div>
         </div>
           <div style='display:flex'>
                 <b>b</b>
                 <div style='margin-left:10px'>
              <input/>
                 </div>
         </div>
           <div style='display:flex'>
                 <b>c</b>
                 <div style='margin-left:10px'>
              <input/>
                 </div>
         </div>
           <div style='display:flex'>
                 <b>d</b>
                 <div style='margin-left:10px'>
              <input/>
                 </div>
         </div>
           <div style='display:flex'>
                 <b>e</b>
                 <div style='margin-left:10px'>
              <input/>
                 </div>
         </div>
         <div style='display:flex'>
                 <b>f</b>
                 <div style='margin-left:10px'>
              <input/>
                 </div>
         </div>
         <div style='display:flex'>
                 <b>g</b>
                 <div style='margin-left:10px'>
              <input/>
                 </div>
         </div>
         <div style='display:flex'>
                 <b>h</b>
                 <div style='margin-left:10px'>
              <input/>
                 </div>
         </div>
         <div style='display:flex'>
                 <b>i</b>
                 <div style='margin-left:10px'>
              <input/>
                 </div>
         </div>

        </div>

        </div>        `,
        answer: [],
      },
    ],
  },
  8: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P108-U1-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '3',
        title: `Read the <span style='color:#860052'>Learn this!</span> box. Then read the sentences and circle the correct meaning, a or b. Use a dictionary to help you. `,

        color: '#860052',
      },
    ],
    textAlign: 'left',
    // inputSize: 510,
    // maxLength: 200,
    exerciseKey: 'img/FriendsPlus/Page108/Key/U1E3answerKey.png',
    // questionImage: [],
    // isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `A <br> B`, //1
          `A <br> B`, //1
          `A <br> B`, //1
          `A <br> B`, //1
          `A <br> B`, //1
          `A <br> B`, //1
        ],
        answers: ['0-4', '1-4', '2-0', '3-0', '4-4', '5-4'],
        initialValue: [],
      },
      Layout: `
                            <div><img src='/img/FriendsPlus/Page108/E8/1.jpg' style='max-width:45%;display:flex;justify-content: center;'/></div>

      <div style='display:flex;font-size:21px'>
  <div style='flex: 1'>
    <b>1</b>&ensp;She gave up halfway through the London Marathon.
    <div style="display: flex;">
      <div style="display: flex; margin-left: 40px">
        <div><input id='0' type='Circle'/></div>
        <div style='margin-left:20px'>
          went faster <br>
          stopped trying
        </div>
      </div>
    </div>

    <div style ='flex:1;'>
      <b>2</b>&ensp;The film turned into a comedy towards the end.  <br>
      <div style="display: flex;margin-left:40px">
        <div><input id='1' type='Circle'/></div>
        <div style='margin-left:20px'>
          stopped being <br>
          became
        </div>
      </div>

      <b>3</b>&ensp;We <u>set off</u> just as it was getting dark <br>
      <div style="display: flex;margin-left:40px">
        <div><input id='2' type='Circle'/></div>
        <div style='margin-left:20px'>
          started our journey <br>
          finished our journey
        </div>
      </div>
    </div>
  </div>

  <div style='flex: 1'>
    <b>4</b>&ensp;The rocket blew up as it entered the Earth’s atmosphere.
    <div style="display: flex;margin-left:40px">
      <div><input id='3' type='Circle'/></div>
      <div style='margin-left:20px'>
        exploded <br>
        changed direction
      </div>
    </div>
    <b>5</b>&ensp;A woman in the front row of the audience passed out.
    <div style="display: flex;margin-left:40px">
      <div><input id='4' type='Circle'/></div>
      <div style='margin-left:20px'>
        making things in space factories. <br>
        living and visiting on holiday. <br>
      </div>
    </div>

    <b>6</b>&ensp;At the age of 65, my grandfather took up ice skating.
    <div style="display: flex;">
      <div style="display: flex;margin-left:40px">
        <div><input id='5' type='Circle'/></div>
        <div style='margin-left:20px'>
          More and more inventions <br>
          Progress now and then <br>
        </div>
      </div>

      
    </div>
  </div>
</div>



        
      `,
    },
  },
};

export default json;
