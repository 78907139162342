import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: "Grammar Builder and Reference",
    id: "FGF11-GBAR-P115-E1",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title:
          "Complete the sentences. Use the past simple or the past continuous form of the verbs in brackets.",
        color: "#92278f",
      },
    ],
    stylesTextInput: {
      fontSize: 22,
      textAlign: "center",
      // borderBottom: "none",
      backgroundColor: "transparent",
    },
    inputSize: 180,
    // maxLength: 7,
    exerciseKey: "img/FriendsPlus/Page115/Key/E1answerKey.png",
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style ='line-height:2'>
         
            <div>
              <b>1.</b>  <span>I # (have) a crash while I # (learn) to drive.
            </span>
              </div>
            

            <div>
              <b>2.</b>
              <span>
              Sam # (get) his first job while he # (live) in London.
            </span>
            </div>
            
            
            <div>
              <b>3.</b> 
              <span>
              It # (rain), so we # (decide) to cancel the barbecue.
            </span>
              </div>
           
             
            <div>
              <b>4.</b>
            <span>
              What you # (do) when I # (see) you in town?.
            </span> 
            </div>

             
            <div>
              <b>5.</b>
              <span>
                Emma # (not hear) the phone ringing because she # (listen) to music in her bedroom.
              </span>
            </div>

              <div>
              <b>6.</b>
              <span>
                Ryan # (break) his arm while he # (ski) in France.
              </span>
            </div>

              <div>
              <b>7.</b>
              <span>
               Harry # (work) as a chef when he # (meet) Sally.
              </span>
            </div>

            <div>
              <b>8.</b>
              <span>
               You obviously # (not listen) when I # (ask) you to turn down the music.
              </span>
            </div>
            
        </div>
				`,
        answer: [
          "had",
          "was learning",
          "got",
          "was living",
          "was raining",
          "decided",
          "were doing",
          "saw",
          "didn't hear",
          "was listening",
          "broke",
          "was skiing",
          "was working",
          "met",
          "weren't listening",
          "asked",

        ],
      },
    ],
  },
  2: {
    unit: 'Grammar Builder and Referenc',
    id: 'FGF11-GBAR-P115-U1-E2',
    // audio: "Audios/1-02 Friends Plus 7.mp3",
    video: "",
    component: T6v2,
    titleQuestion: [
      {
        num: "2",
        title:
          'Complete the sentences. Use the past simple or the past perfect form of the verbs in brackets.',
        color: "#92278f",
      },
    ],
    exerciseKey: "img/FriendsPlus/Page115/Key/E2answerKey.png",
    stylesTextInput: {
      fontSize: 22,
      // height: 24,
      // borderBottom: "none",
      backgroundColor: "transparent",
    },
    textAlign: "center",
    inputSize: 270,
    // maxLength: 13,
    titleImage: "",
    questionImage: [],
    questions: [
      {
        title: `
					<div style ='line-height:1.5'>
         
            <div>
              <b>1.</b>  <span>My uncle and aunt # (already / get engaged) before they # (emigrate) to Australia.
            </span>
              </div>
            

            <div>
              <b>2.</b>
              <span>
              I # (not / can) buy anything because I # (forget) my wallet.
            </span>
            </div>
            
            
            <div>
              <b>3.</b> 
              <span>
              Robert # (be) upset because he # (split up) with his girlfriend.
            </span>
              </div>
           
             
            <div>
              <b>4.</b>
            <span>
             Kelly # (start) her first business before she # (leave) university.
            </span> 
            </div>

             
            <div>
              <b>5.</b>
              <span>
                As soon as Sara # (inherit) the money from her grandmother, she # (buy) a car.
              </span>
            </div>

              <div>
              <b>6.</b>
              <span>
                By the time Joe # (retire), he # (become) a grandfather.
              </span>
            </div>

              <div>
              <b>7.</b>
              <span>
               After Fred # (settle down) in London, he # (decide) to have a change of career.
              </span>
            </div>

            <div>
              <b>8.</b>
              <span>
               We # (spend) the weekend moving house, so we # (go) to bed very early on Sunday.
              </span>
            </div>
            
        </div>
				`,
        answer: [
          "had already got engaged",
          "emigrated",
          "couldn't buy",
          "had forgotten",
          "was",
          "had split up",
          "had started || started",
          "left",
          "had inherited || inherited",
          "bought",
          `retired`,
          `had become`,
          `had settled down || settled down`,
          `decided`,
          `had spent || spent`,
          `went`,

        ],
      },
    ],
  },

  3: {
    unit: 'Grammar Builder and Referenc',
    id: 'FGF11-GBAR-P115-U1-E3',
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page115/Key/E3answerKey.png",
    inputSize: 240,

    titleQuestion: [
      {
        num: "3",
        title: "Choose the best ending (a or b) for the sentences.",
        color: "#92278f",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b`, //1
          `a <br> b`, //1
          `a <br> b`, //1
          `a <br> b `,
          `a <br> b`,//1
          `a <br> b`,


        ],
        answers: ['0-0', '1-4', '2-0', '3-4', '4-0', '5-0',],
        initialValue: [],
      },
      Layout: `
      <div >
        <div style ='flex:1;width:100%'>


 						<b>1</b>&ensp;
		     We couldn’t open the front door because
            <div style="display: flex;margin-left:40px">
		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      it had snowed all night. <br>
              it snowed all night.
		          </div>
            </div>


          
            <b>2</b>&ensp;
		     It was a lovely spring day and the birds
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	     sang.<br>
             were singing. 
		          </div>
            </div>

              <b>3</b>&ensp;
		          I looked out of the window and noticed that 
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	       it had stopped raining. <br>
                it was stopping raining.
		          </div>
            </div>


           <b>4</b>&ensp;
		         The plants died because we
            <div style="display: flex;margin-left:40px">
		          <div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      were forgetting to water them. <br>
             forgot to water them.
		          </div>
            </div>

             <b>5</b>&ensp;
		          Before I left the house, I
            <div style="display: flex;margin-left:40px">
		          <div><input id='4' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	     locked all the windows. <br>
             was locking all the windows. 
		          </div>
            </div>
            

             <b>6</b>&ensp;
		          I wasn’t particularly hungry because
            <div style="display: flex;margin-left:40px">
		          <div><input id='5' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      I’d already had lunch. <br>
              I already had lunch. 
		          </div>
            </div>

		          </div>
            </div>
           </div>
      </div>

                        </div>


        
      `,
    },
  },
  4: {
    unit: 'Grammar Builder and Referenc',
    id: 'FGF11-GBAR-P115-U1-E1',
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page115/Key/E1,1answerKey.png",
    inputSize: 240,
    // //hideBtnFooter: true,
    stylesTextInput: {
      fontSize: 24,
      // textTransform: "uppercase",
      textAlign: "center"
    },
    titleQuestion: [
      {
        num: "1",
        title:
          " Complete the dialogue with the correct form of will or be going to and the verbs in brackets.",
        color: "#92278f",
        // prefix: "default",
        // prefix: { icons: ['far fa-comment', 'fas fa-pencil-alt'], text: 'CULTURAL' },
      },
    ],
    questions: [
      {
        title: `
       <div>
            <div>
              <b>Laura</b> <span style="margin-left:20px">Hi, Harry. What are you doing here? Are you meeting somebody?</span>
            </div>
            <div>
              <b>Harry</b> <span style="margin-left:20px"> No, I just came in for a drink.</span>
            </div>
            <div>
              <b>Laura</b> <span style="margin-left:20px">I <sup>1</sup> # (buy) you a drink. I owe you one</span>
            </div>
            <div>
              <b>Harry</b> <span style="margin-left:20px"> Thanks! I <sup>2</sup> # (have) a coffee.</span>
            </div>
            <div>
              <b>Laura</b> <span style="margin-left:20px">Would you like a cake too?</span>
            </div>
            <div>
              <b>Harry</b> <span style="margin-left:20px"> No, thanks. I <sup>3</sup> # (have) dinner soon.</span>
            </div>
            <div>
              <b>Laura</b> <span style="margin-left:20px">OK. What are your plans for the weekend?</span>
            </div>
            <div>
              <b>Harry</b> <span style="margin-left:20px"> I <sup>4</sup> # (do) some preparation for our exam on Monday. What about you? <sup>5</sup> # you # (revise) tomorrow?</span>
            </div>
            <div>
              <b>Laura</b> <span style="margin-left:20px">Yes. But in the evening, I <sup>6</sup> # (go) to a gig at the town hall.</span>
            </div>
            <div>
              <b>Harry</b> <span style="margin-left:20px">Really? Who’s playing?</span>
            </div>
            <div>
              <b>Laura</b> <span style="margin-left:20px">They’re called The Wave. I <sup>7</sup> # (meet) Poppy there.</span>
            </div>
            <div>
              <b>Harry</b> <span style="margin-left:20px">It sounds like a good night out.</span>
            </div>
            <div>
              <b>Laura</b> <span style="margin-left:20px"> I <sup>8</sup> # (get) a ticket for you too, then. OK?</span>
            </div>
            <div>
              <b>Harry</b> <span style="margin-left:20px">Thanks! I <sup>9</sup> # (give) you the money for it now. How much is it?</span>
            </div>
            <div>
              <b>Laura</b> <span style="margin-left:20px"> I’m not sure. But it <sup>10</sup> # (not be) expensive. They aren’t a well-known band.</span>
            </div>
       </div>
        `,
        answer: [
          `will buy / 'll buy `,
          `'ll have / will have `,
          `'m going to have `,
          `'m going to do `,
          `Are`,
          `going to revise`,
          `'m going to go`,
          `'m going to meet`,
          `'ll get`,
          `'ll give`,
          `isn't going to be / won't be`,
        ],
      },
    ],
  },
};

export default json;
