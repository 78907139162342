import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
// import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
  5: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P49-E5',
    exerciseKey: 'img/FriendsPlus/Page49/E5/Key/E5answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 450,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 20,
      height: 30,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '5',
        title: `
         <span style=" color: #92278f"> VOCABULARY</span>  
        Match nine of the words below with meanings 1-8. Check the meaning of all the words.
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>
          <div style='font-size:21px;'>
              <div style=" gap: 20px; color:rgb(146,39,143);  ">
                <b>Describing houses and rooms</b>
                &ensp;beautifully restored
                &ensp;charming
                &ensp;contemporary
                &ensp;conveniently located
                &ensp;cosy
                &ensp;cramped
                &ensp;dilapidated
                &ensp;impressive
                &ensp;peaceful
                &ensp;popular / lively area
                &ensp;remote
                &ensp;spacious
                &ensp;substantial
                &ensp;tiny
              </div>

              <div style=' width:relative; margin-top:40px'>
                  <div style="display: flex; flex-direction:column; width: 100%; margin-right:40px">
                      <div style='display:flex; justify-content: space-between'><b>1</b>&ensp;uncomfortably small  &ensp;#</div>
                      <div style='display:flex; justify-content: space-between'><b>2</b>&ensp;very small  &ensp;#</div>
                      <div style='display:flex; justify-content: space-between'><b>3</b>&ensp;quiet  &ensp;#</div>
                      <div style='display:flex; justify-content: space-between'><b>4</b>&ensp;far from other places  &ensp;#</div>

                      <div style='display: flex; justify-content: space-between'><b>5</b>&ensp;modern  &ensp;#</div>
                      <div style='display: flex; justify-content: space-between'><b>6</b>&ensp;in a good location  &ensp;#</div>
                      <div style='display: flex; justify-content: space-between'><b>7</b>&ensp;in very bad condition  &ensp;#</div>
                      <div style='display: flex; justify-content: space-between'><b>8</b>&ensp;large (two words)  &ensp;#</div>
                  </div>
                  
              </div>
              
            </div>
              
          </div>
          
        `,
        answer: [
          'cramped',
          'tiny',
          'peaceful',
          'remote',
          'contemporary',
          'conveniently located',
          'dilapidated',
          'spacious, substantial/ subtantial, spacious',
        ],
      },
    ],
  },

  6: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P49-E6',
    exerciseKey: '/img/FriendsPlus/Page49/E6/Key/E6answerKey.png',
    // audio: '/Audios/Friends Global Grade 10 SB_CD2_06.mp3',
    audio: 'Audios/2.04-audio.mp3',
    video: '',
    component: T6,
    inputSize: 300,
    maxLength: 30,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 23,
    },
    titleQuestion: [
      {
        num: '6',
        title: `
          <headphone1 name="2.04" src="Audios/2.04-tieude.mp3"></headphone1> <span style='font-size:24px'>Listen again. Complete the sentences with words or phrases from the list in exercise 5.</span>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="gap :50px; width:90%">
          <div><b>1 </b>It’s &ensp;#&ensp;near to the shops.</div>
          <div><b>2 </b>We’re in a very&ensp;#&ensp;of town.</div>
          <div><b>3 </b>On the right is the living room.&ensp;#&ensp;Isn’t it?</div>
          <div><b>4 </b>‘It’s&ensp;#&ensp;.’ ‘It certainly isn’t&ensp;#&ensp;, but I wouldn’t say it’s&ensp;#&ensp;</div>
          <div><b>5 </b>The back fence is very&ensp;#&ensp;near to the shops.</div>
          <div><b>6 </b>There’s a&ensp;#&ensp;view from the window.</div>
          <div><b>7 </b>It could be&ensp;#&ensp;.</div>
        </div>
        `,
        answer: [
          'conveniently located',
          'popular area',
          'Cosy',
          'tiny',
          'spacious',
          'cramped',
          'dilapidated',
          'charming',
          'beautifully restored',
        ],
      },
    ],
  },
  7: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P49-E7',
    exerciseKey: 'img/FriendsPlus/Page49/E7/Key/E7answerKey.png',
    audio: 'Audios/2.05-audio.mp3',
    video: '',
    component: T6,
    inputSize: 100,
    maxLength: 5,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 23,
      height: 30,
      textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '7',
        title: `
         <headphone1 name="2.05" src="Audios/2.05-tieude.mp3"></headphone1> 
        Listen to four people describing their homes. Match sentences a-e with speakers 1-4. There is one extra sentence.
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
          <div style='font-size:22px'>

              <div style="display: flex; flex-direction:column; gap: 20px;">
                <div>This home</div>
                <div><b>a&ensp;</b>is not as modern as the owners would like.</div>
                <div><b>b&ensp;</b>did not cost very much.</div>
                <div><b>c&ensp;</b>is very dilapidated.</div>
                <div><b>d&ensp;</b>has got wonderful views.</div>
                <div><b>e&ensp;</b>is in a remote location.</div>
                
              </div>
              <div style='display:flex; margin-top:40px'>
              <b>ANSWER:&ensp;</b>
              <b>1&ensp;#&ensp;</b>
              <b>2&ensp;#&ensp;</b>
              <b>3&ensp;#&ensp;</b>
              <b>4&ensp;#&ensp;</b>
              </div>
          </div>
          
        `,
        answer: ['b', 'd', 'e', 'a'],
      },
    ],
  },
  8: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P49-E8',
    exerciseKey: 'img/FriendsPlus/Page49/E5/Key/E5answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.02.mp3',
    component: T6,
    recorder: true,
    inputSize: 450,
    maxLength: 20,
    textAlign: 'center',
    hideBtnFooter: true,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 20,
      height: 30,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '8',
        title: `
        <div>
            <span style="margin-bottom: 10px;font-size: 25px; color: rgb(81,179,133)">SPEAKING&ensp;</span>  
            <span style="margin-bottom: 10px;font-size: 25px; color: #92278f">KEY PHRASES</span>  
            Work in pairs. Describe your home to your partner. Use the phrases below to help you.
        </div>
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
        <div style='display:flex; flex-direction:column; border-left: 2px solid rgb(166,74,160); margin: 20px; padding-left:30px'>
          <div style="color: rgb(166,74,160)">Describing where you live</div>  
          <div>It's a flat / detached house / terraced house.</div>
          <div>It's in the town centre / on the outskirts / in the suburbs.</div>
          <div>There's a park / There are some shops nearby.</div>
          <div>It's got ... (bedrooms).</div>
          <div>There is ... (other rooms).</div>
          <div>There's / There isn't a garden / drive, etc.</div>
          <div>It's a bit / very ... (adjective).</div>
              
        </div>
          
        `,

        answer: [''],
      },
    ],
  },
};

export default json;
