import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 6',
    id: 'FG10-M-P78-E1',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: '1',
        title: `
          <b>
            Look at the book cover. 
						What do you think the book is about?
          </b>
          
        `,
        color: '#860052',
      },
    ],

    // exerciseKey: '/img/FriendsPlus/Page78/E1/Key/answerKey.png',
    questionImage: [[{ url: '/img/FriendsPlus/Page78/E1/1.jpg' }]],
  },
  2: {
    unit: 'Unit 6',
    id: 'FG10-M-P78-E2',
    exerciseKey: 'img/FriendsPlus/Page78/key/E2answerKey.png',
    audio: '',
    video: '',
    component: T6,
    // //hideBtnFooter: true,
    inputSize: 600,
    // maxLength: 50,
    textareaStyle: { width: 500, height: 180 },
    titleQuestion: [
      {
        num: '2',
        title: `
            Read the article and answer the questions.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        
        <div style='display:flex'>
          <div style=''>
            <b>1</b>&ensp; What was her great interest as a young girl?<br><textarea id='0' rows='3'></textarea><br>
            <b>b</b>&ensp; What is Silent Spring about??<br><textarea id='1' rows='3'></textarea><br>
          </div>
            <img style='height:165mm;margin-left:15px' src='img/FriendsPlus/Page78/E2/1.jpg'>

        </div>
        `,
        answer: [
          'She had great interest in the natural world and writing',
          " Silent Spring is about Rachel Carson's disapproval of using pesticides and her calling for new policies to protect humans and the environment.",
        ],
      },
    ],
  },
  3: {
    unit: 'Money',
    id: 'FG10-M-P78-E3',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    inputSize: 600,
    maxLength: 50,
    //hideBtnFooter: true,
    titleQuestion: [
      {
        num: '3',
        title: `
          Now compare photos 1 and 2. 
          Describe a) the main similarities and b) the main differences. 
          Use the phrases below to help you.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style="margin-top: 30px; display: flex; gap: 30px; align-items: start;">
            <div style="flex: 1; padding-left: 20px; border-left: 5px solid rgb(139, 191, 147);">
              <div>
                <div style="font-weight: bold; color: rgb(0, 147, 69);">Similarities</div>
                <div>The common theme in the photos is …</div>
                <div>You can see … in both photos.</div>
                <div>Both photos show …</div>
                <div>In both photos there are …</div>
              </div
              <div>
                <div style="font-weight: bold; color: rgb(0, 147, 69);">Differences</div>
                <div>The first photo shows … , whereas the second photo shows …</div>
                <div>In the first photo … , but in the second photo …</div>
                <div>One obvious difference is (that) …</div>
                <div>Unlike the first photo, the second photo shows …</div>
                <div>In contrast to the first photo, the second photo …</div>
              </div
            </div>
            <div style="flex: 1;"><img src="img/FriendsPlus/Page78/E1/E1.png" style="max-width: 100%;" /></div>
          </div>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Money',
    id: 'FG10-M-P78-E4',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    //hideBtnFooter: true,
    inputSize: 600,
    maxLength: 50,
    titleQuestion: [
      {
        num: '4',
        title: `
            Does your school have a music or sports club?
            What other clubs are there? Are you a member of any? 
            In pairs, discuss the question below and make notes.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style="margin-top: 20px;">
            <img src="/img/FriendsPlus/Page78/E4/E4.png">
          </div>
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: 'Money',
    id: 'FG10-M-P78-E5',
    exerciseKey: '',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_33.mp3',
    video: '',
    component: T6,
    //hideBtnFooter: true,
    inputSize: 600,
    maxLength: 50,
    titleQuestion: [
      {
        num: '5',
        title: `
          <headphone1 name="2.33"></headphone1>
            Listen to a candidate answering the
            question in an exam. Compare your ideas with
            hers. Are any the same? Which are different?
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div style="margin-top: 20px;">
            <img src="/img/FriendsPlus/Page78/E5/E5.png">
          </div>
        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: 'Money',
    id: 'FG10-M-P78-E6',
    exerciseKey: '/img/FriendsPlus/Page78/E6/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_33.mp3',
    video: '',
    component: T6,
    //hideBtnFooter: true,
    inputSize: 600,
    maxLength: 50,
    titleQuestion: [
      {
        num: '6',
        title: `
          <headphone1 name="2.33"></headphone1>
          <span style="color: rgb(146,39,143);margin-left: 20px;">KEY PHRASES</span> 
          Listen again. Which phrases does the candidate use?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				  <div style="margin-top: 30px; display: flex; gap: 30px; align-items: start; font-size: 21px;">
				    <div style="flex: 1; padding-left: 20px; border-left: 5px solid rgb(139, 191, 147);">
				      <div>
				        <div style="font-weight: bold; color: rgb(0, 147, 69);">Structuring your speech</div>
				        <div>I’d like to start by saying / looking at …</div>
				        <div>First of all, we need to decide / examine / look at / ask ourselves …</div>
				        <div>I’ll begin with …</div>
				        <div>Now I’d like to move on to …</div>
				        <div>This leads to my next point.</div>
				        <div>Now let’s look at / move on to (the question of) …</div>
				      </div>
				      <div>
				        <div style="font-weight: bold; color: rgb(0, 147, 69);">Ordering points or opinions</div>
                <div>First, … Second, … Finally,…</div>
				      </div>
				      <div>
				        <div style="font-weight: bold; color: rgb(0, 147, 69);">Justifying your opinions</div>
                <div>There are a number of reasons why I believe this.</div>
                <div>First, …</div>
                <div>I’ll tell you why I think that.</div>
                <div>The reason I say that is …</div>
                <div>The main reason I feel this way is …</div>
				      </div>
				      <div>
				        <div style="font-weight: bold; color: rgb(0, 147, 69);">Summing up your opinion</div>
                <div>To sum up, … In conclusion, … All in all, …</div>
                <div>Just to summarise the main points, …</div>
				      </div>
				    </div>
				    <div style="flex: 1;"><img src="img/FriendsPlus/Page78/E1/E1.png" style="max-width: 100%;" /></div>
				  </div>
				`,
        answer: [
          `I’d like to start by saying … The reason I say that is … Now let's move on to (the question of) … I’ll tell you why I think that. First, … Second, … Finally, … To sum up, …`,
        ],
      },
    ],
  },
  7: {
    unit: 'Money',
    id: 'FG10-M-P78-E7',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_33.mp3',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '7',
        title: `
        <headphone1 name="2.33"></headphone1>
          <span style="color: rgb(39,131,197);margin-left: 20px;">PRONUNCIATION</span> 
          Listen again and notice how the candidate pronounces the phrases in exercise 6. 
          Work in pairs and practise saying them. <br>

				  <div style="margin-top: 30px; display: flex; gap: 30px; align-items: start; font-size: 21px;">
				    <div style="flex: 1; padding-left: 20px; border-left: 5px solid rgb(139, 191, 147);">
				      <div>
				        <div style="font-weight: bold; color: rgb(0, 147, 69);">Structuring your speech</div>
				        <div>I’d like to start by saying / looking at …</div>
				        <div>First of all, we need to decide / examine / look at / ask ourselves …</div>
				        <div>I’ll begin with …</div>
				        <div>Now I’d like to move on to …</div>
				        <div>This leads to my next point.</div>
				        <div>Now let’s look at / move on to (the question of) …</div>
				      </div>
				      <div>
				        <div style="font-weight: bold; color: rgb(0, 147, 69);">Ordering points or opinions</div>
                <div>First, … Second, … Finally,…</div>
				      </div>
				      <div>
				        <div style="font-weight: bold; color: rgb(0, 147, 69);">Justifying your opinions</div>
                <div>There are a number of reasons why I believe this.</div>
                <div>First, …</div>
                <div>I’ll tell you why I think that.</div>
                <div>The reason I say that is …</div>
                <div>The main reason I feel this way is …</div>
				      </div>
				      <div>
				        <div style="font-weight: bold; color: rgb(0, 147, 69);">Summing up your opinion</div>
                <div>To sum up, … In conclusion, … All in all, …</div>
                <div>Just to summarise the main points, …</div>
				      </div>
				    </div>
				    <div style="flex: 1;"><img src="img/FriendsPlus/Page78/E1/E1.png" style="max-width: 100%;" /></div>
				  </div>
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [],
  },
};
export default json;
