import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P53-E1',
		audio: '',
		video: '',
		component: T6,
		// recorder: true,
		hideBtnFooter: true,
		maxLength: 16,
		exerciseKey: 'img/FriendsPlus/Page53/Key/E1answerKey.png',
		question: [],
		textAlign: 'center',
		titleQuestion: [
			{
				num: '1',
				title: `<span style="color:rgb(10 127 64)">SPEAKING</span> Work in pairs. Imagine you were trying to sell
the house in the photo. How would you describe it? Use
the words below to help you.
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Nouns</b>',
					'cliff',
					'glass',
					'metal',
					'ocean',
					'rock',
					'view',
					'<b>Adjectives</b>',
					'modern',
					'spacious',
					'spectacular',
					'unique',
				],
				width: 700,
				fontColor: '#8d268a',
				borderColor: 'none',
			},
		],
		// maxLength: 14,
		recorder: true,
		isHiddenCheck: true,
		textareaStyle: { width: 800 },
		inputSize: 450,
		// textAlign: 'center',
		//hideBtnFooter: true,
		questionImage: [],
		questions: [
			{
				title: `
          <hintbox id=0></hintbox>
          <img src="img/FriendsPlus/Page53/E1/1.jpg" />
          `,
				answer: [''],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 4',
		audio: '',
		id: 'FGF11-U4-P53-E2',
		exerciseKey: 'img/FriendsPlus/Page53/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `Work in pairs. Complete the dialogue in an estate ’s
using the infinitive without to of these verbs: do, make,
take.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		maxLength: 4,
		hintBox: [
			{
				src: ['contact', 'enjoy', 'ignore', 'talk', 'try'],
				width: 600,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		// isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 80,
		textAlign: 'center',
		hideBtnFooter: true,
		questionImage: [],
		questions: [
			{
				title: `
        <div style='position: relative;'>
              <div style='margin-left:120px;width:20cm'>
                   Good morning. What can I <sup>1</sup># for you? <br>
                  Can I ask you about that amazing house that’s
                  built on a cliff? <br>
                    Yes, of course. You can <sup>2</sup># a look at some
                  more pictures on my computer. Look at the
                  view from that sofa!  <br>
                  I love it. Can I <sup>3</sup># an appointment to look
                  around? <br>
                    I’m afraid you can’t <sup>4</sup># that. The house
                  doesn’t exist yet! You need to choose a
                  location first. But the house would only
                  <sup>5</sup># twelve weeks to build.  <br>
                  I see. I need to find somewhere soon, so I’ll
                  have to <sup>6</sup># up my mind quickly. <br>
                    Can I ask what you <sup>7</sup>#  ?  <br>
                   I’m a yoga teacher. <br>
                    Where do you teach? <br>
                   At the local sports centre. But if I moved to
                  a bigger house, I’d want to <sup>8</sup># one room
                  into a yoga studio. <br>
                    Well, this house would be a great place to
                  <sup>9</sup># yoga! Just <sup>10</sup># your yoga mat
                  outside onto the cliff. Imagine the sunrise
                  over the waves, the solitude … <br>
                  I suppose you would be alone there … except
                  for all the people who stop to <sup>11</sup># photos
                  of your house! <br>
                </div>
                <b style='position: absolute; top: 0px; left: 0px'>Agent</b>
                <b style='position: absolute; top: 38px; left: 0px'>Woman</b>
                <b style='position: absolute; top: 76px; left: 0px'>Agent</b>
                <b style='position: absolute; top: 151px; left: 0px'>Woman</b>
                <b style='position: absolute; top: 190px; left: 0px'>Agent</b>
                <b style='position: absolute; top: 301px; left: 0px'>Woman</b>
                <b style='position: absolute; top: 377px; left: 0px'>Agent</b>
                <b style='position: absolute; top: 415px; left: 0px'>Woman</b>
                <b style='position: absolute; top: 453px; left: 0px'>Agent</b>
                <b style='position: absolute; top: 491px; left: 0px'>Woman</b>
                <b style='position: absolute; top: 567px; left: 0px'>Agent</b>
                <b style='position: absolute; top: 679px; left: 0px'>Woman</b>
        </div>
          

           
          `,
				answer: ['do', 'take', 'make', 'do', 'take', 'make', 'do', 'make', 'do', 'take', 'take'],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P53-E3',
		audio: 'Audios/2.10-audio.mp3',
		video: '',
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page53/Key/E3answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: `<headphone1 name='2.10' src='Audios/2.10-tieude.mp3'></headphone1>  Listen and check your answers. Do you think the
woman is keen on buying the house? Why? / Why not?
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		// maxLength: 6,
		component: T6,
		// isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 84,
		textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
        <div style='display:flex'>
							<b>
										Agent<br>
										Woman<br>
										Agent<br><br>
										Woman<br>
										Agent<br><br>
										Woman<br>
										Agent<br>
										Woman<br>
										Agent<br>
										Woman<br><br>
										Agent<br><br>
										Woman
							</b>
              <div style='margin-left:20px;width:25cm'>
                  			Good morning. What can I <sup>1</sup># for you?
										<br> Can I ask you about that amazing house that’s 
										built on a cliff?
										<br> Yes, of course. You can <sup>2</sup># a look at some 
										more pictures on my computer. Look at the 
										view from that sofa!
										<br> I love it. Can I <sup>3</sup># an appointment to look 
										around?
										<br> I’m afraid you can’t <sup>4</sup># that. The house 
										doesn’t exist yet! You need to choose a 
										location first. But the house would only 
										<sup>5</sup># twelve weeks to build.
										<br> I see. I need to find somewhere soon, so I’ll 
										have to <sup>6</sup># up my mind quickly.
										<br> Can I ask what you <sup>7</sup>#  ?
										<br> I’m a yoga teacher. 
										<br> Where do you teach? 
										<br> At the local sports centre. But if I moved to 
										a bigger house, I’d want to <sup>8</sup># one room 
										into a yoga studio.
										<br> Well, this house would be a great place to 
										<sup>9</sup># yoga! Just <sup>10</sup># your yoga mat 
										outside onto the cliff. Imagine the sunrise 
										over the waves, the solitude …
										<br> I suppose you would be alone there … except 
										for all the people who stop to<br> <sup>11</sup># photos 
										of your house!
 									<br>
                </div>
                
        </div>
          

           
          `,
				answer: ['do', 'take', 'make', 'do', 'take', 'make', 'do', 'make', 'do', 'take', 'take'],
			},
		],
	},

	4: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P53-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page53/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `Read the <span style="color:#8e258c">Learn this!</span> box. Which examples of do, make
and take in the dialogue in exercise 2 match the basic
meanings in the <span style="color:#8e258c">Learn this!</span> box?
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		maxLength: 101,
		component: T6,
		isHiddenCheck: true,
		textareaStyle: { width: 850 },
		inputSize: 850,
		textAlign: 'left',
		questionImage: [],
		questions: [
			{
				title: `
        <img src="img/FriendsPlus/Page53/E4/1.jpg" />
        <div style='line-height:50px'>
          <div style='display:flex'><b>do:</b>&ensp;<textarea id='2' rows='2'></textarea></div>
          <b>make:</b>&ensp;#<br>
          <b>take:</b>&ensp;#<br>
        </div>
          `,
				answer: [
					'Can I make an appointment to look around?',
					'Just take your yoga mat outside onto the cliff.',
					`What can I do for you? I'm afraid you can't do that. Well, this house would be a great place to do yoga.`,
				],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P53-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page53/Key/E5answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `<span style="color:#8e258c">DICTIONARY WORK</span> Read a short extract from a dictionary entry for take. Answer the questions.
`,
				color: '#8e258c',
				//prefix: { icon: [''], text: '' },
			},
		],
		// stylesTextInput: { fontSize: 25, height: 25, borderBottom: 'none', background: 'none' },
		// maxLength: 11,
		component: T6,
		isHiddenCheck: true,
		// isHiddenCheck: true,
		stylesTextInput: {
			fontSize: 22,
			height: 27,
		},
		inputSize: 200,
		textAlign: 'center',
		// recorder: true,
		questionImage: [],
		questions: [
			{
				title: `
        <img style='width: 17cm' src="img/FriendsPlus/Page53/E5/1.jpg" />
        <div style='font-size: 22px'>
            <b>1</b>&ensp;What do ‘pt’ and ‘pp’ stand for?<br>
            pt:#&emsp;&emsp;pp:#
        </div>
        <div>
            <b>2</b>&ensp;How many different meanings are included?<br>
            <input id='1' width='500px'></input>
        </div>
        <div style='width:17cm'>
            <b>3</b>&ensp;Do the words printed in bold in the examples show a
grammatical rule, a useful collocation, or a spelling rule?<br>
            <input id='2' width='500px'></input>
        </div>
          `,
				answer: ['past tense', 'past participle', 'Three meanings are included.', 'they show a useful collocation.'],
			},
		],
	},

	6: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P53-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page53/Key/E6answerKey.png',

		titleQuestion: [
			{
				num: '6',
				title: `<span style="color:#8e258c">USE OF ENGLISH</span> Fill in the gap in each pair of sentences
with the correct form of do, make and take. Use a
dictionary to check the meaning of the verbs.
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: ['<b>Compound nouns</b>', ''],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 10,
		// isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 145,
		// stylesTextInput: { fontSize: 22, height: 23, borderBottom: 'none', background: 'none' },
		textAlign: 'center',
		// recorder: true,
		// //hideBtnFooter: true,
		questionImage: [],
		questions: [
			{
				title: `
        <div style='line-height:50px'>
          <div style='display:flex'>
            <b>1</b>
            <div style='margin-left:25px'>
                <b>a</b>&ensp;I#French classes for a year, but I can’t speak it well.<br>
                <b>b</b>&ensp;I#my Spanish exam last week and passed.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:25px'>
                <b>a</b>&ensp;Thanks for inviting me to your party, but I’m afraid I can’t#it.<br>
                <b>b</b>&ensp;What’s the time? I#it 7.30
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b>
            <div style='margin-left:25px'>
                <b>a</b>&ensp;The waves are#a lot of damage to the cliffs.<br>
                <b>b</b>&ensp;He was#150 km/h when the police stopped him.
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b>
            <div style='margin-left:25px'>
                <b>a</b>&ensp;That noise has been going on all night. I can’t#it any more!<br>
                <b>b</b>&ensp;I wish you would#your schoolwork more seriously
            </div>
          </div>
        
        </div>
                 
          `,
				answer: ['took', 'took', 'make', 'make', 'doing', 'doing', 'take', 'take'],
			},
		],
	},
	7: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P53-E7',
		audio: '',
		video: '',
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page53/Key/E7answerKey.png',
		titleQuestion: [
			{
				num: '7',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b>  Tell your partner about a time when you

`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 1,
		// isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 40,
		textAlign: 'center',
		recorder: true,
		//hideBtnFooter: true,
		questionImage: [],
		questions: [
			{
				title: `
          
         <div>
          <b>1</b>&ensp;did your best.<br>
          <b>2</b>&ensp;took it easy.<br>
          <b>3</b>&ensp;didn’t take something seriously.  <br>
         </div>
          `,
				answer: [''],
			},
		],
	},
	8: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P53-E8',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page24/Key/E4answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '8',
				title: `<b style="color:rg#(10 127 64)">SPEAKING</b> Work in groups. Which facility from exercise 7
would you like most for your school? Why? Can the whole
class agree on one choice?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page53/E8/1.pNg' }]],
	},
}

export default json
