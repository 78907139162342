import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 6',
    id: 'FGF11-U6-P82-E1',
    audio: '',
    video: '',
    component: T6,
    // exerciseKey: '',
    hideBtnFooter: true,
    recorder: true,
    titleQuestion: [
      {
        num: '1',
        title: `
				<span style="color: rgb(45, 206, 137)">SPEAKING</span> Describe the photo, which shows children 
having breakfast at a ‘public school’ in England. How is it 
different from the canteen at your school? Would you like 
to eat here? Why? / Why not?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 160,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: ['England', 'France', 'Kenya', 'Peru', 'Turkey', 'the USA', 'Viet Nam'],

    // checkDuplicated: true,
    hintBox: [
      {
        src: ['England', 'France', 'Kenya', 'Peru', 'Turkey', 'the USA', 'Viet Nam'],
        // arrow: true,
        width: 800,
        borderColor: '#000',
      },
    ],
    questions: [
      {
        title: `
        
          <div style="margin-left:250px; margin-top:30px"><img src= 'img/FriendsPlus/Page82/E1/1.jpg'/></div>

			  `,
        answer: ['France', 'Turkey', 'the USA', 'Peru', 'Viet Nam', 'Kenya', 'England'],
      },
    ],
  },

  2: {
    unit: "Unit 7",
    id: "FGF11-U6-P82-E2",
    audio: "",
    video: "",
    exerciseKey: '/img/FriendsPlus/Page82/key/E2answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '2',
        title: `
         Read the text. Which of these sentences is true about 
schools in the UK?
        `,
        color: '#860052',
      },
    ],

    question: {
      Write: {
        inputStyle: { width: 150 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight: "bold"
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 3px",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: [
          `
          a <br> b <br> c
          
          
          `
        ],
        answers: ['0-22'],
        initialValue: [],
      },
      Layout: `
<div>
      In the UK, 93% of children go to state schools, which 
are funded by the government. The remaining 7% go 
to private schools, which the government does not 
fund or control. The students pay fees to attend these 
schools. A small group of very old private schools are, 
confusingly, known as ‘public schools’. These include 
Eton College, which has educated nineteen British 
Prime Ministers over the past three centuries – as well 
as many other famous people from around the world, 
including Prince William and Prince Harry, actor Hugh 
Laurie and the former king of Nepal.
To many people, these public schools seem very odd
and old-fashioned, with their strange uniforms and 
confusing traditions. For example, all of the public 
schools divide the academic year into three terms. 
At Eton College, these are called ‘halves’, but at 
Charterhouse they’re called ‘quarters’ (and the 
shortest of the three is known as the ‘long quarter’).
Although private schools seem irrelevant to most British 
people, the influence they have on society is very 
real. That is because a very large number of people in 
important positions attended these schools, including 
71% of senior judges and 62% of senior officers in the 
armed forces. Some people even argue that the UK 
parliament does not represent the country because 32% 
of MPs are privately educated.</div>
      <div style="display:flex; margin-top:30px">
      
      <div>
        <input id='0' type='Circle'/>
        </div>
        <div style="margin-left:40px">
           All ‘public schools’ are private schools. <br>
           All private schools are ‘public schools’. <br>
           All state schools are ‘public schools’ <br>
        </div>
      </div>
      `,
    },
  },
  3: {
    unit: 'Introduction',
    id: 'FGF11-U6-P82-E3',
    audio: '',
    video: '',
    component: MatchDots,
    exerciseKey: '/img/FriendsPlus/Page82/key/E3answerKey.png',
    stylesTextInput: { borderBottom: 'dotted' },
    inputSize: 700,
    fontSize: 55,
    maxLength: 200,
    // checkUppercase: true,
    titleQuestion: [
      {
        num: '3',
        title: `
         <span style="color:rgb(107 27 150)">VOCABULARY </span> Match a–f with 1–6 to make compounds. They are all in the text.`,
        color: '#92278f',
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {

            boxMatchStyle: {
              position: 'absolute',
              top: '55px',
              left: '400px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 10
          {

            boxMatchStyle: {
              position: 'absolute',
              top: '96px',
              left: '400px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 11
          {

            boxMatchStyle: {
              position: 'absolute',
              top: '140px',
              left: '400px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 0
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '180px',
              left: '400px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 1
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '220px',
              left: '400px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 2
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '260px',
              left: '400px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          },
          {// 3
            boxMatchStyle: {
              position: 'absolute',
              top: '55px',
              left: '550px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 10
          {

            boxMatchStyle: {
              position: 'absolute',
              top: '96px',
              left: '550px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 11
          {

            boxMatchStyle: {
              position: 'absolute',
              top: '140px',
              left: '550px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 0
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '180px',
              left: '550px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 1
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '220px',
              left: '550px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 2
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '260px',
              left: '550px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 3

        ],
        answers: ['0-9', '1-11', '2-7', '3-6', '10-4', '5-8'],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex;margin-left:200px;margin-top:40px;margin-bottom:50px'>
        <input id='0' type= 'boxMatch' />
        <input id='1' type= 'boxMatch' />
        <input id='2' type= 'boxMatch' />
        <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
        <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />
        <input id='9' type= 'boxMatch' />
        <input id='10' type= 'boxMatch' />
        <input id='11' type= 'boxMatch' />
        <img src='img/FriendsPlus/Page82/E3/1.jpg'></img>

      </div>
        
      `,
    },
  },
  4: {
    // Exercise num
    unit: 'Unit 6',
    id: 'FGF11-U6-P82-E4',
    audio: 'Audios/2.37-audio.mp3',
    video: '',
    component: T6,
    inputSize: 910,
    exerciseKey: '/img/FriendsPlus/Page82/key/E4answerKey.png',
    // recorder: true,
    titleQuestion: [
      {
        num: '4',
        title: `
				<headphone1 name="2.37" src="Audios/2.37-tieude.mp3"></headphone1>  Listen to a radio interview about public schools. 
How many public schools do they mention?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 160,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: ['England', 'France', 'Kenya', 'Peru', 'Turkey', 'the USA', 'Viet Nam'],

    // checkDuplicated: true,
    hintBox: [
      {
        src: ['England', 'France', 'Kenya', 'Peru', 'Turkey', 'the USA', 'Viet Nam'],
        // arrow: true,
        width: 800,
        borderColor: '#000',
      },
    ],
    questions: [
      {
        title: `
        
          <div style="margin-left:50px; margin-top:30px">
          <h1>Answer:</h1>
            <p>#</p>
          </div>

			  `,
        answer: ['Three schools are mentioned. (Eton College, Rugby College, Westminster School).'],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "FGF11-U6-P82-E5",
    audio: "Audios/2.37-audio.mp3",
    video: "",
    exerciseKey: '/img/FriendsPlus/Page82/key/E5answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '5',
        title: `
          <headphone1 name="2.37" src="Audios/2.37-tieude.mp3"></headphone1>  Listen again and circle the correct answers (a–d).
        `,
        color: '#860052',
      },
    ],

    question: {
      Write: {
        inputStyle: { width: 150 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 6,
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight: "bold",

        },
        selectWordStyle: {
          padding: 5,
          border: "solid 3px",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: [
          `
          a <br> b <br> c <br> d
          
          
          `, `
          a <br> b <br> c <br> d
          
          
          `, `
          a <br> b <br> c <br> d
          
          
          `, `
          a <br> b <br> c <br> d
          
          
          `
        ],
        answers: ['0-26', '1-34', '2-26', '3-30'],
        initialValue: [],
      },
      Layout: `
      <div style="margin-top:40px;margin-left:30px">
     
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='0' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <p style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">1 </span>  David Brown decided to write a book about public school traditions because</p>
        <div style="margin-top:46px ; margin-top:-0px">
        <p style="margin-top:-21px">they reminded him of his own school days. </p><p style="margin-top:-21px">he’s always had an interest in public schools.</p><p style="margin-top:-21px">he works in a public school.</p><p style="margin-top:-21px">somebody else asked him to write it.</p>
        </div>
        </div>
        
      </div>
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='1' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <p style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">2 </span>  ‘The Wall Game’ is a game which</p>
        <div style="margin-top:46px ; margin-top:-0px">
        <p style="margin-top:-21px">is only played at a small number of public schools. </p><p style="margin-top:-21px">has been played since 1909.</p><p style="margin-top:-21px">involves kicking a ball over a wall to score.</p><p style="margin-top:-21px">has very few goals.</p>
        </div>
        </div>
        
      </div>
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='2' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <p style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">3 </span>  The sport of rugby was invented</p>
        <div style="margin-top:46px ; margin-top:-0px">
        <p style="margin-top:-21px">by a schoolboy called Rugby in 1823. </p><p style="margin-top:-21px">by a schoolboy called Ellis at a school called Rugby College.</p><p style="margin-top:-21px">in various different countries around the same time.</p><p style="margin-top:-21px">at Eton College.</p>
        </div>
        </div>
        
      </div>
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='3' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <p style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">4 </span>  What does the game called ‘the Greaze’ involve?</p>
        <div style="margin-top:46px ; margin-top:-0px">
        <p style="margin-top:-21px">Students throw books at a cook. </p><p style="margin-top:-21px">Students make a pancake.</p><p style="margin-top:-21px">Students try to get a piece of a pancake.</p><p style="margin-top:-21px">Students throw a pancake as high as possible.</p>
        </div>
        </div>
        
      </div>
      `,
    },
  },
  6: {
    // Exercise num
    unit: 'Unit 6',
    id: 'FGF11-U6-P82-E6',
    audio: '',
    video: '',
    component: T6,
    inputSize: 910,
    // exerciseKey: '',
    hideBtnFooter: true,
    recorder: true,
    titleQuestion: [
      {
        num: '6',
        title: `
				<span style="color: rgb(45 206 137)">SPEAKING</span> What traditions are there in schools in Viet Nam? 
Use the ideas below to help you.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 160,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: ['England', 'France', 'Kenya', 'Peru', 'Turkey', 'the USA', 'Viet Nam'],

    // checkDuplicated: true,
    hintBox: [
      {
        src: ['England', 'France', 'Kenya', 'Peru', 'Turkey', 'the USA', 'Viet Nam'],
        // arrow: true,
        width: 800,
        borderColor: '#000',
      },
    ],
    questions: [
      {
        title: `
        
          <div style="margin-left:0px; margin-top:30px">
          <p style="color: rgb(55 125 166)">ceremonies clothes first / last day at school <br>food graduation public holidays<br>shows and performances songs</p>
          </div>

			  `,
        answer: [''],
      },
    ],
  },
};

export default json;
