import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 7',
    id: 'FGF11-U7-P91-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '1',
        title: `
				Look at the photos. Where would you prefer to go on holiday? Why?
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    //hideBtnFooter: true,
    questions: [
      {
        title: `
          <div><img src= 'img/FriendsPlus/Page91/E1/1.jpg' style="width: 1300px; margin-left: -100px"/></div>
			  `,
        answer: [],
      },
    ],
  },

  2: {
    // Exercise num
    unit: 'Unit 7',
    id: 'FGF11-U7-P91-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page91/E2/key/answerKey.png',
    inputSize: 760,
    stylesTextInput: [],
    maxLength: 70,
    textAlign: 'left',
    titleQuestion: [
      {
        num: '2',
        title: `
				Read the blog posts. Who has already done four things and has another two planned? Who has done one thing and has another one planned?
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <img src='/img/FriendsPlus/Page91/E2/1.jpg' style='width: 1300px; margin-left: -100px;'/>
        
        <div>
          <div><b>Who has already done four things and has another two planned?</b></div>
          <div>#</div>
        </div>
        <div>
          <div><b>Who has done one thing and has another one planned?</b></div>
          <div>#</div>
        </div>
			  `,
        answer: [
          'Harry has done four things and has two more planned.',
          'Emily has done one thing and has one more planned.',
        ],
      },
    ],
  },

  3: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P91-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page91/E3/key/answerKey.png',
    inputSize: 760,
    stylesTextInput: [],
    maxLength: 70,
    textAlign: 'left',
    titleQuestion: [
      {
        num: '3',
        title: `
				Read the task below. Do both blog posts include all four points?
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    questions: [
      {
        title: `
        <div style='background-color: rgb(210, 222, 244); margin-top: 20px; margin-left:100px; width:700px; border-radius: 15px; '>
          <div style='padding: 15px 25px 25px 25px;' >
            <div>You are on holiday with your family. Write a blog post. Include information about:</div>
            <ul>
              <li>the journey to your holiday destination and where you are staying.</li>
              <li>the weather.</li>
              <li>some holiday activities you have done.</li>
              <li>something you plan to do in the next few days.</li>
            </ul>
          </div>
        </div>
        
        <div>
          <div><b>Do both blog posts include all four points?</b></div>
          <div>#</div>
        </div>
        <img src='/img/FriendsPlus/Page91/E3/1.jpg' style='width: 1300px; margin-left: -100px; margin-top:15px;'/>
			  `,
        answer: ["No: the first post doesn't mention where Harry is staying."],
      },
    ],
  },

  4: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P91-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page91/E4/key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `
				<headphone1 name='3.11' src=''></headphone1> Read the <span style='color:rgb(43, 125, 233);'> Writing Strategy </span>. Find sentences where the writers have omitted the subject.
			  `,
        color: '#7c084f',
      },
    ],
    inputSize: 930,
    stylesTextInput: [],
    maxLength: 200,
    textAlign: 'left',
    textareaStyle: { width: 950 },

    checkDuplicated: true,
    isHiddenCheck: true,
    titleImage: '',
    questions: [
      {
        title: `
        
			  <div style='background-color: rgb(210, 222, 244); margin-top: 20px; margin-left:100px; width:700px; border-radius: 15px; border: 5px dotted rgb(43, 125, 233)'>
          <div style='padding: 15px 25px 25px 25px;' >
            <div ><b style='color:rgb(43, 125, 233) ;'>Writing Strategy</b></div>
            <div>In informal style (e.g. in emails, blogs and postcards) we can omit sentence subjects I and we.</div>
            <div><i style='color:rgb(1, 142, 211);'> Must go now! Wish you were here!</i></div>
            <div>With the present continuous, we can also omit the auxiliaries am and are.</div>
            <div><i style='color:rgb(1, 142, 211);'> Having a great time in Italy!</i></div>
            <div>However, only do this once or twice in a text. Do not omit every instance of I and we.</div>
          </div>
        </div>

        <div>
          <div><b>Sentences 1:</b></div>
          <div>#</div>
        </div>
        <div>
          <div><b>Sentences 2:</b></div>
          <div>#</div>
        </div>
        <div>
          <div><b>Sentences 3:</b></div>
          <div>#</div>
        </div>
        <div>
          <div><b>Sentences 4:</b></div>
          <div>#</div>
        </div>
        <div>
          <div><b>Sentences 5:</b></div>
          <div><textarea id="5" rows="2"></textarea></div>
        </div>
        <div>
          <div><b>Sentences 6:</b></div>
          <div>#</div>
        </div>
        <img src='/img/FriendsPlus/Page91/E4/1.jpg' style='width: 1300px; margin-left: -100px; margin-top:15px;'/>
			  `,
        answer: [
          'Hilarious, lol!',
          'Still cloudy, but it’s stopped raining.',
          'Can’t wait! ',
          'Will post another entry tomorrow  when we’re back from Disneyland.',
          'Really looking forward to it!',
          'Arrived in Tri An (Dong Nai Province) two days ago with Joanna after a tiring journey by coach from Ho Chi Minh City.',
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P91-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page91/E5/key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `
				Rewrite the sentences, omitting the subject.
			  `,
        color: '#7c084f',
      },
    ],
    inputSize: 530,
    stylesTextInput: [],
    maxLength: 90,
    textAlign: 'left',
    titleImage: '',
    questions: [
      {
        title: `
      	<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>
            <div>We have finally arrived!</div>
            <div>#</div>
          </div>
        </div>
      	<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>
            <div>I am looking forward to seeing you again.</div>
            <div>#</div>
          </div>
        </div>
      	<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>
            <div>I went up the Empire State Building yesterday.</div>
            <div>#</div>
          </div>
        </div>
      	<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>
            <div>We visited Ben Thanh Market on Friday.</div>
            <div>#</div>
          </div>
        </div>
      	<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div>
            <div>We are flying back tomorrow.</div>
            <div>#</div>
          </div>
        </div>

			  `,
        answer: [
          'Finally arrived! / Have finally arrived!',
          'Looking forward to seeing you again.',
          'Went up the Empire State Building yesterday.',
          'Visited Ben Thanh Maket on Friday.',
          'Flying back tomorrow.',
        ],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 7',
    id: 'FGF11-U7-P91-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page91/E6/key/answerKey.png',
    inputSize: 113,
    stylesTextInput: [],
    maxLength: 10,
    textAlign: 'left',
    titleQuestion: [
      {
        num: '6',
        title: `
				<span style='color:rgb(124, 8, 79);'>VOCABULARY</span> Complete the holiday activities below. They are all in the blog posts in exercise 2.
			  `,
        color: 'rgb(124, 8, 79)',
      },
    ],
    questions: [
      {
        title: `
        
        <div style='border-left: 5px solid rgb(210, 222, 244);'>
          <div  style='margin: 20px;'>
            <div><b style='color:rgb(43, 125, 233);'> Holiday activities</b></div>
            <div><b>1 &emsp;</b> go &emsp;<b>s#/ k#</b></div>
            <div><b>2 &emsp;</b> take a &emsp;<b>b# t#</b></div>
            <div><b>3 &emsp;</b> go up a &emsp;<b>t#</b></div>
            <div><b>4 &emsp;</b> visit an &emsp;<b>a#</b></div>
            <div><b>5 &emsp;</b> hire a &emsp;<b>b#</b></div>
            <div><b>6 &emsp;</b> buy &emsp;<b>s#</b></div>
          </div>
        </div>
        <img src='/img/FriendsPlus/Page91/E6/2.jpg' style='width: 400px; margin-left: 50px;'/>
        <img src='/img/FriendsPlus/Page91/E6/1.jpg' style='width: 1300px; margin-left: -100px;'/>
			  `,
        answer: ['hopping', 'ayaking', 'oat', 'rip', 'ower', 'rt gallery', 'ike', 'ouvenirs'],
      },
    ],
  },
  7: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P91-E7',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    //hideBtnFooter: true,
    titleQuestion: [
      {
        num: '7',
        title: `
				Read the task in exercise 3 again. Make notes for each of the four points.
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    questions: [
      {
        title: `
        <div style='background-color: rgb(210, 222, 244); margin-top: 20px; margin-left:100px; width:700px; border-radius: 15px; '>
          <div style='padding: 15px 25px 25px 25px;' >
            <div>You are on holiday with your family. Write a blog post. Include information about:</div>
            <ul>
              <li>the journey to your holiday destination and where you are staying.</li>
              <li>the weather.</li>
              <li>some holiday activities you have done.</li>
              <li>something you plan to do in the next few days.</li>
            </ul>
          </div>
        </div>
        <img src='/img/FriendsPlus/Page91/E7/1.jpg' style='width: 1300px; margin-left: -100px; margin-top:15px;'/>
			  `,
        answer: [],
      },
    ],
  },
  8: {
    // Exercise num
    unit: 'Unit 7',
    id: 'FGF11-U7-P91-E8',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '8',
        title: ` Write your blog post (120–150 words).
			  `,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 950 },
    //hideBtnFooter: true,
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title: `
					  <div style=" width: 750px; background-image: url('img/FriendsPlus/Page91/E8/1.jpg');  background-repeat: no-repeat;background-size: 750px 230px;">
              <div style='padding:25px 15px 25px 55px;'>
                    <div><b style='color:rgb(1, 142, 211);'>LEARN THIS!</b></div>
                    <div>Have you …</div>
                    <ul>
                      <li>included all four points in the task?</li>
                      <li>used an appropriately informal style?</li>
                      <li>checked the spelling and grammar?</li>
                    </ul>
                </div>
            </div>
          <div><textarea id="0" rows="7"></textarea></div>
			  `,
        answer: [],
      },
    ],
  },
};

export default json;
