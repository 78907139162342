import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P48-E1',
    exerciseKey: 'img/FriendsPlus/Page48/E1/Key/E1answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    audio: 'Audios/2.02-audio.mp3',
    component: T6,
    inputSize: 250,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 30,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '1',
        title: `
       <headphone1 name='2.02' src='Audios/2.02-tieude.mp3'></headphone1> <span style=" color: #92278f">VOCABULARY</span>  
        
        Look at the photos. What types of homes are they? Listen and choose from the words below. What type of home do you live in? 
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>
              <div style='display:flex; width:90%; margin-bottom: 40px; padding: 10px;'>
              
               <img  style='width:50%; height: 400px' src='img/FriendsPlus/Page48/E1/1.jpg' />
               <img  style='width:75%; height: 419px' src='img/FriendsPlus/Page48/E1/2.jpg' />
              </div>
          <div style='font-size:21px;'>
              <div style=" gap: 20px; color:rgb(146,39,143);  ">
                <b>Types of homes</b>
                &ensp;bungalow
                &ensp;detached house
                &ensp;farmhouse
                &ensp;flat
                &ensp;houseboat
                &ensp;mansion
                &ensp;mobile home
                &ensp;semi-detached house
                &ensp;terraced house
                &ensp;thatched cottage
                &ensp;villa
              </div>
              <div style="display: flex; gap: 20px; margin-top:10px">
                <b>A </b>#&ensp;
                <b>B </b>#&ensp;
                
              </div>
              <div style="display: flex; gap: 20px; margin-top:20px">
             
                <b>C </b>#&ensp;
                <b>D </b>#&ensp;
              </div>
            </div>
              
          </div>
          
        `,
        answer: ['mansion', 'thatched cottage', ' terraced house', 'flat'],
      },
    ],
  },
  2: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P48-E2',
    exerciseKey: 'img/FriendsPlus/Page48/E2/Key/E2answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    audio: 'Audios/2.03-audio.mp3',
    component: T6,
    inputSize: 1000,
    maxLength: 200,
    textAlign: 'left',

    textareaStyle: {
      // background: 'none',
      width: 1000,
      fontSize: 22,
      height: 100,
    },
    titleQuestion: [
      {
        num: '2',
        title: `
         <headphone1 name='2.03' src='Audios/2.03-tieude.mp3'></headphone1> <span style=" color: #92278f">VOCABULARY</span>  
        
       Work in pairs. Listen to the words below. Put them into two groups: those that are more likely to be a) parts of a house and b) in a garden. 
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 850px; position: relative; '>
          <div style='font-size:22px;'>
              <div style=" gap: 20px; color:rgb(146,39,143);  ">
                <b>Parts of a house and garden</b>
                <s style='margin-left:10px'>attic</s>
                &ensp;balcony
                &ensp;basement
                &ensp;cellar
                &ensp;conservatory
                &ensp;drive
                &ensp;extension
                &ensp;fence
                &ensp;flower bed
                &ensp;garage
                &ensp;gate
                &ensp;hall
                &ensp;hedge
                &ensp;landing
                &ensp;lawn
                &ensp;path
                &ensp;patio
                &ensp;pond
                &ensp;porch
                &ensp;shutters
                &ensp;sliding doors
                &ensp;stairs
                &ensp;swimming pool

              </div>
              <div style="font-size: 18px; color: rgb(148,196,209); margin-top:10px"><i>Parts of a house: attic, ...</u></div>
              <div><i style='font-size:18px; color: rgb(152,198,204)'>*Write the selected words in the sorted order of the list above</i></div>
              <div style=" text-align: left">
                  <div style=" gap: 20px; margin-top:10px">
                    <b>Parts of a house: </b><textarea id='0' row='3'>
                  </div>
                  <div style=" gap: 20px; margin-top:20px">
                     <b>In a garden: </b><textarea id='1' row='3'>
                  </div>
              </div>
            </div>
              
          </div>
          
        `,
        answer: [
          ' balcony, basement, cellar, conservatory, extension, hall, landing, porch, shutters, sliding doors, stairs, garage',
          'drive, fence, flowerbed, gate, hedge, lawn, path, patio, pond, swimming pool',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P48-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page48/E3/Key/E3answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '3',
        title: `
				 Which is the odd-one-out? Explain why. Sometimes more than one answer is possible
				`,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'none', borderColor: 'transparent' },
        // initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, borderBottom: '2px solid', borderColor: 'gray' },
        limitSelect: 3,
        listWords: [
          `cellar&ensp; basement&ensp; attic`, //0
          `lawn&ensp; path&ensp; porch`, // 1
          `detached_house&ensp; semi-detached_house&ensp; terraced_house`, // 2
          `fence&ensp; gate&ensp; hedge`, // 3
          `houseboat&ensp; mobile_home&ensp; villa`, // 4
        ],
        answers: [`0-4`, `1-4`, `2-0`, `3-2`, `4-4`],

        initialValue: [],
      },
      Layout: `
      
					<div style="  padding-top: 10px">
						
            <div style='margin-bottom:20px'><b>1&ensp;</b><input id='0' type='Circle' /></div>
            <div style='margin-bottom:20px'><b>2&ensp;</b><input id='1' type='Circle' /></div>
            <div style='margin-bottom:20px'><b>3&ensp;</b><input id='2' type='Circle' /></div>
            <div style='margin-bottom:20px'><b>4&ensp;</b><input id='3' type='Circle' /></div>
            <div style='margin-bottom:20px'><b>5&ensp;</b><input id='4' type='Circle' /></div>

					</div>
          
        `,
    },
  },
  4: {
    unit: 'Unit 4',
    id: 'FGF11-P48-E4',
    exerciseKey: 'img/FriendsPlus/Page48/E4/Key/E4answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    audio: 'Audios/2.04-audio.mp3',
    component: T6,
    inputSize: 200,
    maxLength: 900,
    textAlign: 'center',

    textareaStyle: {
      // background: 'none',
      width: 700,
      fontSize: 22,
      height: 100,
    },
    titleQuestion: [
      {
        num: '4',
        title: `
        <headphone1 name='2.04' src='Audios/2.04-tieude.mp3'></headphone1> 
        
        Listen to an estate agent showing some one round a house. Which seven parts of the house are mentioned in the dialogue?
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 850px; position: relative; '>
          
              <div style="font-size: 18px; color: rgb(148,196,209); margin-top:10px"><i>Answer: a, b, c ...</u></div>
              <div style=" text-align: left; margin-top: 50px; font-size: 22px">
                  <div>
                    <b>ANSWER: 
                    <div>#,#,#,#,#,#,#</div>
                  </div>
                  
              </div>
            </div>
              
          </div>
          
        `,
        answer: [
          'front gate/  hall/ living room/ kitchen/ back garden/ back fence/ main bedroom',
          'front gate/  hall/ living room/ kitchen/ back garden/ back fence/ main bedroom',
          'front gate/  hall/ living room/ kitchen/ back garden/ back fence/ main bedroom',
          'front gate/  hall/ living room/ kitchen/ back garden/ back fence/ main bedroom',
          'front gate/  hall/ living room/ kitchen/ back garden/ back fence/ main bedroom',
          'front gate/  hall/ living room/ kitchen/ back garden/ back fence/ main bedroom',
          'front gate/  hall/ living room/ kitchen/ back garden/ back fence/ main bedroom',
        ],
      },
    ],
  },
};

export default json;
