import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P84-E1',
    exerciseKey: 'img/FriendsPlus/Page84/E1/Key/E1answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 130,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '1',
        title: `
        <span style="margin-bottom: 10px;font-size: 22px; color: rgb(146,39,143)">VOCABULARY</span>   
       Match the photos (A–H) with art forms from the list below. In your 
      opinion, which art form needs the most skill and which the least? Give reasons, 
      using the phrases below to help you.
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>
          <div style='font-size:24px; position: relative'>
              <div style="color:rgb(146,39,143); margin-bottom:10px  ">
                <b>Art forms</b>
                &ensp;ballet
                &ensp;cartoon
                &ensp;classical music
                &ensp;dance
                &ensp;drawing
                &ensp;mime
                &ensp;musical
								&ensp;novel
								&ensp;opera
								&ensp;painting
								&ensp;play
                &ensp;poem
                &ensp;pop music
                &ensp;sculpture
                &ensp;sitcom
              </div>
              <div style='display:flex'>
              <img style="width:13cm" src='img/FriendsPlus/Page84/E1/1.jpg' />
              <img style="width:13cm" src='img/FriendsPlus/Page84/E1/2.jpg' />
              </div>
							
							<div style='border-left: 3px solid rgb(146,39,143); padding: 10px; margin-bottom:10px'>
              <div>You have to (be able to) … in order to …</div>
              <div>It’s very difficult to … / It isn’t that difficult to … / It’s relatively easy to …</div>
              <div>It doesn’t take much practice to … / It takes a lot of practice to …</div>
              </div>
							
              <b>ANSWER:</b>
              <div>
              <b>&ensp;A - #</b>&ensp;
              <b>&ensp;B - #</b>&ensp;
              <b>&ensp;C - #</b>&ensp;
              <b>&ensp;D - #</b>&ensp;<br>
              <b>&ensp;E - #</b>&ensp;
              <b>&ensp;F - #</b>&ensp;
              <b>&ensp;G - #</b>&ensp;
              <b>&ensp;H - #</b>&ensp;
              </div>
            </div>
              
          </div>
          
        `,
        answer: ['ballet', 'cartoon', 'play', 'musical', 'sitcom', 'drawing', 'dance', 'opera'],
      },
    ],
  },
  2: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P84-E2',
    exerciseKey: 'img/FriendsPlus/Page84/E2/Key/E2answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.03.mp3',
    component: T6,
    inputSize: 850,
    // maxLength: 200,
    textAlign: 'left',
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      height: 40,
    },
    titleQuestion: [
      {
        num: '2',
        title: `
        <div style="font-size: 23px"> 
        Put the art forms in exercise 1 into four groups. Some can go in more than one group.
        </div>  
        
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
        <div style='width:80%; position: relative; '>
          <div style='font-size:20px;'>
              <div style=" color:rgb(146,39,143); ">
                <b>Art forms</b>
                &ensp;ballet
                &ensp;cartoon
                &ensp;classical music
                &ensp;dance
                &ensp;drawing
                &ensp;mime
                &ensp;musical
								&ensp;novel
								&ensp;opera
								&ensp;painting
								&ensp;play
                &ensp;poem
                &ensp;pop music
                &ensp;sculpture
                &ensp;sitcom
              </div>
              <div><i style='font-size:18px; color: rgb(152,198,204)'>*Write the selected words in the sorted order of the list above: ballet, cartoon,...</i></div>
              <div style=" text-align: left; line-height:2.5">
                  <div style="font-size:25px">
                    <b>A </b>Literature
                    <div>#</div>
                  </div>
                  <div style="font-size:25px">
                    <b>B </b>Music
                    <div>#</div>
                  </div>
                  <div style="font-size:25px">
                    <b>C </b>Performing arts
                    <div>#</div>
                  </div>
                  <div style="font-size:25px">
                    <b>D </b>Visual arts
                    <div>#</div>
                  </div>
                  
              </div>
            </div>
              
          </div>
          
        `,
        answer: [
          'novel, play, poem',
          'classical music, musical, opera, pop music',
          'ballet, classical music, dance, mime, musical, opera, play, pop music, sitcom',
          'cartoon, drawing, painting, sculpture',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P84-E3',
    exerciseKey: 'img/FriendsPlus/Page84/E3/Key/E3answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 300,
    maxLength: 20,
    textAlign: 'center',
    //hideBtnFooter: true,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '3',
        title: `
        <span style="margin-bottom: 10px;font-size: 22px; color: rgb(146,39,143)">VOCABULARY</span>   
       Work in pairs. Name one famous person for each type of artists below.
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>
          <div style='font-size:24px; position: relative'>
              <div style="color:rgb(146,39,143); margin-bottom:10px  ">
                <b>Artists</b>
                &ensp;actor
                &ensp;composer
                &ensp;conductor
                &ensp;dancer / choreographer
                &ensp;director
                &ensp;novelist
                &ensp;opera singer
                &ensp;painter
                &ensp;playwright
                &ensp;poet
                &ensp;pop singer
                &ensp;sculptor
              </div>
							
              <b style='margin-top:20px'>ANSWER:</b>
              <div style='display:flex; width:498px'>
              <div style='margin-right:100px'>
              <b>&ensp;actor: #</b>&ensp;<br>
              <b>&ensp;composer: #</b>&ensp;<br>
              <b>&ensp;conductor: #</b>&ensp;<br>

              <b>&ensp;dancer / choreographer: #</b>&ensp;<br>
              <b>&ensp;director: #</b>&ensp;<br>
              <b>&ensp;novelist: #</b>&ensp;
              </div>

              <div style='width: 636px'>
               <b>&ensp;opera singer: #</b>&ensp;<br>
              <b>&ensp;painter: #</b>&ensp;<br>
              <b>&ensp;playwright: #</b>&ensp;<br>

              <b>&ensp;poet: #</b>&ensp;<br>
              <b>&ensp;pop singer: #</b>&ensp;<br>
              <b>&ensp;sculptor: #</b>&ensp;
              </div>
              
              </div>
            </div>
              
          </div>
          
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
