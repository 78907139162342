import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P43-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page43/E1/key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `
        <b style='color:rgb(2, 146, 97);'>SPEAKING</b> Look at the photo.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 440,
    recorder: true,
    textAlign: 'left',
    questions: [
      {
        title: `
        <div style='display: flex; gap:30px; margin-left: 50px;'>
          <div>
              <div><b> Do you know this film? </b></div>
              <div>#</div>
              <div><b> Can you name the actor? </b></div>
              <div>#</div>

				  </div>
				  <img src='img/FriendsPlus/Page43/E1/1.jpg' style='width: 400px;' />
        </div>
			  `,
        answer: ['The film is Iron Man 3. ', 'The actor is Robert Downey Jr.'],
      },
    ],
  },
  2: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P43-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page43/E2/key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
        Read the task below and the letter on the right. Find the parts of the letter that match elements 1–4 of the task.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 60,
    textAlign: 'center',
    maxLength: 2,
    questions: [
      {
        title: `
        <div style='display: flex; gap:30px; margin-left: 50px;'>
          <div style='background-color: rgb(210, 222, 244); margin-top: 20px; margin-left:-50px; width:600px; border-radius: 15px; '>
            <div style='padding: 15px 25px 25px 25px;' >
              <div>You and a friend went to see a film at the cinema recently. Write a letter to another friend in which you:</div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 1 </b></div>
                <div>
                  <div> say what film you saw and who you went with.</div>
                  <div>line <i>(1-22)</i> #</div>
                </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 2 </b></div>
                <div>
                  <div> give your personal opinion of the film.</div>
                  <div>line <i>(1-22)</i> #</div>
                </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 3 </b></div>
                <div>
                  <div> mention what you did after the film.</div>
                  <div>line <i>(1-22)</i> #</div>
                </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 4 </b></div>
                <div>
                  <div> invite your friend to do an activity with you soon.</div>
                  <div>line <i>(1-22)</i> #</div>
                </div>
              </div>

            </div>
          </div>
				  <img src='img/FriendsPlus/Page43/E2/1.jpg' style='width: 500px;' />
        </div>
        <div style='position: absolute; top: 206px; left: 952px;font-size: 15px;'><b><i>line 4</i></b></div>
        <div style='position: absolute; top: 255px; left: 952px;font-size: 15px;'><b><i>line 6</i></b></div>
        <div style='position: absolute; top: 385px; left: 952px;font-size: 15px;'><b><i>line 12</i></b></div>
        <div style='position: absolute; top: 452px; left: 952px;font-size: 15px;'><b><i>line 15</i></b></div>
        <div style='position: absolute; top: 544px; left: 952px;font-size: 15px;'><b><i>line 19</i></b></div>
			  `,
        answer: ['4', '8', '12', '15'],
      },
    ],
  },
  3: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P43-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page43/E3/key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `
        Look at the part of the letter where Andy mentions his personal opinion and read the aspects of the film below. Which ones does he mention?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 160,
    textAlign: 'center',
    maxLength: 20,

    checkDuplicated: true,
    hintBox: [
      {
        src: ['acting', 'characters', 'ending', 'plot', 'scenes', 'script', 'soundtrack', 'special effects'],
        // arrow: true,
        width: 600,
        borderColor: '#2284c8',
      },
    ],
    questions: [
      {
        title: `
        <div style='display: flex; gap:30px; margin-left: 0px;'>
            <div style='padding: 5px 5px 25px 5px;' >
				      <div style='margin: 20px 0px 10px 0px;'><hintBox id='0' ></hintBox></div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 1 </b></div>
                <div>#</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 2 </b></div>
                <div>#</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 3 </b></div>
                <div>#</div>
              </div>
            </div>
				    <img src='img/FriendsPlus/Page43/E3/1.jpg' style='width: 500px;' />
        </div>
			  `,
        answer: ['plot/script/special effects', 'plot/script/special effects', 'plot/script/special effects'],
      },
    ],
  },
  4: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P43-E4',
    audio: '',
    video: '',
    component: T6,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page43/E4/key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `
				Read the <span style='color:rgb(34, 132, 200);'> Writing Strategy </span>. Then answer the questions.
			  `,
        color: '#92278f',
      },
    ],
    inputSize: 300,
    textAlign: 'left',
    maxLength: 40,
    titleImage: '',
    questions: [
      {
        title: `
			  <div style='background-color: rgb(210, 222, 244); margin-top: 20px; margin-left:0px; width:700px; border-radius: 15px; border: 5px dotted rgb(34, 132, 200);'>
          <div style='padding: 15px 25px 25px 25px;' >
            <div ><b style='color:rgb(34, 132, 200) ;'> Writing Strategy</b></div>
            <div>When you write a letter to a friend or relative:</div>
            <ul>
              <li>Start with: Dear [<i>Jake</i>],</li>
              <li>Begin the main part of the letter with a short  introduction. You can use phrases like: <i>How are you? Sorry I didn’t reply sooner. Thanks for your letter</i></li>
              <li>Write in paragraphs.</li>
              <li>Do not use very formal language in your letter.</li>
              <li>Use contractions (<i>it’s, there’s</i>, etc).</li>
              <li>Finish the main part of the letter with a short paragraph. You can use phrases like: <i>That’s all for now. I’d better go now. Write again soon.</i></li>
              <li>End the letter with Love, or Best wishes, and your first name.</li>
            </ul> 
          </div>
        </div>
            <div style="margin-top: 20px; display: flex; flex-direction: column; gap: 20px">
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 1 </b></div>
                <div>
                  <div>What phrase(s) does Andy include in his introduction?</div>
                  <div>
                    <input id='0' width='260px' />, 
                    <input id='1' width='300px' />
                  </div>
                </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 2 </b></div>
                <div>
                  <div> How many paragraphs does he write? <input id='2' width='60px' /></div>
                </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 3 </b></div>
                <div>
                  <div> Does he use any very formal language? <input id='3' width='60px' /></div>
                </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 4 </b></div>
                <div>
                  <div>
                    What contractions does he use? 
                    <input id='4' width='100px' />, 
                    <input id='5' width='100px' />, 
                    <input id='6' width='100px' /> 
                  </div>
                </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 5 </b></div>
                <div>
                  <div>
                    What phrase(s) does Andy include in the final paragraph? <input id='7' width='210px' />
                  </div>
                </div>
              </div>
            </div>
			  `,
        answer: [
          'Thanks for your letter.',
          "sorry I didn't reply sooner.",
          'five',
          'no',
          "didn't",
          "isn't",
          "They're",
          "I'd better go now.",
        ],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P43-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page43/E5/key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `
				Read the <span style='color:rgb(97, 156, 236);'>Learn this!</span> box and complete it with the phrase Andy uses for making his invitation.
			  `,
        color: '#92278f',
      },
    ],
    inputSize: 180,
    textAlign: 'left',
    maxLength: 30,
    titleImage: '',
    questions: [
      {
        title: `
        <div style='border-radius: 20px; border: 2px solid rgb(97, 156, 236); width: 600px; margin: 10px 50px 30px 50px'>
          <div style='margin:15px;'>
            <div><b style='color:rgb(97, 156, 236);'>LEARN THIS!</b><b> Invitations</b></div>
            <div><i>Would you like</i> + infinitive?</div>
            <div><i>Why don’t you / we</i> + infinitive without <i>to</i>?</div>
            <div><i>How about</i> + <i>-ing</i> form?</div>
            <div># + <i>-ing</i> form?</div>
          </div>
        </div>
        
				    <img src='img/FriendsPlus/Page43/E5/2.jpg' style='width: 500px; margin-left: 100px' />

			  `,
        answer: ['Do you fancy'],
      },
    ],
  },
  6: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P43-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page43/E6/key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `
				You are going to do the task in exercise 2. Plan your letter using the prompts below and your ideas. Write your letter using the plan.
			  `,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 1100 },
    //hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='display: flex;'>
            <div style='margin: 15px;'>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 1 </b></div>
                <div> Title of film, who you went with</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 2 </b></div>
                <div> Your opinion</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 3 </b></div>
                <div> Later activity</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 4 </b></div>
                <div> A suggestion to do something</div>
              </div>
           </div>
			  <div style='background-color: rgb(210, 222, 244); margin-top: 20px; margin-left:0px; width:700px; border-radius: 15px; border: 5px dotted rgb(34, 132, 200);'>
          <div style='padding: 15px 25px 25px 25px;' >
            <div ><b style='color:rgb(34, 132, 200) ;'> CHECK YOUR WORK</b></div>
            <div>Have you …</div>
            <ul>
              <li>mentioned and developed each element of the task?</li>
              <li>followed the advice in the Writing Strategy?</li>
              <li>checked the spelling and grammar?</li>
            </ul> 
          </div>
        </div>
      </div>
			  <textarea id="0" rows="6">
        
			  `,
        answer: [],
      },
    ],
  },
};

export default json;
