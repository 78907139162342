import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P17-E1',
		audio: '',
		video: '',
		component: T6,
		isHiddenCheck: true,
		inputSize: 700,
		exerciseKey: 'img/FriendsPlus/Page17/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: `
				Read the article about a film. 
				Explain in your own words what is unusual about the main character.
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="margin-top: 15px;"><span style="font-style: italic; text-decoration: underline;">Answer</span>: #</div>
        <div style="margin-top: 20px;"><img src='img/FriendsPlus/Page17/E1/1.jpg' style='width: 14cm';/></div>
        `,
				answer: ['He lives his life in reverse: he is born old and dies a baby.'],
			},
		],
	},
	2: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P17-E2',
		audio: '',
		video: '',
		component: T6,
		isHiddenCheck: true,
		inputSize: 210,
		maxLength: 18,
		textAlign: "center",
		exerciseKey: 'img/FriendsPlus/Page17/E2/Key/answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `
				Match the highlighted phrasal verbs in the article with their definitions below.
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="display: flex; flex-wrap: wrap; gap: 20px; align-items: start;">
					<div style="flex: 1;"><img src='img/FriendsPlus/Page17/E1/1.jpg' width='100%';/></div>

					<div style="flex: 1; display: flex; flex-direction: column; gap: 10px;
						padding: 20px; border-radius: 10px; border: 1px solid rgb(199, 160, 205);">
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">1</span>
							<div>
								<div>to have a (good / bad) relationship with</div>
								<div><span style="font-style: italic; text-decoration: underline;">Answer</span>: #</div>
							</div>
						</div>
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">2</span>
							<div>
								<div>to complete something</div>
								<div><span style="font-style: italic; text-decoration: underline;">Answer</span>: #</div>
							</div>
						</div>
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">3</span>
							<div>
								<div>to match or equal</div>
								<div><span style="font-style: italic; text-decoration: underline;">Answer</span>: #</div>
							</div>
						</div>
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">4</span>
							<div>
								<div>to use all of your supply of something</div>
								<div><span style="font-style: italic; text-decoration: underline;">Answer</span>: #</div>
							</div>
						</div>
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">5</span>
							<div>
								<div>to succeed in finding or reaching somebody</div>
								<div><span style="font-style: italic; text-decoration: underline;">Answer</span>: #</div>
							</div>
						</div>
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">6</span>
							<div>
								<div>to look and act like part of a group</div>
								<div><span style="font-style: italic; text-decoration: underline;">Answer</span>: #</div>
							</div>
						</div>
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">7</span>
							<div>
								<div>to tolerate or be patient about something</div>
								<div><span style="font-style: italic; text-decoration: underline;">Answer</span>: #</div>
							</div>
						</div>
					</div>
				</div>
        `,
				answer: [
					'gets on with',
					'go through with',
					'lives up to',
					'run out of',
					'catches up with',
					'fits in with',
					'put up with',
				],
			},
		],
	},
	3: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P17-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page17/E3/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '3',
				title: `
				Circle the correct words to complete the <span style="color: rgb();"></span>Learn this! box. 
				Use the examples in the article to help you.
				`,
				color: '#92278f',
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: { border: 'none', borderColor: 'transparent' },
				selectWordStyle: { padding: 5, borderRadius: '50%', border: '2px solid', borderColor: 'gray' },
				// selectWordStyle: { padding: 5, borderBottom: '2px solid', borderColor: 'gray' },
				limitSelect: 1,
				listWords: [`one / two`, `one / two`, `transitive / intransitive`, `after / before / between`],
				answers: ['0-0', '1-4', '2-0', '3-0'],
				initialValue: [],
			},
			Layout: `
			<div style="position: relative;">
				<div><img src='img/FriendsPlus/Page17/E3/1.jpg' width='67%';/></div>
				<div style="position: absolute; top: 54px; left: 68px; display: flex; flex-direction: column; gap: 5px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">a</span>
						<div>A three-part phrasal verb has <strong><sup>1</sup><input id='0' type='Circle' /></strong> verb(s) and <br />
						<strong><sup>2</sup><input id='1' type='Circle' /></strong> particle(s).</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">b</span>
						<div>Three-part phrasal verbs are <strong><sup>3</sup><input id='2' type='Circle' /></strong> <br />
						(they have a direct object).</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">c</span>
						<div>The object always goes <strong><sup>4</sup><input id='3' type='Circle' /></strong> the <br />
						two particles.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">d</span>
						<div>In questions, the three parts of the phrasal verb <br />
						usually stay together.</div>
					</div>
					<div style="margin-left: 34px; color: rgb(10, 111, 143);">What kind of course did you sign up for?</div>
				</div>	
			</div>
      `,
		},
	},
	4: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P17-E4',
		audio: '',
		video: '',
		component: T6,
		isHiddenCheck: true,
		inputSize: 800,
		exerciseKey: 'img/FriendsPlus/Page17/E4/Key/answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `
				<span style="margin-right: 10px; color: rgb(146, 39, 144)">DICTIONARY WORK</span>
				Read the <span style="color: rgb(146, 39, 144);">Dictionary tip</span>. 
				Then find these phrasal verbs in a dictionary and 
				check the difference in meaning between the two- and three-part phrasal verbs.
				`,
				color: '#92278f',
			},
		],
		hintBox: [{
			src: ["look up / look up to", "get away / get away with", "make up / make up for", "go back / go back on",],
			width: 590,
			borderColor: "none",
			fontColor: "#92278f"
		}],
		questions: [
			{
				title: `
				<div style='display:flex;width:28cm' >
					<img src='img/FriendsPlus/Page17/E4/1.jpg' width='55%';/>
						<div style='line-height:50px;margin-top:30px'>
							<hintbox id='0'></hintbox>
						</div>
					
				</div>

				
				<div style='display:flex;line-height:50px'>
					<b>
						look up<br>
						look up to<br>
						get away<br>
						get away with<br>
						make up<br>
						make up for<br>
						go back<br>
						go back on<br>
					</b>
					<div>
						#<br>
						#<br>
						#<br>
						#<br>
						#<br>
						#<br>
						#<br>
						#<br>
					</div>
				</div>

        `,
				answer: [
					`to search for information in a book`,
					`to respect and admire somebody`,
					`to succeed in leaving or escaping from somebody or a place`,
					`to do something bad and not be punished for it`,
					`to invent something, often something that is not true`,
					`to do something that corrects a bad situation`,
					`to return to a place`,
					`to break a promise, an agreement, etc.`,
				],
			},
		],
	},
	5: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P17-E5',
		audio: '',
		video: '',
		component: T6,
		textAlign: 'center',
		isHiddenCheck: true,
		inputSize: 150,
		maxLength: 11,
		exerciseKey: 'img/FriendsPlus/Page17/E5/Key/answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `
				Complete the sentences with two- or three-part phrasal verbs from exercise 4.
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<div>We sent her a present to # the disappointment of missing the music festival.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<div>We # his name on the internet to check his story was true.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<div>You said you would take us on holiday this summer – you can’t # your promise!</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">4</span>
						<div>Did you # that story or is it true?</div>
					</div>
				</div>
        `,
				answer: [`make up for`, `looked up`, `go back on`, `make up`],
			},
		],
	},
	6: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P17-E6',
		audio: '',
		video: '',
		component: T6,
		textAlign: 'center',
		isHiddenCheck: true,
		inputSize: 150,
		maxLength: 14,
		exerciseKey: 'img/FriendsPlus/Page17/E6/Key/answerKey.png',
		titleQuestion: [
			{
				num: '6',
				title: `
				Use three-part phrasal verbs from exercises 2 or 4 to replace the underlined words. 
				Ask and answer these questions with your partner.
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="max-width: 863px; color: rgb(146, 39, 144); display: flex; gap: 5px 30px; flex-wrap: wrap;">
					<span>lives up to</span><span>fits in with</span><span>gets on with</span>
					<span>catches up with</span><span>go through with</span><span>put up with</span>
					<span>run out of</span>
					<span>look up / look up to</span><span>get away / get away with</span>
					<span>make up / make up for</span><span>go back / go back on</span>
				</div>

				<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<div>
							<div>Which famous people do you <span style="text-decoration: underline;">admire?</span></div>
							<div>Which famous people do you #?</div>
						</div>
						
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<div>
							<div>What kind of behaviour is the most difficult to <span style="text-decoration: underline;">tolerate?</span></div>
							<div>What kind of behaviour is the most difficult to #?</div>
						</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<div>
							<div>What kind of people do you find it easiest to <span style="text-decoration: underline;">be friendly with?</span></div>
							<div>What kind of people do you find it easiest to #?</div>
						</div>
					</div>
				</div>	
        `,
				answer: [`look up to`, `put up with`, `get on with`],
			},
		],
	},
}

export default json
