import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P93-E1',
    // exerciseKey: 'img/FriendsPlus/Page92/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 130,
    maxLength: 20,
    hideBtnFooter: true,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '1',
        title: `
        <span style='margin-bottom: 10px;font-size: 25px'><span style='color: rgb(45,206,137)'>SPEAKING </span> 
         Work in pairs. What was the last book you read? What was it about? Did you enjoy it? Why? / Why not?
        </span>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style=' position: relative'>
         
        </div>
          
        `,
        answer: [''],
      },
    ],
  },
  2: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P93-E2',
    exerciseKey: 'img/FriendsPlus/Page93/E2/Key/E2answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 750,
    maxLength: 120,
    textAlign: 'left',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '2',
        title: `
        <div style='margin-bottom: 10px;font-size: 23px'> 
         Read the task above and the entry. Did the writer enjoy the book? Did he / she have any reservations?
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style=''>
          <img style="width:10cm; height:2cm" src='img/FriendsPlus/Page93/E2/1.jpg' />
          <img style="width:15cm; height:5cm" src='img/FriendsPlus/Page93/E2/2.jpg' />
        
          <div style=';display:flex; flex-direction: column; '>
            <b>ANSWER:</b><br>
            <div> # .</div>
          </div>

        </div>
          
        `,
        answer: [
          'The reader enjoy the book very much.',
          // 'The reader enjoyed the book very much.'
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P93-E3',
    exerciseKey: 'img/FriendsPlus/Page93/E3/Key/E3answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 950,
    maxLength: 320,
    textAlign: 'left',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 30,
      fontWeight: 'bold',
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '3',
        title: `
        <div style='margin-bottom: 10px;font-size: 23px'> 
         Read <span style='color:rgb(80,158,209)'>Writing Strategy 1 </span> and the sample and check.
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='display:flex; flex-direction: row'>
          <img style="width:15cm; height:20cm" src='img/FriendsPlus/Page93/E3/1.jpg' />
          <div style='display:flex; flex-direction: column'>
            <img style="width:12cm; height:6cm" src='img/FriendsPlus/Page93/E3/2.jpg' />
            
          </div>
        </div>
        <div style='display:flex; flex-direction: column; margin-top:10px; font-size:22px '>
          <div>- Does the writer address the readers directly?</div>
          <div> # .</div>
          <div>- Is the style formal or informal?</div>
          <div> # .</div>
          <div>- Which title is better?</div>
          <div><b>1 </b>My favourite book: The Hunger Games</div>
          <div><b>2 </b>This is probably one of the best books that I have ever read</div>
          <div> # .</div>
        </div>

          
        `,
        answer: [
          "Yes. She asks the reader 'Have you ever read a book that you just couldn't put down?",
          'Yes. The style is informal and lively.',
          '1 Number 2 is too long.',
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P93-E4',
    exerciseKey: 'img/FriendsPlus/Page93/E4/Key/E4answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 100,
    maxLength: 320,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 30,
      textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '4',
        title: `
        <span style='margin-bottom: 10px;font-size: 23px'> 
        Read <span style='color:rgb(80,158,209)'>Writing Strategy 2. </span>  In which paragraph (A–D) does the writer
        </span>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='display:flex; flex-direction: row'>
          <img style="width:15cm; height:20cm" src='img/FriendsPlus/Page93/E4/1.jpg' />
          <div style='display:flex; flex-direction: column; margin-left:30px'>
            <img style="width:12cm; height:6cm" src='img/FriendsPlus/Page93/E4/2.jpg' />
            <div style='display:flex; flex-direction: column; margin-top:10px; font-size:22px '>
              <div><b>1 </b>describe the plot and characters?</div>
              <div><b>2 </b>mention something he / she liked?</div>
              <div><b>3 </b>give some background information about the book?</div>
              <div><b>4 </b>reccomend the book?</div>
            </div>
            <div style='margin-left: 50px;margin-top:30px; display:flex; flex-direction: column; '>
              <b>ANSWER:</b>
              <b>1 - #</b><br>
              <b>2 - #</b><br>
              <b>3 - #</b><br>
              <b>4 - #</b><br>
            </div>
          </div>
        </div>
        

          
        `,
        answer: ['B', 'C', 'A', 'D'],
      },
    ],
  },
  5: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P93-E5',
    exerciseKey: 'img/FriendsPlus/Page93/Key/E5answerKey.jpg',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 200,
    maxLength: 320,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 30,
      textTransform: 'lowercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '5',
        title: `
        <span style='margin-bottom: 10px;font-size: 23px'> 
        Which tense does the writer use to describe the plot and characters?
        </span>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='display:flex; flex-direction: row'>
          <img style="width:15cm; height:20cm" src='img/FriendsPlus/Page93/E4/1.jpg' />
          <div style='display:flex; flex-direction: column; margin-left:30px'>
            
            <div style='margin-left: 50px;margin-top:30px; display:flex; flex-direction: column; '>
              <b>ANSWER:</b>
              <b> # .</b><br>
            </div>
          </div>
        </div>
        

          
        `,
        answer: ['present simple/present simple tense'],
      },
    ],
  },
  6: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P93-E6',
    exerciseKey: 'img/FriendsPlus/Page93/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 170,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '6',
        title: `
        <span style="margin-bottom: 10px;font-size: 22px; color: rgb(146,39,143)">KEY PHRASES</span>
        Complete the useful phrases with the words below. Underline them in the sample
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style=' position: relative'>
          <div style='font-size:24px; position: relative'>
              <div style="color:rgb(80,158,209); margin-bottom:10px  ">
                page
                &ensp;recommend
                &ensp;set
                &ensp;tells
                &ensp;twists
              </div>

              <div style='display: flex'>
                <img style="width:14cm" src='img/FriendsPlus/Page93/E5/1.jpg' />
                <div style='border-left: 2px solid rgb(80,158,209) ; padding: 10px; margin-bottom:10px;margin-left:20px; width:fit-content; height: fit-content; font-size: 22px '>
                <div style='color:rgb(80,158,209) '><b>Describing stories</b></div>
                <div>It’s <sub>1 </sub># in (place and / or time).</div>
                <div>It <sub>2 </sub># the story of (character).</div>
                <div>There are lots of <sub>3 </sub># and turns.</div>
                <div>It’s a real <sub>4 </sub># -turner.</div>
                <div>I would definitely <sub>5 </sub># it.</div>
                </div>
              </div>

            </div>
              
          </div>
          
        `,
        answer: ['set', 'tells', 'twists', 'page', 'recommend'],
      },
    ],
  },
  7: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P93-E7',
    // exerciseKey: 'img/FriendsPlus/Page93/E4/Key/E4answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 800,
    maxLength: 320,
    hideBtnFooter: true,
    // textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 25,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '7',
        title: `
        <div style='margin-bottom: 10px;font-size: 23px'> 
        Do the task in exercise 1. Follow the advice in the 
        strategies, use the structure of the sample 
        (see exercise 4) and use phrases from exercise 6.
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='display:flex; flex-direction: column'>
          <img style="width:13cm; height:3cm" src='img/FriendsPlus/Page93/E7/1.jpg' />
          <div style='display:flex'>
            <img style="width:13cm; height:6cm" src='img/FriendsPlus/Page93/E7/2.jpg' />
            <img style="width:13cm; height:6cm" src='img/FriendsPlus/Page93/E7/3.jpg' />
          </div>

          <div style='margin-top: 10px;'>
              <b>ANSWER:</b>
            <div> # .</div>
          </div>
        </div>
        

          
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
