import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P41-E1',
    audio: '',
    video: '',
    component: T6,
    recorder: true,
    hideBtnFooter: true,
    // exerciseKey: 'img/FriendsPlus/Page41/E3/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `
				<b><span style='color:#92278f;'>VOCABULARY</span></b> In pairs, read the adjectives below and talk about situations in which you experience those feelings. 
			  `,
        color: 'rgb(124, 8, 79)',
      },
    ],
    hideBtnFooter: true,
    titleImage: '',
    questionImage: [],
    inputSize: 50,
    // stylesTextInput: [],
    // maxLength: 2,
    textAlign: 'center',

    // isHiddenCheck: true,

    selectStyle: {
      color: '#00ADFE',
      width: 60,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    questions: [
      {
        title: `
			  <div style="max-width: 600px; color: #92278f;  display: flex; gap: 5px 30px ; flex-wrap: wrap; padding-top:10px">
					<span style='font-weight: bold;'>Adjectives to describe feelings</span><span>afraid</span><span>anxious</span>
					<span>ashamed</span><span>cross</span><span>disgusted</span><span>envious</span><span>pround</span>
				</div>
        <img style='padding-top:40px;' src='img/FriendsPlus/Page41/E1/1.jpg'>
			  `,
        answer: [],
      },
    ],
  },
  2: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P41-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page41/Key/E2answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
				Read the article and look at the pictures. Match the underlined words in the article with pictures 1–4. 
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 50,
    stylesTextInput: [],
    maxLength: 10,
    textAlign: 'center',
    isHiddenCheck: true,
    selectStyle: {
      color: '#00ADFE',
      width: 60,
      textAlign: 'center',
      // fontSize: 20,
    },
    // selectOptionRandom: true,
    // selectOptionValues: ['1', '2', '3', '4', '5'],
    questions: [
      {
        title: `	<div style="display: flex; flex-wrap: wrap; gap: 30px;">
					<div style="flex: 2.5;"><img src='img/FriendsPlus/Page41/E2/2.1.jpg' width='100%';/></div>
					<div style="position: relative;flex: 2.5;">

						<div> <img src='img/FriendsPlus/Page41/E2/2.2.jpg' width='95%'> </div>
            
            <div style=" position: absolute; top:43px; left: 6px; "><input id='0' font-size='17px' width='120px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 43px; left: 205px; "><input id='1' font-size='15px' width='120px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 255px; left: 129px; "><input id='2' font-size='15px' width='120px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 255px; left: 355px; "><input id='3' font-size='17px' width='120px' border-bottom='0px'></input></div>
						

					<div>
				</div>
			  
			  `,
        answer: ['Anger', 'Happiness', 'Depression', 'Shame'],
      },
    ],
  },
  3: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P41-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page41/Key/E3answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `
				Complete the tables with words from exercises 1 and 2.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 70,
    stylesTextInput: [],
    // maxLength: 2,
    textAlign: 'center',

    isHiddenCheck: true,
    selectStyle: {
      color: '#00ADFE',
      width: 60,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: ['1', '2', '3', '4', '5'],
    questions: [
      {
        title: `
			 <div style=" position: relative; padding-top:20px ">
						<div> <img style='width:25cm' src='img/FriendsPlus/Page41/E3/1.jpg' /> </div>
            
            <div style=" position: absolute; top:103px;   left: 50px;   "><input id='0' font-size='27px' width='150px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 158px; left: 270px;  "><input id='1' font-size='27px' width='170px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 215px; left: 65px;   "><input id='2' font-size='27px' width='150px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 270px; left: 65px;   "><input id='3' font-size='27px' width='150px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 98px;  left: 524px;  "><input id='4' font-size='27px' width='170px' border-bottom='0px'></input></div>       
            <div style=" position: absolute; top: 160px; left: 720px;  "><input id='5' font-size='27px' width='180px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 215px; left: 520px;  "><input id='6' font-size='27px' width='180px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 270px; left: 520px;  "><input id='7' font-size='27px' width='180px' border-bottom='0px'></input></div>

				</div>
			  `,
        answer: ['anger', 'anxious', 'shame', 'envy', 'happiness', 'proud', 'sadness', 'surprise'],
      },
    ],
  },
  4: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P41-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page41/Key/E4answerKey.png',

    titleQuestion: [
      {
        num: '4',
        title: `
          Read the <b><span style='color:rgb(124, 8, 79);'>Lean this!</span></b> box. In pairs, write down two more examples for each rule (a–d). Use a dictionary to help you  if necessary.   
        `,
        color: '#860052',
      },
    ],
    textareaStyle: { width: 550 },

    questions: [
      {
        title: `
        
        <div style="display: flex; flex-wrap: wrap; gap: 5px;">
					<div style="flex: 4;"><img style=' max-width:100%'  src='/img/FriendsPlus/Page41/E4/1.jpg'></div>
					<div style="flex: 1;">
        <div>
        <b>a</b> &ensp; 					<textarea id="0" rows="2"></textarea>
        <b>b</b> &ensp; 					<textarea id="1" rows="2"></textarea>
        <b>c</b> &ensp; 					<textarea id="2" rows="2"></textarea>
        <b>d</b> &ensp; 					<textarea id="3" rows="2"></textarea>
  

        </div>
					
						

					<div>
				</div>
			 
			  `,
        answer: [
          `tired – tiredness, merry – merriment`,
          `excited, exciting, dangerous, useful, harmless, funny, typical`,
          `slowly, differently`,
          `tidily – untidily, possible – impossible`,
        ],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P41-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page41/key/E5answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `
				<span style='color:rgb(124, 8, 79);'>USE OF ENGLISH</span> Complete the sentence with the adjective or adverb related to the noun in brackets. You may need to add a prefix. 
			  `,
        color: '#92278f',
        fontSize: 10,
      },
    ],
    titleImage: '',
    inputSize: 160,
    stylesTextInput: [],
    maxLength: 20,
    textAlign: 'center',

    // checkDuplicated: true,
    // hintBox: [
    //   {
    //     src: ['cause', 'express', 'get', 'have', 'have', 'make', 'take part in'],
    //     // arrow: true,
    //     width: 700,
    //     borderColor: '#4b4999',
    //   },
    // ],
    questions: [
      {
        title: `
        <div> 
          <div style='margin: 10px;'>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 1 </b></div>
              He told me
              <div> #  not to be late again.</div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 2 </b></div>
              Liam is
              <div> #  (hope) that he’ll pass all his exams. </div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 3 </b></div>
               I was 
              <div> # (surprise), but I tried not to show it.</div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 4 </b></div>
              We waited
              <div> #  (anxiety) for news of his arrival. </div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 5 </b></div>
              He looked for his wallet, but 
              <div> #  (sadness) he couldn’t find it. </div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 6 </b></div>
              That’s a good mark for this exam – don’t be 
              <div> # shame) of it.</div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 7 </b></div>
               He stared 
              <div> #  (envy) at his friend’s new bike. </div>
            </div>
             <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 8 </b></div>
                
              <div style='flex-direction:column'>
              Her neighbour is always very bad-tempered, so she found his angry reaction  #  (surprise).  </div>
            </div>
             <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 9 </b></div>
               My sister was 
              <div> #   (annoy) late.  </div>
            </div>
             <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 10 </b></div>
              He didn’t try to hide his 
              <div> #    (happy) – he just cried.  </div>
            </div>
          </div>
        </div>

			  `,
        answer: [
          'angrily',
          'hopeful ',
          'surprised',
          'anxiously',
          'sadly',
          'ashamed',
          'enviously',
          'unsurprising',
          'annoyingly',
          'unhappiness',
        ],
      },
    ],
  },
  6: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P41-E6',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    // exerciseKey: 'img/FriendsPlus/Page41/E6/key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `
				<b style="color:rgb(10 127 64)">SPEAKING</b>  Discuss the questions in pairs. 
			  `,
        color: '#92278f',
      },
    ],
    recorder: 'true',
    titleImage: '',
    questionImage: [],
    isHiddenCheck: true,
    inputSize: 600,
    stylesTextInput: [],
    maxLength: 80,
    textAlign: 'left',
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b> </div>
          <div>
            <div>Do you think money can make people happy? Why? /  Why not? </div>
            
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>
           <div> When is pride good? When is it bad?</div>
            
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>
            <div> Is fear ever a good emotion? If so, when?</div>
            
          </div>
        </div>
        
        
			  `,
        answer: [],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P41-E7',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page41/E7/key/answerKey.png',
    titleQuestion: [
      {
        num: '7',
        title: `
				<b style='color:rgb(0,147,70);'>SPEAKING</b> Work in groups. Complete the questions with <i>How much</i> or <i>How many</i>. Then ask and answer.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    textAlign: 'center',
    inputSize: 160,
    recorder: true,
    hintBox: [
      {
        src: ['How many di²erent types of screen do you use regularly ? '],
        arrow: true,
        position: 1,
        width: 700,
        borderColor: '#4b4999',
      },
      {
        src: ['I use …'],
        arrow: true,
        position: 2,
        width: 200,
        borderColor: '#4b4999',
      },
    ],
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div> # different types of screen do you use regularly? (phone, computer, tablet, TV, etc.)</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div> # time do you spend watching TV or DVDs each day?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div> # different video games do you play?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div> # different people do you play video games with?</div>
        </div>
				<div style='margin: 20px 100px 10px 100px;'><hintBox id='0' ></hintBox></div>
				<div style='margin: 20px 0 50px 650px;'><hintBox id='1' ></hintBox></div>

			  `,
        answer: ['How many', 'How much', 'How many', 'How many'],
      },
    ],
  },
};

export default json;
