import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Introduction',
    id: 'FGF11-I-P8-E1',
    audio: '',
    video: '',
    component: T6,
    recorder: true,
    isHiddenCheck: true,
    hideBtnFooter: true,
    inputSize: 500,
    // exerciseKey: 'img/FriendsPlus/Page8/Key/E2answeranswerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span>Work in pairs. Look at the photo. Ask and answer the questions.`,
        color: '#92278f',
      },
    ],

    textareaStyle: { width: 800 },
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img style="height:7cm;margin-right:10px" src='img/FriendsPlus/Page8/E1/1.jpg' />
          <div >
            <div>
              <b>1</b>&ensp; Which tourist attaction is it?<br>
            </div>
			      <div  style='margin:50px 0px'>
              <b>2</b>&ensp; Have you been there?If so, did you have a good time?<br>
            </div>
			      <div>
              <b>3</b>&ensp; If you haven't been there, would you like to go? Why? / Why not?<br>
            </div>
            </div>
        </div>

        `,
        answer: ['', '', ''],
      },
    ],
  },
  2: {
    unit: 'Introduction',
    id: 'FGF11-I-P8-E2',
    audio: 'Audios/1.02-audio.mp3',
    video: '',
    component: T6,
    maxLength: 35,
    isHiddenCheck: true,
    inputSize: 430,
    // stylesTextInput: {
    //   fontSize: '22px',
    // },
    exerciseKey: 'img/FriendsPlus/Page8/Key/E2answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `<headphone1 name="1.02" src='Audios/1.02-tieude.mp3'></headphone1>
         Read and listen to the dialogue. Who had a more enjoyable holiday: Dave or Lan? `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
				<div style="display: flex; flex-wrap: wrap; gap: 0px;">
					<div style="flex: 3;"><img src='img/FriendsPlus/Page8/E2/3.jpg' width='90%';/></div>
					<div style="flex: 2;" display: flex; gap: 20px; flex-direction: column; margin-top: 50px; font-size: 22px;">
						<div>
							<u style='font-style:italic;font-weight:bold'>Answers:</u>#
						</div>

					<div>
				</div>
        `,
        answer: ['Lan had a more enjoyable holiday./Lan.'],
      },
    ],
  },
  3: {
    unit: 'Introduction',
    id: 'FGF11-I-P8-E3',
    audio: '',
    video: '',
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page8/Key/E3answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `<span style="color: purple ">VOCABULARY</span> Look at the list of tourist and visitor attractions below. Then underline three more in the dialogue. How many more can you think of?  `,

        color: '#92278f',
      },
    ],
    component: Circle_Write,

    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 149, fontSize: 23, borderBottom: 'none', textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: "50%",
          borderColor: 'transparent',
        },
        selectWordStyle: {
          // padding: 5,
          borderBottom: 'solid 2px',
          borderColor: '#00aeef',
        },
        limitSelect: 3,
        listWords: [
          `Hi,_Lan._Did_you_have_a_good_holiday? <br>
           Yes,_it_was_great,_thanks. <br> 
          What_did_you_get_up_to? <br> 
          I_went_on_a_city_break_with_my_family. <br>
          Abroad? <br>
          No,_we_went_to_Da_Nang. <br>
          Cool._Did_you_have_a_good_time?<br>
          Yes,_we_visited Ba_Na_Hills and Hoi_An_Ancient_Town. We_bought_a_lot_of_souvenirs.<br>
          Did_you_go_on_a_boat_trip_to Cham_Island? <br>
          No,_the_weather_was_bad. How_was_your_holiday?<br>
          Not_bad._But_I_didn’t_do_much. We_didn’t_go_away. I_hung_out_with_my_friends. We_went_to_a_music_festival_and_we_visited_a_theme_park_a_couple_of_times.<br>
          Sounds_fun.<br>
          Yes,_it_was_OK. Just_a_shame_the_holidays_weren’t_longer!
         `, //1
        ],
        answers: ['0-174', '0-178', '0-204'],
        initialValue: [],
      },
      Layout: `
        <div style='position: relative;'>
          <img style='height:' src='img/FriendsPlus/Page8/E3/1.jpg' />
          
          
        </div> 
        <div style='display:flex;line-height:45px'>
          
          <div style='margin-right:50px; font-weight:bold'>
            Dave<br>
            Lan<br> 
            Dave<br>
            Lan<br> 
            Dave<br>
            Lan<br> 
            Dave<br>
            Lan<br> 
            Dave<br>
            Lan<br> 
            Dave<br>
             <br>
            Lan<br> 
            Dave<br>
          </div>
          <div>
            <input id='0'  type='Circle' />
          </div>
        </div>
      `,
    },
  },
  4: {
    unit: 'Introduction',
    id: 'FGF11-I-P8-E4',
    audio: '',
    video: '',
    component: T6v2,
    maxLength: 18,
    inputSize: 700,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page8/Key/E4answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `<span style=" color: #92278f ">VOCABULARY</span> Complete the holiday activities with the words or phrases below.
				`,
        color: '#92278f',
      },
    ],
    hintBox: [
      {
        src: [
          'the beach',
          'beach volleyball',
          'a bike',
          'a bike ride',
          'cards',
          'an excursion',
          'kayaking',
          'a castle',
          'mountain biking',
          'a theme park',
        ],
        borderColor: 'none',
        width: 700,
        fontColor: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
<div style=" position: relative; padding-top:10px; ">

				<div style="margin-top: 20px;"><img src='img/FriendsPlus/Page8/E4/1.jpg';/></div>

			<div style='margin-top:50px; line-height:49px; width:20%' >
        <div style=" position: absolute; top: 53px; left: 202px; height: 20px"><input id='0' font-size='22px' width='130px'border-bottom='0px' ></input></div>
        <div style=" position: absolute; top: 90px; left: 2px; height: 20px "><input id='2' font-size='22px' width='155px'border-bottom='0px' ></input></div>
          <div style="position: absolute; top: 125px; left: 405px; height: 20px "><input id='3' font-size='22px' width='130px'border-bottom='0px' ></input></div>
        <div style=" position: absolute; top: 156px; left: 117px; height: 20px "><input id='4' font-size='22px' width='178px' border-bottom='0px'></input></div>
        <div style=" ;position: absolute; top: 191px; left: 204px; height: 20px "><input id='5' font-size='22px' width='130px' border-bottom='0px'></input></div>
          <div style=" position: absolute; top: 190px; left: 400px; height: 20px "><input id='6' font-size='22px' width='150px'border-bottom='0px' ></input></div>
        <div style=" position: absolute; top: 256px; left: 55px; height: 20px "><input id='7' font-size='22px' width='130px' border-bottom='0px'></input></div>
        <div style="position: absolute; top: 288px; left: 195px; height: 20px "><input id='8' font-size='20px' width='170px' border-bottom='0px'></input></div>
        <div style=" position: absolute; top: 288px; left: 406px; height: 20px "><input id='9' font-size='22px' width='130px' border-bottom='0px'></input></div>
        <div style=" position: absolute; top: 357px; left: 83px; height: 20px "><input id='10' font-size='22px' width='130px'border-bottom='0px' ></input></div>

        </div>

				`,
        answer: [
          `a castle`,
          `a theme park`,
          `kayaking`,
          `mountain biking`,
          `a bike ride`,
          `an excursion`,
          `a bike`,
          `beach volleyball`,
          `cards`,
          `the beach`,
        ],
      },
    ],
  },
  5: {
    unit: 'Introduction',
    id: 'FGF11-I-P8-E5',
    audio: '',
    video: '',
    component: T6v2,
    textAlign: 'center',
    inputSize: 270,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page8/Key/E5answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `
				Find three of the holiday activities from exercise 4 in the dialogue in exercise 2.
				`,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
			<div style="display: flex; flex-wrap: wrap; gap: 30px;">
					<div style="flex: 3;"><img src='img/FriendsPlus/Page8/E2/3.jpg' width='95%';/></div>
					<div style="flex: 2; display: flex; gap: 20px; flex-direction: column;  font-size: 25px;">
						<div>
							<u style='font-style:italic;font-weight:bold'>Answers:</u>  <br />#
              #
              #
						</div>

					<div>
				</div>
        `,
        initialValue: ['hello'],

        answer: ['visit a theme park', 'go on a boat trip', ' buy souvenirs'],
        // answer: ['moved', 'was growing up', 'had learned / learned', 'bought','left','went','studied','got','had fallen','were working'],
      },
    ],
  },
  6: {
    unit: 'Introduction',
    id: 'FGF11-I-P8-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page8/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `
			 Underline examples of the following past simple forms in the dialogue.
			`,
        color: '#92278f',
      },
    ],
    component: T6,
    // recorder: true,
    textAlign: 'center',
    inputSize: 700,
    isHiddenCheck: true,
    textareaStyle: { width: 570 },
    questions: [
      {
        title: `
				<div style='display:flex'>
          <img style='height:15cm' src="img/FriendsPlus/Page8/E6/1.jpg" />
				  <div style='border-left: 5px solid purple;padding-left:10px'>
            <b>1</b>&ensp;Regular affirmative <br>
            <textarea id='0' rows='2'></textarea>
            <b>2</b>&ensp;Irregular affirmative<br>
            <textarea id='1' rows='5'></textarea>
            <b>3</b>&ensp;Negative<br>
            <textarea id='2' rows='2'></textarea>
            <b>4</b>&ensp;Interrogative <br>
            <textarea id='3' rows='3'></textarea>
          </div>
        </div>

        `,
        answer: [
          "Yes, we visited Ba Na Hills and Hoi An Ancient Town. we visited a theme park a couple of times.",
          "Yes, it was great, thanks. I went on a city break with my family. No, we went to Da Nang. We bought a lot of souvenirs. No, the weather was bad. I hung out with my friends. We went to a music festival. Yes, it was OK.",
          "But I didn't do much. We didn't go away. Just a shame the holidays weren't longer!",
          " Did you have a good holiday? What did you get up to? Did you have a good time? Did you go on a boat trip to Cham Island? How was your holiday?",
        ],
      },
    ],
  },
  7: {
    unit: 'Introduction',
    id: 'FGF11-I-P8-E7',
    audio: '',
    video: '',
    titleQuestion: [
      {
        num: '7',
        title: `
				<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span>
				Work in pairs. Ask and answer about your summer holidays.
				`,
        color: '#92278f',
      },
    ],
    component: T6,
    // recorder: true,
    hideBtnFooter: true,
    textAlign: 'center',
    inputSize: 700,
    isHiddenCheck: true,
    recorder: true,
    exerciseKey: 'img/FriendsPlus/Page2/E7/Key/answerKeyanswerKey.png',
    questions: [
      {
        title: `
				<div style="padding-top:20px">
          <b>1</b>&ensp;  What was the most enjoyable thing you did during the holidays? Why? <br>
        </div>
				<div>
          <b>2</b>&ensp;  What was the least enjoyable thing you did? Why?
<br>
        </div>

        `,
        answer: ['', ''],
      },
    ],
  },
};

export default json;
