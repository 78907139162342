import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P70-E1',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page68/Key/E1answerKey.png',
    component: T6,
    inputSize: 600,
    recorder: true,
    // maxLength: 60,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '1',
        title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
        Can you think of any famous people from the history of Viet Nam? Use the words below to help you.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				 <div style="margin-top:10px;max-width: 900px; color: rgb(0, 111, 147); font-weight: bold; display: flex; gap: 5px 30px; flex-wrap: wrap;">
          <span>actor</span><span>composer</span><span>explorer</span>
          <span>inventor</span><span>musician</span><span>politician / statesman</span>
          <span>scientist</span><span>soldier</span><span>sportsman / sportswoman</span>
          <span>writer</span>
        </div>
         
					
				`,
        answer: [`science`, `They may / might / could be recording some results.`],
      },
    ],
  },
  2: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P70-E2',
    audio: 'Audios/2.25-audio.mp3',
    video: '',
    component: T6,
    // inputSize: 600,
    // maxLength: 60,
    // recorder: true,
    exerciseKey: '/img/FriendsPlus/Page70/Key/E2answerKey.png',

    isHiddenCheck: true,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '2',
        title: `
          <headphone1 name="2.25" src="Audios/2.25-tieude.mp3"></headphone1>
Read the introductory paragraph. Then listen. Complete the missing dates in the timeline of Benjamin Franklin’s life.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div style='flex:3'>
        <div style='position: relative;font-size:19px'>
                <div style=" position: absolute; top:550px; left: 120px; "><input id='0' font-size='23px' width='90px' border-bottom='0px'></input></div>
                <div style=" position: absolute; top:715px; left: 120px; width:80px "><input id='0' font-size='23px' width='100px' border-bottom='0px'></input></div>
                <div style=" position: absolute; top:835px; left: 120px; width:80px "><input id='0' font-size='23px' width='100px' border-bottom='0px'></input></div>
                <div style=" position: absolute; top:1065px; left: 120px; width:80px "><input id='0' font-size='23px' width='70px' border-bottom='0px'></input></div>
                <div style=" position: absolute; top:1290px; left: 120px; width:80px "><input id='0' font-size='23px' width='150px' border-bottom='0px'></input></div>
        <img src='img/FriendsPlus/Page70/E2/1.jpg' style='margin-top:10px;max-width:70%'>
        </div>
       

					
				`,
        answer: [`1716`, `1729`, `1733`, `1752`, `1790`],
      },
    ],
  },
  3: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P70-E3',
    exerciseKey: '/img/FriendsPlus/Page70/Key/E3answerKey.png',
    audio: 'Audios/2.25-audio.mp3',
    video: '',
    component: T6,
    inputSize: 120,
    maxLength: 7,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '3',
        title: `
          <headphone1 name="2.25" src="Audios/2.25-tieude.mp3"></headphone1>
           Listen again and complete the collocations with  the verbs below.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div style="margin-top:10px;max-width: 900px; color: rgb(0, 111, 147); font-weight: bold; display: flex; gap: 5px 30px; flex-wrap: wrap;">
          <span>attend</span><span>build</span><span>carry out</span>
          <span>found</span><span>publish</span><span>retire</span>
          <span>start</span><span>write </span>
         
        </div>
          <div style="margin-top: 20px;"><b>Answers:</b></div>
          <div>
            <b>1</b> 
             &ensp;# your own business <br>
            <b>2</b>
             &ensp;# an article for a newspaper <br>
            <b>3</b>
             &ensp;# a book <br>
            <b>4</b> 
             &ensp;# a hospital <br>
             <b>5</b>
             &ensp;#  a scientific experiment  <br>
            <b>6</b>
             &ensp;#  strong relations between two countries  <br>
            <b>7</b>
             &ensp;# from a job  <br>
            <b>8</b>
             &ensp;# a funeral
          </div>
        `,
        answer: ['start', 'write', 'publish', 'found', `carry out`, `build`, `retire`, `attend`],
      },
    ],
  },
  4: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P70-E4',
    audio: '',
    video: '',
    component: T6,
    // inputSize: 600,
    // maxLength: 60,
    // exerciseKey:,
    recorder: true,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '4',
        title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
       Describe the picture using the words below.  Have you heard of this experiment? What do you think Franklin was trying to discover?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				 <div style="max-width: 900px; color: rgb(0, 111, 147); font-weight: bold; display: flex; gap: 5px 30px; flex-wrap: wrap;">
          <span>cord</span><span>electricity</span><span>key</span>
          <span>kite</span><span>lightning</span><span>spark</span>
          <span>storm clouds</span>
        </div>
        <div>
        <img src='img/FriendsPlus/Page70/E4/1.jpg' style='margin-top:10px;max-width:70%'>
        </div>
         
					
				`,
        answer: [``],
      },
    ],
  },
  5: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P70-E5',
    audio: 'Audios/2.26-audio.mp3',
    video: '',
    component: Circle_Write,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page70/Key/E5answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `
          <headphone1 name="2.26" src="Audios/2.26-tieude.mp3"></headphone1>
 Listen to a news report about Franklin’s famous kite experiment. Are the sentences true (T) or false (F)? 			  `,
        color: '#860052',
      },
    ],
    question: {
      Write: {
        inputStyle: {
          borderBottom: '',
          color: '#000',
          textAlign: 'center',
        },
        answers: [],
        initialValue: [],

        textAlign: 'center',
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: '#000',
          fontWeight: 700,
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: ['T / F', 'T / F', 'T / F', 'T / F', 'T / F', 'T / F'],
        answers: ['0-0', '1-4', '2-0', '3-4', '4-4', '5-4'],
        initialValue: [],
      },
      Layout: `
            <div style=' padding: 5px 5px 25px 5px;' >
              <div style='display: flex;'>
                <div><b> 1 &emsp;</b></div>
                <div> Franklin’s experiment became well known in America and other countries too. <b><input id='0' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 2 &emsp;</b></div>
                <div> According to the traditional story, Franklin bought a kite and attached a piece of metal to it.  <b><input id='1' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 3 &emsp;</b></div>
                <div> According to the story, Franklin put his hand near the piece of metal and felt an electrical spark.  <b><input id='2' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 4 &emsp;</b></div>
                <div>  Dr Tom Tucker began investigating the experiment while working at a university.  <b><input id='3' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 5 &emsp;</b></div>
                <div>  Dr Tucker first became suspicious after reading Franklin’s own reports of the experiment. . <b><input id='4' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 6 &emsp;</b></div>
                <div> Dr Tucker successfully recreated Franklin’s experiment when he used a modern kite.  <b><input id='5' type='Circle' /></b></div>
              </div>
            </div>


      `,
    },
  },
  6: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P70-E6',
    audio: '',
    video: '',
    component: T6,
    // exerciseKey:,
    // inputSize: 600,
    // maxLength: 60,
    recorder: true,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '6',
        title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
Work in pairs. Discuss the following quotation by Benjamin Franklin. What does it mean? Do you agree or disagree with it?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `

        <div>
        <img src='img/FriendsPlus/Page70/E6/1.jpg' style='margin-top:30px;max-width:100%'>
        </div>
         
					
				`,
        answer: [``],
      },
    ],
  },
};

export default json;
