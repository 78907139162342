import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Grammar Builder and Reference',
		id: 'FGF11-GBAR-P118-E1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '1',
				title: `<span style="color:#860052">USE OF ENGLISH</span> Complete the second sentence so that it has the same meaning as the first.Use the words in brackets.`,
				color: '#860052',
			},
		],
		stylesTextInput: {
			fontSize: 24,
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 550,
		maxLength: 100,
		exerciseKey: '/img/FriendsPlus/Page118/key/E1answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style=''>
						<p><span style="font-weight:bold;margin-right:10px; font-size: 22px">1.</span>Wendy probably won’t eat with us.</p>
            <p><span style="color:rgb(12,32,244); font-size: 22px">I don’t think Wendy will eat with us.</span> (don’t think)</p>
            <p><span style="font-weight:bold;margin-right:10px; font-size: 22px">2.</span> I’m certain you’ll pass all your exams. </p>
            <p>You #. (definitely)</p>
            <p><span style="font-weight:bold;margin-right:10px; font-size: 22px">3.</span>I don’t think it’ll rain tomorrow. </p>
            <p>I #. (doubt)</p>
            <p><span style="font-weight:bold;margin-right:10px; font-size: 22px">4.</span>You’ll definitely enjoy the film. </p>
            <p>I #. (sure)</p>
            <p><span style="font-weight:bold;margin-right:10px; font-size: 22px">5.</span>It’s possible that Ben will come round later.</p>
            <p>Ben #. (might)</p>
            <p><span style="font-weight:bold;margin-right:10px; font-size: 22px">6.</span>I might not go to school tomorrow. </p>
            <p>I #. (may)</p>
            <p><span style="font-weight:bold;margin-right:10px; font-size: 22px">7.</span>George will probably know the answer.</p>
            <p>I #. (think)</p>
            <p><span style="font-weight:bold;margin-right:10px; font-size: 22px">8.</span>I doubt we’ll go away this summer.</p>
            <p>We #. (probably)</p>
					</div>			
				`,
				answer: [
					"'ll definitely pass all your exams.",
					"doubt that it'll rain tomorrow./doubt it'll rain tomorrow.",
					"'m sure that you'll enjoy the film./'m sure you'll enjoy the film.",
					'might come round later.',
					'may not go to school tomorrow.',
					'think that George will know the answer./think George will know the answer.',
					"probably won't go away this summer.",
				],
			},
		],
	},
	3: {
		unit: 'Grammar Builder and Reference',
		id: 'FGF11-GBAR-P118-E3',
		audio: '',
		video: '',
		component: Circle_Write,
		exerciseKey: '/img/FriendsPlus/Page118/key/E3answerKey.png',

		titleQuestion: [
			{
				num: '3',
				title: 'Choose the best tense in these sentences.',
				color: '#860052',
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					margin: '5 10',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '60%',
				},
				selectWordStyle: {
					border: 'solid 3px',
					borderColor: '#00a8ec',
					margin: '-2px',
				},
				limitSelect: 1,
				listWords: [
					'I’ve_seen / I’ve_been_seeing ',
					'hasn’t_written / hasn’t_been_writing',
					'have_you_waited / have_you_been_waiting ? ',
					'she’s_worked / she’s_been_working',
					'haven’t_finished / haven’t_been_finishing',
					'We’ve_got / We’ve_been_getting',
					' haven’t_understood / haven’t_been_understanding ',
				],
				answers: ['0-0', '1-0', '2-4', '3-4', '4-0', '5-4', '6-2'],
				initialValue: [],
			},
			Layout: /*html */ `
      <div style='line-height:2.5'>
       <div style="display: flex; column-gap: 20px;">
        <strong>1</strong>
        <div>
          I love that film. <strong><input id="0" type="Circle" /></strong> it three times!
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>2</strong>
        <div>
          She’s got some great ideas for her book, but she <strong><input id="1" type="Circle" /></strong> it yet.
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>3</strong>
        <div>
          Sorry I’m late. How long <strong><input id="2" type="Circle" /></strong>

        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>4</strong>
        <div>
          My mum often works abroad. This week, <strong><input id="3" type="Circle" /></strong> in Paris.
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>5</strong>
        <div>
          Don’t take my plate away. I <strong><input id="4" type="Circle" /></strong> my lunch!
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>6</strong>
        <div>
          <strong><input id="5" type="Circle" /></strong> ready for the party. We still need to decorate the room.
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>7</strong>
        <div>
          Can you speak more slowly? I <strong><input id="6" type="Circle" /></strong> everything.
        </div>
       </div>
      
      </div>
      `,
		},
	},
	4: {
		unit: 'Grammar Builder and Reference',
		id: 'FGF11-GBAR-P118-E4',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '4',
				title:
					'Complete the email. Use the present perfect continuous form where possible.If not, use the present perfect simple.',
				color: '#860052',
			},
		],
		stylesTextInput: {
			fontSize: 21,
			textAlign: 'center',
			borderBottom: 'none',
			backgroundColor: 'transparent',
		},
		inputSize: 220,
		maxLength: 20,
		exerciseKey: '/img/FriendsPlus/Page118/key/E4answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div>
						<div style='position: relative;'>
							<div style='display:flex'>
									<img src="/img/FriendsPlus/Page118/E4/1.jpg" style='height:14cm; width: 18cm'  >
							</div>
          		<div style=" position: absolute; top: 77px; left: 180px; "><input id='0' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 77px; left: 400px; "><input id='1' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 132px; left: 36px; "><input id='2' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 159px; left: 229px; "><input id='3' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 187px; left: 49px; "><input id='4' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 214px; left: 240px; "><input id='5' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 269px; left: 38px; "><input id='6' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 297px; left: 35px; "><input id='7' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 325px; left: 34px; "><input id='8' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 325px; left: 265px; "><input id='9' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 352px; left: 192px; "><input id='10' style="width:0px"></input></div>
          		
          
						</div>
	
						
					</div>			
				`,
				answer: [
					'Have',
					'been enjoying',
					"haven't finished",
					"haven't started",
					"'ve been spending",
					"haven't met",
					"'ve been playing",
					"'s been teaching",
					'Have',
					'bought',
					"'ve been talking",
				],
			},
		],
	},
}

export default json
