import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Grammar Builder and  Reference',
		id: 'FGF11-GBAR-P116-E1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '1',
				title: 'Complete the dialogue with the correct form of used to and the verbs in brackets.',
				color: '#860052',
			},
		],
		stylesTextInput: {
			fontSize: 24,
			textAlign: 'center',
			// backgroundColor: 'transparent',
		},
		inputSize: 250,
		maxLength: 15,
		exerciseKey: '/img/FriendsPlus/Page116/key/E1answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
        <div style='line-height:1.75'>
         <div style='display:flex'>
                 <b>Maria</b>
                 <div style='margin-left:15px'>
              Let me see that photo. Hey, you <sup>1</sup> <input/> (have) long hair! I didn’t know that.
                 </div>
         </div>

           <div style='display:flex'>
                 <b>Sam</b>
                 <div style='margin-left:15px'>
           I know. I was about fourteen then. I <sup>2</sup><input/> (be) a fan of heavy metal.
                 </div>
         </div>

           <div style='display:flex'>
                 <b>Maria</b>
                 <div style='margin-left:15px'>
             My brother <sup>3</sup> <input/> (like) heavy metal. He often went to concerts with friends. <br> But he <sup>4</sup> <input/> (not invite) me.
                 </div>
         </div>

           <div style='display:flex'>
                 <b>Sam</b>
                 <div style='margin-left:15px'>
             I <sup>5</sup> <input/> (not go) to concerts. The tickets were too expensive. <br> But I <sup>6</sup> <input/> (listen) to CDs a lot. <br> What about you? What kind of music <sup>7</sup> <input/> (you / listen) <br> to when you were younger?
                 </div>
         </div>

           <div style='display:flex'>
                 <b>Maria</b>
                 <div style='margin-left:15px'>
I <sup>8</sup> <input/> (enjoy) listening to pop music and dancing with my friends.                  </div>
         </div>

           <div style='display:flex'>
                 <b>Sam</b>
                 <div style='margin-left:15px'>
<sup>9</sup> <input/> (you / go) to discos?                 </div>
         </div>

           <div style='display:flex'>
                 <b>Maria</b>
                 <div style='margin-left:15px'>
Not really. We were too young. But my dad  <sup>10</sup> <input/> (take) me to pop concerts sometimes. I loved those.                  </div>
         </div>
        </div>

				`,
				answer: [
					'used to have',
					'used to be',
					'used to like',
					"didn't use to invite",
					"didn't use to go",
					'used to listen',
					'did you use to listen',
					'used to enjoy',
					'Did you use to go',
					'used to take',
				],
			},
		],
	},
	2: {
		unit: 'Grammar Builder and  Reference',
		id: 'FGF11-GBAR-P116-E2',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '2',
				title: 'Complete the sentences with used to / didn’t use to and the verbs below.',
				color: '#860052',
			},
		],
		stylesTextInput: {
			fontSize: 23,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 200,
		maxLength: 200,
		exerciseKey: '/img/FriendsPlus/Page116/key/E2answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div>
            <div>
              <p style="color:rgb(247,144,61);word-spacing:15px;border:2px solid rgb(247,144,61);border-radius:20px; text-align:center; width:600px">cook cost drink go live play sell win</p>
            </div>
            <div>
              <p><span style="font-weight:bold;margin-right:10px">1. </span> They # in Canada. Then they moved to the USA.</p>
              <p><span style="font-weight:bold;margin-right:10px">2. </span> You can buy a laptop quite cheaply now. They # a lot more.</p>
              <p><span style="font-weight:bold;margin-right:10px">3. </span> I the guitar. I # started learning it last month.</p>
              <p><span style="font-weight:bold;margin-right:10px">4. </span> That shop # computers. It was a bookshop.</p>
              <p><span style="font-weight:bold;margin-right:10px">5. </span> My sister # skiing every winter, but now she prefers snowboarding.</p>
              <p><span style="font-weight:bold;margin-right:10px">6. </span> I # milk with every meal, but now I always have water.</p>
              <p><span style="font-weight:bold;margin-right:10px">7. </span> My dad # dinner every evening, but now my sister and I usually do it.</p>
              <p><span style="font-weight:bold;margin-right:10px">8. </span> I really enjoyed chess when I was younger, but I # very often.</p>
            </div>
          </div>
          
				`,
				answer: [
					'used to live',
					'used to cost',
					"didn't use to play",
					"didn't use to sell",
					'used to go',
					'used to drink',
					'used to cook',
					"didn't use to win",
				],
			},
		],
	},
}

export default json
