import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P69-E1',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page69/E1/Key/answerKey.png',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    recorder: true,
    hideBtnFooter: true,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 21,
    },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '1',
        title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span>
         Ask and answer the questions about internet forums.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 1 </b></div>
                <div> What is an internet forum?
</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 2 </b></div>
                <div>  Have you ever contributed to one? If so, what type <br> of forum was it? Why did you contribute?
</div>
              </div>
             
              </div>
         </div>
				`,
        answer: ['D', 'C', 'B', 'E'],
      },
    ],
  },
  2: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P69-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page69/Key/E2answerKey.png',
    component: T6,
    inputSize: 150,
    // maxLength: 1,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '2',
        title: `
          Read the
          <span style="color: rgb(0, 111, 147)">Writing Strategy</span>
          and the task below. Identify the verbs in each point that tell you what you should do.
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
         

         <div style="background-color:rgb(222, 239, 225); margin-left: 40px; border: 8px dotted rgb(0, 111, 147); border-radius: 10px; padding: 20px;">
 <span style="color: rgb(0, 111, 147)">Writing Strategy</span> <br>
  <div>Each point in the task will ask you to do a different thing, </div>
  <div>such as describe, recommend, relate, express an opinion, </div>
  <div>suggest, etc. Read the task carefully and make sure that </div>
  <div>you understand exactly what you are being asked to do. </div>

</div>
<div style=" padding: 20px; background-color: rgb(222, 239, 225);  margin-left: 40px;border-radius:10px">
              <div>
                You recently bought a new computer. Write a forum post in which you
              </div>
              <div>
							<span class="black-dot-list" style='list-style-type: disc;display: inline;margin: 0;'>
							<li>describe your experience of using the computer. </li>
							<li>  compare it with another computer you have used </li>
							<li> present the opinions of people who think teenagers rely too much on computers.</li>
              <li> ask other contributors to react to your post. </li>

              </span>
               <div style=' margin:10px 100px;display:flex; justify-content: space-between;'>

          <b>1</b>#&emsp;
          <b>2</b>#&emsp;
          <b>3</b>#&emsp;
          <b>4</b>#&emsp;

       </div></div>

            </div>
            


				`,
        answer: ["describe", "compare", "present", "ask"],
      },
    ],
  },
  3: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P69-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page69/Key/E3answerKey.png',
    component: T6,
    inputSize: 650,
    maxLength: 600,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '3',
        title: `Read the forum posts. Did both writers cover all four points in the way described in the task? Explain your answers. 
				`,
        color: '#860052',
      },
    ],
    textareaStyle: { width: 500 },

    questions: [
      {
        title: `
         <div style='display:flex'>
        <div style ='flex:1'>
        <img src='img/FriendsPlus/Page69/E3/1.jpg' style ='max-width:100%'>

        </div>
        <div style ='flex:1'>
       
        <div style='display: flex; margin-left:10px'>
                <div>Did both writers cover all four points in the way described in the task? <br> Explain your answers.
</div>
              </div>
              <textarea id='0' rows='10'></textarea>

        </div>
        </div>
				`,
        answer: [`Sam 245 has completed the task correctly. HollyXX has not. She describes her computer’s appearance rather than her experience of using it. She gives her own opinion about teenagers relying too much on computers rather than the opinions of other people.`],
      },
    ],
  },
  4: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P69-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page69/Key/E4answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: ` Read the
          <span style="color: rgb(0, 111, 147)">Writing Strategy</span>
           Underline four examples of concession clauses in the forum posts.  `,

        color: '#92278f',
      },
    ],
    component: Circle_Write,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 149, fontSize: 23, borderBottom: 'none', textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: "50%",
          borderColor: 'transparent',
          fontSize:19
        },
        selectWordStyle: {
          // padding: 5,
          borderBottom: 'solid 2px',
          borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `Last_month_I_bought_a_new_laptop.
 I_use_it_every_day_schoolwork_and_for_accessing_social_media. 
 It’s_also_great_for_messaging_my_friends.<br>
 
 I used to have a desktop computer.
 Although_it_was_powerful, it_was_very_slow_and_it_didn’t_have_much_storage.`,
 `Despite_its_small_size, the_laptop_is_lightning-quick_and_has_a_massive_1TB_hard_drive.
 Unfortunately_my_parents_are_always_telling_me_to_get_off_the_computer. 
 They_think_that_teenagers_should_spend_less_time_interacting_with_people_via_a_screen_and_more_time_talking_face-to-face.<br>
 I’d_be_interested_to_hear_what_you_think_about_this.
 Should_we_spend_less_time_at_our_computers?`,
 
 `I_love_my_new_PC! 
 It’s_an_all-in-one_desktop_with_a_widescreen_display. 
 It’s_a_really_up-to-date_model, despite_the_fact_that_i_got_it_second-hand_on_eBay.
 It’s_bright_green_with_a_black_keyboard_and_a_wireless_mouse.  <br>       
 Until_now,_I’ve_always_used_my_mum’s_old_laptop.
 The_screen_was_much_smaller_and_the_operating_system_was_old_so_it_was_often_impossible_to_download_new_software.`,

 `I_agree_that_teenagers_rely_a_lot_on_computers,
 although_i_don’t_really_think_it’s_a_serious_problem.<br>
 What_do_you_think? Do_you_agree_with_me?
 Have_you_bought_a_new_PC_recently? What’s_it_like?`,


        ],
        answers: ['3-4', '2-14', '0-34', '1-0'],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
      
        <img src='img/FriendsPlus/Page69/E4/2.jpg' style='height:10cm'  /> 
        <div style='position:relative'>
          <img src='img/FriendsPlus/Page69/E4/1.jpg' style='height:24cm;width:17cm;'  />
          <div style='position:absolute;top:100px;left:137px;width:123mm;line-height:25px'>
            <input id='0' type='Circle'>
          </div>
          <div style='position:absolute;top:280px;left:137px;width:123mm;line-height:25px'>
            <input id='1' type='Circle'>
          </div>
          <div style='position:absolute;top:520px;left:137px;width:123mm;line-height:25px'>
            <input id='2' type='Circle'>
          </div>
          <div style='position:absolute;top:770px;left:137px;width:123mm;line-height:25px'>
            <input id='3' type='Circle'>
          </div>
         
        </div>
      
      </div>
        
          
           
        
      `,
    },
  },
  5: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P69-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page69/Key/E5answerKey.png',
    component: T6,
    inputSize: 550,
    // maxLength: 1,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '5',
        title: `
          Read the task below. Identify the key words in the four points that tell you what you should do.
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
         
<div style=" padding: 20px; background-color: rgb(222, 239, 225);  margin-left: 40px;border-radius:10px">
              <div>
                You recently bought a new tablet. Write a forum post in which you
              </div>
              <div>
							<span class="black-dot-list" style='list-style-type: disc;display: inline;margin: 0;'>
							<li>describe your experience of using the computer. </li>
							<li>give other forum contributors brief instructions on how <br>&ensp;&ensp; to use it or one of its features (e.g. taking a photo)</li>
							<li>give your overall opinion of the tablet.</li> 
              <li>explain how it helps you with your schoolwork. </li>

              </span>
               <div style='justify-content: space-between;'>

          <b>1</b>&ensp;# <br>
          <b>2</b>&ensp;# <br>
          <b>3</b>&ensp;# <br>
          <b>4</b>&ensp;#

       </div></div>

            </div>
            


				`,
        answer: ["describe the tablet and some of its features.",
          "give other forum contributors brief instructions", "give your (overall) opinion",
          "explain how it helps you"],
      },
    ],
  },
  6: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P69-E6',
    audio: '',
    video: '',
    component: T6,
    inputSize: 550,
    textareaStyle: { width: 700 },
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '6',
        title: `
           Brainstorm ideas for each of the four points in the task  in exercise 5.
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
         <i>Answer:</i>
         <textarea id='0' rows='4'></textarea>



				`,
        answer: [``],
      },
    ],
  },
  7: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P69-E7',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page68/Key/E9answerKey.png',
    component: T6,
    inputSize: 600,
    // maxLength: 60,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '7',
        title: `
          Write your forum post (100–120 words).
        `,
        color: '#860052',
      },
    ],
    textareaStyle: { width: 500 },

    questions: [
      {
        title: `
			 <div style='display:flex'>
			 <div style='flex:1'>
			 <img src="img/FriendsPlus/Page69/E7/1.jpg" style="max-width: 100%; margin-top:10px" />

			 
			 </div>
			 <div style='flex:1;margin-left:10px'>
									<textarea id='0' rows ='12'></textarea>

			 </div>
			 </div>

					
				`,
        answer: [` `],
      },
    ],
  },
};

export default json;
