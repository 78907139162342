import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	5: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P37-E5',
		audio: 'Audios/1.30-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page37/Key/E5answerKey.png',
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '5',
				title: `<headphone1 name='1.30' src='Audios/1.30-tieude.mp3'></headphone1> Listen to three dialogues between
        doctors and their patients. Complete the table using the
        words below to complete the treatments.
`,
				color: '#8e258c',
				prefix: { icon: [''], text: 'VOCABULARY' },
			},
		],

		component: D1,
		// recorder: true,
		fontSize: 22,
		// maxLength: 7,
		textAlign: 'center',

		questionImage: [
			[{ url: 'img/FriendsPlus/Page37/E5/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page37/E5/2.jpg' },
				{ url: 'img/FriendsPlus/Page37/E5/3.jpg', input: true, answer: 'ankle' },
				{ url: 'img/FriendsPlus/Page37/E5/4.jpg', input: true, answer: 'head' },
				{ url: 'img/FriendsPlus/Page37/E5/5.jpg', input: true, answer: 'thumb' },
				{ url: 'img/FriendsPlus/Page37/E5/6.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page37/E5/7.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page37/E5/8.jpg' },
				{ url: 'img/FriendsPlus/Page37/E5/9.jpg', input: true, answer: 'yesterday evening' },
				{ url: 'img/FriendsPlus/Page37/E5/10.jpg', input: true, answer: 'this morning, about two hours ago' },
				{ url: 'img/FriendsPlus/Page37/E5/11.jpg', input: true, answer: 'last night' },
				{ url: 'img/FriendsPlus/Page37/E5/12.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page37/E5/13.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page37/E5/14.jpg' },
				{ url: 'img/FriendsPlus/Page37/E5/15.jpg', input: true, answer: 'bandage' },
				{ url: 'img/FriendsPlus/Page37/E5/16.jpg', input: true, answer: 'painkillers' },
				{ url: 'img/FriendsPlus/Page37/E5/17.jpg', input: true, answer: 'X-ray' },
				{ url: 'img/FriendsPlus/Page37/E5/18.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page37/E5/19.jpg' }],
		],
	},
	6: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P37-E6',
		audio: 'Audios/1.30-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page37/Key/E6answerKey.png',

		titleQuestion: [
			{
				num: '6',
				title: `<headphone1 name='1.30' src='Audios/1.30-tieude.mp3'></headphone1> Read the <span style='color:#92278f'>Recycle!</span> box. Complete the extracts
				from the dialogues with the verbs in brackets. Use the present perfect or past simple. Listen again and check your answers.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		// maxLength: 11,
		// isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 150,
		textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
      <div style='display:flex' >
          <img style='height:85mm' src='img/FriendsPlus/Page37/E6/1.jpg'>
          <div>
            <b>Dialogue 1</b>
            <div>
              <b>a</b>&ensp;My ankle really hurts. I think I#(twist) it.<br>
              <b>b</b>&ensp;Yes, it's a bit swollen. You#(sprain) it.<br>
            </div>
              <b>Dialogue 2</b>
            <div>
              <b>c</b>&ensp;I#(have) an accident. I#(bang) my head.<br>
              <b>d</b>&ensp;I#(trip) over the cat and#(hit) my head on the corner of a table.<br>
            </div>
            <b>Dialogue 3</b>
            <div>
              <b>e</b>&ensp;I#(hurt) my thumb. I#(trap) it in the car door.<br>
              <b>f</b>&ensp;You certainly#(bruise) it.<br>
              <b>g</b>&ensp;It’s really painful. Do you think I#(break) it?<br>
            </div>
          </div>
      </div>
          `,
				answer: [
					"'ve twisted",
					"'ve sprained",
					"'ve had",
					'banged',
					'tripped',
					'hit',
					"'ve hurt",
					'trapped',
					"'ve bruised",
					"'ve broken",
				],
			},
		],
	},
	7: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P37-E7',
		audio: '',
		video: '',
		// exerciseKey: 'img/FriendsPlus/Page37/Key/E7answerKey.png',
		titleQuestion: [
			{
				num: '7',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Work in pairs. Ask and answer about experiences
using the present perfect and the phrases below. If the
answer is ‘yes’, give more information.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'bang your head',
					'break a bone',
					'bruise yourself badly',
					'burn yourself',
					'cut yourself badly',
					'have a bad nosebleed',
					'twist your ankle',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		// maxLength: 1,
		// isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 40,
		textAlign: 'center',
		recorder: true,
		hideBtnFooter: true,
		questionImage: [],
		questions: [
			{
				title: `
        <hintbox id='0'></hintbox>
        <img src='img/FriendsPlus/Page37/E7/1.jpg'>
          `,
				answer: [''],
			},
		],
	},
	8: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P37-E8',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page24/Key/E4answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '8',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Work in groups. Which facility from exercise 7
would you like most for your school? Why? Can the whole
class agree on one choice?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page37/E8/1.pNg' }]],
	},
}

export default json
