import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'unit 5',
    id: 'FGF11-A-P64-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page64/Key/E1answerKey.png',
    component: T6,
    textareaStyle: { width: 700 },
    inputSize: 800,
    // maxLength: 49,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '1',
        title: `
        Read the dialogue. Who has a problem with their phone: <br> Freya, Archie or both? Explain your answer,`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div style="margin-left:">
						<img src="img/FriendsPlus/Page64/E1/1.jpg" style="max-width: 100%; margin-bottom:20px; margin-left:100px" />
						<textarea id='0' row='1'></textarea>
						
					</div>
        `,
        answer: [
          `Both. Archie's phone hasn't been working for a week. Freya might have downloaded malware on her phone`,
        ],
      },
    ],
  },
  2: {
    unit: 'unit 5',
    id: 'FGF11-A-P64-E2',
    audio: 'Audios/2.20-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page64/E2/Key/answerKey.png',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    recorder: true,
    hideBtnFooter: true,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '2',
        title: `
           <headphone1 name="2.20" src="Audios/2.20-tieude.mp3"></headphone1> <span style="color:rgb(66, 64, 153)"> PRONUNCIATION </span>Listen to the dialogue. How is have pronounced?
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					
				`,
        answer: ['2', 'A', '', '', '1', 'B'],
      },
    ],
  },
  3: {
    unit: 'unit 5',
    id: 'FGF11-A-P64-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page64/Key/E3answerKey.png',
    component: T6,
    inputSize: 250,
    // maxLength: 21,
    stylesTextInput: {
      textAlign: 'center',
      // fontSize: 20,
    },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    checkDuplicated: true,
    titleQuestion: [
      {
        num: '3',
        title: `
					Read the <span style="color: rgb(246, 141, 31)">Learn this!</span> box. Underline must / have to + 
					infinitive in the dialogue. Then complete the following 
					sentences using must mustn’t have to don’t have to.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div style="letter-spacing: 1px;">
						<img src="img/FriendsPlus/Page64/E1/3.jpg" style="max-width: 100%; margin-left:160px" />
						<div >
							<div><b>1.</b> A hotel receptionist # be good at communication.</div>
						<div><b>2.</b> You # pay for the tickets in advance.</div>
						<div><b>3.</b>Examinees # switch off all modern gadgets during the exams.</div>
						<div><b>4.</b> You # leave litter lying around.</div>
						</div>
					</div>
				`,
        answer: ['must', "don't have to", ' have to', "mustn't"],
      },
    ],
  },
  4: {
    unit: 'unit 5',
    id: 'FGF11-A-P64-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page64/Key/E4answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `
					Read the <span style="color:rgb(246, 141, 31)">Learn this!</span> box. Underline the past modal verbs in the dialogue in exercise 1.
			`,
        color: '#860052',
      },
    ],
    component: Circle_Write,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 149, fontSize: 23, borderBottom: 'none', textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },

      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: "50%",
          borderColor: 'transparent',
        },
        selectWordStyle: {
          // padding: 5,
          borderBottom: 'solid 2px',
          borderColor: '#00aeef',
        },
        limitSelect: 7,
        listWords: [
          `I can’t find my camera, Archie.<br>
 There it is. It’s under your bag. Why do you have 
to bring it to class?<br>
 I must take it to my photography class today. By 
the way, I didn’t understand that email you sent 
me yesterday.<br>
 I didn’t send any emails yesterday.<br>
 Well, you might_have_sent it earlier. But I got it 
yesterday.<br>
 I can’t_have_sent you an email. My phone hasn’t 
been working for a week.<br>
 Somebody must_have_used your account. The 
email had a link to a competition, but when I 
clicked on it, nothing happened.<br>
 Oh no! You shouldn’t_have_clicked on the link. 
You’ve possibly downloaded some malware onto 
your phone.<br>
 That’s terrible! You should_have_warned me earlier.<br>
 I didn’t know! Anyway, you might_not_have_downloaded anything. It’s possible that you were 
lucky. You just need to wait and see.<br>
 Anyway, you need to warn your other friends. 
That email could_have_gone to everybody in your 
address book! `, //0
        ],
        answers: ['0-116', '0-138', '0-168', '0-218', '0-254', '0-272', '0-328'],
        initialValue: [],
      },
      Layout: `

        <div style='display:flex'>
        <b >
         
         
							Freya<br>
							Archie<br><br>
							Freya<br><br><br>
							Archie<br>
							Freya<br><br>
							Archie<br><br>
							Freya<br><br><br>
							Archie<br><br><br>
							Freya<br><br>
							Archie<br><br><br>
							Freya<br>
     		 </b> 
      		<div style="margin-left: 30px; width:15cm;"> 
					 <input id='0' type="Circle"/>
      		</div> 
					<img src="img/FriendsPlus/Page64/E4/1.jpg" style='height:15cm'/>

      </div> 
      `,
    },
  },
  5: {
    unit: 'unit 5',
    id: 'FGF11-A-P64-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page64/Key/E5answerKey.png',
    component: T6,
    inputSize: 400,
    // maxLength: 1,
    stylesTextInput: {
      fontSize: '23px',
    },
    textAlign: 'center',
    titleQuestion: [
      {
        num: '5',
        title: `
					<span style="color:rgb(146, 39, 144)">USE OF ENGLISH </span>Complete the second sentence so that it means the same as the first. Include the word in brackets.
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div style="font-size:23px">
						<div><b>1.</b> It was a bad idea for you to lend Jake your phone. 
								(shouldn’t)# You Jake your phone.</div>
						<div><b>2.</b> I It’s possible that Fraser deleted your messages 
								by accident. (could) # Fraser by accident.</div>
						<div><b>3.</b> The only possible explanation is that you sent that email 
								to the wrong person. (must) # You to the wrong person.</div>
						<div><b>4.</b> It’s possible that I didn’t dial the correct number. (may) # I the correct number.</div>
						<div><b>5.</b> Leaving your phone on was a bad idea. (should)# You your phone off.</div>
						<div><b>6.</b> It’s not possible that Tom phoned Kim. (can’t) # Tom Kim.</div>
					</div>
        `,
        answer: [
          "shouldn't have lent ",
          'could have deleted your messages',
          'must have sent that email',
          'may not have dialled ',
          'should have turned',
          "can't have phoned ",
        ],
      },
    ],
  },
  6: {
    unit: 'unit 5',
    id: 'FGF11-A-P64-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page64/E6/Key/answerKey.png',
    component: T6,
    inputSize: 100,
    maxLength: 6,
    hideBtnFooter: true,
    recorder: true,
    stylesTextInput: {
      textAlign: 'center',
      // fontSize: 20,
    },
    titleQuestion: [
      {
        num: '6',
        title: `
					<span style="color:rgb(45,206,137)">SPEAKING</span> Work in pairs. Describe the photo. Say what might / must / can’t have happened.
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<img src="img/FriendsPlus/Page64/E6/1.jpg" style="max-width: 70%; margin-left:200px" />
		    `,
        answer: [],
      },
    ],
  },
};

export default json;
