import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	1: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P22-E1',
		audio: '',
		video: '',
		// recorder: true,
		hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page22/E1/Key/answerKey.png',
		component: T6,
		textAlign: 'center',
		inputSize: 225,
		checkUppercase: true,
		maxLength: 10,
		titleQuestion: [
			{
				num: '1',
				title: `
        <span style='color:rgb(1 145 67)'>SPEAKING</span> Describe the photos. Can you name the ethnic
groups in the photos?`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 525 },
		questions: [
			{
				title: `
            <div style='display:flex'>
            		<div >
											<img style='margin-bottom:24px' src='img/FriendsPlus/Page22/E1/1.jpg'  /> <br> #
            		 </div>
            		<div style='margin: 0px 50px'>
											<img style='margin-bottom:37px' src='img/FriendsPlus/Page22/E1/2.jpg'  /> <br> #
            		 </div>
            		<div >
											<img src='img/FriendsPlus/Page22/E1/3.jpg'  /> <br> #
            		 </div>
             </div>

        `,
				answer: ['Muong', 'Cham', "H'Mong"],
			},
		],
	},
	2: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P22-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page22/E2/Key/answerKey.png',
		component: T6,
		inputSize: 505,
		isHiddenCheck: true,
		maxLength: 48,

		titleQuestion: [
			{
				num: '2',
				title: `
        Read the text. Where in Viet Nam do these peoples live?`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 500 },
		questions: [
			{
				title: `
          <div>
						  <div style='display:flex'>
                <img style='height:4cm' src='img/FriendsPlus/Page22/E2/1.jpg'  />
                <div>
                  Viet Nam is a multi-racial country with 54 ethnic groups. More
                  than 85% of Viet Nam’s population is the Kinh people who
                  mainly inhabit the Red River Delta, the central coastal delta,
                  the Mekong Delta and major cities. The rest of Viet
                </div>
             </div>
            Nam’s population, about 8 million, is scattered in mountainous areas around the country and makes up the other 53 ethnic groups.
            Major ethnic groups include Tay and Nung (in North East
            provinces), Thai and Muong people (in Phu Tho Province, Hoa
            Binh Province), Hoa (around Viet Nam), Khmer (in the Mekong
            Delta). All ethnic groups in Viet Nam have achieved high levels
            of development in terms of culture and social strata.
            Ethnic groups have distinct
            cultures and customs. Take
            marriage practices and weddings
            as an example. On arrival at the
            groom’s house, a bride in the
            Nung people pours a bottle of
            water into a saucepan, which
            symbolises hard work and dedication
            to the family. Meanwhile, a wedding ceremony of the Tay
            people is characterised by a lot of singing known as ‘hat quan
            lang’. A Thai man usually stays with and works for his fiancé’s
            family for 3 years
            before the wedding
            ceremony. Varied as
            the customs are, they
            all mean blessings to
            the newly-weds in
            their marriage life
          </div>
        <div>
            <b>1</b>&ensp;Tay#<br>
            <b>2</b>&ensp;Muong#<br>
            <b>3</b>&ensp;Khmer#<br>
            <b>4</b>&ensp;Thai#<br>
        </div>
        `,
				answer: [
					'in North East provinces',
					'in Phu Tho Province, Hoa Binh Province',
					'in the Mekong Delta',
					'in Phu Tho Province, Hoa Binh Province',
				],
			},
		],
	},
	3: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P22-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page22/E3/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		textAlign: 'center',
		maxLength: 48,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '3',
				title: `
        Read the text again. Are these statements
true or false? Write (T) and (F).`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 500 },
		questions: [
			{
				title: `
          <div>
						  <div style='display:flex'>
                <img style='height:4cm' src='img/FriendsPlus/Page22/E2/1.jpg'  />
                <div>
                  Viet Nam is a multi-racial country with 54 ethnic groups. More
                  than 85% of Viet Nam’s population is the Kinh people who
                  mainly inhabit the Red River Delta, the central coastal delta,
                  the Mekong Delta and major cities. The rest of Viet
                </div>
             </div>
            Nam’s population, about 8 million, is scattered in mountainous areas around the country and makes up the other 53 ethnic groups.
            Major ethnic groups include Tay and Nung (in North East
            provinces), Thai and Muong people (in Phu Tho Province, Hoa
            Binh Province), Hoa (around Viet Nam), Khmer (in the Mekong
            Delta). All ethnic groups in Viet Nam have achieved high levels
            of development in terms of culture and social strata.
            Ethnic groups have distinct
            cultures and customs. Take
            marriage practices and weddings
            as an example. On arrival at the
            groom’s house, a bride in the
            Nung people pours a bottle of
            water into a saucepan, which
            symbolises hard work and dedication
            to the family. Meanwhile, a wedding ceremony of the Tay
            people is characterised by a lot of singing known as ‘hat quan
            lang’. A Thai man usually stays with and works for his fiancé’s
            family for 3 years
            before the wedding
            ceremony. Varied as
            the customs are, they
            all mean blessings to
            the newly-weds in
            their marriage life
          </div>
        <div>
            <b>1</b>&ensp;Most of Vietnamese people belong to
                          the Kinh ethnic group.#<br>
            <b>2</b>&ensp;Different wedding customs of ethnic
                          groups have different meanings.#<br>
            <b>3</b>&ensp;As far as culture is concerned, the Kinh
                          people is the most developed in
                          Viet Nam.#<br>
        </div>
        `,
				answer: ['T', 'F', 'F'],
			},
		],
	},
	4: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P22-E4',
		audio: 'Audios/1.18-audio.mp3',
		video: '',
		component: T6,
		inputSize: 120,
		exerciseKey: 'img/FriendsPlus/Page22/E4/Key/answerKey.png',
		checkUppercase: true,
		// maxLength: 48,
		textAlign: 'center',
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '4',
				title: `
        <headphone1 name='1.18' src='Audios/1.18-tieude.mp3'></headphone1> Listen to three Westerners
talking about the wedding ceremonies
they witnessed in Viet Nam. Which ethnic
groups are mentioned by the speakers?`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 525 },
		questions: [
			{
				title: `
            <div style='line-height:60px'>
              <u style='font-weight:bold;font-style:italic'>SPEAKER 1:</u>&ensp;#<br>
              <u style='font-weight:bold;font-style:italic'>SPEAKER 2:</u>&ensp;#<br>
              <u style='font-weight:bold;font-style:italic'>SPEAKER 3:</u>&ensp;#<br>
            </div>
        `,
				answer: ['Thai', 'Tay', 'Nung'],
			},
		],
	},
	5: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P22-E5',
		audio: 'Audios/1.18-audio.mp3',
		video: '',
		component: T6,
		inputSize: 75,
		exerciseKey: 'img/FriendsPlus/Page22/E5/Key/answerKey.png',
		checkUppercase: true,
		maxLength: 3,
		textAlign: 'center',
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '5',
				title: `
         <headphone1 name='1.18' src='Audios/1.18-tieude.mp3'></headphone1> Listen again. Match the
sentences with the speakers (1–3). Some
sentences match with more than one
speaker.`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 925 },
		questions: [
			{
				title: `
        Which speaker(s)
               <div style='line-height: 60px'>
                <b>A</b>&ensp;awere amazed by the ceremony?#<br>
                <b>B</b>&ensp;had read about the customs before?#<br>
                <b>C</b>&ensp;mention his / her previous trip to
Viet Nam?#<br>
                <b>D</b>&ensp;make a positive comment about the
bride?#<br>
                <b>E</b>&ensp;understand the meaning of the customs?#<br>
               
               </div>
        `,
				answer: ['1-2 / 1,2 / 2,1 / 2-1', '2', '3', '3', '2-3 / 3-2 / 3,2 / 2,3'],
			},
		],
	},
	6: {
		unit: 'Unit 1',
		id: 'FG11-SB-U1-P22-E6',
		audio: '',
		video: '',
		// recorder: true,
		hideBtnFooter: true,
		component: UI,
		titleQuestion: [
			{
				num: '6',
				title: `
              <span style='color:rgb(1 145 67)'>SPEAKING</span>
            Work in pairs. Discuss these questions.`,
				color: '#92278f',
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page22/E6/1.jpg' }]],
	},
}

export default json
