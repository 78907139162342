import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Grammar Builder and  Reference',
    id: 'FGF11-U8-P106-E1',
    audio: '',
    video: '',
    component: T6,
    recorder: true,
    titleQuestion: [
      {
        num: '1',
        title:
          '<span style="font-weight:bold; color :rgb(90,161,86)">SPEAKING</span> Which of the following rivers is not located in Asia? Where is it located?',
        color: 'rgb(158,16,86)',
      },
    ],
    stylesTextInput: {
      fontSize: 30,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 250,
    exerciseKey: 'img/FriendsPlus/Page106/key/1.png',
    questionImage: [],
    isHiddenCheck: true,
    textareaStyle: {
      width: 800,
    },
    questions: [
      {
        title: /*html */ `
         <div style="display: flex; column-gap: 20px; flex-wrap: wrap; color: rgb(66,153,210); flex-direction:column">
       The Euphrates The Ganges The Red River <br />
       The Danube The Tigris The Mekong River 
       <div style="display:flex;flex-direction:row">
       
       <span style="width:300" id="0">#</span> in <span id="1">#</span>
       </div>
          </div>
				`,
        answer: ['The Danube', 'Europe'],
      },
    ],
  },
  2: {
    unit: 'Grammar Builder and  Reference',
    id: 'FGF11-U8-P106-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: 'Read the article and name the countries the Mekong River runs through.',
        color: 'rgb(158,16,86)',
      },
    ],
    stylesTextInput: {
      fontSize: 30,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 200,
    exerciseKey: 'img/FriendsPlus/Page106/key/2.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display: flex; flex-direction:column">
          
          <div style="display: flex">
          <img src="img/FriendsPlus/Page106/E2/1.png" style="width: 600px" />
               <img src="img/FriendsPlus/Page106/E2/2.png" style="width: 600px" />
          </div>


        <div style="display:flex;flex-direction:row;margin-top:20px">
        <div style="display:flex;flex-direction:column;margin-right:20px">
         <div style="display:flex;flex-direction:row"><span  style="font-weight:bold; "> 1  </span><span style="margin-left:20px" id="0">#</span></div>
           <div style="display:flex;flex-direction:row">   <span style="font-weight:bold; ">2  </span><span style="margin-left:20px" id="1">#</span></div>
             <div style="display:flex;flex-direction:row"> <span style="font-weight:bold; ">3 </span> <span style="margin-left:20px" id="2">#</span></div>

 
     
       
        </div>

         <div style="display:flex;flex-direction:column">
  <span style="font-weight:bold; ">  4  <span style="margin-left:20px" id="3">#</span></span>
        <span style="font-weight:bold; ">  5   <span style="margin-left:20px" id="4">#</span></span>
        <span style="font-weight:bold; ">   6    <span style="margin-left:20px" id="5">#</span></span>
        </div>
        
        </div>
 
          </div>
				`,
        answer: [
          `China / Myanmar / Laos / Thailand / Cambodia  / Viet Nam. `,
          `China / Myanmar / Laos / Thailand / Cambodia  / Viet Nam.  `,
          `China / Myanmar / Laos / Thailand / Cambodia  / Viet Nam. `,
          `China / Myanmar / Laos / Thailand / Cambodia  / Viet Nam. `,
          `China / Myanmar / Laos / Thailand / Cambodia  / Viet Nam. `,
          'China / Myanmar / Laos / Thailand / Cambodia  / Viet Nam. ',
        ],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and  Reference',
    id: 'FGF11-U8-P106-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page106/key/3.png',

    component: Circle_Write,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '3',
        title: "<span style='font-size:30px'>Read the article again and choose the correct answers (A–C)</span>",
        color: 'rgb(144 38 143)',
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
        },
        selectWordStyle: {
          border: 'solid 2px',
          padding: '0 5px',
          borderColor: '#00a8ec',
        },
        limitSelect: 1,
        listWords: ['A <br> B <br> C ', 'A <br> B <br> C', 'A <br> B <br> C', 'A <br> B <br> C', 'A <br> B <br> C '],
        answers: ['0-4', '1-8', '2-4', '3-4', '4-8'],
        initialValue: [],
      },
      /*html */
      Layout: `
       <div style="display:flex;">
            <img src="img/FriendsPlus/Page106/E3/1.png" style="width: 400px"/>
                <img src="img/FriendsPlus/Page106/E3/2.png" style="width: 740px"/>
                    </div>


        <div>
        <b>1</b>&ensp;Compared to the other rivers in the world, the Mekong
River is
  <div style="display: flex;margin-left:40px">

		          <div style='font-weight:bold;margin-right:20px'><input id='0' type='Circle'/></div>
		          <div style=''>
              the longest.  <br>
             not as long as eleven other rivers. <br>
              longer than twelve other rivers.
                    </div>
                       </div>
        </div>

        <div>
        <b>2</b>&ensp; The Mekong River got its name from
  <div style="display: flex;margin-left:40px">

		          <div style='font-weight:bold;margin-right:20px'><input id='1' type='Circle'/></div>
		          <div style=''>
              Chinese. <br>  Burmese. <br>  Lao.
                    </div>
                       </div>
        </div>

         <div>
        <b>3</b>&ensp; The Mekong River provides means for Southeast Asian people to access
  <div style="display: flex;margin-left:40px">

		          <div style='font-weight:bold;margin-right:20px'><input id='2' type='Circle'/></div>
		          <div style=''>
             turbulent sections and physical obstacles.   <br>   social facilities and natural resources.  <br>   job opportunities and challenges.
                    </div>
                       </div>
        </div>


         <div>
        <b>4</b>&ensp; The navigation along the Mekong River
  <div style="display: flex;margin-left:40px">

		          <div style='font-weight:bold;margin-right:20px'><input id='3' type='Circle'/></div>
		          <div style=''>
              is already well-developed. <br>   still has more potential. <br>   is limited in Laos only.
                    </div>
                       </div>
        </div>

           <div>
        <b>5</b>&ensp; Improvements should be made so that
  <div style="display: flex;margin-left:40px">

		          <div style='font-weight:bold;margin-right:20px'><input id='4' type='Circle'/></div>
		          <div style=''>
             more tourists could visit the countries in the Southeast Asia. <br>   boats can replace all of other means of transport in the Southeast Asia. <br>   larger boats can pass some parts of the river more easily to better the goods exchange.
                    </div>
                       </div>
        </div>

      


       
      
				`,
      answer: [],
    },
  },
  4: {
    unit: 'Grammar Builder and  Reference',
    id: 'FGF11-U8-P106-E4',
    audio: 'Audios/3.20-audio.mp3',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title:
          "<headphone1 name='3.20'></headphone1>  Listen to a talk on the course of the Mekong River. Arrange the places along the course of the Mekong River in the order of listening..",
        color: 'rgb(158,16,86)',
      },
    ],
    stylesTextInput: {
      fontSize: 30,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 600,
    exerciseKey: 'img/FriendsPlus/Page106/key/4.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
        <div style="display:flex;flex-direction:column;font-size:30px">
        <div> 
       <span style="font-weight:bold"> A</span> Golden Triangle
        </div>

         <div> 
        <span style="font-weight:bold"> B</span> Tibetan Plateau
        </div>

         <div> 
       <span style="font-weight:bold"> C</span> The tripoint of Myanmar, China and Laos
        </div>

         <div> 
        <span style="font-weight:bold"> D</span>  The Cuu Long River
        </div>

         <div> 
        <span style="font-weight:bold"> E</span>  The Three Parallel Rivers Area
        </div>
        <div style="display:flex;flex-direction:row;margin-top:20px"><strong>1</strong> <span style="font-size:35px"  id="0">#</span> </div>
         <div style="display:flex;flex-direction:row"><strong>2</strong>   <span style="font-size:35px"  id="1">#</span> </div>
          <div style="display:flex;flex-direction:row"><strong>3</strong>          <span style="font-size:35px"  id="2">#</span> </div>
           <div style="display:flex;flex-direction:row"><strong>4</strong> 
         <span  style="font-size:35px" id="3">#</span> </div>
            <div style="display:flex;flex-direction:row"><strong>5</strong>         <span  style="font-size:35px" id="4">#</span> </div>

         
       

 





        </div>
      
				`,
        answer: [
          `Tibetan Plateau`,
          `the Three Parallel Rivers Area`,
          `the tripoint of Myanmar, China and Laos`,
          `Golden Triangle`,
          `The Cuu Long River`,
        ],
      },
    ],
  },
  5: {
    unit: 'Grammar Builder and  Reference',
    id: 'FGF11-U8-P106-E5',
    audio: 'Audios/3.20-audio.mp3',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title:
          "<headphone1 name='3.20'></headphone1>   Listen again and decide whether the following statements are true (T) or false (F).",
        color: 'rgb(158,16,86)',
      },
    ],
    stylesTextInput: {
      borderBottom: 'dash',
      backgroundColor: 'transparent',
      fontSize: 30,
    },
    inputSize: 100,
    exerciseKey: 'img/FriendsPlus/Page106/key/5.png',
    questionImage: [],
    textareaStyle: { width: 800 },
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <b>1</b>&ensp;The Mekong River has most of its drainage area in Laos and Thailand. 
        <div>           <textarea id='0' rows='2'/>
</div>
 <b>2</b>&ensp;The Mekong River is to the west of the Salween River.
        <div>           <textarea id='1' rows='2'/>
</div>
<b>3</b>&ensp;The Golden Triangle is referred to as the second tripoint the Mekong River runs through.
        <div>           <textarea id='2' rows='2'/>
</div>
<b>4</b>&ensp;The Mekong River runs through Laos for over 1,000 kilometres before entering Cambodia.
        <div>           <textarea id='3' rows='2'/>
</div>
         
          
           
				`,
        answer: [
          'F (three-fourths of the drainage area of the Mekong lies within the four countries: Laos, Thailand, Cambodia, and Viet Nam.)',
          'F (the Mekong River lies between Salween River on the west and Yangtze River on the east)',
          'T',
          'T',
        ],
      },
    ],
  },
  6: {
    unit: 'Grammar Builder and  Reference',
    id: 'FGF11-U8-P106-E6',
    audio: '',
    video: '',
    component: T6,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '6',
        title:
          "<span style='font-weight:bold;color:rgb(90,161,86)'>SPEAKING</span> Work in pairs. Discuss the following question.",
        color: 'rgb(158,16,86)',
      },
    ],
    textAlign: 'center',
    stylesTextInput: {
      borderBottom: 'dash',
      backgroundColor: 'transparent',
    },
    inputSize: 100,
    exerciseKey: 'img/FriendsPlus/Page106/E6/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
         <div style='display:flex'>
          <span style="font-style: italic;">What do you think is the most interesting thing when 
travelling on a boat trip or boat tour?</span>
         </div>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
