import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
  1: {
    unit: 'Science',
    id: 'FG10-S-P101-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page101/Key/E1answerKey.png',
    component: T6,
    inputSize: 650,
    maxLength: 900,
    ////hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    textareaStyle: { width: 500, fontSize: 20 },
    titleQuestion: [
      {
        num: '1',
        title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
           Describe the photo in pairs. What would be the best and worst things about going on a long trip on a boat like this?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<img style='padding-left:250px' src="/img/FriendsPlus/Page101/E1/1.jpg" >
          <div style="padding-top:20px; padding-left:210px">
            <div>#</div>
            <div>#</div>
          </div>
				`,
        answer: [
          'The best thing would be the relaxation and sightseeing.',
          'The worst thing would be the accommodation.'
        ],
      },
    ],
  },
  2: {
    unit: 'Science',
    id: 'FG10-S-P101-E2',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page100/Key/E2answerKey.png',
    component: T6,
    inputSize: 750,
    maxLength: 1500,
    hideBtnFooter: true,
    textareaStyle: { width: 990 },
    //isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '2',
        title: `
					Read the text. If you had been on a trip like this, would you have enjoyed it? Why? / Why not?
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				
						<img src="img/FriendsPlus/Page101/E2/1.jpg" style="max-width: 100%; border-radius: 10px; padding-left:250px" />
					
				`,
        answer: ['']
      },
    ],
    recorder: true,
  },
  3: {
    unit: 'Science',
    id: 'FG10-S-P101-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page101/Key/E3answerKey.png',
    component: T6,
    inputSize: 50,
    maxLength: 10,
    textareaStyle: { width: 980 },
    ////hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '3',
        title: `
					Read the <span style='color:rgb(146,39,143)'>Learn this!</span> box. Then look at the highlighted verbs in the text above. Which verb pattern (a–e) does not have an example in the text?`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				<div>
					
						<div style="display: flex; width: 100%;">
							<div style="display: flex; justify-content: center; align-items: center; padding-left:90px;">
								<img src="/img/FriendsPlus/Page101/E3/1.jpg" style="max-width: 100%; border-radius: 10px;" />
							</div>
              <div style="padding:100px;display: flex;">
                <div><b>Answers: </b></div>
                <div>#</div>
              </div>
					</div>

				</div>
				`,
        answer: [

          'e'
        ],
      },
    ],
  },
  4: {
    unit: 'Science',
    id: 'FG10-S-P101-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page101/Key/E4answerKey.png',
    component: T6v2,
    inputSize: 150,
    maxLength: 1000,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '4',
        title: `
        Add the verbs from the text to the table. Can you add any more verbs?
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				<div>
        <img src="img/FriendsPlus/Page101/E2/1.jpg" style="max-width: 100%; border-radius: 10px; padding-left:250px" />
            <table style='border: 2px solid rgb(146,39,143); border-collapse: collapse;'>
                <tr>
                    <th style='border: 1px solid whitesmoke; border-collapse: collapse; color: whitesmoke; background-color: rgb(146,39,143); padding: 20px;'>verb + infinitive</th>
                    <th style='border: 1px solid whitesmoke; border-collapse: collapse; color: whitesmoke; background-color: rgb(146,39,143); padding: 20px;'>verb + -ing form</th>
                    <th style='border: 1px solid whitesmoke; border-collapse: collapse; color: whitesmoke; background-color: rgb(146,39,143); padding: 20px;'>infinitive or -ing (same meaning)</th>
                    <th style='border: 1px solid rgb(146,39,143); border-collapse: collapse; color: whitesmoke; background-color: rgb(146,39,143); padding: 20px;'>infinitive or -ing (different meaning)</th>
                </tr>
                <tr>
                    <td style='border: 1px solid rgb(146,39,143); border-collapse: collapse; padding: 10px; text-align: center;'>
                    choose # <br># <br># <br># <br># <br># 
                    </td>
                    <td style='border: 1px solid rgb(146,39,143); border-collapse: collapse; padding: 10px; text-align: center;'>
                    not mind # <br># <br># <br># 
                    </td>
                    <td style='border: 1px solid rgb(146,39,143);border-collapse: collapse; padding: 10px; text-align: center;'>
                    like <br> # 
                    </td>
                    <td style='border: 1px solid rgb(146,39,143); border-collapse: collapse; padding: 10px; text-align: center;'>
                    remember <br> #  
                    </td>
                </tr>
            </table>
        </div>
				`,
        answer: [
          'decide/expect/offer/manage/afford/agree',
          'decide/expect/offer/manage/afford/agree',
          'decide/expect/offer/manage/afford/agree',
          'decide/expect/offer/manage/afford/agree',
          'decide/expect/offer/manage/afford/agree',
          'decide/expect/offer/manage/afford/agree',
          'fancy/keep/end up/enjoy',
          'fancy/keep/end up/enjoy',
          'fancy/keep/end up/enjoy',
          'fancy/keep/end up/enjoy',
          'continue',
          'stop'
        ],
      },
    ],
  },
  5: {
    unit: 'Science',
    id: 'FG10-S-P101-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page101/Key/E5answerKey.png',
    component: T6,
    inputSize: 900,
    maxLength: 2000,
    // hideBtnFooter: true,
    stylesTextInput: {
      textAlign: '',
      //fontSize: 20,
    },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '5',
        title: `
        Compare sentences a and b. How is the meaning of the
        underlined verb different when followed by an -ing form
        or an infinitive?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				<div style="gap :50px; position: relative">
        
          <div><b>1 a</b> I <u>tried</u> buying a present for her, but she was still angry with me.</div>
          <div style="padding-left:20px">#</div>
          <div style="padding-left:20px"><b>b</b> I <u>tried</u> to buy a present for her, but everything was too expensive.</div>
          <div  style="padding-left:20px">#</div>

          <div><b>2 a</b> I <u>stopped</u> talking to my friend when the film started.</div>
          <div style="padding-left:20px">#</div>
          <div style="padding-left:20px"><b>b</b> I <u>stopped</u> to talk to my friend when I met him in town.</div>
          <div  style="padding-left:20px">#</div>

          <div><b>3 a</b> After the film, they <u>went on</u> eating popcorn.</div>
          <div style="padding-left:20px">#</div>
          <div style="padding-left:20px"><b>b</b> After the film, they <u>went on</u> to eat pizza.</div>
          <div  style="padding-left:20px">#</div>


          <div><b>4 a</b> I won't <u>forget</u> visiting the Taj Mahal – it was amazing.</div>
          <div style="padding-left:20px">#</div>
          <div style="padding-left:20px"><b>b</b> I won't <u>forget</u> to visit the Taj Mahal – I've heard it's amazing.</div>
          <div  style="padding-left:20px">#</div>

        </div>
      </div>
				`,
        answer: [
          'I bought a present to see if it would make her less angry.',
          "I made an attempt to buy a present, but I didn't have enough money.",
          'I was talking to my friend, but stopped when the film started.',
          'I began to talk to my friend when I met him.',
          'They were eating popcorn and when the film ended they continued to eat it.',
          'The film ended and they went to eat pizza.',
          "I remember visiting the Taj Mahal (in the past) and I won't forget it.",
          'I will remember to visit the Taj Mahal (in the future) because it is amazing.'
        ],
      },
    ],
  },
  6: {
    unit: 'Science',
    id: 'FG10-S-P101-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page101/Key/E6answerKey.png',
    component: T6,
    inputSize: 150,
    maxLength: 2000,
    stylesTextInput: {
      textAlign: 'center',
      //fontSize: 20,
    },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '6',
        title: `
        Complete the text with the infinitive (with or without to) or -ing form of the verbs in brackets.
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div style='padding:0px 60px 0px 60px;border: 15px solid rgb(10, 99, 44); border-radius: 30px;'>
        <table style='padding-left:60px;'>
            <tr>
                <th style="width: 1000px;  ">In the UK, people who are travelling long distances by road
                often stop <sup>1 #</sup> (spend) the night at a roadside
                hotel. But David and Jean Davidson have spent 22 years
                <sup>2 #</sup> (live) in one.
                The Davidsons first tried <sup>3 #</sup> (visit)
                Travelodge hotels in 1985. They enjoyed <sup>4 #</sup>
                (be) there so much that gradually they stopped
                <sup>5 #</sup> (go) back to their own home. When a
                new Travelodge opened 65 km from Sheffield, they decided
                <sup>6 #</sup> (become) permanent guests. They like
                <sup>7 #</sup> (live) there because it is safe and convenient.
                The retired couple can afford <sup>8 #</sup> (pay) for
                their room because they book it weeks in advance and get
                a good rate. In fact, last year, they managed
                <sup>9 #</sup> (save) enough money for a three-week
                holiday abroad in the USA. And where did they end up
                <sup>10 #</sup> (stay)?
                In an American Travelodge hotel, of course!</th>
            </tr>
        </table>
    </div>
				`,
        answer: [
          'to spend',
          'living',
          'visiting',
          'being',
          'going',
          'to become',
          'living',
          'to pay',
          'to save',
          'staying'
        ],
      },
    ],
  },
  7: {
    unit: 'Science',
    id: 'FG10-S-P101-E7',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page100/E7/Key/answerKey.png',
    component: T6,
    inputSize: 160,
    maxLength: 15,
    hideBtnFooter: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 20,
    },
    //hideBtnFooter: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '7',
        title: `
        <b style='color:rgb(0,147,70);'>SPEAKING</b>
        Work in pairs. Tell your partner about something that you
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div style="margin-left:30px">
        <div style="display:flex; justify-content: space-between;width :550px;">
          <div><b>1</b> love to do.</div>
          <div><b>3</b> will never forget doing.</div>
        </div>

        <div style="display:flex; justify-content: space-between;width :540px;">
         <div><b>2</b> often forget to do.</div>
          <div><b>4</b> will try doing one day.</div>
        </div>
      </div>
				`,
        answer: [''],
      },
    ],
    recorder: true,
  },
  8: {
    unit: 'Science',
    id: 'FG10-S-P101-E8',
    audio: '',
    video: '',
    exerciseKey: '',
    component: T6,
    inputSize: 80,
    maxLength: 5,
    //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    hintBox: [
      {
        src: ['If the wheel didn’t exist, we couldn’t travel by car or bus.'],
        borderColor: 'rgb(182, 179, 217)',
        arrow: true,
        position: 2,
        width: 525,
      },
      {
        src: ['If we didn’t have aeroplanes, journeys would take much longer.'],
        borderColor: 'rgb(182, 179, 217)',
        arrow: true,
        position: 1,
        width: 525,
      },
    ],
    titleQuestion: [
      {
        num: '8',
        title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in groups. Decide which three inventions and discoveries from history are the most important. 
					Use the ideas below or think of your own. Give reasons.
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div style="margin-top: 10px; color: rgb(66, 64, 153);">
						<div>
							<span>aeroplanes</span>
							<span style="padding-left: 30px;">antibiotics</span>
							<span style="padding-left: 30px;">books</span>
							<span style="padding-left: 30px;">cars</span>
							<span style="padding-left: 30px;">computers</span>
						</div>
						<div>
							<span>electricity</span>
							<span style="padding-left: 30px;">fire</span>
							<span style="padding-left: 30px;">printing</span>
							<span style="padding-left: 30px;">television</span>
							<span style="padding-left: 30px;">wheels</span>
						</div>
					</div>

					<div style="margin-top: 20px; text-align: center;"><hintbox id='0'></hintbox></div>
					<div style="margin-top: 50px; margin-left: 150px; text-align: center;"><hintbox id='1'></hintbox></div>
				`,
        answer: [],
      },
    ],
  },
}

export default json
