import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Grammar Builder and Referenc',
    id: 'FGF11-GBAR-P113-I1-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '1',
        title: 'Complete the sentences with the verbs in brackets. Use the past simple affirmative. ',
        // color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 22,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 150,
    exerciseKey: 'img/FriendsPlus/Page113/Key/E1answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
       <div style='line-height:2;'>
          <div style="display: flex; column-gap: 20px; margin-top:20px">
            <strong>1</strong>
            <div>
              <div>I
              <input /> Turkey in August. (visit)
              </div>

            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
             <div>  The weather
              <input />  great in July. (be)
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div> The sea was warm, so we
              <input />    swim. (can)
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
            <div> I
              <input />  you a postcard. (send)
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div> I  <input /> English at a school in Brighton. (study)
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <div> We <input />  every day. (sunbathe)
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>7</strong>
            <div>
              <div> I  <input />  kayaking in June. (go)
              </div>
            </div>
          </div>

         </div>
				`,
        answer: ['visited', 'was', 'could', 'sent', 'studied', 'sunbathed', 'went'],
      },
    ],
  },
  2: {
    unit: 'Grammar Builder and Referenc',
    id: 'FGF11-GBAR-P113-I1-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: 'Make the sentences in exercise 1 negative. ',
        // color: "#0067b4",
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page113/Key/E2answerKey.png',
    stylesTextInput: {
      // fontSize: 18,
      // height: 24,
      // borderBottom: "none",
      backgroundColor: 'transparent',
    },
    textAlign: 'center',
    inputSize: 390,
    maxLength: 13,
    titleImage: '',
    questionImage: [],

    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page113/Key/E1answerKey.png' width='900px'/>
         <div style ='line-height:2;'>
         <b>1</b>&ensp; <input/>. <br>
         <b>2</b>&ensp; <input/>. <br>
         <b>3</b>&ensp; <input/>. <br>
         <b>4</b>&ensp; <input/>. <br>
         <b>5</b>&ensp; <input/>. <br>
         <b>6</b>&ensp; <input/>. <br>
         <b>7</b>&ensp; <input/>.
         
         </div>
        `,
        answer: ["didn't visit", "wasn't", "couldn't", "didn't send", "didn't study", "didn't sunbathe", "didn't go"],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and Referenc',
    id: 'FGF11-GBAR-P113-I1-E3',
    audio: '',
    video: '',
    component: T6v2,
    exerciseKey: '',
    inputSize: 240,
    hideBtnFooter: true,
    recorder: true,

    titleQuestion: [
      {
        num: '3',
        title:
          "         <span style='color:rgb(90,161,86);'><b>Speaking</b></span> Ask and answer using the prompts below. Use the past simple. Give extra information in your answer.",
        // color: "#0067b4",
      },
    ],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page113/E3/1.jpg'>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Grammar Builder and Referenc',
    id: 'FGF11-GBAR-P113-I2-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page113/Key/I2E1answerKey.png',
    inputSize: 200,
    textAlign: 'center',
    checkUppercase: true,
    // //hideBtnFooter: true,
    titleQuestion: [
      {
        num: '1',
        title:
          'Complete the sentences with the verbs in brackets.Use the present simple or the present continuous tense.',
        // color: "#0067b4",
      },
    ],
    questions: [
      {
        title: `
         <div style='line-height:1.5;'>
          <div style="display: flex; column-gap: 20px; margin-top:20px">
            <strong>1</strong>
            <div>
              <div>‘Where <input />  you <input /> (go)?’ <br>
              ‘To the shops. I <input />  (need) to get some bread.’
 
              </div>

            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
             <div> 
              <input /> you usually  <input />  (wear) jeans to school?
              
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div> I
              <input />   (not understand) this question.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
            <div> Why <input /> you <input />  (smile)? It isn’t funny!
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div> ‘ <input />  you <input />  (come) bowling with Jan and me tonight?’ <br>
              ‘No, thanks. I  <input />  (not like) bowling.’
              </div>
            </div>
          </div>

           <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <div>  Hurry up! The bus <input />   (leave) in three minutes.
             
              </div>
            </div>
          </div>

         </div>
        `,
        answer: [
          `are`,
          `going`,
          `need`,
          `Do`,
          `wear`,
          `don't understand`,
          `are`,
          `smiling`,
          `Are`,
          `coming`,
          `don't like`,
          `leaves`,
        ],
      },
    ],
  },
};

export default json;
