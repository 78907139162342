import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P127-U8.1-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page127/key/E1answerKey.png',
    component: T6,
    stylesTextInput: {
      textAlign: 'center',
      // fontSize: 22,
    },
    titleQuestion: [
      {
        num: '1',
        title: `<span>Write third conditional sentences. Use the verbs in brackets.</span>`,
        color: 'rgb(144 38 143)',
      },
    ],
    inputSize: 230,
    maxLength: 21,
    textareaStyle: {
      marginTop: 20,
    },
    isHiddenCheck: true,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div>
          <div style="display:flex;flex-direction:row"><strong style="margin-right:20px">1</strong> If I <span> # </span> (drive) faster, we </div>
           <div style="display:flex;flex-direction:row;margin-left:36px">  <span>#</span> (arrive) before six</div>  
            <div style="display:flex;flex-direction:row"><strong style="margin-right:20px">2</strong>If Mary <span> # </span> (drive) (get) to the cinema earlier,</div>
            <div style="display:flex;flex-direction:row;margin-left:36px"> she  <span>#</span>(see) the start of the film.</div> 
              <div style="display:flex;flex-direction:row"><strong style="margin-right:20px">3</strong>You <span>#</span> (know) what to do if you</div> 
               <div style="display:flex;flex-direction:row;margin-left:36px">   <span>#</span>  (listen) to the instructions.</div> 
                <div style="display:flex;flex-direction:row"><strong style="margin-right:20px">4</strong> You <span>#</span> (not cut) yourself if you </div> 
                 <div style="display:flex;flex-direction:row;margin-left:36px"> <span>#</span> (not be) so careless with the knife.</div> 
                  <div style="display:flex;flex-direction:row"><strong style="margin-right:20px">5</strong> If I <span> # </span> (have) the time, I</div> 
                   <div style="display:flex;flex-direction:row;margin-left:36px"><span>#</span>  (call) you.
</div> 
                    <div style="display:flex;flex-direction:row"><strong style="margin-right:20px">6</strong>  I <span> # </span>(not get) angry if you</div> 
                     <div style="display:flex;flex-direction:row;margin-left:36px"> <span>#</span> (not be) so rude!</div> 
            
          </div>
				`,
        answer: [
          'had driven',
          'would have arrived',
          'had got',
          'would have seen',
          'would have known',
          'had listened',
          `wouldn't have cut`,
          `hadn't been`,
          'had had',
          'would have called',
          `wouldn't have got`,
          `hadn't been`,
        ],
        // answer: ['hall', "head teacher's office", 'stairs', 'playground', 'science lab'],
      },
    ],
  },
  2: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P127-U8.1-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page127/key/E2answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: `<span>Rewrite the two sentences as one. Use the third conditional.</span>`,
        color: 'rgb(144 38 143)',
      },
    ],
    inputSize: 790,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div style="display:flex;flex-direction:column;">
          <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">1</strong> Rewrite the two sentences as one. Use the third conditional.</div>
          <span style="font-style:italic; color:rgb(66,153,210);margin-left:35px">If you’d gone to bed early, you wouldn’t have been tired the
following morning. </span>
            <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">2</strong> Emma didn’t catch the bus. She had to walk to school. </div>
<input/>              <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">3</strong> The tickets were expensive. I didn’t travel by plane.</div>
<input/>                 <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">4</strong>I ate too much. I felt ill.</div>
<input/>                   <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">5</strong> I spent all my money. I was broke.</div>
<input/>                     <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">6</strong> I took a painkiller. My headache went away.</div>
<input/>

          </div>
        `,
        answer: [
          `If Emma had caught the bus, she wouldn't have had to walk to school.`,
          `If the tickets hadn't been expensive, I'd have travelled by plane.`,
          `If I hadn't eaten too much, I wouldn't have felt ill.`,
          `If I hadn't spent all my money, I wouldn't have been broke.`,
          `If I hadn't taken a painkiller, my headache wouldn't have gone away.`,
        ],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P127-U8.2-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page127/key/E1.1answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '1',
        title: ` <span>Rewrite the sentences using participle or infinitive clauses
to replace the relative clauses.</span>`,
        color: 'rgb(144 38 143)',
      },
    ],
    stylesTextInput: {
      textAlign: 'left',
    },
    inputSize: 790,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div style="display:flex;flex-direction:column;">
           <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">1</strong> I saw three men who were arguing about a taxi.</div>
          <span style="font-style:italic; color:rgb(66,153,210);margin-left:35px">I saw three men arguing about a taxi. </span>
            <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">2</strong>She was the only person who finished the course.</div>
<input/>
              <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">3</strong> A suspected burglar, who had been arrested by the police,
has escaped.</div>
<input/>
                <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">4</strong>My uncle bought a pen that was made of gold.</div>
<input/>
                  <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">5</strong> He was the youngest person who was honoured at the
ceremony.</div>
<input/>
                    <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">6</strong> Three men, who were coming out of the restaurant late at
night, saw the robbery.</div>
<input/>

     
          </div>
        `,
        answer: [
          'She was the only person to finish the course.',
          'A suspected burglar, arrested by the police, has escaped.',
          'My uncle bought a pen made of gold.',
          'He was the youngest person honoured at the ceremony.',
          'Three men, coming out of the restaurant late at night, saw the robbery.',
        ],
      },
    ],
  },
};

export default json;
