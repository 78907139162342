import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P74-E1',
    exerciseKey: '/img/FriendsPlus/Page74/Key/E1answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 600,
    maxLength: 50,
    hideBtnFooter: false,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
      marginLeft: -12,
    },
    textareaStyle: { width: 500 },
    titleQuestion: [
      {
        num: '1',
        title: `
           Read the article. What qualities does she think young people should have to set up their own business successfully?
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="display:flex; justify-content: space-between; line-height:9px;" >
              <img style='margin-left:10px' src="/img/FriendsPlus/Page74/E1/1.jpg" ' >
            <div style='margin-left:50px'>
              <h1>Answer:</h1>
              <textarea id='0' rows='2'></textarea>
            </div>
        </div>

        `,
        answer: [`She thinks young people should be confident when they want to do things`],
      },
    ],
  },
  2: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P74-E2',
    exerciseKey: '/img/FriendsPlus/Page74/Key/E2answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 230,
    // maxLength: 30,
    isHiddenCheck: true,
    checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
    },
    textareaStyle: { width: 500 },
    titleQuestion: [
      {
        num: '2',
        title: `
              Read the <span style='color:rgb(245,140,31,1);'>Learn this!</span> box. Underline three examples of use a, b and c in the article.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
                          
        <div style="display:flex; justify-content: space-between; line-height:9px;" >
             <img src="/img/FriendsPlus/Page74/E2/1.jpg" alt=""/>
            <div style='margin:20px'>
              <h1 style='margin-bottom:20px'>Answer:</h1>
              <b>a</b> <div style='margin:-30px 0px 0px 20px'><textarea id='0' rows='3'></textarea></div>
              <b>b</b> <div style='margin:-30px 0px 0px 20px'><textarea id='1' rows='3'></textarea></div>
              <b>c</b> <div style='margin:-30px 0px 0px 20px'><textarea id='2' rows='3'></textarea></div>
            </div>
        </div>
        `,
        answer: [
          'It is the courses of Steer that can make it different from other companies.',
          'It is just a lack of confidence, not any other factor that holds them back.',
          'It was when she was looking for a web design course for herself that the idea for the company Steer came to her.',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P74-E3',
    exerciseKey: '/img/FriendsPlus/Page74/Key/E3answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 950,
    maxLength: 73,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      //borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
    },
    textareaStyle: { width: 700 },
    titleQuestion: [
      {
        num: '3',
        title: `
              Finish each of the following sentences emphasising the underlined information.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          
        <div style="gap :50px; position: relative; line-height:2">
        
          <div><b>1 </b>Dancers in A O Show gave their performance <u>with great devotion.</u></div>
          <div style="color:rgb(0,121,133,1);">It was with great devotion that dancers in A O Show gave their performance.</div>
          <div><b>2 </b>The Vietnamese silk paintings typically depict <u>the countryside and landscapes.</u></div>
          <div style='margin-left:25px'>It # .</div>
          <div><b>3 </b><u>Vincent van Gogh</u> painted Starry Night, not Gaugin.</div>
          <div style='margin-left:25px'>It # .</div>
          <div><b>4 </b>We missed the chance to meet a celebrity <u>because we came so late</u></div>
          <div style='margin-left:25px'>It # .</div>
         <div><b>5 </b><u>The young entrepreneur</u> has mentioned some new design techniques.</div>
          <div style='margin-left:25px'>It # .</div>

        </div>
        `,
        answer: [
          'is the countryside and landscapes that the Vietnamese silk paintings typically depict',
          'was Vincent van Gogh, not Gaugin who painted Starry Night',
          'was because we came so late that we missed the chance to meet a celebrity',
          'is the young entrepreneur that has mentioned some new design techniques',
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P74-E4',
    exerciseKey: '/img/FriendsPlus/Page74/Key/E4answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 800,
    maxLength: 73,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      //borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
    },
    isHiddenCheck: true,
    textareaStyle: { width: 830 },
    titleQuestion: [
      {
        num: '4',
        title: `
            Make cleft sentences from the following cues.        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
      <div style="gap :50px; position: relative">
        
          <div><b>1 </b>It / be / creativity and inspiration / most artists / need</div>
          <div style="color:rgb(0,121,133,1);">It is creativity and inspiration that most artists need.</div>
          <div><b>2 </b>It / be / with/ self-confidence / scientists / make /good inventions</u></div>
          <div>It #</div>
          <div><b>3 </b> It / be / sociability / some jobs / require / not intelligence</div>
          <div style="display:flex;">
            <div>It </div>
            <div style="margin-top:-7.5px; margin-bottom: -24px;"><textarea id='2' rows='2'></textarea></div>
          </div>
          <div><b>4 </b>It / be / Dame Anita Roddick / found / The Body Shop /in 1976</div>
          <div>It #</div>
         <div><b>5 </b> It / be / because / they / have / colourful tones and optimistic images / Dong Ho paintings / be / part of Vietnamese Tet holidays</div>
          <div style="display:flex;">
            <div>It </div>
            <div style="margin-top:-7.5px;"><textarea id='3' rows='2'></textarea></div>
          </div>

      </div>
        `,
        answer: [
          'was with self-confidence that scientists made good inventions',
          'was Dame Anita Roddick who found The Body Shop in 1976',
          'is sociability that some jobs require, not intelligence.or It is sociability, not intelligence that some jobs require',
          'is because they have colourful tones and optimistic images that Dong Ho paintings are part of Vietnamese Tet holidays',
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P74-E7',
    exerciseKey: '/img/FriendsPlus/Page74/Key/E5answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 870,
    maxLength: 80,
    stylesTextInput: {
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '5',
        title: `
             Reread the <span style='color:rgb(245,140,31,1);'>Learn this!</span> box. Identify the mistake in each of the following sentences.
        `,
        color: '#8e258c',
        prefix: { icon: [''], text: 'USE OF ENGLISH' },
      },
    ],

    questions: [
      {
        title: `
          <div>
            <b>1.</b>
              <span> It is sympathy, not pride brings friends together.</span> <br>
            <b>2.</b> 
              <span style="margin-left:10px">It is a leading environmentalist that came to our school club last month.</span> <br>
            <b>3</b> 
              <span style="margin-left:10px"> It was Monday that the winners of the school sports games were awarded.</span> <br>
            <b>4</b> 
              <span style="margin-left:10px"> It was when Jack came back from the war we learnt the truth about his cousins.</span><br>
            <b>5</b> 
              <span style="margin-left:10px"> It is shyness that the teacher wants the children to grow out of it.</span><br>
            <b>6</b> 
              <span style="margin-left:10px">It was Rachel Carson that wrote Silent Spring, not Julia Hill.</span>
          </div>

          <div style="margin-top: 20px;">
            <b>Answers:</b> <br>
            <b>1</b> <span> #</span> <br>
            <b>2</b> <span> #</span> <br>
            <b>3</b> <span> #</span> <br>
            <b>4</b> <span> #</span> <br>
            <b>5</b> <span> #</span> <br>
            <b>6</b> <span> #</span> <br>
          </div>
        `,
        answer: [
          `It is sympathy, not pride that brings friends together`,
          `It was a leading environmentalist that came to our school club last month`,
          `It was on Monday that the winners of the school sports games were awarded`,
          `It was when Jack came back from the war that we learnt the truth about his cousins`,
          `It is shyness that the teacher wants the children to grow out of`,
          `It was Rachel Carson who wrote Silent Spring, not Julia Hill`,
        ],
      },
    ],
  },
  6: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P74-E6',
    // exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    inputSize: 360,
    maxLength: 50,
    hideBtnFooter: true,
    stylesTextInput: {
      textAlign: 'center',
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '6',
        title: `
              <b style='color:rgb(0,147,70);'>SPEAKING</b> Work in pairs. Ask and answer about your favourite high-flyer. The following cues can help you.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="padding-left: 40px;">
        
          <div><b>1 </b>The high-flyer you admire</div>
          <div><b>2 </b>How you got to know about him / her</div>
          <div><b>3 </b>The reason why you like him / her</div>
          <div><b>4 </b>What you think decides his / her success</div>
      </div>

      <div style="padding: 10px;"><b>Answer the questions again using cleft sentences to emphasise some information.</b></div>
      <div style="padding-left: 210px;"><img src="/img/FriendsPlus/Page74/E6/1.jpg" ></div>
        `,
        answer: [
          'had won the lottery',
          'had bought his ticket',
          `hadn't checked the numbers`,
          'had lost his ticket',
          'had taken the lottery company',
          'had lost',
        ],
      },
    ],
    recorder: true,
  },
  7: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P74-E7',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '7',
        title: `
                    <b style='color:rgb(0,147,70);'>SPEAKING</b> 
                                Work in pairs. Discuss the explanations for
                                the situation or event using the past perfect. What
                                had happened?
          <div style="margin-top: 20px; font-weight: 400;">
            <b>1</b>&nbsp<span> The car drove onto the pavement and hit a lamp
              post.</span> <br>
            <b>2</b> &nbsp<span> A cleaner found a wallet under the seats in the
              cinema.</span> <br>
            <b>3</b> &nbsp<span> The boy opened the envelope, read the letter and
              started to dance around.</span> <br>
          </div>
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [],
  },
};

export default json;
