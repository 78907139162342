import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'unit 5',
    id: 'FGF11-A-P65-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page65/Key/E5answerKey.png',
    component: T6,
    inputSize: 100,
    maxLength: 49,
    hideBtnFooter: true,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 23,
    },
    // recorder: true,
    titleQuestion: [
      {
        num: '1',
        title: `
          <span style="color:rgb(90,161,86)">SPEAKING</span>  Work in pairs. Which of these activities do you 
spend a lot of time doing? How often do you do them?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div style=" position: relative;">
        <ul>
         <li>Checking your phone for messages</li>
            <li>Taking photos of yourself</li>
               <li>Browsing social networking sites</li>
                  <li>Playing video games</li>
        </ul>
      
        </div>


        `,
        answer: [`to`, `to`, `for`, `of`, `with`, `with`, `to`, `with`, `about`, `with`, `in`, `to`],
      },
    ],
  },
  2: {
    unit: 'unit 5',
    id: 'FGF11-A-P65-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page65/Key/E2answerKey.png',
    component: T6,

    maxLength: 69,
    // hideBtnFooter:true,
    stylesTextInput: {
      textAlign: 'left',
      fontSize: 25,
    },
    inputSize: 1200,
    textareaStyle: {
      width: 500,
    },
    // recorder: true,
    titleQuestion: [
      {
        num: '2',
        title: `
          Read the messages between a father and his son. Do you 
think the son was addicted to playing video games? Find 
evidence for your opinion.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div style=" position: relative; display:flex">
     <img style="width:600px"  src="img/FriendsPlus/Page65/E2/1.png"/>
        <div style='margin-top:25%; margin-left:20px'>
        <i><u>Answer:</u></i>
        <textarea id='0' rows='4'></textarea>
        </div>
      
        </div>


        `,
        answer: [
          "There is no evidence apart from his father's concern. We do not know how much time he spent playing them.",
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P65-E3',
    exerciseKey: 'img/FriendsPlus/Page65/Key/E3answerKey.jpg',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',

    component: Circle_Write,
    hintBox: [
      {
        src: [
          'addicted',
          'aware',
          'curious',
          'dissatisfied',
          'good',
          'interested',
          'responsible',
          'sensitive',
          'shocked',
          'successful',
          'unhappy',
          'worried',
        ],
        width: 900,
        fontColor: 'rgb(146,39,143)',
        borderColor: 'none',
      },
    ],
    titleQuestion: [
      {
        num: '3',
        title: `
        <div style="margin-bottom: 10px;">Read the <span style='color:rgb(146,39,143)'>Learn this! </span>
        box. Then underline these adjectives in the messages. Which prepositions follow them?</div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 5,
        inputStyle: { width: 100, fontSize: 25, textAlign: 'center' },
        answers: ['to', 'of', 'about', 'with', 'at', 'in', 'for', 'to', 'at', 'in', 'with', 'about'],
        initialValue: [],
      },

      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: "50%",
          borderColor: 'transparent',
          fontSize: 19,
        },
        selectWordStyle: {
          // padding: 5,
          borderBottom: 'solid 2px',
          borderColor: '#00aeef',
        },
        limitSelect: 150,
        listWords: [
          `Dad, I’m interested in playing video games, you know. However, you are always unhappy with that. You get curious about the types of game I play. You’re afraid that I’ll be addicted to it. You’re worried about the amount of time I spend online. Although I’m a teenager, I’m aware of what I should do. I’m responsible enough for my studying. You see, I’m always successful in maths and English. I love you, Dad.`, //0
          `Dear my boy, I feel dissatisfied with your staying up late playing video games. I’m sometimes shocked at your words when I remind you to do homework. I know you’re good at maths and English, but I think you need to spend more time playing sports. And don’t be too sensitive to my advice. How about a game of badminton with me next Saturday evening? Lots of love.`, //1
        ],
        answers: ['0-4', '0-26', '0-36', '0-62', '0-70', '0-98', '0-112', '0-130', '1-10', '1-32', '1-60', '1-100'],
        initialValue: [],
      },
      Layout: `
      <hintbox id='0'></hintbox>
        <div style='position: relative;line-height:25px'>
          <img style='height:21cm;width:32cm' src='img/FriendsPlus/Page65/E3/1.jpg' />
          
          <div style=" position: absolute; top: 135px; left: 775px; width:105mm; "><input id='0'  type='Circle' /></div>
          <div style=" position: absolute; top: 485px; left: 690px; width:105mm; "><input id='1'  type='Circle' /></div>
          <div style=" position: absolute; top: 300px; left: 50px; width:15cm; display:flex;line-height:50px ">
            <div>
              addicted <input id='0'><br>
              aware <input id='1'><br>
              curious<input id='2'><br>
              dissatisfied <input id='3'><br>
              good<input id='4'><br>
              interested<input id='5'><br>
            </div>
            <div style='margin-left:50px'>
              responsible <input id='6'><br>
              sensitive<input id='7'><br>
              shocked<input id='8'><br>
              successful <input id='9'><br>
              unhappy<input id='10'><br>
              worried<input id='11'><br>
            </div>
          </div>
          
        
      `,
    },
  },

  7: {
    unit: 'Ambition',
    id: 'FG10-A-P65-E7',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page65/E1/Key/answerKey.png',
    component: T6,
    inputSize: 530,
    maxLength: 49,
    hintBox: [
      {
        src: ['As I see it, the job of model builder would be quite repetitive.'],

        borderColor: 'rgb(182, 179, 217)',
        arrow: true,
        position: 2,
        width: 525,
      },
      {
        src: ['I don’t agree. I think …'],
        borderColor: 'rgb(182, 179, 217)',
        arrow: true,
        position: 1,
        width: 300,
      },
    ],
    //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '7',
        title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. 
          Decide which of the two jobs looks a) more fun and b) more difficult. 
          Explain your decision. Use the words below to help you, and your own ideas.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div style="margin-left:100px">
          <img src="img/FriendsPlus/Page65/E1/1.jpg" style="max-width: 50%; margin-left:200px" />
          <textarea id='0' row='1' ></textarea>
        </div>
        `,
        answer: [
          `There is no evidence apart from his father's concern. We do not know how much time he spent playing them.`,
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 5',
    id: 'FGF11-A-P65-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page65/Key/E4answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '4',
        title: `
       Circle the correct prepositions to go with these adjectives. Use a dictionary to help you. 
			  `,
        color: '#860052',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `for / to`, //1
          `about / for`, //1
          `of / with`, //1
          `at / to `,
          `to / with`, //1
          `in / with`,
        ],
        answers: ['0-4', '1-0', '2-4', '3-4', '4-4', '5-4'],
        initialValue: [],
      },
      Layout: `
      <div style="display:flex" >

          <div>
 						<div>
              <b>1</b>&ensp;
		      harmful&ensp;<span><input id='0' type='Circle'/></span>
            </div>

            	<div>
              <b>2</b>&ensp;
		      angry&ensp;<span><input id='1' type='Circle'/></span>
            </div>
            
            <div>
              <b>3</b>&ensp;
		      obsessed&ensp;<span><input id='2' type='Circle'/></span>
            </div>

            
          </div>

          
          <div style="margin-left:150px">
 						<div>
              <b>4</b>&ensp;
		      similar&ensp;<span><input id='3' type='Circle'/></span>
            </div>

            	<div>
              <b>5</b>&ensp;
		      pleased&ensp;<span><input id='4' type='Circle'/></span>
            </div>
            
            <div>
              <b>6</b>&ensp;
		      familiar&ensp;<span><input id='5' type='Circle'/></span>
            </div>

          </div>
      
    </div>


        
      `,
    },
  },
  5: {
    unit: 'unit 5',
    id: 'FGF11-A-P65-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page65/Key/E5answerKey.png',
    component: T6,
    inputSize: 100,
    maxLength: 49,
    isHiddenCheck: true,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 23,
    },
    // recorder: true,
    titleQuestion: [
      {
        num: '5',
        title: `
          <span style="color:rgb(146, 39, 144)">USE OF ENGLISH</span> Complete the text with the correct prepositions. All of the adjectives are in exercises 2–4.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div style=" position: relative; background:rgb(214,239,244);border-radius: 80px; max-width:70%; margin:auto; ">
          <img src="img/FriendsPlus/Page65/E1/2.jpg" style="width:100% margin:auto;border-radius: 5px ;padding-top:10px; padding-bottom:10px " />
              <div style="width: 100%; padding:20px; ">
                  A teenager in the UK, Danny Bowman, became addicted 
                  <sup>1</sup> # ‘selfies’ and spent up to ten hours a day taking 
                  around 200 photos of himself with his phone. This was a 
                  true case of addiction: it was harmful <sup>2</sup> # his education 
                  and his friendships and responsible <sup>3</sup> # several health 
                  problems. Experts are aware <sup>4</sup> # the condition and say it 
                  is becoming more and more common. Danny first posted 
                  selfies on Facebook when he was fifteen and soon became 
                  obsessed <sup>5</sup> # reading his friends’ comments. Although 
                  he was pleased <sup>6</sup> # many of the things his friends wrote, 
                  he was also very sensitive <sup>7</sup> # criticism. Before long, he 
                  was feeling very dissatisfied <sup>8</sup> # his appearance. He took 
                  more and more photos of himself. His parents became 
                  very worried  <sup>9</sup> # his behaviour. Because they were both 
                  mental health nurses, they were familiar <sup>10</sup> # addictions. 
                  They knew that Danny needed help. Now, after months 
                  of hospital treatment, Danny has been successful <sup>11</sup> #
                  beating his addiction. But it’s a growing problem, with 
                  many other cases similar <sup>12</sup> # Danny’s.
                </div>
        </div>


        `,
        answer: [`to`, `to`, `for`, `of`, `with`, `with`, `to`, `with`, `about`, `with`, `in`, `to`],
      },
    ],
  },
  6: {
    unit: 'unit 5',
    id: 'FGF11-A-P65-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page65/E1/Key/answerKey.png',
    component: T6,
    inputSize: 530,
    maxLength: 49,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '6',
        title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> Work in pairs. Tell your partner about something that you are (or have been)
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div>
          <div><b>1.</b> successful in. </div>
          <div><b>2.</b> satisfied with. </div>
          <div><b>3.</b> good at. </div>
          <div><b>4.</b> angry about. </div>
        </div>
          <img src="img/FriendsPlus/Page65/E6/1.png" style="" />
        `,
        answer: [``],
      },
    ],
  },
};

export default json;
