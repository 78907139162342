import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P36-E1',
    audio: '',
    video: '',
    component: T6,
    // recorder: true,
    maxLength: 16,
    exerciseKey: 'img/FriendsPlus/Page36/Key/E1answerKey.png',
    question: [],
    // recorder: true,
    textAlign: 'center',
    titleQuestion: [
      {
        num: '1',
        title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Describe the photo. How is the footballer feeling?
What has happened, do you think? `,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    hintBox: [
      {
        src: [
          '<b>Adventure activities</b>',
          'art club',
          'astronomy club',
          'baking club',
          'computer club',
          'film club',
          'fitness club',
          'photography club',
          'school choir',
          'science club',
        ],
        width: 1100,
        fontColor: '#2887c6',
        borderColor: 'none',
      },
    ],
    // maxLength: 14,
    isHiddenCheck: true,
    textareaStyle: { width: 450 },
    inputSize: 450,
    // textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
           <div style='display:flex'>
            <img style='height:13cm'  src="img/FriendsPlus/Page36/E1/1.jpg" />

            <u style='font-style:italic;font-weight:bold'>Answers:</u>
            <div>
              <textarea id='0' rows='3'></textarea>
            </div>

           </div>
          `,
        answer: [
          'The footballer is lying on the football pitch. She is in pain and has probably hurt her ankle in a tackle.',
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P36-E2',
    exerciseKey: 'img/FriendsPlus/Page36/Key/E2answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `Match 1–14 in the photo with parts of the body from the list below. Check the meaning of all the words in the list.`,
        color: '#8e258c',
        prefix: { icon: [''], text: 'VOCABULARY' },
      },
    ],
    // maxLength: 9,
    hintBox: [
      {
        src: [
          '<b>Parts of the body</b>',
          'ankle',
          'blood',
          'bottom',
          'brain',
          'calf',
          'cheek',
          'chin',
          'elbow',
          'eyebrow',
          'eyelid',
          'forehead',
          'heart',
          'heel',
          'hip',
          'intestine',
          'jaw',
          'kidney',
          'knee',
          'lung',
          'muscle',
          'nail neck',
          'rib',
          'scalp',
          'shin',
          'shoulder',
          'skin',
          'skull',
          'spine',
          'stomach',
          'thigh',
          'throat',
          'thumb',
          'toe',
          'waist',
          'wrist',
        ],
        width: 900,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: T6,
    // isHiddenCheck: true,
    textareaStyle: { width: 640 },
    inputSize: 150,
    textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
          <hintbox id='0'></hintbox>

          <div style='display:flex'>
            <img style='height:15cm'  src="img/FriendsPlus/Page36/E2/1.jpg" />
              <div style='margin-left:20px'>

                <b>1</b>:&ensp;#<br>
                <b>2</b>:&ensp;#<br>
                <b>3</b>:&ensp;#<br>
                <b>4</b>:&ensp;#<br>
                <b>5</b>:&ensp;#<br>
                <b>6</b>:&ensp;#<br>
                <b>7</b>:&ensp;#<br>
                <b>8</b>:&ensp;#<br>
                <b>9</b>:&ensp;#<br>
                <b>10</b>:&ensp;#<br>
                <b>11</b>:&ensp;#<br>
                <b>12</b>:&ensp;#<br>
                <b>13</b>:&ensp;#<br>
                <b>14</b>:&ensp;#<br>

              </div>
          </div>

          `,
        answer: [
          'nail',
          'wrist',
          'calf',
          'elbow',
          'shoulder',
          'neck',
          'cheek',
          'forehead',
          'ankle',
          'shin',
          'knee',
          'thigh',
          'chin',
          'thumb',
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P36-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page36/Key/E3answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Work in pairs. Which parts of the body in the list in exercise 2`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    // maxLength: 19,
    hintBox: [
      {
        src: [
          '<b>Parts of the body</b>',
          'ankle',
          'blood',
          'bottom',
          'brain',
          'calf',
          'cheek',
          'chin',
          'elbow',
          'eyebrow',
          'eyelid',
          'forehead',
          'heart',
          'heel',
          'hip',
          'intestine',
          'jaw',
          'kidney',
          'knee',
          'lung',
          'muscle',
          'nail neck',
          'rib',
          'scalp',
          'shin',
          'shoulder',
          'skin',
          'skull',
          'spine',
          'stomach',
          'thigh',
          'throat',
          'thumb',
          'toe',
          'waist',
          'wrist',
        ],
        width: 900,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: T6,
    // isHiddenCheck: true,
    textareaStyle: { width: 640 },
    inputSize: 720,
    // textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
          <hintbox id='0'></hintbox>
          <div style=''>
            <b>1</b>&ensp;are inside your body?<br><textarea id='2' rows='2'></textarea>
            <div>
              <b>2</b>&ensp;are part of your head or neck?<br><textarea id='3' rows='2'></textarea>
            </div>
            <b>3</b>&ensp;are part of your arm or hand?<br>#
            <div>
              <b>4</b>&ensp;are part of your leg or foot?<br>#
            </div>
          </div>

          `,
        answer: [
          'blood, elbow, muscle, nail, skin, thumb, wrist',
          'ankle, blood, calf, heel, knee, muscle, nail, shin, skin, thigh, toe',
          'blood, brain, heart, intestine, kidney, lung, muscle, rib, skull, spine, stomach, throat',
          'blood, brain, cheek, chin, eyebrow, eyelid, forehead, jaw, lip, muscle, scalp, skin, skull, spine, throat',
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P36-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page36/Key/E4answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Work in pairs or small groups. Do the body quiz on page 37. Check your answers with your teacher.
`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],

    totalInput: 7,
    component: DesignUnderLine,
    // isAllowSubmit: false,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page36/Images/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page36/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page36/E4/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page36/E4/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/9.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E4/10.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page36/E4/11.jpg' }],
      [
        { url: 'img/FriendsPlus/Page36/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page36/E4/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/16.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page36/E4/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/19.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E4/20.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/21.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E4/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E4/23.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/24.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page36/E4/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E4/26.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/27.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page36/E4/28.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E4/29.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/30.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page36/E4/31.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E4/32.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/33.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E4/34.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E4/35.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/36.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page36/E4/37.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/38.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page36/E4/39.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/40.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page36/E4/41.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/42.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E4/43.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page36/E4/44.jpg' }],
      [
        { url: 'img/FriendsPlus/Page36/E4/45.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/46.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page36/E4/47.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E4/48.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/49.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E4/50.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/51.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page36/E4/52.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/53.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E4/54.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/55.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page36/E4/56.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E4/57.jpg' },
        { url: 'img/FriendsPlus/Page36/E4/58.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page36/E4/59.jpg' },
      ],
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P36-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page36/Key/E5answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `Read paragraph A of the text. Which of the ideas in
exercise 3 are mentioned in the reading?
`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],

    hintBox: [
      {
        src: [
          '<b>Compound nouns</b>',
          'flood lights',
          'football pitch',
          'main road',
          'mountain range',
          'safety net',
          'sea shore',
          'swimming pool',
          'tennis court',
          'tennis player',
          'tower block',
        ],
        width: 800,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: T6v2,
    // maxLength: 19,
    // isHiddenCheck: true,
    textareaStyle: { width: 530, fontSize: 22 },
    inputSize: 530,
    stylesTextInput: { fontSize: 22 },
    // textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
      <div style='display:flex'>
			    <div style='width:15cm; background: rgb(244 228 202);padding:3mm 5mm'>
            <b style='border-radius:5px; background:white; border:2px solid rgb(0 188 212); padding:2px 5px'>A</b>&ensp;Home (2009) is a 90-minute documentary about
              human threats to the Earth’s ecological balance. It is made
              up of aerial shots of various places across the globe. Aiming
              to raise people’s awareness of the alarming environmental
              destruction, this French movie shows human huge impacts
              on the Earth and the consequences of climate change.
              Viewers of the documentary particularly enjoy the footage
              of human origins, the Earth’s water cycle, population growth,
              the melting Arctic ice cap and the coral reef.

          </div>
          <div style='width:15cm; background: rgb(244 228 202);padding:3mm 5mm'>
            <b style='border-radius:5px; background:white; border:2px solid rgb(0 188 212); padding:2px 5px'>B</b>&ensp;The film was completed in eighteen months and the film
              crew did their filming of 488-hour raw footage by flying in a
              helicopter over various regions in more than fifty countries.
              At a press conference in Paris on March 9, 2009, its director,
              Yann Arthus-Bertrand, announced that its released versions
              would include theatre, television, DVD and the internet.
              About two months later, the film’s worldwide release date
              was confirmed to be June 5, 2009. Despite its availability in
              various forms, Home (2009) had its world festival premiere
              at the Dawn Breakers International Film Festival in 2012.
          </div>

      </div>

      <div style='display:flex'>

          <div style='width:15cm;height:11cm; background: rgb(244 228 202);padding:3mm 5mm'>
                <b style='border-radius:5px; background:white; border:2px solid rgb(0 188 212); padding:2px 5px'>C</b>&ensp;Home (2009) is available for free access in 14
                  languages. Director Yann Arthus-Bertrand has insisted
                  that the film have no copyright. He has also stressed the
                  educational benefits of the film over its financial gains. With
                  this aim in mind, the film producer made the film available
                  to as many people as possible. The film can be downloaded
                  to your smart TV or any kind of digital device, with the
                  proceeds all going to the GoodPlanet Foundation.

              </div>
              <div style='width:20cm;margin-left:5px;'>
                488:<input id='0' width='400px'></input><br>
                2009:<input id='1' width='350px'></input><br>
                 <div style='display:flex'>
                  14:#<br>

                 </div>
                 <div style='display:flex'>
                  50:<textarea id='3'></textarea><br>

                 </div>
                 <div style='display:flex'>
                  2012:<textarea id='4'></textarea><br>

                 </div>
              </div>
      </div>

          `,
        answer: [
          'the length (in hours) of the raw footage',
          'the year when the film was made',
          'the number of languages that the film is available in',
          'the number of countries/ nations where the filming is done',
          'the year when the film had its world festival premiere (at the Dawn Breakers International Film)',
        ],
      },
    ],
  },

  6: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P36-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page36/Key/E6answerKey.png',

    titleQuestion: [
      {
        num: '6',
        title: `Answer the questions in your own words.`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    hintBox: [
      {
        src: [
          '<b>Compound nouns</b>',
          'flood lights',
          'football pitch',
          'main road',
          'mountain range',
          'safety net',
          'sea shore',
          'swimming pool',
          'tennis court',
          'tennis player',
          'tower block',
        ],
        width: 800,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: T6,
    maxLength: 1,
    // isHiddenCheck: true,
    textareaStyle: { width: 540 },
    inputSize: 40,
    textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
     <div>
        <b>1</b>&ensp;What images can be seen in the film?<br>
        <textarea id='0' rows='5'></textarea>
     </div>
     <div>
        <b>2</b>&ensp;Where can Home (2009) be seen?<br>
        <textarea id='1' rows='3'></textarea>
     </div>
     <div>
        <b>3</b>&ensp;Why doesn’t Home (2009) have any copyright?<br>
        <textarea id='2' rows='3'></textarea>
     </div>
          `,
        answer: [
          'The film depicts pictures of the starts of humans, the cycle of water, the increasing number of people on Earth, the melting ice at the poles and the coral reef.',
          'Viewers can see the film at home (on television, on the internet) or at the cinema.',
          'The director and producer of the film emphasise its educational benefits and purposes.',
        ],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P36-E7',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page36/Key/E7answerKey.png',
    titleQuestion: [
      {
        num: '7',
        title: `<b style="color:rgb(10 127 64)">SPEAKING</b>  Work in groups. Discuss the question and then
share with the class.`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    hintBox: [
      {
        src: [
          '<b>Compound nouns</b>',
          'flood lights',
          'football pitch',
          'main road',
          'mountain range',
          'safety net',
          'sea shore',
          'swimming pool',
          'tennis court',
          'tennis player',
          'tower block',
        ],
        width: 800,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: T6,
    maxLength: 1,
    // isHiddenCheck: true,
    textareaStyle: { width: 540 },
    inputSize: 40,
    textAlign: 'center',
    recorder: true,
    //hideBtnFooter: true,
    questionImage: [],
    questions: [
      {
        title: `
      <div style='font-style:italic;width:20cm'>
        Which do you think is more effective in raising people’s
awareness of protecting the environment: a film or a book?
Justify your answer.
      </div>
          `,
        answer: [''],
      },
    ],
  },
  8: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P36-E8',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page24/Key/E4answerKey.png',
    question: [],
    titleQuestion: [
      {
        num: '8',
        title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Work in groups. Which facility from exercise 7
would you like most for your school? Why? Can the whole
class agree on one choice?`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [[{ url: 'img/FriendsPlus/Page36/E8/1.pNg' }]],
  },
};

export default json;
