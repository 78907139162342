import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P89-E1',
    exerciseKey: 'img/FriendsPlus/Page89/E1/Key/E1answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    audio: '/Audios/3.07-audio.mp3',
    component: T6v2,
    inputSize: 300,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '1',
        title: `
        <headphone1 name='3.07' src='Audios/3.07.mp3'></headphone1> <span style="margin-bottom: 10px;font-size: 22px; color: rgb(146,39,143)">VOCABULARY&ensp;</span>   
      Work in pairs. Listen to the musical excerpts. Match the excerpts (1–9) with the musical genres below.
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>
          <div style='font-size:24px; position: relative'>
              <div style="color:rgb(146,39,143); margin-bottom:30px  ">
                <b>Musical genres</b>
                &ensp;blues
                &ensp;classical
                &ensp;country and western 
                &ensp;folk
                &ensp;heavy metal
                &ensp;hip hop / rap
                &ensp;jazz
                &ensp;pop / rock
                &ensp;techno
              </div>
							
              <b>ANSWER:</b>
              <div style=''>
                <div style='color: rgb(152,198,204)'>1  heavy metal</div>
                <div style='display:flex;'>
                  <div style='margin-right:40px'>
                    <div><b>&ensp;2 - #</b>&ensp;</div>
                    <div><b>&ensp;3 - #</b>&ensp;</div>
                    <div><b>&ensp;4 - #</b>&ensp;</div>
                    <div><b>&ensp;5 - #</b>&ensp;</div>
                  </div>
                  <div>
                    <div><b>&ensp;6 - #</b>&ensp;</div>
                    <div><b>&ensp;7 - #</b>&ensp;</div>
                    <div><b>&ensp;8 - #</b>&ensp;</div>
                    <div><b>&ensp;9 - #</b>&ensp;</div>
                  </div>
                </div>
              </div>
              </div>
            </div>
              
          </div>
          
        `,
        answer: ['pop / rock', 'folk', 'jazz', 'blues', 'hip hop / rap', 'techno', 'country and western', 'classical'],
      },
    ],
  },
  2: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P89-E2',
    exerciseKey: 'img/FriendsPlus/Page89/E2/Key/E2answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/3.07.mp3',
    component: T6v2,
    inputSize: 600,
    // maxLength: 20,
    textAlign: 'left',
    textareaStyle: {
      // background: 'none',
      width: 500,
      fontSize: 22,
      height: 200,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '2',
        title: `
        Read the article. What is unusual about John Cage’s most famous composition?
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 900px; position: relative; display:flex'>
          <img style="width:19cm; height:21cm" src='img/FriendsPlus/Page89/E2/1.jpg' />
          <div style='font-size:22px; margin-left:20px'>
              <div><b>ANSWER:</b></div>
              <div><textarea id='0' row='3'></div>
              </div>
          </div>
              
        </div>
          
        `,
        answer: [
          'The performer walks on stage and then nothing happens. The audience just listen to the sounds around them. ',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P89-E3',
    exerciseKey: 'img/FriendsPlus/Page89/E3/Key/E3answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/3.07.mp3',
    component: T6,
    inputSize: 200,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      borderBottom: 'none',
      fontSize: 25,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '3',
        title: `
        Complete the table with indefinite pronouns from the article.
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='position: relative;'>
        <img style="width:23cm; height:8cm" src='img/FriendsPlus/Page89/E3/1.jpg' />
        <img style="width:15cm; height:23cm" src='img/FriendsPlus/Page89/E2/1.jpg' />
          <div style='position:absolute; top:78px ;left:13px'><input id='0'></div>
          <div style='position:absolute; top:78px ;left:224px'><input id='1'></div>
          <div style='position:absolute; top:78px ;left:646px'><input id='2'></div>

          <div style='position:absolute; top:131px ;left:646px'><input id='3'></div>
          
          <div style='position:absolute; top:186px ;left:13px'><input id='4'></div>
          <div style='position:absolute; top:186px ;left:434px'><input id='5'></div>
          
          <div style='position:absolute; top:242px ;left:13px'><input id='6'></div>
          <div style='position:absolute; top:242px ;left:224px'><input id='7'></div>
          <div style='position:absolute; top:242px ;left:434px'><input id='8'></div>

         
              
        </div>
          
        `,
        answer: [
          'no one',
          'anyone',
          'everyone',
          'everybody',
          'nowhere',
          'somewhere',
          'nothing',
          'anything',
          'something',
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P89-E4',
    exerciseKey: 'img/FriendsPlus/Page89/E4/Key/E4answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 200,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      borderBottom: 'none',
      fontSize: 20,
      // height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '4',
        title: `
        <div style='font-size:23px'>
        Read the <span style='color: rgb(146,39,143)'>Learn this!</span> box. Complete it with the words 
        below. Use the article to help you.
        </div>
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
    <div style='position:relative'>
      <div style="color:rgb(146,39,143); margin-bottom:30px  ">
                &ensp;affirmative
                &ensp;negative
                &ensp;plural
                &ensp;questions
                &ensp;singular
              </div>
      <div style='display:flex; margin-bottom:30px'>
        <img style='width:13cm; height: 19cm; ' src='img/FriendsPlus/Page89/E2/1.jpg' />
        <img style='width:15cm; height: 10cm' src='img/FriendsPlus/Page89/E4/1.jpg' />
      </div>

        <div style='position:absolute; top:149px ;left:525px'><input id='0'></div>
        <div style='position:absolute; top:178px ;left:636px'><input id='1'></div>
        <div style='position:absolute; top:209px ;left:531px'><input id='2'></div>
        <div style='position:absolute; top:245px ;left:759px'><input id='3'></div>
        <div style='position:absolute; top:340px ;left:605px'><input id='4'></div>

      
    </div>
          
        `,
        answer: ['affirmative', 'negative', 'questions', 'singular', 'plural'],
      },
    ],
  },
  5: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P89-E5',
    exerciseKey: 'img/FriendsPlus/Page89/E5/Key/E5answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 170,
    maxLength: 9,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      // fontSize: 19,
      // height: 27,
      // textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '5',
        title: `
        <div >
        Read the <span style='color: rgb(146,39,143)'>Look out!</span> box. Complete the dialogue with the 
        correct indefinite pronouns.
        </div>
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
    <div style='line-height:2'>                                                             
        <img style='width:13cm ' src='img/FriendsPlus/Page89/E5/1.jpg' />
      <div>
      
      <div style='display:flex'><b>Amelia</b>&ensp;&ensp;<span>Are you doing <sup>1</sup> # this weekend?</span></div>
      <div style='display:flex'><b>Jake</b>&ensp;&ensp;&ensp; No, I’m not</div>

      <div style='display:flex'> <b>Amelia</b>&ensp;&ensp;<span>Would you like to do <sup>2</sup> # together, then? How about the cinema?</span></div>
      <div style='display:flex'> <b>Jake</b>&ensp;&ensp;&ensp; <span>Not the cinema. There’s <sup>3</sup> # I want to see. Let’s go <sup>4</sup> # different for a change. We could see some live music</span></div>

      <div style='display:flex'> <b>Amelia</b>&ensp;&ensp;<span>Is there <sup>5</sup> # in town that has live music?</span></div>
      <div style='display:flex'> <b>Jake</b>&ensp;&ensp;&ensp; Yes – the Oxygen Arena. I can find the programme online. Wait a moment …</div>

      <div style='display:flex'> <b>Amelia</b>&ensp;&ensp;<span>Is <sup>6</sup> # good playing?</span></div>
      <div style='display:flex'> <b>Jake</b>&ensp;&ensp;&ensp;  <span> There’s <sup>7</sup> # well-known, I’m afraid. But this band might be OK – Purple Dawn. They play hip hop.</span></div>

      <div> <b>Amelia</b>&ensp;&ensp;<span>  I’ll ask James. He knows <sup>8</sup> # about hip hop</span></div>
      
      </div>

      
    </div>
          
        `,
        answer: [
          'anything',
          'something',
          'nothing',
          'somewhere',
          'anywhere',
          'anyone / anybody',
          'no one / nobody',
          'everything',
        ],
      },
    ],
  },
  6: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P89-E6',
    // exerciseKey: 'img/FriendsPlus/Page85/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    hideBtnFooter: true,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '6',
        title: `
        <span style='color:rgb(2, 146, 97);'>SPEAKING</span>
      Work in pairs. Discuss what kinds of musical genres you enjoy listening to.
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
          
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
