import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P12-E1',
		audio: '',
		video: '',
		component: T6,
		// recorder: true,
		inputSize: 420,
		stylesTextInput: {
			textAlign: 'center',
		},
		exerciseKey: 'img/FriendsPlus/Page12/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. 
          Can you answer this famous riddle from Sophocles’ play <i>Oedipus the King</i>? 
          Explain your answer.
          `,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
        <div style='margin-top: 20px;'>
          ‘What creature walks on four legs in the morning, 
          two legs in the afternoon, and three in the evening?’
        </div>
				<div style="margin-top: 20px;"><span style="font-style: italic; text-decoration: underline;">Answer</span>: #</div>
        `,
				answer: [`a human being`],
			},
		],
	},
	2: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P12-E2',
		audio: 'Audios/1.07-audio.mp3',
		video: '',
		component: T6,
		inputSize: 220,
		maxLength: 1,
		isHiddenCheck: true,
		stylesTextInput: {
			textAlign: 'center',
			width: '40px',
			height: '40px',
			border: '1px solid gray',
		},
		exerciseKey: 'img/FriendsPlus/Page12/E2/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `
          <span style='margin-right: 10px;'><headphone1 name="1.07" src="Audios/1.07-tieude.mp3"></headphone1></span>
          <span style="margin-right: 10px; color: rgb(146, 39, 144);">VOCABULARY</span>  
          Number the stages of life in the order that people reach them. 
          Then listen and check.
          `,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
        <div style="margin-top: 20px; max-width: 900px; color: rgb(146, 39, 144); display: flex; gap: 20px 30px; flex-wrap: wrap;">
          <span style="font-weight: bold;">Stages of life</span>
          <span># be an adult</span><span># be a centenarian</span><span># be an infant</span><span># be a toddler</span>
          <span># be a young child</span><span># be elderly</span><span># be in your teens</span><span># be in your twenties</span><span># be middle-aged</span>           
        </div>
        `,
				answer: ['5', '9', '1', '2', '3', '8', '4', '6', '7'],
			},
		],
	},
	3: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P12-E3',
		audio: '',
		video: '',
		component: T6,
		inputSize: 180,
		maxLength: 20,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page12/E3/Key/answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: `
          Match some of the phrases in exercise 2 with the pictures 
          below of the woman at different stages of her life.
          `,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style='display: flex;'>
					<div><img src='img/FriendsPlus/Page12/E3/1.jpg' width='100%';/></div>
				</div>
        <div style="margin-top: 20px; max-width: 900px; color: rgb(146, 39, 144); display: flex; gap: 5px 30px; flex-wrap: wrap;">
          <span style="font-weight: bold;">Stages of life</span>
          <span>be an adult</span><span>be a centenarian</span><span>be an infant</span><span>be a toddler</span>
          <span>be a young child</span><span>be elderly</span><span>be in your teens</span><span>be in your twenties</span><span>be middle-aged</span>           
        </div>
        <div style="margin-top: 20px;" >
					<div style="display: flex; gap: 7px 30px; flex-wrap: wrap;">
						<div style="display: flex; flex-direction: column; gap: 7px;">
							<div style="color: rgb(10, 111, 143);">In picture A, she’s an infant.</div>
							<div>In picture B, she’s #.</div>
							<div>In picture C, she’s #.</div>
							<div>In picture D, she’s #.</div>
						</div>
						<div style="display: flex; flex-direction: column; gap: 7px;">
							<div>In picture E, she’s #.</div>
							<div>In picture F, she’s #.</div>	
							<div>In picture G, she’s #.</div>
							<div>In picture H, she’s #.</div>
						</div>
					</div>
        </div>
        `,
				answer: [
					'a toddler',
					'a young child',
					'a teenager',
					'in her twenties',
					'middle-aged',
					'elderly',
					'a centenarian',
				],
			},
		],
	},
	4: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P12-E4',
		audio: '',
		video: '',
		component: T6,
		checkDuplicated: true,
		textAlign: 'center',
		inputSize: 400,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page12/E4/Key/answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `
          <span style="margin-right: 10px; color: rgb(146, 39, 144)">VOCABULARY</span>
          Check the meaning of the life events below. 
          At what age are they most likely to happen, do you think? 
          Put them in groups A–E. Compare your answers with your partner’s. 
          Do you agree?
          `,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
        <div style="max-width: 900px; color: rgb(146, 39, 144); display: flex; gap: 5px 30px; flex-wrap: wrap;">
          <span style="font-weight: bold;">Life events</span>
          <span>be born</span><span>be brought up (by)</span><span>become a grandparent</span>
          <span>buy a house or flat</span><span>emigrate</span><span>fall in love</span>
          <span>get divorced</span><span>get engaged</span><span>get married</span>
          <span>get your first job</span><span>go to university</span><span>grow up</span>
          <span>have a change of career</span><span>inherit (money, a house, etc.)</span><span>learn to drive</span>
          <span>leave home</span><span>leave school</span><span>move (house)</span>
          <span>pass away</span><span>retire</span><span>settle down</span>
          <span>split up</span><span>start a business</span><span>start a family</span>
          <span>start school</span>
        </div>
				<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 20px;">
					<div style="display: flex; gap: 10px;">
						<div style="width: 220px;">
							<span style="font-weight: bold; margin-right: 10px;">A</span>
							<span>Before you are 20</span>
						</div>
						<div>
							<div>#, #</div>
							<div>#, #</div>
							<div>#, #</div>
							<div>#, #</div>
						</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 220px;">
							<span style="font-weight: bold; margin-right: 10px;">B</span>
							<span>From 20 to 40</span>
						</div>
						<div>
							<div>#, #</div>
							<div>#, #</div>
							<div>#, #</div>
							<div>#</div>
						</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 220px;">
							<span style="font-weight: bold; margin-right: 10px;">C</span>
							<span>From 40 to 60</span>
						</div>
						<div>
							<div>#, #</div>
							<div>#, #</div>
						</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 220px;">
							<span style="font-weight: bold; margin-right: 10px;">D</span>
							<span>Over 60</span>
						</div>
						<div>
							<div>#, #</div>
						</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<div style="width: 220px;">
							<span style="font-weight: bold; margin-right: 10px;">E</span>
							<span>At any age</span>
						</div>
						<div>
							<div>#, #</div>
							<div>#, #</div>
						</div>
					</div>

				</div>
        `,
				answer: [
					'be born / be brought up (by) / go to university / grow up / learn to drive / leave home / leave school / start school',
					'be born / be brought up (by) / go to university / grow up / learn to drive / leave home / leave school / start school',
					'be born / be brought up (by) / go to university / grow up / learn to drive / leave home / leave school / start school',
					'be born / be brought up (by) / go to university / grow up / learn to drive / leave home / leave school / start school',
					'be born / be brought up (by) / go to university / grow up / learn to drive / leave home / leave school / start school',
					'be born / be brought up (by) / go to university / grow up / learn to drive / leave home / leave school / start school',
					'be born / be brought up (by) / go to university / grow up / learn to drive / leave home / leave school / start school',
					'be born / be brought up (by) / go to university / grow up / learn to drive / leave home / leave school / start school',
					'buy a house or flat / get engaged / get married / get your first job / settle down / split up / start a family',
					'buy a house or flat / get engaged / get married / get your first job / settle down / split up / start a family',
					'buy a house or flat / get engaged / get married / get your first job / settle down / split up / start a family',
					'buy a house or flat / get engaged / get married / get your first job / settle down / split up / start a family',
					'buy a house or flat / get engaged / get married / get your first job / settle down / split up / start a family',
					'buy a house or flat / get engaged / get married / get your first job / settle down / split up / start a family',
					'buy a house or flat / get engaged / get married / get your first job / settle down / split up / start a family',
					'get divorced / have a change of career / inherit (money, a house, etc.) / start a business',
					'get divorced / have a change of career / inherit (money, a house, etc.) / start a business',
					'get divorced / have a change of career / inherit (money, a house, etc.) / start a business',
					'get divorced / have a change of career / inherit (money, a house, etc.) / start a business',
					'become a grandparent / retire',
					'become a grandparent / retire',
					'emigrate / fall in love / move house / pass away',
					'emigrate / fall in love / move house / pass away',
					'emigrate / fall in love / move house / pass away',
					'emigrate / fall in love / move house / pass away',
				],
			},
		],
	},
}

export default json
