import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Grammar Builder and Referenc',
    id: 'FGF11-GBAR-P114-I3-E1',
    exerciseKey: 'img/FriendsPlus/Page114/E1/Key/E1answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.12.mp3',

    component: Circle_Write,

    titleQuestion: [
      {
        num: '1',
        title: `
        <div style="margin-bottom: 10px;">Find eight more mistakes with articles in this paragraph. </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        // maxLength: 5,
        inputStyle: { width: 180, fontSize: 23, textAlign: 'center' },
        answers: ['The restaurant', 'the water', 'the food', 'the best ', 'a chef', 'fish', 'at night'],
        initialValue: [],
      },

      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: "50%",
          borderColor: 'transparent',
        },
        selectWordStyle: {
          // padding: 5,
          borderBottom: 'solid 2px',
          borderColor: '#00aeef',
        },
        limitSelect: 150,
        listWords: [
          `It was my mum's birthday last night, so we had ̶t̶̶h̶̶e̶ dinner in a_restaurant near the station. Restaurant was quite expensive – water was about £5 the_bottle – butit was worth it because food was fantastic! For me, a_best part of the meal was the main course. My dad and I both had salmon with potatoes and a delicious sauce. My dad said it was very well cooked, and he’s chef so he knows about cooking. My brother ordered steak because he hates the_fish. My dad had ordered a birthday cake for pudding. We had a great time and didn’t leave the restaurant until 11.30 at_the_night`,
        ],
        answers: ['0-34', '0-44', '0-66', '0-76', '0-166', '0-210', '0-140'],
        initialValue: [],
      },
      Layout: `
        
     <div style='display:flex'>
     <div style='flex:1'>
      <div style='border: 0px solid black;background-color:rgb(231, 231, 231);box-shadow:2px 3px 4px 0px rgb(163, 163, 163);width:600px;padding:10px 20px'>
     <input id='0'  type='Circle' />
      </div>
     </div>

     <div style='flex:1;margin-left:15px'>
     <u><b>Answer:</b></u>
     <div style='line-height:50px'>
      <b>1</b>&ensp;<u style='text-align:center;width:165px;font-style:italic'>&emsp;dinner&emsp;</u> <br>
      <b>2</b>&ensp;<input id='0' /> <br>
      <b>3</b>&ensp;<input id='1'/> <br>
      <b>4</b>&ensp;<input id='2'/> <br>
      <b>5</b>&ensp;<input id='3'/> <br>
      <b>6</b>&ensp;<input id='4'/> <br>
      <b>7</b>&ensp;<input id='5'/> <br>
      <b>8</b>&ensp;<input id='6'/> <br>
     </div>

      `,
    },
  },
  2: {
    unit: 'Grammar Builder and Referenc',
    id: 'FGF11-GBAR-P114-I2-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page114/Key/E2answerKey.png',
    inputSize: 150,
    textAlign: 'center',
    // //hideBtnFooter: true,
    titleQuestion: [
      {
        num: '2',
        title: 'Complete the sentences with the present simple and present continuous form of the verb given.',
        // color: "#0067b4",
      },
    ],
    questions: [
      {
        title: `
         <div style='line-height:1;'>
          <div style="display: flex; column-gap: 20px;">
            <strong>1</strong>
            <div>
              <div>work<br>
              <b>a</b> &ensp; Dad <input /> in a factory. <br>
              <b>b</b> &ensp; Mum <input />  at home today for a change.

 
              </div>
              

            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
            <div>have<br>
              <b>a</b> &ensp; We <input /> fish for dinner tonight.  <br>
              <b>b</b> &ensp; We usually <input />   fish on Fridays.


              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div>take<br>
              <b>a</b> &ensp; Dad <input />  a coat today because it’s a bit cold.  <br>
              <b>b</b> &ensp; Mum <input />   a few weeks off work over the summer.


              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
          <div>arrive<br>
              <b>a</b> &ensp; I’ll phone you as soon as I  <input />. <br>
              <b>b</b> &ensp;  Come on! The train <input />  . We mustn’t miss it.


              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
             <div>listen<br>
              <b>a</b> &ensp;  ‘What  <input />  you  <input /> to?’ ‘It’s Adele’s latest album.’   <br>
              <b>b</b> &ensp; Liam  <input />  to music while he’s doing his homework.


              </div>
          </div>

          

         </div>
        `,
        answer: [
          `works`,
          `is working`,
          `are having`,
          `have`,
          `am taking`,
          `take`,
          `arrive`,
          `is arriving`,
          `are`,
          `listening`,
          `listens`,
        ],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and Referenc',
    id: 'FGF11-GBAR-P114-I2-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page114/Key/E3answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: ` Choose the correct tense to complete the sentences. Then decide if the verb is a dynamic, stative or linking verb.
`,
        color: '#860052',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: 'black',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          'tastes / is_tasting', //0
          'do_you_look_at / are_you_looking_at? ', //1
          'has / is_having', //2
          'appears / is_appearing ', //3
          'I_think / I’m_thinking',
          'I_don’t_see / I’m_not_seeing',
        ],
        answers: ['0-0', '1-4', '2-0', '3-4', '4-0', '5-0'],
        initialValue: [],
      },
      Layout: `
       <div style='line-height:2.25;'>
        <div><b>1</b> This pizza   <b><input id='0' type='Circle' /></b> good.</div>
        <div><b>2</b>  What   <b><input id='1' type='Circle' /></b></div>
        <div><b>3</b>  Mandy   <b><input id='2' type='Circle' /></b>  brown hair.</div>
        <div><b>4</b>  Look. The sun   <b><input id='3' type='Circle' /></b>  from behind those clouds.  </div>
        <div><b>5</b>  <b><input id='4' type='Circle' /></b> that we should go.</div>
        <div><b>6</b>  <b><input id='5' type='Circle' /></b>   what the problem is. </div>
       


       </div>
      `,
    },
  },
};

export default json;
