import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P24-E1',
		audio: '',
		video: '',
		component: T6,
		textareaStyle: { width: 700 },
		// recorder: true,
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page24/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title:
					'<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span>Work in pairs. Ask about your partner’s hobbies. Find two things that he or she a) <i>usually does at the weekend</i> and b) <i>occasionally</i> does at the weekend.',
				color: '#8e258c',
				// prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        
          `,
				answer: [],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P24-E2',
		audio: '',
		video: '',
		component: T6,
		textareaStyle: { width: 700 },
		inputSize: 350,
		maxLength: 20,
		titleImage: '',
		textAlign: 'left',
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page24/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title:
					'<span style="color: purple"> VOCABULARY </span> Check the meaning of the activities and sports below. How many can you find in photos A–F?',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		hintBox: [
			{
				src: [
					'bake cakes',
					'collect figures, cards, stamps, etc.',
					'draw',
					'hang out with friends',
					'make clothes',
					'read books',
					'read magazines',
					'text your friends',
					'use social media',
					'video blog',
					'watch videos online',
				],
				width: 1000,
				borderColor: 'none',
				fontColor: 'Purple',
				justifyContent: 'left',
			},
		],
		questions: [
			{
				title: `
      <img style='width: 24.5cm; height: 16cm' src='img/FriendsPlus/Page24/E1/1.jpg'>

        <div style='width:20cm; display: flex; margin-top: 20px'>
        <div style='margin-right: 50px'>
          <div style='display:flex;'>
              <b>A</b> #
            </div>
            <div style='display:flex;'>
              <b>B</b>#
            </div>
            <div style='display:flex;'>
              <b>C</b># 
            </div>
        </div>
        <div>
          <div style='display:flex;'>
              <b>D</b># 
            </div>
            <div style='display:flex;'>
              <b>E</b>#
            </div>
            <div style='display:flex;'>
              <b>F</b># 
            </div>
        </div>
            
         </div>
         `,
				answer: [`bowling`, `board games`, `photography`, `camping`, `play the guitar`, `read books`],
			},
		],
	},
}

export default json
