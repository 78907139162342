import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Grammar Builder and Reference',
		id: 'FGF11-GBAR-P122-U4.3-E1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '1',
				title: 'Rewrite these critical sentences using I wish … or If only … + would.',
				color: '#860052',
			},
		],
		stylesTextInput: {
			//fontSize: 20,
			//textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 630,
		exerciseKey: '/img/FriendsPlus/Page122/Key/E1answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
        <div><b>1</b> Jack is always forgetting his sports kit.</div>
        <div style="padding-left:20px; color:rgb(0,121,133,1);">I wish Jack wouldn't forget his sports kit.</div>
        
        <div><b>2</b> My dad is always singing in the shower.</div>
        <div style="padding-left:10px;">#</div>
        
        <div><b>3</b> She's always sending me text messages at night.</div>
        <div style="padding-left:10px;">#</div>

        <div><b>4</b> You're always borrowing my dictionary.</div>
        <div style="padding-left:10px;">#</div>

        <div><b>5</b> Our car is always breaking down on the motorway.</div>
        <div style="padding-left:10px;">#</div>

        <div><b>6</b> You're always telling people my secrets</div>
        <div style="padding-left:10px;">#</div>

				`,
				answer: [
					"I wish my dad wouldn't sing in the shower./ If only my dad wouldn't sing in the shower.",
					"I wish she wouldn't send me text messages at night./ If only she wouldn't send me text messages at night.",
					"I wish you wouldn't borrow my dictionary./ If only you wouldn't borrow my dictionary.",
					"I wish our car wouldn't break down on the motorway./ If only our car wouldn't break down on the motorway.",
					"I wish you wouldn't tell people my secrets./ If only you wouldn't tell people my secrets.",
				],
			},
		],
	},
	2: {
		unit: 'Grammar Builder and Reference',
		id: 'FGF11-GBAR-P122-U4.3-E2',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '2',
				title:
					'Complete the sentences with the past simple or would + the infinitive without to form of the verbs in brackets.',
				color: '#860052',
			},
		],
		stylesTextInput: {
			fontSize: 22,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 150,
		exerciseKey: 'img/FriendsPlus/Page122/Key/E2answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
         <div style='line-height:2.5;'>
				  <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <span>I wish I <input /> (have) a brother or sister.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <span> If only they <input /> (stop) talking – I can't hear the film.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <span> I wish you <input /> (take) a photo of me so I could send it to my friends.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <span> If only we <input /> (understand) Spanish, we could ask for directions.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <span> I wish you <input /> (like) Chinese food; I don't want pizza again.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <span> I wish you<input />(finish) that burger; I don't like the smell.</span>
          </div>
				 </div>
        `,

				answer: ['had', 'would stop', 'would take', 'understood', 'liked', 'would finish'],
			},
		],
	},
	3: {
		unit: 'Grammar Builder and Reference',
		id: 'FGF11-GBAR-P122-U4.4-E1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '1',
				title:
					"Write a preference using I'd rather and the words in brackets. Include any other words that are necessary.",
				color: '#860052',
			},
		],
		stylesTextInput: {
			//fontSize: 20,
			//textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 600,
		exerciseKey: '/img/FriendsPlus/Page122/Key/E3answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
        <div><b>1</b> My friends all play basketball. (football)</div>
        <div style="padding-left:20px; color:rgb(0,121,133,1);">I'd rather they played football.</div>
        
        <div><b>2</b> I live near the sea. (mountains)</div>
        <div style="padding-left:20px;">#</div>
        
        <div><b>3</b> My sister often gives me books. (CDs)</div>
        <div style="padding-left:20px;">#</div>

        <div><b>4</b> My friend Sally always arrives late. (early)</div>
        <div style="padding-left:20px;">#</div>

        <div><b>5</b> My dad usually cooks pasta for dinner. (burgers)</div>
        <div style="padding-left:20px;">#</div>

        <div><b>6</b> Our neighbours have got a large dog. (a cat)</div>
        <div style="padding-left:20px;">#</div>
        `,
				answer: [
					"I'd rather live near the mountains.",
					"I'd rather she gave me CDs.",
					"I'd rather she arrived early.",
					"I'd rather he cooked burgers.",
					"I'd rather had a cat.",
				],
			},
		],
	},
	4: {
		unit: 'Grammar Builder and Reference',
		id: 'FGF11-GBAR-P122-U4.4-E2',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '2',
				title: 'Rewrite the advice using <i>had better</i> instead of <i>should</i> or <i>ought to</i>.',
				color: '#860052',
			},
		],
		stylesTextInput: {
			// fontSize: 20,
			//textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 600,
		exerciseKey: '/img/FriendsPlus/Page122/Key/E4answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
        <div><b>1</b> I don't think you should phone her now.</div>
        <div style="padding-left:20px; color:rgb(0,121,133,1);">You'd better not phone her now.</div>
        
        <div><b>2</b> I think we should save some money for the journey.</div>
        <div style="padding-left:20px;">#</div>
        
        <div><b>3</b> I don't think you should tell her about that email.</div>
        <div style="padding-left:20px;">#</div>

        <div><b>4</b> We ought to go home – it's late.</div>
        <div style="padding-left:20px;">#</div>

        <div><b>5</b> I think we should work hard for these exams.</div>
        <div style="padding-left:20px;">#</div>

        <div><b>6</b> I don't think you should drink that milk; it smells funny.</div>
        <div style="padding-left:20px;">#</div>
        `,
				answer: [
					"We'd better save some money for the journey.",
					"You'd better not tell her about that email.",
					"We'd better go home - it's late.",
					"We'd better work hard for these exams.",
					"You'd better not drink that milk; it smells funny.",
				],
			},
		],
	},
}

export default json
