/* eslint-disable no-sparse-arrays */
export const dataBook = {
	1: [[{ imgUrl: 'img/FriendsPlus/ListPage/1.jpg', excerciseNum: 0 }]],
	2: [[{ imgUrl: 'img/FriendsPlus/ListPage/2.jpg', excerciseNum: 0 }]],
	3: [[{ imgUrl: 'img/FriendsPlus/ListPage/3.jpg', excerciseNum: 0 }]],
	4: [[{ imgUrl: 'img/FriendsPlus/ListPage/4.jpg', excerciseNum: 0 }]],
	5: [[{ imgUrl: 'img/FriendsPlus/ListPage/5.jpg', excerciseNum: 0 }]],
	6: [[{ imgUrl: 'img/FriendsPlus/ListPage/6.jpg', excerciseNum: 0 }]],
	7: [[{ imgUrl: 'img/FriendsPlus/ListPage/7.jpg', excerciseNum: 0 }]],
	8: [
		[{ imgUrl: 'img/FriendsPlus/Page8/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page8/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page8/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page8/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page8/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page8/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page8/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page8/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page8/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page8/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page8/11.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page8/12.jpg', excerciseNum: 3 }],
		[{ imgUrl: 'img/FriendsPlus/Page8/13.jpg', excerciseNum: 0 }],
	],
	9: [
		[{ imgUrl: 'img/FriendsPlus/Page9/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page9/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page9/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page9/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page9/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page9/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page9/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page9/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page9/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page9/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page9/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page9/12.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page9/13.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page9/14.jpg', excerciseNum: 0 }],
	],
	10: [
		[{ imgUrl: 'img/FriendsPlus/Page10/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page10/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page10/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page10/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page10/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page10/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page10/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page10/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page10/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page10/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page10/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page10/12.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page10/13.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page10/14.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page10/15.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page10/16.jpg', excerciseNum: 0 }],
	],
	11: [
		[{ imgUrl: 'img/FriendsPlus/Page11/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page11/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page11/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page11/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page11/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page11/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/12.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page11/13.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page11/14.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page11/15.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page11/16.jpg', excerciseNum: 0 }],
	],
	12: [
		[{ imgUrl: 'img/FriendsPlus/Page12/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page12/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page12/3.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page12/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page12/5.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page12/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page12/7.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page12/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page12/9.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page12/10.jpg', excerciseNum: 0 }],
	],
	13: [
		[{ imgUrl: 'img/FriendsPlus/Page13/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page13/2.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page13/3.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page13/4.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page13/5.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page13/6.jpg', excerciseNum: 0 }],
	],
	14: [
		[{ imgUrl: 'img/FriendsPlus/Page14/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page14/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page14/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page14/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page14/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page14/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page14/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page14/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page14/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page14/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page14/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page14/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page14/13.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page14/14.jpg', excerciseNum: 0 }],
	],
	15: [
		[{ imgUrl: 'img/FriendsPlus/Page15/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page15/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page15/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page15/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page15/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page15/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page15/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page15/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page15/9.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page15/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page15/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page15/12.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page15/13.jpg', excerciseNum: 8 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page15/14.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page15/15.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page15/16.jpg', excerciseNum: 0 }],
	],
	16: [
		[{ imgUrl: 'img/FriendsPlus/Page16/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page16/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page16/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page16/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page16/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page16/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page16/13.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page16/14.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page16/15.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page16/16.jpg', excerciseNum: 0 }],
	],
	17: [
		[{ imgUrl: 'img/FriendsPlus/Page17/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page17/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page17/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page17/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page17/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page17/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page17/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page17/8.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page17/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page17/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page17/11.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page17/12.jpg', excerciseNum: 0 }],
	],
	18: [
		[{ imgUrl: 'img/FriendsPlus/Page18/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page18/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page18/3.jpg', excerciseNum: 2 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page18/4.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page18/5.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page18/6.jpg', excerciseNum: 0 }],
	],
	19: [
		[{ imgUrl: 'img/FriendsPlus/Page19/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page19/2.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page19/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page19/4.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page19/5.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page19/6.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page19/7.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page19/8.jpg', excerciseNum: 0 }],
	],
	20: [
		[{ imgUrl: 'img/FriendsPlus/Page20/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page20/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page20/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page20/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page20/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page20/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page20/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page20/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page20/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page20/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page20/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page20/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page20/13.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page20/14.jpg', excerciseNum: 0 }],
	],
	21: [
		[{ imgUrl: 'img/FriendsPlus/Page21/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page21/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page21/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page21/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page21/9.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page21/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page21/13.jpg', excerciseNum: 8 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/14.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page21/15.jpg', excerciseNum: 9 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/16.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page21/17.jpg', excerciseNum: 10 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page21/18.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page21/19.jpg', excerciseNum: 10 },
		],
	],
	22: [
		[{ imgUrl: 'img/FriendsPlus/Page22/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page22/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page22/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page22/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page22/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page22/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page22/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page22/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page22/9.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page22/10.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page22/11.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page22/12.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page22/13.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page22/14.jpg', excerciseNum: 0 }],
	],
	23: [
		[{ imgUrl: 'img/FriendsPlus/Page23/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page23/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page23/3.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page23/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page23/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page23/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page23/7.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page23/8.jpg', excerciseNum: 0 }],
	],
	24: [
		[{ imgUrl: 'img/FriendsPlus/Page24/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page24/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page24/3.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page24/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page24/5.jpg', excerciseNum: 2 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page24/6.jpg', excerciseNum: 0 }],
	],
	25: [
		[{ imgUrl: 'img/FriendsPlus/Page25/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page25/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page25/3.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page25/4.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page25/5.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page25/6.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page25/7.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page25/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page25/9.jpg', excerciseNum: 8 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page25/10.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page25/11.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page25/12.jpg', excerciseNum: 0 }],
	],
	26: [
		[{ imgUrl: 'img/FriendsPlus/Page26/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page26/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page26/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page26/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page26/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page26/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page26/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page26/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page26/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page26/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page26/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page26/12.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page26/13.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page26/14.jpg', excerciseNum: 0 }],
	],
	27: [
		[{ imgUrl: 'img/FriendsPlus/Page27/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page27/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page27/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page27/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page27/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page27/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page27/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page27/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page27/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page27/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page27/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page27/12.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page27/13.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page27/14.jpg', excerciseNum: 0 }],
	],
	28: [
		[{ imgUrl: 'img/FriendsPlus/Page28/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page28/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page28/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page28/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page28/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page28/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page28/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page28/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page28/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page28/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page28/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page28/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page28/13.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page28/14.jpg', excerciseNum: 0 }],
	],
	29: [
		[{ imgUrl: 'img/FriendsPlus/Page29/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page29/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page29/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page29/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page29/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page29/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page29/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page29/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page29/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page29/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page29/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page29/12.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page29/13.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page29/14.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page29/15.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page29/16.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page29/17.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page29/18.jpg', excerciseNum: 0 }],
	],
	30: [
		[{ imgUrl: 'img/FriendsPlus/Page30/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page30/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page30/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page30/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page30/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page30/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page30/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page30/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page30/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page30/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page30/11.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page30/12.jpg', excerciseNum: 0 }],
	],
	31: [
		[{ imgUrl: 'img/FriendsPlus/Page31/1.jpg', excerciseNum: 0 }],
		[{ imgUrl: 'img/FriendsPlus/Page31/2.jpg', excerciseNum: 1 }],
		[{ imgUrl: 'img/FriendsPlus/Page31/3.jpg', excerciseNum: 0 }],
	],
	32: [
		[{ imgUrl: 'img/FriendsPlus/Page32/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page32/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page32/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page32/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page32/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page32/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page32/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page32/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page32/9.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page32/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page32/11.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page32/12.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page32/13.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page32/14.jpg', excerciseNum: 0 }],
	],
	33: [
		[{ imgUrl: 'img/FriendsPlus/Page33/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page33/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page33/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page33/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page33/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page33/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page33/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page33/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page33/9.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page33/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page33/11.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page33/12.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page33/13.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page33/14.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page33/15.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page33/16.jpg', excerciseNum: 0 }],
	],
	34: [
		[{ imgUrl: 'img/FriendsPlus/Page34/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page34/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page34/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page34/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page34/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page34/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page34/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page34/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page34/9.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page34/10.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page34/11.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page34/12.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page34/13.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page34/14.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page34/15.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page34/16.jpg', excerciseNum: 0 }],
	],
	35: [
		[{ imgUrl: 'img/FriendsPlus/Page35/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page35/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page35/3.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page35/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page35/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page35/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page35/7.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page35/8.jpg', excerciseNum: 0 }],
	],
	36: [
		[{ imgUrl: 'img/FriendsPlus/Page36/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page36/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page36/3.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page36/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page36/5.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page36/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page36/7.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page36/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page36/9.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page36/10.jpg', excerciseNum: 0 }],
	],
	37: [
		[{ imgUrl: 'img/FriendsPlus/Page37/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page37/2.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page37/3.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page37/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page37/5.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page37/6.jpg', excerciseNum: 0 }],
	],
	38: [
		[{ imgUrl: 'img/FriendsPlus/Page38/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page38/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page38/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page38/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page38/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page38/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page38/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page38/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page38/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page38/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page38/11.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page38/12.jpg', excerciseNum: 0 }],
	],
	39: [
		[{ imgUrl: 'img/FriendsPlus/Page39/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page39/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page39/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page39/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page39/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page39/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page39/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page39/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page39/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page39/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page39/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page39/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page39/13.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page39/14.jpg', excerciseNum: 0 }],
	],
	40: [
		[{ imgUrl: 'img/FriendsPlus/Page40/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page40/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page40/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page40/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page40/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page40/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page40/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page40/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page40/9.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page40/10.jpg', excerciseNum: 0 }],
	],
	41: [
		[{ imgUrl: 'img/FriendsPlus/Page41/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page41/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page41/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page41/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page41/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page41/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page41/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page41/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page41/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page41/10.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page41/11.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page41/12.jpg', excerciseNum: 0 }],
	],
	42: [
		[{ imgUrl: 'img/FriendsPlus/Page42/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page42/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page42/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page42/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page42/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page42/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page42/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page42/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page42/9.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page42/10.jpg', excerciseNum: 0 }],
	],
	43: [[{ imgUrl: 'img/FriendsPlus/ListPage/43.jpg', excerciseNum: 0 }]],
	44: [
		[{ imgUrl: 'img/FriendsPlus/Page44/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page44/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page44/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page44/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page44/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page44/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page44/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page44/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page44/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page44/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page44/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page44/12.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page44/13.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page44/14.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page44/15.jpg', excerciseNum: 8 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page44/16.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page44/17.jpg', excerciseNum: 9 },
		],
	],
	45: [
		[{ imgUrl: 'img/FriendsPlus/Page45/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page45/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page45/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page45/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page45/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page45/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page45/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page45/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page45/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page45/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page45/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page45/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page45/13.jpg', excerciseNum: 8 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page45/14.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page45/15.jpg', excerciseNum: 8 },
		],
	],
	46: [
		[{ imgUrl: 'img/FriendsPlus/Page46/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page46/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page46/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page46/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page46/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page46/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page46/7.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page46/8.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page46/9.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page46/10.jpg', excerciseNum: 4 },
		],
	],
	47: [
		[{ imgUrl: 'img/FriendsPlus/Page47/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page47/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page47/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page47/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page47/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page47/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page47/7.jpg', excerciseNum: 5 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page47/8.jpg', excerciseNum: 0 }],
	],
	48: [
		[{ imgUrl: 'img/FriendsPlus/Page48/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page48/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page48/3.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page48/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page48/5.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page48/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page48/7.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page48/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page48/9.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page48/10.jpg', excerciseNum: 0 }],
	],
	49: [
		[{ imgUrl: 'img/FriendsPlus/Page49/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page49/2.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page49/3.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page49/4.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page49/5.jpg', excerciseNum: 8 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page49/6.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page49/7.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page49/8.jpg', excerciseNum: 0 }],
	],
	50: [
		[{ imgUrl: 'img/FriendsPlus/Page50/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page50/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page50/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page50/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page50/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page50/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page50/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page50/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page50/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page50/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page50/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page50/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page50/13.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page50/14.jpg', excerciseNum: 0 }],
	],
	51: [
		[{ imgUrl: 'img/FriendsPlus/Page51/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page51/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page51/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page51/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page51/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page51/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page51/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page51/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page51/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page51/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page51/11.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page51/12.jpg', excerciseNum: 0 }],
	],
	52: [
		[{ imgUrl: 'img/FriendsPlus/Page52/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page52/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page52/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page52/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page52/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page52/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page52/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page52/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page52/9.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page52/10.jpg', excerciseNum: 4 }],
	],
	53: [
		[{ imgUrl: 'img/FriendsPlus/Page53/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page53/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page53/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page53/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page53/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page53/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page53/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page53/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page53/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page53/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page53/11.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page53/12.jpg', excerciseNum: 0 }],
	],
	54: [
		[{ imgUrl: 'img/FriendsPlus/Page54/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page54/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page54/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page54/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page54/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page54/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page54/7.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page54/8.jpg', excerciseNum: 0 }],
	],
	55: [
		[{ imgUrl: 'img/FriendsPlus/Page55/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page55/2.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page55/3.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page55/4.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page55/5.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page55/6.jpg', excerciseNum: 0 }],
	],
	56: [
		[{ imgUrl: 'img/FriendsPlus/Page56/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page56/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page56/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page56/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page56/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page56/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page56/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page56/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page56/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page56/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page56/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page56/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page56/13.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page56/14.jpg', excerciseNum: 0 }],
	],
	57: [
		[{ imgUrl: 'img/FriendsPlus/Page57/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page57/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page57/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page57/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page57/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page57/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page57/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page57/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page57/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page57/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page57/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page57/12.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page57/13.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page57/14.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page57/15.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page57/16.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page57/17.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page57/18.jpg', excerciseNum: 0 }],
	],
	58: [
		[{ imgUrl: 'img/FriendsPlus/Page58/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page58/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page58/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page58/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page58/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page58/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page58/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page58/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page58/9.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page58/10.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page58/11.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page58/12.jpg', excerciseNum: 0 }],
	],
	59: [
		[{ imgUrl: 'img/FriendsPlus/Page59/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page59/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page59/3.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page59/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page59/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page59/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page59/7.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page59/8.jpg', excerciseNum: 0 }],
	],
	60: [
		[{ imgUrl: 'img/FriendsPlus/Page60/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page60/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page60/3.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page60/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page60/5.jpg', excerciseNum: 2 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page60/6.jpg', excerciseNum: 0 }],
	],
	61: [
		[{ imgUrl: 'img/FriendsPlus/Page61/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page61/2.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page61/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page61/4.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page61/5.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page61/6.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page61/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page61/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page61/9.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page61/10.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page61/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page61/12.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page61/13.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page61/14.jpg', excerciseNum: 0 }],
	],
	62: [
		[{ imgUrl: 'img/FriendsPlus/Page62/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page62/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page62/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page62/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page62/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page62/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page62/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page62/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page62/9.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page62/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page62/11.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page62/12.jpg', excerciseNum: 0 }],
	],
	63: [
		[{ imgUrl: 'img/FriendsPlus/Page63/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page63/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page63/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page63/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page63/5.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page63/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page63/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page63/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page63/9.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page63/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page63/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page63/12.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page63/13.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page63/14.jpg', excerciseNum: 0 }],
	],
	64: [
		[{ imgUrl: 'img/FriendsPlus/Page64/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page64/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page64/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page64/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page64/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page64/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page64/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page64/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page64/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page64/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page64/11.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page64/12.jpg', excerciseNum: 0 }],
	],
	65: [
		[{ imgUrl: 'img/FriendsPlus/Page65/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page65/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page65/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page65/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page65/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page65/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page65/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page65/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page65/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page65/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page65/11.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page65/12.jpg', excerciseNum: 0 }],
	],
	66: [
		[{ imgUrl: 'img/FriendsPlus/Page66/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page66/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page66/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page66/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page66/5.jpg', excerciseNum: 3 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page66/6.jpg', excerciseNum: 0 }],
	],
	67: [
		[{ imgUrl: 'img/FriendsPlus/Page67/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page67/2.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page67/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page67/4.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page67/5.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page67/6.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page67/7.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page67/8.jpg', excerciseNum: 0 }],
	],
	68: [
		[{ imgUrl: 'img/FriendsPlus/Page68/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page68/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page68/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page68/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page68/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page68/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page68/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page68/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page68/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page68/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page68/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page68/12.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page68/13.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page68/14.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page68/15.jpg', excerciseNum: 8 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page68/16.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page68/17.jpg', excerciseNum: 9 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page68/18.jpg', excerciseNum: 0 }],
	],
	69: [
		[{ imgUrl: 'img/FriendsPlus/Page69/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page69/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page69/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page69/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page69/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page69/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page69/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page69/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page69/9.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page69/10.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page69/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page69/12.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page69/13.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page69/14.jpg', excerciseNum: 0 }],
	],
	70: [
		[{ imgUrl: 'img/FriendsPlus/Page70/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page70/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page70/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page70/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page70/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page70/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page70/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page70/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page70/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page70/10.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page70/11.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page70/12.jpg', excerciseNum: 0 }],
	],
	71: [
		[{ imgUrl: 'img/FriendsPlus/Page71/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page71/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page71/3.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page71/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page71/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page71/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page71/7.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page71/8.jpg', excerciseNum: 0 }],
	],
	72: [
		[{ imgUrl: 'img/FriendsPlus/Page72/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page72/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page72/3.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page72/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page72/5.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page72/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page72/7.jpg', excerciseNum: 3 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page72/8.jpg', excerciseNum: 0 }],
	],
	73: [
		[{ imgUrl: 'img/FriendsPlus/Page73/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page73/2.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page73/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page73/4.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page73/5.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page73/6.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page73/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page73/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page73/9.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page73/10.jpg', excerciseNum: 0 }],
	],
	74: [
		[{ imgUrl: 'img/FriendsPlus/Page74/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page74/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page74/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page74/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page74/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page74/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page74/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page74/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page74/9.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page74/10.jpg', excerciseNum: 3 }],
		[{ imgUrl: 'img/FriendsPlus/Page74/11.jpg', excerciseNum: 0 }],
	],
	75: [
		[{ imgUrl: 'img/FriendsPlus/Page75/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page75/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page75/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page75/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page75/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page75/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page75/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page75/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page75/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page75/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page75/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page75/12.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page75/13.jpg', excerciseNum: 8 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page75/14.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page75/15.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page75/16.jpg', excerciseNum: 0 }],
	],
	76: [
		[{ imgUrl: 'img/FriendsPlus/Page76/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page76/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page76/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page76/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page76/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page76/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page76/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page76/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page76/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page76/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page76/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page76/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page76/13.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page76/14.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page76/15.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page76/16.jpg', excerciseNum: 0 }],
	],
	77: [
		[{ imgUrl: 'img/FriendsPlus/Page77/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page77/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page77/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page77/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page77/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page77/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page77/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page77/8.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page77/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page77/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page77/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page77/12.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page77/13.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page77/14.jpg', excerciseNum: 0 }],
	],
	78: [
		[{ imgUrl: 'img/FriendsPlus/Page78/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page78/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page78/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page78/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page78/5.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page78/6.jpg', excerciseNum: 0 }],
	],
	79: [
		[{ imgUrl: 'img/FriendsPlus/Page79/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page79/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page79/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page79/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page79/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page79/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page79/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page79/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page79/9.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page79/10.jpg', excerciseNum: 0 }],
	],
	80: [
		[{ imgUrl: 'img/FriendsPlus/Page80/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page80/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page80/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page80/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page80/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page80/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page80/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page80/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page80/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page80/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page80/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page80/12.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page80/13.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page80/14.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page80/15.jpg', excerciseNum: 8 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page80/16.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page80/17.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page80/18.jpg', excerciseNum: 0 }],
	],
	81: [
		[{ imgUrl: 'img/FriendsPlus/Page81/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page81/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page81/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page81/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page81/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page81/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page81/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page81/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page81/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page81/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page81/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page81/12.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page81/13.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page81/14.jpg', excerciseNum: 0 }],
	],
	82: [
		[{ imgUrl: 'img/FriendsPlus/Page82/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page82/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page82/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page82/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page82/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page82/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page82/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page82/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page82/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page82/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page82/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page82/12.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page82/13.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page82/14.jpg', excerciseNum: 0 }],
	],
	83: [
		[{ imgUrl: 'img/FriendsPlus/Page83/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page83/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page83/3.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page83/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page83/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page83/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page83/7.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page83/8.jpg', excerciseNum: 0 }],
	],
	84: [
		[{ imgUrl: 'img/FriendsPlus/Page84/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page84/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page84/3.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page84/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page84/5.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page84/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page84/7.jpg', excerciseNum: 3 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page84/8.jpg', excerciseNum: 0 }],
	],
	85: [
		[{ imgUrl: 'img/FriendsPlus/Page85/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page85/2.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page85/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page85/4.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page85/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page85/6.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page85/7.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page85/8.jpg', excerciseNum: 0 }],
	],
	86: [
		[{ imgUrl: 'img/FriendsPlus/Page86/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page86/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page86/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page86/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page86/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page86/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page86/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page86/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page86/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page86/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page86/11.jpg', excerciseNum: 0 },
		],
	],
	87: [
		[{ imgUrl: 'img/FriendsPlus/Page87/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page87/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page87/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page87/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page87/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page87/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page87/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page87/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page87/9.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page87/10.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page87/11.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page87/12.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page87/13.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page87/14.jpg', excerciseNum: 7 },
			{ imgUrl: 'img/FriendsPlus/Page87/15.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page87/16.jpg', excerciseNum: 8 },
			{ imgUrl: 'img/FriendsPlus/Page87/17.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page87/18.jpg', excerciseNum: 0 }],
	],
	88: [
		[{ imgUrl: 'img/FriendsPlus/Page88/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page88/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page88/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page88/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page88/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page88/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page88/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page88/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page88/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page88/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page88/11.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page88/12.jpg', excerciseNum: 6 }],
	],
	89: [
		[{ imgUrl: 'img/FriendsPlus/Page89/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page89/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page89/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page89/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page89/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page89/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page89/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page89/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page89/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page89/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page89/11.jpg', excerciseNum: 6 },
		],
	],
	90: [
		[{ imgUrl: 'img/FriendsPlus/Page90/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page90/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page90/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page90/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page90/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page90/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page90/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page90/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page90/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page90/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page90/11.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page90/12.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page90/13.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page90/14.jpg', excerciseNum: 0 }],
	],
	91: [[{ imgUrl: 'img/FriendsPlus/ListPage/91.jpg', excerciseNum: 0 }]],
	92: [
		[{ imgUrl: 'img/FriendsPlus/Page92/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page92/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page92/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page92/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page92/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page92/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page92/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page92/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page92/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page92/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page92/11.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page92/12.jpg', excerciseNum: 4 }],
	],
	93: [
		[{ imgUrl: 'img/FriendsPlus/Page93/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page93/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page93/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page93/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page93/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page93/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page93/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page93/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page93/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page93/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page93/11.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page93/12.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page93/13.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page93/14.jpg', excerciseNum: 0 }],
	],
	94: [
		[{ imgUrl: 'img/FriendsPlus/Page94/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page94/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page94/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page94/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page94/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page94/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page94/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page94/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page94/9.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page94/10.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page94/11.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page94/12.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page94/13.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page94/14.jpg', excerciseNum: 0 }],
	],
	95: [
		[{ imgUrl: 'img/FriendsPlus/Page95/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page95/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page95/3.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page95/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page95/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page95/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page95/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page95/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page95/9.jpg', excerciseNum: 4 },
		],
	],
	96: [
		[{ imgUrl: 'img/FriendsPlus/Page96/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page96/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page96/3.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page96/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page96/5.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page96/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page96/7.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page96/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page96/9.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page96/10.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page96/11.jpg', excerciseNum: 5 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page96/12.jpg', excerciseNum: 0 }],
	],
	97: [
		[{ imgUrl: 'img/FriendsPlus/Page97/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page97/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page97/3.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page97/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page97/5.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page97/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page97/7.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page97/8.jpg', excerciseNum: 0 }],
	],
	98: [
		[{ imgUrl: 'img/FriendsPlus/Page98/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page98/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page98/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page98/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page98/5.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page98/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page98/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page98/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page98/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page98/10.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page98/11.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page98/12.jpg', excerciseNum: 0 }],
	],
	99: [
		[{ imgUrl: 'img/FriendsPlus/Page99/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page99/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page99/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page99/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page99/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page99/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page99/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page99/8.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page99/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page99/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page99/11.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page99/12.jpg', excerciseNum: 0 }],
	],
	100: [
		[{ imgUrl: 'img/FriendsPlus/Page100/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page100/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page100/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page100/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page100/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page100/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page100/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page100/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page100/9.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page100/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page100/11.jpg', excerciseNum: 5 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page100/12.jpg', excerciseNum: 0 }],
	],
	101: [
		[{ imgUrl: 'img/FriendsPlus/Page101/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page101/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page101/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page101/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page101/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page101/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page101/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page101/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page101/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page101/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page101/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page101/12.jpg', excerciseNum: 4 },

			{ imgUrl: 'img/FriendsPlus/Page101/13.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page101/14.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page101/15.jpg', excerciseNum: 7 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page101/16.jpg', excerciseNum: 0 }],
	],

	102: [
		[{ imgUrl: 'img/FriendsPlus/Page102/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page102/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page102/3.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page102/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page102/5.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page102/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page102/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page102/8.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page102/9.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page102/10.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page102/11.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page102/12.jpg', excerciseNum: 0 }],
	],
	103: [[{ imgUrl: 'img/FriendsPlus/ListPage/103.jpg', excerciseNum: 0 }]],
	104: [
		[{ imgUrl: 'img/FriendsPlus/Page104/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page104/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page104/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page104/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page104/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page104/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page104/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page104/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page104/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page104/10.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page104/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page104/12.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page104/13.jpg', excerciseNum: 7 },
		],
	],
	105: [
		[{ imgUrl: 'img/FriendsPlus/Page105/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page105/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page105/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page105/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page105/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page105/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page105/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page105/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page105/9.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page105/10.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page105/11.jpg', excerciseNum: 5 },
		],
	],
	106: [
		[{ imgUrl: 'img/FriendsPlus/Page106/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page106/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page106/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page106/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page106/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page106/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page106/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page106/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page106/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page106/10.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page106/11.jpg', excerciseNum: 6 },
		],
	],
	107: [
		[{ imgUrl: 'img/FriendsPlus/Page107/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page107/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page107/3.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page107/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page107/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page107/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page107/7.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page107/8.jpg', excerciseNum: 0 }],
	],
	108: [
		[{ imgUrl: 'img/FriendsPlus/Page108/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page108/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page108/3.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page108/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page108/5.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page108/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page108/7.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page108/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page108/9.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page108/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page108/11.jpg', excerciseNum: 6 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page108/12.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page108/13.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page108/14.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page108/15.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page108/16.jpg', excerciseNum: 0 }],
	],
	109: [
		[{ imgUrl: 'img/FriendsPlus/Page109/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page109/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page109/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page109/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page109/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page109/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page109/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page109/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page109/9.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page109/10.jpg', excerciseNum: 0 }],
	],
	110: [
		[{ imgUrl: 'img/FriendsPlus/Page110/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page110/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page110/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page110/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page110/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page110/6.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page110/7.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page110/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page110/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page110/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page110/11.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page110/12.jpg', excerciseNum: 0 }],
	],
	111: [
		[{ imgUrl: 'img/FriendsPlus/Page111/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page111/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page111/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page111/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page111/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page111/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page111/7.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page111/8.jpg', excerciseNum: 0 }],
	],
	112: [
		[{ imgUrl: 'img/FriendsPlus/Page112/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page112/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page112/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page112/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page112/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page112/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page112/7.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page112/8.jpg', excerciseNum: 0 }],
	],
	113: [
		[{ imgUrl: 'img/FriendsPlus/Page113/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page113/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page113/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page113/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page113/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page113/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page113/7.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page113/8.jpg', excerciseNum: 0 }],
	],
	114: [
		[{ imgUrl: 'img/FriendsPlus/Page114/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page114/2.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page114/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page114/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page114/5.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page114/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page114/7.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page114/8.jpg', excerciseNum: 0 }],
	],
	115: [
		[{ imgUrl: 'img/FriendsPlus/Page115/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page115/2.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page115/3.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page115/4.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page115/5.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page115/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page115/7.jpg', excerciseNum: 3 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page115/8.jpg', excerciseNum: 0 }],
	],
	116: [
		[{ imgUrl: 'img/FriendsPlus/Page116/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page116/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page116/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page116/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page116/5.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page116/6.jpg', excerciseNum: 0 }],
	],
	117: [
		[{ imgUrl: 'img/FriendsPlus/Page117/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page117/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page117/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page117/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page117/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page117/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page117/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page117/8.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page117/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page117/10.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page117/11.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page117/12.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page117/13.jpg', excerciseNum: 6 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page117/14.jpg', excerciseNum: 0 }],
	],
	118: [
		[{ imgUrl: 'img/FriendsPlus/Page118/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page118/2.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page118/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page118/4.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page118/5.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page118/6.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page118/7.jpg', excerciseNum: 1 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page118/8.jpg', excerciseNum: 0 }],
	],
	119: [
		[{ imgUrl: 'img/FriendsPlus/Page119/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page119/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page119/3.jpg', excerciseNum: 2 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page119/4.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page119/5.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page119/6.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page119/7.jpg', excerciseNum: 0 }],
	],
	120: [
		[{ imgUrl: 'img/FriendsPlus/Page120/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page120/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page120/3.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page120/4.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page120/5.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page120/6.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page120/7.jpg', excerciseNum: 3 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page120/8.jpg', excerciseNum: 0 }],
	],
	121: [
		[{ imgUrl: 'img/FriendsPlus/Page121/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page121/2.jpg', excerciseNum: 4 },
			{ imgUrl: 'img/FriendsPlus/Page121/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page121/4.jpg', excerciseNum: 5 },
			{ imgUrl: 'img/FriendsPlus/Page121/5.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page121/6.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page121/7.jpg', excerciseNum: 7 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page121/8.jpg', excerciseNum: 6 },
			{ imgUrl: 'img/FriendsPlus/Page121/9.jpg', excerciseNum: 8 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page121/10.jpg', excerciseNum: 0 }],
	],
	122: [
		[{ imgUrl: 'img/FriendsPlus/Page122/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page122/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page122/3.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page122/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page122/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page122/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page122/7.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page122/8.jpg', excerciseNum: 0 },],
		[
			{ imgUrl: 'img/FriendsPlus/Page122/9.jpg', excerciseNum: 3 },

			{ imgUrl: 'img/FriendsPlus/Page122/10.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page122/11.jpg', excerciseNum: 0 }],
	],
	123: [
		[{ imgUrl: 'img/FriendsPlus/Page123/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page123/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page123/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page123/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page123/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page123/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page123/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page123/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page123/9.jpg', excerciseNum: 5 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page123/10.jpg', excerciseNum: 0 }],
	],
	124: [
		[{ imgUrl: 'img/FriendsPlus/Page124/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page124/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page124/3.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page124/4.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page124/5.jpg', excerciseNum: 1 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page124/6.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page124/7.jpg', excerciseNum: 2 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page124/8.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page124/9.jpg', excerciseNum: 2 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page124/10.jpg', excerciseNum: 0 }],
	],
	125: [
		[{ imgUrl: 'img/FriendsPlus/Page125/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page125/2.jpg', excerciseNum: 0 },
			{ imgUrl: 'img/FriendsPlus/Page125/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page125/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page125/5.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page125/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page125/7.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page125/8.jpg', excerciseNum: 0 }],
	],
	126: [
		[{ imgUrl: 'img/FriendsPlus/Page126/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page126/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page126/3.jpg', excerciseNum: 0 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page126/4.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page126/5.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page126/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page126/7.jpg', excerciseNum: 4 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page126/8.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page126/9.jpg', excerciseNum: 5 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page126/10.jpg', excerciseNum: 3 },
			{ imgUrl: 'img/FriendsPlus/Page126/11.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page126/12.jpg', excerciseNum: 0 }],
	],
	127: [
		[{ imgUrl: 'img/FriendsPlus/Page127/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page127/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page127/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page127/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page127/5.jpg', excerciseNum: 0 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page127/6.jpg', excerciseNum: 0 }],
	],
	128: [
		[{ imgUrl: 'img/FriendsPlus/Page128/1.jpg', excerciseNum: 0 }],
		[
			{ imgUrl: 'img/FriendsPlus/Page128/2.jpg', excerciseNum: 1 },
			{ imgUrl: 'img/FriendsPlus/Page128/3.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page128/4.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page128/5.jpg', excerciseNum: 3 },
		],
		[
			{ imgUrl: 'img/FriendsPlus/Page128/6.jpg', excerciseNum: 2 },
			{ imgUrl: 'img/FriendsPlus/Page128/7.jpg', excerciseNum: 4 },
		],
		[{ imgUrl: 'img/FriendsPlus/Page128/8.jpg', excerciseNum: 0 }],
	],
	129: [[{ imgUrl: 'img/FriendsPlus/ListPage/129.jpg', excerciseNum: 0 }]],
	130: [[{ imgUrl: 'img/FriendsPlus/ListPage/130.jpg', excerciseNum: 0 }]],
	131: [[{ imgUrl: 'img/FriendsPlus/ListPage/131.jpg', excerciseNum: 0 }]],
	132: [[{ imgUrl: 'img/FriendsPlus/ListPage/132.jpg', excerciseNum: 0 }]],
	133: [[{ imgUrl: 'img/FriendsPlus/ListPage/133.jpg', excerciseNum: 0 }]],
	134: [[{ imgUrl: 'img/FriendsPlus/ListPage/134.jpg', excerciseNum: 0 }]],
	135: [[{ imgUrl: 'img/FriendsPlus/ListPage/135.jpg', excerciseNum: 0 }]],
	136: [[{ imgUrl: 'img/FriendsPlus/ListPage/136.jpg', excerciseNum: 0 }]],
	137: [[{ imgUrl: 'img/FriendsPlus/ListPage/137.jpg', excerciseNum: 0 }]],
	138: [[{ imgUrl: 'img/FriendsPlus/ListPage/138.jpg', excerciseNum: 0 }]],
	139: [[{ imgUrl: 'img/FriendsPlus/ListPage/139.jpg', excerciseNum: 0 }]],
	140: [[{ imgUrl: 'img/FriendsPlus/ListPage/140.jpg', excerciseNum: 0 }]],
	141: [[{ imgUrl: 'img/FriendsPlus/ListPage/141.jpg', excerciseNum: 0 }]],
	142: [[{ imgUrl: 'img/FriendsPlus/ListPage/142.jpg', excerciseNum: 0 }]],
	143: [[{ imgUrl: 'img/FriendsPlus/ListPage/143.jpg', excerciseNum: 0 }]],
	144: [[{ imgUrl: 'img/FriendsPlus/ListPage/144.jpg', excerciseNum: 0 }]],
}
