import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P73-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
            <b style='color:rgb(0,147,70);'>SPEAKING</b> Have you ever found any money that was
            not yours? What did you do with it?
            
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [],
  },
  2: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P73-E2',
    exerciseKey: '/img/FriendsPlus/Page73/E2/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_30.mp3',
    video: '',
    component: T6,
    inputSize: 600,
    maxLength: 55,
    // checkDuplicated: true,

    textareaStyle: { width: 520 },
    titleQuestion: [
      {
        num: '2',
        title: `
          <headphone1 name="2.30"></headphone1>
           Listen to the story about Glen James. What did he do?
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
           <div style='display:flex'>
              <img style="width: 40%" src="/img/FriendsPlus/Page73/E2/E2.jpg" >
              <div style="margin:60px 10px;">
                <b >Answers:</b> <br>
                <textarea id='0' rows='3'></textarea>
              </div>
            </div>

      
        `,
        answer: [
          'He found a lot of money in a shopping centre. Although he was homeless, he handed it in to the police',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P73-E3',
    exerciseKey: '/img/FriendsPlus/Page73/E3/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 120,
    maxLength: 6,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      borderBottom: 'none',
      fontSize: 27,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '3',
        title: `
            Read the
            Listening Strategy
            and the summary
              below. What kind of information do you need for
              each answer?
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div>
            <img style="width:60%" src="/img/FriendsPlus/Page73/E3/E3.jpg">
          </div>
         
            <div style='position: relative;'>
              <div><img src="/img/FriendsPlus/Page73/E3/E3-2.jpg"></div>
              <div style='position: absolute; top: 85px; left: 94px;'>#</div>
              <div style='position: absolute; top: 124px; left: 60px;'>#</div>
              <div style='position: absolute; top: 166px; left: 60px;'>#</div>
              <div style='position: absolute; top: 206px; left: 60px;'>#</div>
              <div style='position: absolute; top: 246px; left: 315px;'>#</div>
              <div style='position: absolute; top: 326px; left: 550px;'>#</div>
              <div style='position: absolute; top: 406px; left: 120px;'>#</div>
              
            </div>

        `,
        answer: ['verb', 'place', 'noun', 'people', 'person', 'noun', 'number'],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P73-E4',
    exerciseKey: '/img/FriendsPlus/Page73/E4/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    inputSize: 250,
    maxLength: 17,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '4',
        title: `
       <b style='color:rgb(145,39,140,1);'>VOCABULARY</b>  Read the <span style='color:rgb(142,37,140,1);'>Look out!</span> box. What personal qualities do you need for the jobs in the photos in
exercise 1, in your opinion? Use words from exercise 2 and the phrases in the <span style='color:rgb(142,37,140,1);'>Look out!</span> box.
        `,
        color: '#8e258c',
      },
    ],

    questions: [
      {
        title: `
     <img src='/img/FriendsPlus/Page73/E4/2.jpg'>
     <img style='padding: 5px' src='/img/FriendsPlus/Page73/E4/4.jpg'>
        `,
        answer: [''],
      },
    ],
    recorder: true,
  },
  5: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P73-E5',
    exerciseKey: '/img/FriendsPlus/Page73/Key/E5answerKey.png',
    audio: 'Audios/2.29-audio.mp3',
    video: '',
    component: T6,
    inputSize: 1300,
    maxLength: 26,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
    },
    hintBox: [
      {
        src: ['hotel receptionist', 'nurse', 'police officer', 'sales assistant', 'sports coach', 'teacher'],
        width: 600,
        fontColor: '#860052',
        borderColor: 'none',
      },
    ],

    textareaStyle: { width: 500 },
    titleQuestion: [
      {
        num: '5',
        title: `
              <headphone1 name='2.29' src='Audios/2.29-tieude.mp3'></headphone1>  Listen to three people discussing the qualities
needed for particular jobs. Which jobs are they talking about? Choose from the ones below. There are three extra jobs. Explain your choices. `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        
          <hintbox id=0></hintbox>
          <b>Answers:</b> <br>
          <div style="display:flex">
            <div style='display:flex;margin-right:40px'><b>1</b>&ensp;  <textarea id='0' rows='3'></textarea></div>
            <div style='display:flex'><b>2</b>&ensp;  <textarea id='1' rows='7'></textarea></div>
          </div>
          <div style='position: relative;'>
          <div style=" position: absolute; top: -158px; left: 1px; display:flex"> <b>3</b>&ensp;  <textarea id='2' rows='3'></textarea></div>
          </div>
        `,
        answer: ['nurse', 'teacher', 'police officer'],
      },
    ],
  },
  6: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P73-E6',
    exerciseKey: '/img/FriendsPlus/Page73/Key/E6answerKey.png',
    audio: 'Audios/2.29-audio.mp3',
    video: '',
    component: T6,
    inputSize: 220,
    maxLength: 18,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      // fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '6',
        title: `
          <headphone1 name="2.29" src="Audios/2.29-tieude.mp3"></headphone1>
           Read the <span style='color:rgb(142,37,140,1);'>Recycle!</span> box. Complete the sentences with the correct form of the adjectives in brackets. Then listen again and check.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <img  src='/img/FriendsPlus/Page73/E5/1.jpg'>
        <div>
            <b>Dialogue 1</b>
           <div style="margin-top: 10px;">
              <b>1</b> <span>The #(sympathetic) you are, the #(good) you'll be at the job.</span> <br> 
              <b>2</b> <span>I don't think idealism is #(useful) as patience.</span> <br>
              <b>3</b> <span>These days they have to work # and #(long) hours</span>
            </div>
        </div>
         
        <div style="margin-top: 20px;">
        <b>Dialogue 2</b>
           <div style="margin-top: 10px;">
           <b>4</b> <span>I think that #(important) quality is punctuality.</span> <br> 
           <b>5</b> <span>You'll certainly be #(popular) if you can laugh at things.</span> <br>
           </div>
        </div>

        <div style="margin-top: 20px;">
        <b>Dialogue 3</b>
           <div style="margin-top: 10px;">
              <b>6</b> <span>I think patience is less #(important) physical courage.</span> <br> 
              <b>7</b> <span>Which do you think is #(useful) quality of the three?</span> <br>
            </div>
        </div>
        `,
        answer: [
          'more sympathetic',
          ' better',
          'as useful',
          'longer',
          ' longer',
          'the most important',
          'more popular',
          'important than',
          'the most useful',
        ],
      },
    ],
  },
  7: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P73-E7',
    // exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    inputSize: 45,
    maxLength: 1,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '7',
        title: `
          <b style='color:rgb(0,147,70);'>SPEAKING</b> Work in pairs. Agree on which are the three most important or useful qualities when you are in the
following situations. Use the adjectives and nouns from exercise 2, and the phrases from the <span style='color:rgb(142,37,140,1);'>Look out!</span> box and exercise 6 to help you.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="margin-left:300px">
          <div style="display:flex; justify-content: space-between;width :450px;">
            <div><b>1</b> At a party</div>
            <div><b>3</b> On a field trip</div>
          </div>

          <div style="display:flex; justify-content: space-between;width :405px;">
           <div><b>2</b> In a job interview</div>
            <div><b>4</b> In lesson</div>
          </div>
          
          
           <img style="width: 550px; margin-top:50px" src='/img/FriendsPlus/Page73/E7/1.jpg'>
        </div>
        `,
        answer: [''],
      },
    ],
    recorder: true,
  },
  8: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P73-E8',
    exerciseKey: '/img/FriendsPlus/Page73/E8/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 95,
    maxLength: 10,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      // color: 'rgb(40, 130, 157)',
      // fontSize: 23,
      marginLeft: -12,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '8',
        title: `
           Complete the questions with the correct form of the verbs from exercise 5. <br/>Do not use any verbs more than once.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
           <div style="margin-top: 20px;">
              <b>1</b> <span> Does anybody o<span style="color: rgb(40,130,157);">we</span> you any money?</span> <br>
              <b>2</b> <span> Have you ever b<span>#</span> money from your family or friends?</span> <br>
              <b>3</b> <span>Have you ever l<span>#</span> anyone any money?</span> <br>
              <b>4</b> <span>Are you s<span>#</span> for anything at the moment?</span> <br>
              <b>5</b> <span>What’s the most expensive thing you’ve ever b<span>#</span> ?</span> <br>
              <b>6</b> <span>Has a shop assistant ever c<span>#</span> you too much for something in a shop?</span> <br>
              <b>7</b> <span>How much do you s<span>#</span> on clothes each month? </span> <br>
              <b>8</b> <span>Have you ever s<span>#</span> anything online?</span> <br>
            </div>
        `,
        answer: ['orrow', 'ent', 'aving', 'ought', 'harged', 'pend', 'old'],
      },
    ],
  },
  9: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P73-E9',
    audio: '',
    video: '',
    component: UI,
    imageStyle: { width: '60%' },
    titleQuestion: [
      {
        num: '9',
        title: `
          <b style='color:rgb(0,147,70);'>SPEAKING</b> Work in pairs. Ask and answer the
          questions in exercise 8. Where you can, give extra information with your answers.

        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page73/E9/1.jpg' }]],
  },
};
export default json;
