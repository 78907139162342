import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	1: {
		unit: 'Grammar Reference',
		id: 'SB10-GBAR-P123-8.1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '1',
				title: 'Put the nouns below into two lists: a) countable and b) uncountable.',
				color: '#860052',
			},
		],
		stylesTextInput: {
			//fontSize: 20,
			//textAlign: "center",
			//borderBottom: "none",
			//backgroundColor: "transparent",
		},
		inputSize: 700,
		exerciseKey: '/img/FriendsPlus/Page123/Key/E1answerKey.png',
		//questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
				<div style='color:rgb(245,140,31,1);display: flex;justify-content: space-around;'>
            <div style='padding-right:50px;'>document</div>
            <div style='padding-right:50px;'>gadget</div>
            <div style='padding-right:50px;'>habit</div>
            <div style='padding-right:50px;'>health</div>
            <div style='padding-right:50px;'>homework</div>
        </div>
        <div style='padding:2px; color:rgb(245,140,31,1);display: flex;justify-content: space-around;'>
          <div style='padding-right:50px;'>information</div>
          <div style='padding-right:50px;'>money</div>
          <div style='padding-right:50px;'>music</div>
          <div style='padding-right:50px;'>software</div>
          <div style='padding-right:50px;'>website</div>
        </div>
       <div style='padding:10px;'>a) #</div>
       <div style='padding:10px;'>b) #</div>
				`,
				answer: ['document, gadget, habit, website', 'health,homework, information, money, music, software'],
			},
		],
	},
	2: {
		unit: 'Starter',
		id: 'SB7-S-P6-E2',
		audio: '',
		video: '',
		component: Circle_Write,
		exerciseKey: '/img/FriendsPlus/Page123/Key/E2answerKey.png',
		inputSize: 240,

		// //hideBtnFooter: true,
		titleQuestion: [
			{
				num: '2',
				title: 'Choose the correct words to complete the sentences.',
				color: '#860052',
				//prefix: "default",
				// prefix: { icons: ['far fa-comment', 'fas fa-pencil-alt'], text: 'CULTURAL' },
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70, padding: 20 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: { border: 'none', borderRadius: '50%', borderColor: 'transparent', fontWeight: 'bold' },
				// initialWordSt<yle: { fontWeight: 'bold', border: 'none',  borderColor: 'transparent' },
				selectWordStyle: { padding: 5, border: '2px solid', borderColor: 'gray' },
				limitSelect: 1,
				listWords: [
					`every / each`,
					`either / both`,
					`few / a_few`,
					`little / a_little`,
					`much / many`,
					`all / every`,
					`every / all`,
					`All_the / Every`,
					`each / every`,
				],
				answers: ['0-0', '1-0', '2-0', '3-4', '4-4', '5-0', '6-0', '7-4', '8-0'],

				initialValue: [],
			},
			Layout: `
         <div style='line-height:50px'>
      <b>1</b>&ensp;In our school, not <input id='0'  type='Circle' /> student studies English.<br>
      <b>2</b>&ensp;John can kick the ball really well with <input id='1'  type='Circle' /> foot.<br>
      <b>3</b>&ensp;The exam was very difficult, so <input id='2'  type='Circle' /> managed to 
      finish. <br>
      <b>4</b>&ensp;This coffee is rather bitter. Can you put <input id='3'  type='Circle' /> sugar 
      in it, please?<br>
      <b>5</b>&ensp;How <input id='4'  type='Circle' /> pizzas shall we buy?<br>
      <b>6</b>&ensp;Do <input id='5'  type='Circle' /> cats like milk?<br>
      <b>7</b>&ensp;The teacher has marked <input id='6'  type='Circle' /> piece of homework.<br>
      <b>8</b>&ensp;<input id='7'  type='Circle' /> student answered the question, but <input id='8'  type='Circle' /> student gave a different answer.<br></div>
        


        
         `,
		},
	},

	3: {
		unit: 'Starter',
		id: 'SB7-S-P6-E3',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page123/Key/E3answerKey.png',
		inputSize: 600,

		titleQuestion: [
			{
				num: '3',
				title: 'Answer the questions with complete sentences. Use the words in brackets.',
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
        <div><b>1</b> Did you finish your homework? (most)</div>
        <div style="padding-left:20px; color:rgb(0,121,133,1);">I finished most of it.</div>
        
        <div><b>2</b> Which friends are you going to invite to your party? (all)</div>
        <div style="padding-left:5px;">#</div>
        
        <div><b>3</b> How many wearable gadgets have you got? (any)</div>
        <div style="padding-left:5px;">#</div>

        <div><b>4</b> Do you prefer tablets or laptops? (like / both)</div>
        <div style="padding-left:5px;">#</div>

        <div><b>5</b> How much money have you got left? (a little)</div>
        <div style="padding-left:5px;">#</div>

        <div><b>6</b> How many students got full marks in the exam? (none)</div>
        <div style="padding-left:5px;">#</div>

        <div><b>7</b> Did you win all of your matches? (every)</div>
        <div style="padding-left:5px;">#</div>

        <div><b>8</b> Do you like Adele and Emeli Sandé? (either)</div>
        <div style="padding-left:5px;">#</div>
        `,
				answer: [
					"I'm going to invite all my friends. /I'm going to invite all of them to my party.",
					"I haven't got any wearable gadgets.",
					'I like both tablets and laptops. / I like both of them.',
					"I've got a little money left.",
					'None (of them / of the students) got full marks in the exam.',
					'Yes, I won every match / every one of my matches / every one of them.',
					"No, I don't like either Adele or Emeli Sandé / either of them.",
				],
			},
		],
	},
	4: {
		unit: 'Starter',
		id: 'SB7-S-P6-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page123/Key/E4answerKey.png',
		component: Circle_Write,

		titleQuestion: [
			{
				num: '1',
				title: `<span style=''>Choose the best words (a, b or c) to complete the sentences.</span>
				`,
				color: '#92278f',
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70, padding: 20 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: { border: 'none', borderRadius: '50%', borderColor: 'transparent', fontWeight: 'bold' },
				// initialWordSt<yle: { fontWeight: 'bold', border: 'none',  borderColor: 'transparent' },
				selectWordStyle: {padding:"0", border: '3px solid', borderColor: 'gray' },
				limitSelect: 1,
				listWords: [
					`a b c`,
					`a b c`,
					`a b c`,
					`a b c`,
					`a b c`,
					`a b c`,
					`a b c`,
					`a b c`,
					`a b c`,
				],
				answers: ['0-0', '1-4', '2-0', '3-4', '4-4', '5-4', '6-0', '7-2', '8-4'],

				initialValue: [],
			},
			Layout: `

			<div style='position:relative;width:27cm'>
						<b>1</b>&ensp; You look freezing. You worn a coat.
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='0' type='Circle'>
					</div>
						<b>2</b>&ensp; Ask that question again; the teacher heard you.
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='1' type='Circle'>
					</div>
						<b>3</b>&ensp; Your brother has been using your email account. You told him your password.
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='2' type='Circle'>
					</div>
						<b>4</b>&ensp; Jack is really upset. You laughed at him!
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='3' type='Circle'>
					</div>
						<b>5</b>&ensp; I sent you a postcard, but you received it yet.
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='4' type='Circle'>
					</div>
						<b>6</b>&ensp; Your phone is dead again. The instruction says you recharge it after use.
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='5' type='Circle'>
					</div>
						<b>7</b>&ensp; I can’t remember when I last saw you. Maybe it was last April, or it been in the summer.
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='6' type='Circle'>
					</div>
						<b>8</b>&ensp; You invite Sam to your party. He’s a really nice guy.
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='7' type='Circle'>
					</div>
						<b>9</b>&ensp; We were nearly late yesterday. We run for the bus. 
					<div style='display:flex; justify-content:space-around;height:10mm;width: 17cm'>
						<input id='8' type='Circle'>
					</div>
					<div style='position:absolute;top:37px;left:87px'>
						should have<br><br>
						shouldn’t have<br><br>
						must have<br><br>
						may not have<br><br>
						may have<br><br>
						don’t have to<br><br>
						might have<br><br>
						have to<br><br>
						have to<br><br>
					</div>
					<div style='position:absolute;top:37px;left:340px'>
						might not have<br><br>
						could have<br><br>
						mustn’t have<br><br>
						can’t have<br><br>
						should have<br><br>
						must have to<br><br>
						must have<br><br>
						must<br><br>
						must<br><br>
					</div>
					<div style='position:absolute;top:37px;left:593px'>
						could have<br><br>
						can’t have<br><br>
						couldn’t have<br><br>
						shouldn’t have<br><br>
						might not have<br><br>
						have to<br><br>
						can’t have<br><br>
						don’t have to<br><br>
						had to<br><br>
					</div>
			</div>

			


        
         `,
		},},
	5: {
		unit: 'Grammar Builder and Reference',
		id: 'FGF11-GBAR-P116-E1',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page123/Key/E5answerKey.png',
		inputSize: 400,
		maxLength: 4000,
		////hideBtnFooter: true,
		titleQuestion: [
			{
				num: '2',
				title: 'Complete the second sentence in each pair to mean the same as the first. Use modals in the past.',
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div><b>1</b> He can't have been at home.</div>
          <div style="padding-left:20px;">He <b style="color:rgb(0,121,133,1);">must have</b> been out.</div>
          
          <div><b>2</b> I shouldn't have refused the invitation.</div>
          <div style="padding-left:20px;">I # accepted the invitation.</div>
          
          <div><b>3</b> She may not have passed the exam.</div>
          <div style="padding-left:20px;">She # failed the exam.</div>
                
          <div><b>4</b> They must have arrived late.</div>
          <div style="padding-left:20px;">They # arrived early.</div>

          <div><b>5</b> You can't have switched the TV off.</div>
          <div style="padding-left:20px;">You # left the TV on.</div>

          <div><b>6</b> We should have paid by credit card.</div>
          <div style="padding-left:20px;">We # paid with cash.</div>
          </div>

          <div><b>7</b> They couldn't have stolen the car during the day.</div>
          <div style="padding-left:20px;">They # stolen the car at night.</div>
          </div>

          <div><b>8</b> The pilot might not have died in the accident.</div>
          <div style="padding-left:20px;">The # pilot survived the accident.</div>
        `,
				answer: [
					'should have/ ought to have',
					'may / might/ could have',
					"can't have",
					'must have',
					"shouldn't have / ought not to have",
					'must have',
					'might have',
				],
			},
		],
	},
}

export default json
