import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
	1: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P99-E1',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page99/key/E1answerKey.png',
		component: T6,
		inputSize: 500,
		// maxLength: 23,
		checkUppercase: true,
		hideBtnFooter: false, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 800 },
		titleQuestion: [
			{
				num: '1',
				title: `
          Read the text. Find the name of the inventor and the 
invention.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div>
						<div>
							
							<img src="/img/FriendsPlus/Page99/E1/1.jpg" width="80%">
						</div>
						<div style="margin-top:20px;font-style:italic">
							<p>Name of the inventor: #</p>
							<p>The invention: #</p>
						</div>
					</div>
				`,
				answer: ['He Liang.', 'A suitcase you can ride like a motorbike.'],
			},
		],
	},
	2: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P99-E2',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page99/key/E2answerKey.png',
		component: T6v2,
		stylesTextInput: { textAlign: 'center' },
		isHiddenCheck: true,
		inputSize: 200,
		maxLength: 12,
		textareaStyle: { width: 400, fontSize: 20 },
		hideBtnFooter: false, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '2',
				title: `
          <span style="color:rgb(145,47,164)">VOCABULARY</span> Read <span style="color:rgb(196,12,77)">Listening Strategy 1.</span> Match the verbs 
and nouns below to make travel-related collocations. 
Some verbs can go with more than one noun.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div>
					<img src="/img/FriendsPlus/Page99/E2/1.jpg" width="80%">
						<div style="border-left-style:solid;border-left-color:rgb(222,137,139)">
						<div style="margin-left:10px">
							<p style="font-weight:bold;color:rgb(198,10,74)">Collocations: verb + noun</p>
							<p ><span style="font-weight:bold;margin-right:15px;">Verbs </span><span style="margin-right:15px">board </span><span style="margin-right:15px">book </span><span style="margin-right:15px">check in </span><span style="margin-right:15px">check into </span><span style="margin-right:15px">hail </span><br><span style="margin-right:15px">hire </span><span style="margin-right:15px">miss </span><span style="margin-right:15px">reach </span><span style="margin-right:15px">stay at </span></p>
							<p ><span style="font-weight:bold;margin-right:15px;">Nouns </span><span style="margin-right:15px">a cab / taxi </span><span style="margin-right:15px">a car </span><span style="margin-right:15px">a destination </span><span style="margin-right:15px">a holiday </span><br><span style="margin-right:15px">a hotel </span> <span style="margin-right:15px">luggage / bags </span><span style="margin-right:15px">a plane / flight </span><span style="margin-right:15px">a room </span><span style="margin-right:15px">a ship </span><span style="margin-right:15px">a train </span></p>
						</div>
						</div>	
						<div>
						<p style="color:blue">to board a plane / a flight / a ship / a train</p>
							<h1>Answer:</h1>
							<p>board # # #</p>
							<p>book # # # #</p>
							<p>check in #</p>
							<p>check into #</p>
							<p>hail #</p>
							<p>hire # #</p>
							<p>miss # #</p>
							<p>reach #</p>
							<p>stay at #</p>
						</div>
					</div>
				`,
				answer: ['a plane / flight', 'a ship', 'a train', 'a cab / taxi', 'a holiday', 'a hotel', 'a room', 'luggage / bags ', 'a hotel ', 'a cab / taxi ', 'a cab / taxi', 'a car', ' a plane / flight', 'a train', 'a destination', 'a hotel'],
			},
		],
	},
	3: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P99-E3',
		audio: '/Audios/3.15-audio.mp3',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page99/key/E3answerKey.png',
		component: T6,
		inputSize: 300,
		// 
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
					<headphone1 name="3.15"> </headphone1> Listen to six short extracts and answer the 
questions. Listen for verbs and nouns from exercise 2 
to help you.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div>
						<div>
							<p>Who or what is</p>
						</div>
						<div style="display:flex">
							<div style="margin-right:280px;margin-left:100px">
								<p><span style="font-weight:bold; margin-right:10px">1 </span> the <span style="font-style:italic">Princess Ariadne</span>?</p>
								<p>#</p>
								<p><span style="font-weight:bold; margin-right:10px">2 </span> a <span style="font-style:italic">tuk tuk</span>?</p>
								<p>#</p>
								<p><span style="font-weight:bold; margin-right:10px">3 </span> Ely?</p>
								<p>#</p>
							</div>
							<div>
								<p><span style="font-weight:bold; margin-right:10px">4 </span> Martins & Cole?</p>
								<p>#</p>
								<p><span style="font-weight:bold; margin-right:10px">5 </span> Damian Fairchild?</p>
								<p>#</p>
								<p><span style="font-weight:bold; margin-right:10px">6 </span> Bellagio?</p>
								<p>#</p>
							</div>
						</div>
					</div>
				`,
				answer: ['a cruise/a ship', 'a cab/a taxi', ' a destination', 'a travel agency', 'a passenger', 'a hotel'],
			},
		],
	},
	4: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P99-E4',
		audio: '/Audios/3.15-audio.mp3',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page99/key/E4answerKey.png',
		component: T6,
		inputSize: 160,
		// maxLength: 4,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 26,
		},
		titleQuestion: [
			{
				num: '4',
				title: `
					<headphone1 name="3.15"> </headphone1> Read <span style="color:rgb(198,17,76)">Listening Strategy 2</span> . Then listen again. 
Which extracts (1–6) are formal? Which words from the 
strategy do they include?
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div>
						<div style="margin-top:30px; margin-left:200px">
							<img src="/img/FriendsPlus/Page99/E4/1.jpg">
						</div>
						<div style="margin-top:20px;margin-left:50px">
							<b>Which extracts (1–6) are formal?</b>
							<p>#, #</p>
							<b>Which words from the strategy do they include? </b>
							<p>#, #, #, #, #</p>
						</div>
					</div>
				`,
				answer: ['3', '5', 'terminate', 'beverages', 'to purchase', 'proceed', 'adjacent to'],
			},
		],
	},
	5: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P99-E5',
		audio: '',
		video: '',
		// exerciseKey: '',
		hideBtnFooter: true,
		component: T6,
		inputSize: 150,
		textareaStyle: { width: 700 },
		maxLength: 10,
		//hideBtnFooter: true,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 23,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '5',
				title: `
          Make the phrases more formal by replacing the underlined 
words with words from the list below.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					
						<div>
							<div style="display:flex">
								<p style="margin-right:40px;color:rgb(197,9,68)">ahead of schedule</p>
								<p style="margin-right:40px;color:rgb(197,9,68)">appreciate</p>
								<p style="color:rgb(197,9,68)">approaching</p>
							</div>
							<div style="display:flex">
								<p style="margin-right:40px;color:rgb(197,9,68)">approximately</p>
								<p style="margin-right:40px;color:rgb(197,9,68)">complimentary</p>
								<p style="color:rgb(197,9,68)">in due course</p>
							</div>
							<div style="display:flex">
								<p style="margin-right:40px;color:rgb(197,9,68)">inform</p>
								<p style="margin-right:40px;color:rgb(197,9,68)">refreshments</p>
								<p style="margin-right:40px;color:rgb(197,9,68)">regret</p>
								<p style="margin-right:40px;color:rgb(197,9,68)">remain</p>
								<p style="color:rgb(197,9,68)">request</p>
							</div>
							
						</div>
					<div>
						<div>
							<p><span style="font-weight:bold;margin-right:10px">1</span> I <span style="text-decoration: underline;">am sorry</span> to <span style="text-decoration: underline;">tell</span> you that …</p>
							<p><span style="font-weight:bold;margin-right:10px">2</span> I would <span style="text-decoration: underline;">ask</span> that passengers <span style="text-decoration: underline;">stay</span> seated …</p>
							<p><span style="font-weight:bold;margin-right:10px">3</span> … give you an update  <span style="text-decoration: underline;">later</span>.</p>
							<p><span style="font-weight:bold;margin-right:10px">4</span> … pass through the cabin with <span style="text-decoration: underline;">free</span> <span style="text-decoration: underline;">food and drink</span>. </p>
							<p><span style="font-weight:bold;margin-right:10px">5</span> … we are <span style="text-decoration: underline;">getting near</span> our destination. </p>
							<p><span style="font-weight:bold;margin-right:10px">6</span> … we should be there <span style="text-decoration: underline;">about</span> ten minutes <span style="text-decoration: underline;">early</span>. </p>
							<p><span style="font-weight:bold;margin-right:10px">7</span> We <span style="text-decoration: underline;">understand</span> that this is inconvenient … </p>
						</div>

						<u><i>Answer:</i></u>
						<textarea id='0' rows='6'></textarea>
					</div>
						
					</div>
						
				`,
				answer: [''],
			},
		],
	},
	6: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P99-E6',
		audio: '/Audios/3.16-audio.mp3',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page99/key/E6answerKey.png',
		component: T6,
		inputSize: 300,
		maxLength: 10,
		hideBtnFooter: false,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 23,
			fontWeight: 'bold',
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '6',
				title: `
          <headphone1 name="3.16"> </headphone1> Listen to two formal extracts and check your 
answers to exercise 5.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					
						<div>
							<div style="display:flex">
								<p style="margin-right:40px;color:rgb(197,9,68)">ahead of schedule</p>
								<p style="margin-right:40px;color:rgb(197,9,68)">appreciate</p>
								<p style="color:rgb(197,9,68)">approaching</p>
							</div>
							<div style="display:flex">
								<p style="margin-right:40px;color:rgb(197,9,68)">approximately</p>
								<p style="margin-right:40px;color:rgb(197,9,68)">complimentary</p>
								<p style="color:rgb(197,9,68)">in due course</p>
							</div>
							<div style="display:flex">
								<p style="margin-right:40px;color:rgb(197,9,68)">inform</p>
								<p style="margin-right:40px;color:rgb(197,9,68)">refreshments</p>
								<p style="margin-right:40px;color:rgb(197,9,68)">regret</p>
								<p style="margin-right:40px;color:rgb(197,9,68)">remain</p>
								<p style="color:rgb(197,9,68)">request</p>
							</div>
							
						</div>
						<div>
							<p><span style="font-weight:bold;margin-right:10px">1</span> I <span style="text-decoration: underline;">am sorry</span> to <span style="text-decoration: underline;">tell</span> you that …</p>
							<p>#, #</p>
							<p><span style="font-weight:bold;margin-right:10px">2</span> I would <span style="text-decoration: underline;">ask</span> that passengers <span style="text-decoration: underline;">stay</span> seated …</p>
							<p>#, #</p>
							<p><span style="font-weight:bold;margin-right:10px">3</span> … give you an update  <span style="text-decoration: underline;">later</span>.</p>
							<p>#</p>
							<p><span style="font-weight:bold;margin-right:10px">4</span> … pass through the cabin with <span style="text-decoration: underline;">free</span> <span style="text-decoration: underline;">food and drink</span>. </p>
							<p>#, #</p>
							<p><span style="font-weight:bold;margin-right:10px">5</span> … we are <span style="text-decoration: underline;">getting near</span> our destination. </p>
							<p>#</p>
							<p><span style="font-weight:bold;margin-right:10px">6</span> … we should be there <span style="text-decoration: underline;">about</span> ten minutes <span style="text-decoration: underline;">early</span>. </p>
							<p>#, #</p>
							<p><span style="font-weight:bold;margin-right:10px">7</span> We <span style="text-decoration: underline;">understand</span> that this is inconvenient … </p>
							<p>#</p>
							</div>
						
					</div>
						
				`,
				answer: [' regret', 'inform', 'request', 'remain', 'in due course.', 'complimentary', 'refreshments.', 'approaching', 'approximately', 'ahead of schedule.', 'appreciate'],
			},
		],
	},
	7: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P99-E7',
		audio: '',
		video: '',
		// exerciseKey: '',
		component: T6,
		inputSize: 80,
		maxLength: 5,
		hideBtnFooter: true,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 23,
		},
		isHiddenCheck: true,
		//hideBtnFooter: true,
		recorder: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '7',
				title: `
					<span style="color:rgb(0,147,69)">SPEAKING</span> Work in pairs. Tell your partner about a journey 
on which you experienced travel problems, for example, 
a delay. What was the problem? How did it affect your 
journey?
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					
				`,
				answer: ['about', 'with', 'about', 'in', 'to'],
			},
		],
	},

}

export default json
