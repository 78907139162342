import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 7',
    id: 'FGF11-U7-P92-E1',
    audio: '',
    video: '',
    component: T6,
    inputSize: 130,
    recorder: true,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
    },
    exerciseKey: 'img/FriendsPlus/Page92/E1/Key/E1answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `
				<span style='color:rgb(0,147,70);'>SPEAKING</span> 
        Work in pairs. Match photos A–C with three of 
        the types of show below. Then choose one photo each 
        and describe it to your partner.
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    inputSize: 350,
    maxLength: 90,
    textAlign: 'center',
    // //hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>
          <div style='font-size:23px; position: relative'>
              <div style="color:rgb(0,147,70); margin-bottom:10px  ">
                <div><b>Cultural events and shows</b>
                &ensp;an art exhibition
                &ensp;a circus 
                &ensp;a classical concert
                &ensp;a comedy club
                &ensp;a magic show 
                &ensp;a musical
                &ensp;an open-air theatre
                &ensp;a piano recital 
              </div>
              <div style='position:relative; display:flex; flex-direction: row; margin-top:30px'>
              
              <img style=" width:10cm; height:11cm;" src='img/FriendsPlus/Page92/E1/1.jpg' />
              <div style='margin-left: 50px;display:flex; flex-direction: column; '>
              <b>ANSWER:</b><br>
              &ensp;<b>A - #</b>
              &ensp;<b>B - #</b>
              &ensp;<b>C - #</b>
              </div>
             
              </div>
              
          </div>
			  `,
        answer: ['a musical', 'a magic show', 'an open-air theatre'],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 7',
    id: 'FGF11-U7-P92-E2',
    audio: '',
    video: '',
    component: T6,
    inputSize: 130,
    maxLength: 20,
    hideBtnFooter: true,
    textAlign: 'center',
    recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page92/E1/Key/E1answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
				<span style='color:rgb(0,147,70); font-size:22px'>SPEAKING</span> Work in pairs. Compare and contrast photos A–C from exercise 1.
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>

              <div style='position:relative; display:flex; flex-direction: row; margin-top:10px'>
              
              <img style=" width:8cm; height:10cm;" src='img/FriendsPlus/Page92/E1/1.jpg' />
              <img style=" width:12cm; height:7cm;" src='img/FriendsPlus/Page92/E2/1.jpg' />
             
            
              
          </div>
			  `,
        answer: [''],
      },
    ],
  },
  3: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P92-E3',
    audio: '/Audios/3.09-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page92/E3/Key/E3answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '3',
        title: `
        <span style='font-size: 23px; width:90%'><headphone1 name='3.09' src='Audios/3.09.mp3'></headphone1>&ensp; Read the task above. Then listen to a student doing the task. Which show does the student choose? Which of the details below does she also mention?</span>
        `,
        color: '#92278f',
        prefix: { icon: [''], text: '' },
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 41,
        textareaStyle: { width: 700 },
        inputStyle: { width: 450, fontSize: 22, textAlign: 'center' },
        answers: ['She chooses the musical'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: '50%',
          // fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          borderBottom: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 5,
        listWords: [
          `When_to_go_on_the_outing<br> How_to_book_tickets<br> Who_will_pay_for_the_tickets<br> How_to_travel_to_the_show<br> What_to_eat_and_drink`, //0
        ],
        answers: ['0-0', '0-4', '0-6'],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;  '>
        <b>Which of the details below does she also mention?</b>
        <div style=" display:flex;line-height:50px">
          <div>
            <ul>
              <li> </li>
              <li> </li>
              <li> </li>
              <li> </li>
              <li> </li>
            </ul>
          </div>
          <div style='margin-right:40px'>
            <input id='0' type='Circle'/>
          </div>
          </div>
        <div >
            <b>Which show does the student choose?</b><br>
            <input id='0' ></input><br>

          
          </div>
          
		     
        
      `,
    },
  },
  4: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P92-E4',
    // audio: 'Audios/3.05.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page92/E4/Key/E4answerKey.png',
    component: DesignUnderLine,
    totalInput: 2,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '4',
        title: `
        <div><span style='color: #92278f'>KEY PHRASES </span> Read <span style='color: rgb(0,147,70)'>Speaking Strategy 1. </span> 
        Then look at the phrases for expressing preferences. Tick the expression you think is the strongest in each group.
        </div>
			  `,
        color: '#7c084f',
      },
    ],
    questionImage: [
      // IMG
      [{ url: 'img/FriendsPlus/Page92/E4/1.jpg' }],
      [{ url: 'img/FriendsPlus/Page92/E4/2.jpg' }],
      //Q1
      [
        { url: 'img/FriendsPlus/Page92/E4/3.jpg' },
        { url: 'img/FriendsPlus/Page92/E4/4.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page92/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page92/E4/6.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page92/E4/7.jpg' },
      ],
      //Q2
      [
        { url: 'img/FriendsPlus/Page92/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page92/E4/9.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page92/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page92/E4/11.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page92/E4/12.jpg' },
      ],
      //Q3
      [
        { url: 'img/FriendsPlus/Page92/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page92/E4/14.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page92/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page92/E4/16.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page92/E4/17.jpg' },
      ],
      //Q4
      [
        { url: 'img/FriendsPlus/Page92/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page92/E4/19.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page92/E4/20.jpg' },
        { url: 'img/FriendsPlus/Page92/E4/21.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page92/E4/22.jpg' },
      ],
      //Q5
      [
        { url: 'img/FriendsPlus/Page92/E4/23.jpg' },
        { url: 'img/FriendsPlus/Page92/E4/24.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page92/E4/25.jpg' },
        { url: 'img/FriendsPlus/Page92/E4/26.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page92/E4/27.jpg' },
      ],
      //Q6
      [
        { url: 'img/FriendsPlus/Page92/E4/28.jpg' },
        { url: 'img/FriendsPlus/Page92/E4/29.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page92/E4/30.jpg' },
      ],
    ],
  },
  5: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P92-E5',
    audio: '/Audios/3.09-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page92/E5/Key/E5answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '5',
        title: `
        <span style='font-size: 22px;'><headphone1 name='3.09' src='Audios/3.09.mp3'></headphone1>&ensp;
        <span style='color: rgb(96,164,211)'> PRONUNCIATION </span> Read <span style='color: rgb(0,147,70)'>Speaking Strategy 2. </span>
        Then listen again. Which phrases from exercise 4 do 
        you hear? Which ones are introduced by a phrase from 
        the strategy? Practice saying them. Pay attention to the 
        stressed words </span>
        `,
        color: '#92278f',
        prefix: { icon: [''], text: '' },
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 141,
        inputStyle: { width: 600, fontSize: 25, textAlign: 'left' },
        answers: ['To be honest, …; Personally, …'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          borderBottom: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 11,
        listWords: [
          `I'm_a_big_fan_of_…<br> I'm_quite_into_…<br> I'm_really_keen_on_…<br> I_absolutely_love_…<br> I_enjoy_…_very_much<br> `, //0
          `is_not_really_my_thing<br> I'm_not_really_into_…<br> doesn't_do_anything_for_me<br> I've_never_been_that_keen_on_…<br> I_really_can't_stand_…<br> I’m_not_a_big_fan_of_…`, //1
        ],
        answers: ['0-4', '0-8', '1-0', '1-8'],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;  '>
        <div style="display: flex; border-left: 3px solid rgb(0,147,70); padding:10px">
        <div>
          <div style='color:rgb(0,147,70); margin-right: 280px; '>Like</div>
		      <div><input id='0' type='Circle'/></div>
        </div>
        <div>
          <div style='color:rgb(0,147,70)'>Dislike</div>
          <div><input id='1' type='Circle'/></div>
        </div>
        </div>

        <div style='margin-top:20px'>
        <img style='width:16cm' src='img/FriendsPlus/Page92/E5/1.jpg'>
          <div>Which ones are introduced by a phrase from the strategy?</div>
          <div><i style='font-size:18px; color: rgb(152,198,204)'>*I’m afraid …; I must say …, ...</i></div>
          <div> <input id='0'></div>
        </div>
		  	    
		    </div>
	    </div>
      `,
    },
  },
  6: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P92-E6',
    // exerciseKey: 'img/FriendsPlus/Page92/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    hideBtnFooter: true,
    inputSize: 130,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '6',
        title: `
        <div style='margin-bottom: 10px;font-size: 23px'><span style='color: rgb(45,206,137)'>SPEAKING </span> 
        Then listen again. Which phrases from exercise 4 do 
        you hear? Which ones are introduced by a phrase from 
        the strategy? Practice saying them. Pay attention to the 
        stressed words.
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>
          <div style='font-size:24px; position: relative'>
            <img style="width:24cm; height:5cm" src='img/FriendsPlus/Page92/E6/1.jpg' />
          <div style='display: flex; margin-top: 20px'>
            <img style="width:13cm; height:5cm" src='img/FriendsPlus/Page92/E6/2.jpg' />
            <img style="width:13cm; height:5cm" src='img/FriendsPlus/Page92/E6/3.jpg' />
          </div>
          </div>
              
        </div>
          
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
