import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P75-E1',
    audio: '',
    video: '',
    component: UI,

    titleQuestion: [
      {
        num: '1',
        title: `
          <b style='color:rgb(0,147,70);'>SPEAKING</b> Work in pairs. What qualities do you think you
need to be a good journalist? Why? Discuss the qualities below and your own ideas.
        `,
        color: '#92278f',
      },
    ],

    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page75/E1/1.jpg' }]],
  },
  2: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P75-E2',
    exerciseKey: '/img/FriendsPlus/Page75/Key/E2answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 200,
    maxLength: 50,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
      marginLeft: -12,
    },
    textareaStyle: { width: 600 },
    titleQuestion: [
      {
        num: '2',
        title: `
           Complete the article with suitable words.
        `,
        color: '#860052',
        prefix: { icon: [''], text: 'USE OF ENGLISH' },
      },
    ],

    questions: [
      {
        title: `
 
        <div style="display:flex; justify-content: space-between; line-height:9px;" >
              <img src="/img/FriendsPlus/Page75/E2/1.jpg">
          <div style='margin-left:70px; padding-top: 170px;'>
            <h1 style='margin-left:0px;'>Answers:</h1> <br>
              <b>1</b><span>#</span> <br>
              <b>2</b> <span> #</span> <br>
              <b>3</b> <span> #</span> <br>
              <b>4</b> <span> #</span> <br>
              <b>5</b> <span> #</span> <br>
              <b>6</b> <span> #</span> <br>
              <b>7</b> <span> #</span> <br>
              <b>8</b> <span> #</span> <br>
            
          </div>
        </div>
        `,
        answer: [`as`, `that`, `who `, `of `, `in `, `about `, `for `, `as`],
      },
    ],
  },
  3: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P75-E3',
    exerciseKey: '/img/FriendsPlus/Page75/Key/E3answerKey.png',
    audio: 'Audios/2.30-audio.mp3',
    video: '',
    component: T6,
    inputSize: 900,
    maxLength: 550,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
      marginLeft: -12,
    },
    textareaStyle: { width: 970 },
    titleQuestion: [
      {
        num: '3',
        title: `
               <headphone1 name="2.30" src="Audios/2.30-tieude.mp3"></headphone1>
           Listen and check your answers to exercise 2. Which of the qualities from exercise 1 do you think Nellie Bly showed? Justify your answer with evidence from the text.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div >
              <img src="/img/FriendsPlus/Page75/E3/1.jpg">
          </div>
          <div >
      <div >
      <div><b>Answers</b></div>
      <div style="display: flex;"> <span style="margin-left: 20px;"> <textarea id='0' rows='3'></textarea></span></div>
   </div>
        `,
        answer: [
          `Nellie was ambitious and determined to be an investigative journalist who wrote about serious issues. She was also stubborn, because she wouldn't let anything stop her doing investigative journalism`,
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P75-E4',
    exerciseKey: '/img/FriendsPlus/Page75/Key/E4answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 180,
    maxLength: 1210,
    stylesTextInput: {
      textAlign: 'center',
    },
    textareaStyle: { width: 500 },

    titleQuestion: [
      {
        num: '4',
        title: `
          Read the <span style='color:rgb(192,13,69);'>Listening Strategy</span>. Add one more phrase from the list below to each group (a–d).
          <img src="img/FriendsPlus/Page75/E4/1.png" />
          <div style='color:rgb(192,13,69);display: flex;justify-content: space-around; padding:20px'>
          <div>for instance</div>
          <div>for that reason</div>
          <div>however</div>
          <div>indeed</div>
          </div>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="margin-top: 10px;">
              <b>a contrast: </b> <span><i>mind you, though,</i>#</span> <br> 
              <b>b result: </b> <span><i> as a result, consequently,</i>#</span> <br> 
              <b>c emphasis: </b> <span><i> in fact,</i>#</span> <br> 
              <b>d example: </b> <span><i>for example,</i>#</span> <br> 
            </div>
        `,
        answer: ['however', 'for that reason', 'indeed', 'for instance'],
      },
    ],
  },

  5: {
    unit: 'Introduction',
    id: 'FGF11-I-P11-E6',
    audio: 'Audios/2.31-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page75/Key/E5answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '5',
        title: `
				<headphone1 name="2.31" src="Audios/2.31-tieude.mp3"></headphone1>
        Listen to six sentences and the linking words which follow. Circle the endings (a or b) that make sense.
      `,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'none', borderRadius: '50%', borderColor: 'transparent', fontWeight: 'bold' },
        // initialWordSt<yle: { fontWeight: 'bold', border: 'none',  borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: '2px solid', borderColor: 'gray' },
        limitSelect: 1,
        listWords: ['a <br> b', 'a <br> b', 'a <br> b', 'a <br> b', 'a <br> b', 'a <br> b'],
        answers: ['0-0', '1-4', '2-4', '3-0', '4-4', '5-0'],

        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
        <b>1</b>   
        <div  style='display:flex;margin-left:20px'>
            <div>
              <input id='0' type='Circle' />
            </div>
            <div>
              … Nellie was not interested in that.<br>
              … Nellie was happy just to have a job.
            </div>
          </div>
      </div>
        
        <div style='display:flex;margin-top:10px'>
        <b>2</b>   
        <div  style='display:flex;margin-left:20px'>
            <div>
              <input id='1' type='Circle' />
            </div>
            <div>
              … this did not affect Nellie’s ambitions.<br>
              … Nellie was one of the first.
            </div>
            </div>
        </div>  

        <div style='display:flex;margin-top:10px'>
        <b>3</b>   
        <div  style='display:flex;margin-left:20px'>
            <div>
              <input id='2' type='Circle' />
            </div>
            <div>
              … her reports were truthful and well written.<br>
              … she wrote about children who worked in factories.
            </div>
            </div>
        </div>  

        <div style='display:flex;margin-top:10px'>
        <b>4</b>   
        <div  style='display:flex;margin-left:20px'>
            <div>
              <input id='3' type='Circle' />
            </div>
            <div>
              … she was not popular with those people.<br>
              … she was shocked by what she saw.
            </div>
            </div>
        </div>  

        <div style='display:flex;margin-top:10px'>
        <b>5</b>   
        <div  style='display:flex;margin-left:20px'>
            <div>
              <input id='4' type='Circle' />
            </div>
            <div>
             … the pay was not good.<br>
             … they started before sunrise.
            </div>
            </div>
        </div>  

        <div style='display:flex;margin-top:10px'>
        <b>6</b>   
        <div  style='display:flex;margin-left:20px'>
            <div>
              <input id='5' type='Circle' />
            </div>
            <div>
             … nobody seemed to know or care.<br>
             … the staff did not treat the patients well.
            </div>
            </div>
        </div>  
            
      
         
        `,
    },
  },
  6: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P75-E6',
    exerciseKey: '/img/FriendsPlus/Page75/Key/E6answerKey.png',
    audio: 'Audios/2.32-audio.mp3',
    video: '',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      // borderBottom: 'none',
      fontWeight: 'bold',
      // fontSize: 23,
      marginLeft: -12,
    },
    textareaStyle: { width: 600 },
    titleQuestion: [
      {
        num: '6',
        title: `
           <headphone1 name="2.32" src="Audios/2.32-tieude.mp3"></headphone1>
          Listen to the first part of a radio interview about Nellie Bly's most famous adventure: a trip around the world. Are these sentences true or false? Write T or F
          
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div >
          <b>1</b> <span > Nellie's adventure was Joseph Pulitzer's idea.</span> #<br>
          <b>2</b> <span > Elizabeth Bisland worked for a different newspaper. </span># <br>
          <b>3</b> <span > Nellie began her journey in 1888.</span> #<br>
          <b>4</b> <span > Nellie and Elizabeth travelled together some of the way.</span># <br>          
        </div>
        `,
        answer: ['F', 'T', 'F', 'F'],
      },
    ],
  },
  7: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P75-E6',
    exerciseKey: '/img/FriendsPlus/Page75/Key/E7answerKey.png',
    audio: 'Audios/2.33-audio.mp3',
    video: '',
    component: T6,
    inputSize: 800,
    maxLength: 1,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      // fontSize: 23,
      //marginLeft: -12,
    },
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: '7',
        title: `
           <headphone1 name="2.33" src="Audios/2.33-tieude.mp3"></headphone1>
          Who do you think could go around the world first, Nellie or Elizabeth? Listen to the second part of the interview and check your answer
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div >
           <textarea id='0' rows='2'></textarea>    
        </div>
        `,
        answer: [
          'Nellie managed to win the race and go around the world in 72 days, six hours, eleven minutes and fourteen seconds',
        ],
      },
    ],
  },

  8: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P75-E1',
    audio: '',
    video: '',
    component: UI,

    titleQuestion: [
      {
        num: '8',
        title: `
  <b style = 'color:rgb(0,147,70);'> SPEAKING</b >
    In what ways do you think Nellie Bly is a good role model ? Give examples from her life to support your opinions.Use the phrases below and your own ideas.`,

        color: '#92278f',
      },
    ],

    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page75/E8/1.jpg' }]],
  },
};
export default json;
