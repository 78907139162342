import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P38-E1',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    recorder: true,
    maxLength: 16,
    exerciseKey: 'img/FriendsPlus/Page38/Key/E1answerKey.png',
    question: [],
    textAlign: 'center',
    titleQuestion: [
      {
        num: '1',
        title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Look at the photo and the title of the article.
What do you think the article is about? `,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    hintBox: [
      {
        src: [
          '<b>Adventure activities</b>',
          'art club',
          'astronomy club',
          'baking club',
          'computer club',
          'film club',
          'fitness club',
          'photography club',
          'school choir',
          'science club',
        ],
        width: 1100,
        fontColor: '#2887c6',
        borderColor: 'none',
      },
    ],
    // maxLength: 14,
    isHiddenCheck: true,
    textareaStyle: { width: 450 },
    inputSize: 450,
    // textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: ` 
           <div style='margin-left:200px'>
            <img style='height:11cm'  src="img/FriendsPlus/Page38/E1/1.jpg" />

            

           </div>
          `,
        answer: [''],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P38-E2',
    exerciseKey: 'img/FriendsPlus/Page38/Key/E2answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `Read the article and check your ideas.`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    maxLength: 9,
    hintBox: [
      {
        src: ['<b>Parts of the body</b>', 'ankle', 'wrist'],
        width: 900,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: T6,
    // isHiddenCheck: true,
    textareaStyle: { width: 340 },
    inputSize: 150,
    textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex'>
            <img style='height:11cm'  src="img/FriendsPlus/Page38/E1/1.jpg" />

            <u style='font-style:italic;font-weight:bold'>Answers:</u> 
            <div>
              <textarea id='0' rows='3'></textarea>
            </div>

           </div>
           
          `,
        answer: ['Using 3D printers to print human organs and body parts.'],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P38-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page38/Key/E3answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `Match the highlighted structures in the article with rules
a–d in the <span style="color:#ee9933">Learn this!</span> box below. `,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    maxLength: 19,
    hintBox: [
      {
        src: ['<b>Parts of the body</b>', 'ankle', 'blood', 'bottom', 'brain', 'calf', 'cheek', 'chin', 'LOL'],
        width: 900,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: T6,
    // isHiddenCheck: true,
    textareaStyle: { width: 340 },
    inputSize: 340,
    // textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex'>
            <img style='height:11cm'  src="img/FriendsPlus/Page38/E3/1.jpg" />
						
            <div>
            	<u style='font-style:italic;font-weight:bold'>Answers:</u> <br>
              <b>a</b>&ensp;#<br>
							<div style='display:flex'>
              	<b>b</b>&ensp;<textarea id='3' rows='3'></textarea><br>
							</div>
              <b>c</b>&ensp;#<br>
              <b>d</b>&ensp;#<br>
            </div>

           </div>
           
          `,
        answer: [
          `will be able, won't die`,
          'could save, could play',
          'might not come',
          'will probably be, are certain that bio-printing will revolutionise',
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P38-E4',
    audio: '',
    video: '',
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page38/Key/E4answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Work in pairs. Make predictions about your
partner’s future life using the prompts. Use will / may /
might / could and phrases from the <span style="color:#ee9933">Learn this!</span> box above.
Your partner says if he / she agrees or not.
`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],

    component: T6,
    // isHiddenCheck: true,
    textareaStyle: { width: 340 },
    inputSize: 150,
    textAlign: 'center',
    recorder: true,
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='display:flex;line-height:50px'>
          <img src="img/FriendsPlus/Page38/E4/1.jpg" style="width:15cm; height: 5.5cm; margin-left=-80px;" />
           <div>
              <b>1</b>&ensp;What / study at university?<br>
              <b>2</b>&ensp;When / leave home?<br>
              <b>3</b>&ensp;What job / do?<br>
              <b>4</b>&ensp;When / get married?<br>
              <b>5</b>&ensp;How many children / have?<br>
              <b>6</b>&ensp;What type of car / drive?<br>
           </div>
          
            

          </div>
           
          `,
        answer: ['Using 3D printers to print human organs and body parts.'],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P38-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page38/Key/E5answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `Study the examples in the <span style="color:#ee9933">Learn this!</span> box below and
complete the rules with the correct verbs and tenses.
`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],

    hintBox: [
      {
        src: [
          '<b>Compound nouns</b>',
          'flood lights',
          'football pitch',
          'main road',
          'mountain range',
          'safety net',
          'sea shore',
          'swimming pool',
          'tennis court',
          'tennis player',
          'tower block',
        ],
        width: 800,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: T6,
    // maxLength: 14,
    isHiddenCheck: true,
    textareaStyle: { width: 530, fontSize: 22 },
    inputSize: 170,
    stylesTextInput: { fontSize: 22, height: 23, borderBottom: 'none' },
    textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
          <div style='position: relative;margin-left:20px'>
            <img src="img/FriendsPlus/Page38/E5/1.jpg" />
            <span style='position: absolute; top: 53px; left: 496px'>#</span>
            <span style='position: absolute; top: 86px; left: 268px'>#</span>
            <span style='position: absolute; top: 260px; left: 260px'>#</span>
          </div>
                 
                 
           
          `,
        answer: ['present simple', 'will', 'might'],
      },
    ],
  },

  6: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P38-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page38/Key/E6answerKey.png',
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '6',
        title: `Complete the article below with the correct form of the
verbs in brackets. Use the first conditional. In gaps 4 and 6,
use a modal verb to make the prediction less certain.`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    hintBox: [
      {
        src: [
          '<b>Compound nouns</b>',
          'flood lights',
          'football pitch',
          'main road',
          'mountain range',
          'safety net',
          'sea shore',
          'swimming pool',
          'tennis court',
          'tennis player',
          'tower block',
        ],
        width: 800,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: T6v2,
    maxLength: 50,
    // isHiddenCheck: true,
    textareaStyle: { width: 540 },
    inputSize: 145,
    stylesTextInput: { fontSize: 23 },
    textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
          <div style='position: relative;margin-left:20px'>
            <img style='height:175mm;width:270mm' src="img/FriendsPlus/Page38/E6/1.jpg" />
            <div style='position: absolute; top: 30px; left: 265px; width:185mm;font-size:23px'>
							Some animals, like lizards and worms, have an 
							amazing ability to grow new body parts if they 
							lose them. Salamanders can grow new legs and 
							some worms can re-grow every part of their body, 
							including their head! If scientists <sup>1</sup>#(can)
							 discover how this happens, in theory it 
							<sup>2</sup>#(be) possible to re-grow human body 
							parts too. Very few scientists are working on this 
							problem now, but if governments  <sup>3</sup>#(start)
							 to spend more money on research, then 
							this dream<br>  <sup>4</sup><input id='3' width='650px'></input>(become)  a reality. 
							Amazingly, some worms have no limit on lifespan, 
							as they continually re-grow their bodies as they age. 
							So if we <sup>5</sup>#(learn) to repair human 
							limbs and organs, it <sup>6</sup><input id='5' width='300px'></input>(be) possible to 
							prevent us from dying. But that is a long way in the 
							future.
						</div>
          </div>
              
          `,
        answer: [
          'could / can | could | can ',
          'will be',
          'start',
          'will / may / might / could become | will become | may become | might become | could become',
          'learn',
          'may / might / could be | may be | might be | could be',
        ],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P38-E7',
    audio: '',
    video: '',
    hideBtnFooter: true,
    // exerciseKey: 'img/FriendsPlus/Page38/Key/E7answerKey.png',
    titleQuestion: [
      {
        num: '7',
        title: `<b style="color:rgb(10 127 64)">SPEAKING</b>  Work in pairs. Ask and answer using the first
conditional and the ideas below.
`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    hintBox: [
      {
        src: [
          '<b>Compound nouns</b>',
          'flood lights',
          'football pitch',
          'main road',
          'mountain range',
          'safety net',
          'sea shore',
          'swimming pool',
          'tennis court',
          'tennis player',
          'tower block',
        ],
        width: 800,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: T6,
    maxLength: 1,
    // isHiddenCheck: true,
    textareaStyle: { width: 540 },
    inputSize: 40,
    textAlign: 'center',
    recorder: true,
    //hideBtnFooter: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div>
            <b>1</b>&ensp;You feel ill tomorrow morning.<br>
            <b>2</b>&ensp;The weather is fine at the weekend.<br>
            <b>3</b>&ensp;You get poor marks in your next English test.<br>
            <b>4</b>&ensp;Your best friend forgets your birthday.<br>
            <b>5</b>&ensp;Your own ideas<br>
          </div>
            <img src="img/FriendsPlus/Page38/E7/1.jpg" />

          `,
        answer: [''],
      },
    ],
  },
  8: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P38-E8',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page24/Key/E4answerKey.png',
    question: [],
    titleQuestion: [
      {
        num: '8',
        title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Work in groups. Which facility from exercise 7
would you like most for your school? Why? Can the whole
class agree on one choice?`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [[{ url: 'img/FriendsPlus/Page38/E8/1.pNg' }]],
  },
};

export default json;
