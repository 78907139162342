import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
// import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Unit 8',
    id: 'FGF11-U8-P105-E1',
    audio: '',

    video: '',
    exerciseKey: 'img/FriendsPlus/Page104/E1/Key/answerKey.png',
    component: T6,
    inputSize: 280,
    maxLength: 23,
    recorder: true, // Show recorder button
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '1',
        title: `
        <span style="font-weight:bold; color:rgb(90,161,86)">SPEAKING</span> Describe the photo. Have you ever stayed in a
youth hostel? How are they different from hotels?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div style="display: flex;">
            <div><img src="img/FriendsPlus/Page105/E1/1.gif" style="width: 700px;margin-left:200px" /></div>
           
          </div>

        
        
				`,
        answer: [],
      },
    ],
  },
  2: {
    unit: 'Unit 8',
    id: 'FGF11-U8-P105-E2',
    audio: '',

    video: '',
    exerciseKey: 'img/FriendsPlus/Page105/key/2.png',
    component: T6,
    inputSize: 700,
    maxLength: 100,
    stylesTextInput: {
      fontSize: 27,
    },
    // recorder: true, // Show recorder button
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '2',
        title: `
       Read the writing task and the letter. Has the writer
covered all four points in the task?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
            <div style="background-color:rgb(209,222,239);border:0px solid  black;box-shadow: 3px 3px 5px rgba(0,0,0,0.4);width:800px; padding:5px 10px;line-height:2;">
						You are planning to visit Bristol in the UK this summer with a small group of friends and have been given
						 a recommendation for a youth hostel. Write a letter to the youth hostel in which you
						 <ul>
						 <li>give information about your group. </li>
						 <li>inform them of the dates you want to stay and ask about availability. </li>
						 <li>ask about food and drink. </li>
						 <li>ask for suggestions about places to visit in Bristol.
</li>
						 </ul>
						
						</div>
           
						<div style='margin-top:10px'><b><u>Answer:</u></b> <input/>
</div>
        
				`,
        answer: ['yes, the writer has included all elements of the task.'],
      },
    ],
  },
  3: {
    unit: 'Unit 8',
    id: 'FGF11-U8-P105-E3',
    audio: '',

    video: '',
    exerciseKey: 'img/FriendsPlus/Page105/key/E3answerKey.jpg',
    component: T6,
    inputSize: 280,
    maxLength: 23,
    // recorder: true, // Show recorder button
    textareaStyle: {
      width: 1020,
    },
    hideBtnFooter: false, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '3',
        title: `
         Read the <span style="font-weight:bold; color:rgb(66,153,210)">Writing Strategy</span>  and do the tasks below
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div style="display: flex;flex-direction:column">
					<div style="display: flex;flex-direction:row">
					<div style="margin-bottom:10px"><img src="img/FriendsPlus/Page105/E3/1.png" style="width: 600px" /></div>
							<div style="margin-bottom:10px"><img src="img/FriendsPlus/Page105/E3/2.png" style="width: 600px" /></div>
			
					</div>
            <div><span style="font-weight:bold; margin-right:12px">1</span> Divide the letter into six paragraphs</div>
					  <div style="display:flex;flex-direction:row"><strong style="margin-left:30px">Paragraph 1: </strong>  <textarea id="0" row="1"></textarea></div> 
				 <div style="display:flex;flex-direction:row"> <strong style="margin-left:30px">Paragraph 2: </strong> <textarea id="1" row="1"></textarea></div>		
						 <div style="display:flex;flex-direction:row"><strong style="margin-left:30px">Paragraph 3: </strong> <textarea id="2" row="1"></textarea></div> 	
						 <div style="display:flex;flex-direction:row"> <strong style="margin-left:30px">Paragraph 4: </strong> 	<textarea id="3" row="1"></textarea></div>
						 <div style="display:flex;flex-direction:row">  <strong style="margin-left:30px">Paragraph 5: </strong><textarea id="4" row="1"></textarea>	</div>	
							 <div style="display:flex;flex-direction:row">  	<strong style="margin-left:30px">Paragraph 6: </strong><textarea id="5" row="1"></textarea></div>
						  <div><span style="font-weight:bold; margin-right:12px">2</span>Find three examples of short forms. Correct them.</div>
										<textarea id="6" row="0"></textarea>
							  <div ><span style="font-weight:bold; margin-right:12px">3</span>Find words and phrases in the letter which would be betterexpressed with these more formal words and phrases:
								</div>
					   <textarea id="7" row="2"></textarea>

<span style="width:1200px;color: rgb(66,153,210; margin-left:20px; font-weight:500">
<span> friends I would be grateful if you could inform me </span>
<span>I would certainly be interested receiving a reply</span>
<span>recommendations</span>
</span>


               
</div>
			<div ><span style="font-weight:bold; margin-right:12px">4</span>Find words and phrases in the letter which express these
things in a more formal way:
<span style="display:flex;flex-direction:column;color:rgb(66,153,210); margin-left:20px; font-weight:500">
<span>ask about soon sort out tell me about </span>
	   <textarea id="8" row="1"></textarea>

</span>
</div>


           
          </div>

        
        
				`,
        answer: [
          'I am writing to enquire about a visit to your youth hostel in Bristol next month. ',
          "I'll be travelling to Bristol with three mates from school. All four of us are female and aged seventeen or eighteen.",
          "We're planning to stay for seven nights between 10 and 17 August. Please tell me whether you have beds available for those dates.",
          "I'd also like to know whether your youth hostel has a café or restaurant. If not, would it be possible to recommend any places to eat near the hostel which are not too expensive?",
          "This will be our first visit to Bristol. Although I have read about the city on the internet, I'd love to hear any tips for places to visit in the city.",
          'I look forward to getting an answer from you in due course so that we can finalise the details of our visit.',
          "I'll be = I will be; We're = We are; I'd = I would",
          "mates = friends; Please tell me whether = I'd be grateful if you could inform me; I'd love to + I would certainly be interested; getting an answer = receiving a reply; tips = recommendations",
          'enquire about = ask about; in due course = soon; finalise = sort out; recommend = tell me about',
        ],
      },
    ],
  },
  // Thiếu file audio: 3.23
  4: {
    unit: 'Unit 8',
    id: 'FGF11-U8-P105-E4',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page105/E4/Key/answerKey.png',
    component: T6,
    inputSize: 40,
    textareaStyle: { width: 700 },
    hideBtnFooter: true,
    maxLength: 1,
    titleQuestion: [
      {
        num: '4',
        title: `
				Read the task above. Make a paragraph plan and include
brief notes for each of the four points in the task.
        `,
        color: 'rgb(0, 111, 147)',
      },
    ],
    questions: [
      {
        title: `
				
					<div style="display:flex;">
						
						
						<div>
						
						<div style="display:flex;flex-direction:row">	<span style="font-weight:bold;margin-right:10px">Paragraph 1:</span> <span>Giving reason for writing</span> </div>
							<div style="display:flex;flex-direction:row">		<span style="font-weight:bold;margin-right:10px">Paragraph 2:</span> <span>First point in task
								</span> </div>
								<div style="display:flex;flex-direction:row"> 	<span style="font-weight:bold;margin-right:10px">Paragraph 3:</span> <span>Second point in task
									</span></div>
									<div style="display:flex;flex-direction:row">	<span style="font-weight:bold;margin-right:10px">Paragraph 4:</span><span>Third point in task</span> </div>
										<div style="display:flex;flex-direction:row"> 			<span style="font-weight:bold;margin-right:10px">Paragraph 5:</span><span> Fourth point in task</span></div>
											<div style="display:flex;flex-direction:row">		<span style="font-weight:bold;margin-right:10px">Paragraph 6:</span><span> Requesting a reply</span> </div>
						</div>
					
						<div style="margin-bottom:10px"><img src="img/FriendsPlus/Page105/E4/1.png" style="width: 650px;margin-left:20px" /></div>
								
						
							
		
					</div>
					<div style='border: 2px solid black;padding: 10px; width:750px;'>
						<i><u>Answer:</u></i>
						<textarea id='0' rows='6'></textarea>
						</div>
							
				`,
        answer: [],
      },
    ],
  },
  5: {
    unit: 'Unit 8',
    id: 'FGF11-U8-P105-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page105/E5/Key/answerKey.png',
    component: T6,
    inputSize: 40,
    maxLength: 1000,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    textareaStyle: { width: 1000 },
    titleQuestion: [
      {
        num: '5',
        title: `
					Write your letter (150–180 words) using your notes from
exercise 4. Remember to follow the advice in the strategy.
        `,
        color: 'rgb(0, 111, 147)',
      },
    ],
    questions: [
      {
        title: `
					<div style="display: flex;flex-direction:column">
						<div style="margin-bottom:10px"><img src="img/FriendsPlus/Page105/E5/1.png" style="width: 700px" /></div>
						<textarea id="0" rows="8"></textarea>						
					</div>
				`,
        answer: [],
      },
    ],
  },
  6: {
    unit: 'Unit 8',
    id: 'FGF11-U8-P105-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page105/E6/Key/answerKey.png',
    component: T6,
    inputSize: 40,
    maxLength: 1000,
    textareaStyle: { width: 700 },
    //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '6',
        title: `
					Read the task below and write the letter.
        `,
        color: 'rgb(0, 111, 147)',
      },
    ],
    questions: [
      {
        title: `
					<div style="display: flex;">
						<div style="flex: 1; padding: 10px 20px; border-radius: 10px; background-color: rgb(229, 240, 203);">
							You ordered a tablet online 3 weeks ago and it arrived yesterday. 
							Unfortunately, you were not satisfied with the product. 
							Write a letter of complaint (120—150 words).
						</div>

						<div style="margin-left: 20px; flex: 2; padding: 10px 20px; border-radius: 10px; border: 1px solid rgb(209, 223, 242);">
							<textarea id="0" rows="7"></textarea>
						</div>
					</div>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
